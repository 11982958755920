import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ListGroup,
  ListGroupItem,
  ModalFooter,
  Button,
} from "reactstrap";
import { formatCurrencyValue } from "../../helper-methods";
import { getConfiguredString } from "../../helper-methods/configureString";

const FanCostBreakdownModal = ({ isOpen, toggle, category }) => {
  const _closeModal = () => {
    toggle();
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={_closeModal}
      className="modal-dialog-centered modal-dialog-scrollable"
    >
      <ModalHeader toggle={_closeModal}>Category Wise Earnings</ModalHeader>

      <ModalBody>
        <ListGroup className="earningsInDetailWrap">
          <ListGroupItem>
            Messages
            <div>{formatCurrencyValue(category?.message || 0)}</div>
          </ListGroupItem>
          <ListGroupItem>
            Posts
            <div>{formatCurrencyValue(category?.post || 0)}</div>
          </ListGroupItem>
          <ListGroupItem>
            {getConfiguredString("Vault")}
            <div>{formatCurrencyValue(category?.vault || 0)}</div>
          </ListGroupItem>
          <ListGroupItem>
            {getConfiguredString("PPV")}
            <div>{formatCurrencyValue(category?.payperview || 0)}</div>
          </ListGroupItem>
          <ListGroupItem>
            {getConfiguredString("event")}
            <div>{formatCurrencyValue(category?.liveEvent || 0)}</div>
          </ListGroupItem>
          <ListGroupItem>
            {getConfiguredString("shoutout")}
            <div>{formatCurrencyValue(category?.shoutout || 0)}</div>
          </ListGroupItem>
          <ListGroupItem>
            Tip
            <div>{formatCurrencyValue(category?.tips || 0)}</div>
          </ListGroupItem>
          <ListGroupItem>
            Subscription
            <div>{formatCurrencyValue(category?.subscription || 0)}</div>
          </ListGroupItem>
        </ListGroup>
      </ModalBody>
      <ModalFooter>
        <Button className="modalBtnCancel" onClick={_closeModal}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default FanCostBreakdownModal;
