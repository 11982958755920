import React from "react";
import {
  APP_NAME,
  DEFAULT_PROFILE_PICTURE,
  FRONTEND_FAN_BASE_URL,
} from "../config";
import { Button, ListGroupItem } from "reactstrap";
import {
  errorHandler,
  formatCurrencyValue,
  formatTimeFromNow,
  getLowResolutionLink,
  reactHtmlParser,
} from "../helper-methods";
import { useHistory } from "react-router-dom";
import CustomTooltip from "./custom/CustomTooltip";
import { readSingleNotification } from "../http-calls";
import { useDispatch, useSelector } from "react-redux";
import { markReadSingleNotification } from "../redux/actions";
import ReadMoreReadLess from "./custom/ReadMoreReadLess";

const NotificationItem = ({
  isReadAll = false,
  notification,
  lastElementRef,
  prevDateHeading,
  currDateHeading,
}) => {
  const history = useHistory();

  const dispatch = useDispatch();

  const { unreadNotificationCount } = useSelector(
    (state) => state?.notificationData || {}
  );

  const _onUserNameClick = (e, notification) => {
    try {
      if (e) e.stopPropagation();

      if (notification?._from?.userType === "Fan") {
        const username = notification?._from?.username;
        history.push(`/my-profile?tabId=4&username=${username}`);
      } else if (notification?._from?.userType === "Influencer") {
        window.open(
          `${FRONTEND_FAN_BASE_URL}/${notification?._from?.username}`,
          "_blank"
        );
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const _readSingleNotification = (notification) => {
    try {
      if (!notification?._id || notification?.isRead) return;

      readSingleNotification(notification?._id);

      if (unreadNotificationCount) {
        dispatch(markReadSingleNotification(notification?._id));
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const _onNotificationClick = (notification) => {
    _readSingleNotification(notification);

    switch (notification?.category) {
      case "post": {
        history.push(
          `/post/${notification?._post?._id || notification?._post}`
        );
        break;
      }
      case "event": {
        history.push(
          `/live-events/${notification?._event?._id || notification?._event}`
        );
        break;
      }
      case "ppv": {
        history.push(
          `/ppv/${notification?._payperview?._id || notification?._payperview}`
        );
        break;
      }
      case "vault": {
        history.push(`/vault/folder/${notification?._folder}`);
        break;
      }
      case "withdrawal": {
        history.push("/statement");
        break;
      }
      case "alert": {
        if (notification?._post?._id || notification?._post) {
          history.push(
            `/post/${notification?._post?._id || notification?._post}`
          );
        }
        break;
      }
      case "subscription":
      case "referral":
      case "tips": {
        _onUserNameClick(null, notification);
        break;
      }
      default: {
        if (notification?._message?._thread) {
          history.push(`/messages?threadId=${notification?._message?._thread}`);
        } else if (notification?._interractionId?._postId) {
          history.push(`/post/${notification?._interractionId?._postId}`);
        }
        break;
      }
    }
  };

  const _formatNotificationDescription = (notification) => {
    try {
      if (!notification?.title) return "";

      let title = reactHtmlParser(notification?.title);

      if (notification?._from?.username) {
        if (
          notification?.title?.includes(`@${notification?._from?.username}`)
        ) {
          title = title.replace(`@${notification?._from?.username}`, "");
        } else if (
          notification?.title?.includes(`${notification?._from?.username}`)
        ) {
          title = title.replace(`${notification?._from?.username}`, "");
        }
      }

      return title?.trim();
    } catch (error) {
      return "";
    }
  };

  return (
    <>
      {currDateHeading !== prevDateHeading ? (
        <p className="notificationDay">{currDateHeading}</p>
      ) : null}

      <ListGroupItem
        className={
          isReadAll || notification?.isRead
            ? "notificationsWrap"
            : "notificationsWrap highlightRow"
        }
      >
        <div className="notifications" ref={lastElementRef}>
          <img
            src={
              getLowResolutionLink(notification?._from?.profilePicture) ||
              DEFAULT_PROFILE_PICTURE
            }
            onError={(e) => (e.target.src = DEFAULT_PROFILE_PICTURE)}
            alt="Profile Img"
            className="userImg cursorPointer"
            loading="lazy"
            onClick={() => _onNotificationClick(notification)}
          />
          <div>
            {/* on clicking the below anchor link, it should take it to the user's profile page  */}
            {notification?.byAdmin ? (
              <span className="d-inline-block">
                {APP_NAME}-Admin{" "}
                <Button color="link" className="profileID" style={{verticalAlign: 0}}>
                  @Admin
                </Button>
              </span>
            ) : (
              <Button
                color="link"
                onClick={(e) => _onUserNameClick(e, notification)}
                className="profileID"
                style={{verticalAlign: 0}}
              >
                {notification?._from?.username?.length
                  ? `@` + notification?._from?.username
                  : null}
              </Button>
            )}
            <span className="d-inline-block"
              style={{marginLeft: 2}}
              onClick={() => _onNotificationClick(notification)}
            >
              {notification?._transaction?.amount &&
              (notification?.category === "tips" ||
                notification?.category === "ppv") ? (
                <>
                  Paid {formatCurrencyValue(notification?._transaction?.amount)}{" "}
                  for your content
                </>
              ) : (
                <ReadMoreReadLess
                  text={_formatNotificationDescription(notification)}
                />
              )}
            </span>
            {notification?.alertSubject ? (
              <>
                <sup
                  id={`notification_item_alert_${notification?._id}`}
                  className="infoIcon"
                >
                  <i className="fa fa-info-circle" />
                </sup>
                <CustomTooltip
                  text={
                    <div>
                      <div>
                        <b>Reason:</b> <span>{notification?.alertSubject}</span>
                      </div>
                      {notification?.alertText && (
                        <>
                          <div className="mt-1">
                            <b>Describe:</b>{" "}
                            <span>{notification?.alertText}</span>
                          </div>
                        </>
                      )}
                    </div>
                  }
                  target={`notification_item_alert_${notification?._id}`}
                />
              </>
            ) : null}
            <span className="commentTime" style={{ color: "#999" }}>
              {notification?.createdAt
                ? formatTimeFromNow(notification?.createdAt)
                : "N/A"}
            </span>
          </div>
        </div>
      </ListGroupItem>
    </>
  );
};

export default NotificationItem;
