import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  Row,
  Spinner,
  FormGroup,
} from "reactstrap";
import {
  DEFAULT_COVER_PICTURE,
  DEFAULT_PROFILE_PICTURE,
} from "../config/index";
import {
  deepClone,
  getLowResolutionLink,
  handleNameObj,
  showToast,
} from "../helper-methods";
import { getPublicInfluencerDetails } from "../http-calls/index";
import { showLoader, hideLoader } from "../redux/actions/loaderData";
import InfiniteScroll from "react-infinite-scroller";
import PublicFeedViewer from "../components/PublicFeedViewer";
import { APP_NAME } from "../config/index";

class NonSubscriberFeedViewer extends Component {
  state = {
    influencer: null,
    posts: [],
    activeTab: new Array(5).fill("1"),
    popovers: [
      {
        placement: "bottom",
        text: "Bottom",
      },
    ],
    activeIndex: 0,
    custom: [false],
    dropdownOpen: new Array(6).fill(false),
    isFilterPopoverOpen: false,
    activeFilterOption: "allTime", // Other options:  ['last90days', 'last30days', 'last7Days']
    activeSortOption: "latestPosts", // Other options:  ['mostLiked', 'highestTips'],
    filterOptions: [
      {
        key: "allTime",
        label: "All Time",
      },
      {
        key: "last90days",
        label: "Last 90 Days",
      },
      {
        key: "last30days",
        label: "Last 30 Days",
      },
      {
        key: "last7Days",
        label: "Last 7 Days",
      },
    ],
    sortOptions: [
      {
        key: "latestPosts",
        label: "Latest Posts",
      },
      {
        key: "mostLiked",
        label: "Most Liked",
      },
      {
        key: "highestTips",
        label: "Highest Tips",
      },
    ],
    filteredAndSortedPosts: [],
    formattedPosts: [],
    subscriptionData: {
      isSubscribed: false,
      isSubscriptionExpired: false,
    },
    viewAsValue: "",
    feedPagination: {
      skip: 0,
      limit: 20,
    },
    loadMore: false,
  };

  // constructor(props) {
  //   super(props);
  // }

  componentDidMount() {
    this.props.showLoader("Loading non-subscriber view");
    this._getInfluencerDetails();
  }

  _getInfluencerDetails = () => {
    const { posts, feedPagination } = deepClone(this.state);
    feedPagination["skip"] = posts && posts.length ? posts.length : 0;

    getPublicInfluencerDetails(
      this.props.userData.user.username,
      feedPagination
    )
      .then((res) => {
        if (!res.error) {
          if (res.influencer) {
            this.setState(
              {
                influencer: res.influencer,
                posts:
                  posts && posts.length ? posts.concat(res.posts) : res.posts,
                subscriptionData: {
                  isSubscribed: res.isSubscribed,
                  isSubscriptionExpired: res.isSubscriptionExpired,
                },
                totalFeed: res.total,
                loadMore: false,
              },
              () => {
                this.props.hideLoader();
              }
            );
          }
        }
      })
      .catch((err) => {
        this.props.history.replace("/feed");
        showToast(
          err.reason && err.reason.length
            ? err.reason.indexOf("Cast to ObjectId failed for value") > -1
              ? "No influencer found"
              : err.reason
            : "Server Error. Try again after sometime.",
          "error"
        );
        this.props.hideLoader();
      });
  };

  _selectPollOption = (feed, option) => {
    this.props.showLoader("Submitting your answer");
    // const data = {
    //   postId: feed._id,
    //   optionId: option._id
    // };
    // givenPollOption(data)
    //   .then(resp => {
    //     showToast("Your answer registered successfully", "success");
    //     this.props.hideLoader();
    //     this._getInfluencerDetails();
    //   })
    //   .catch(err => {
    //     this.props.hideLoader();
    //     showToast(
    //       err.reason && err.reason.length
    //         ? err.reason
    //         : "Server error. Try again after sometime.",
    //       "error"
    //     );
    //   });
  };

  _scrollToTop = () => {
    if (this.nonSubscriberWindowView) {
      this.nonSubscriberWindowView.scroll({
        top: 0,
        left: 0,
        // behavior: "smooth",
      });
    }
  };

  toggle(tabPane, tab) {
    const newArray = this.state.activeTab.slice();
    newArray[tabPane] = tab;
    this.setState({
      activeTab: newArray,
      popoverOpen: !this.state.popoverOpen,
    });
  }

  toggleCustom(tab) {
    const prevState = this.state.custom;
    const state = prevState.map((x, index) => (tab === index ? !x : false));

    this.setState({
      custom: state,
    });
  }

  _pinToProfile = (id, isPinned) => {
    console.log("TCL: NonSubscriberFeedViewer -> _pinToProfile -> id", id);
    // editPost({ isPinned }, id)
    //   .then(response => {
    //     this._scrollToTop();
    //     this.props.getPosts();
    //   })
    //   .catch(err => {
    //     showToast(
    //       err.reason && err.reason.length
    //         ? err.reason
    //         : "Server error. Try again after sometime.",
    //       "error"
    //     );
    //   });
  };

  _deletePost = (feed, index) => {
    console.log("TCL: NonSubscriberFeedViewer -> _deletePost -> feed", feed);
    // deletPost(feed.id)
    //   .then(resp => {
    //     showToast("Deleted Successfully", "success");
    //     this.props.getPosts();
    //   })
    //   .catch(err => {
    //     showToast(
    //       err.reason && err.reason.length
    //         ? err.reason
    //         : "Server error. Try again after sometime.",
    //       "error"
    //     );
    //   });
  };

  _editProfile = () => {
    // this.props.history.push("./settings");
  };

  _toggleFilterPopover = () => {
    this.setState({
      isFilterPopoverOpen: !this.state.isFilterPopoverOpen,
    });
  };

  _changeSortBy = (newSortOption) => {
    this._toggleFilterPopover();
    this.setState({ activeSortOption: newSortOption }, () => {
      this._filterAndSort();
    });
  };

  _changeFilterBy = (newFilterSortOption) => {
    this._toggleFilterPopover();
    this.setState({ activeFilterOption: newFilterSortOption }, () => {
      this._filterAndSort();
    });
  };

  _filterAndSort = () => {
    return new Promise((resolve, reject) => {
      const { formattedPosts, activeFilterOption, activeSortOption } =
        deepClone(this.state);
      if (formattedPosts.length) {
        // Filter
        let filterdPosts = formattedPosts;
        switch (activeFilterOption) {
          case "last90days":
            filterdPosts = formattedPosts.filter((formattedPost) => {
              return this._isPostNotOlderThan(90, formattedPost.createdAt);
            });
            break;
          case "last30days":
            filterdPosts = formattedPosts.filter((formattedPost) => {
              return this._isPostNotOlderThan(30, formattedPost.createdAt);
            });
            break;
          case "last7Days":
            filterdPosts = formattedPosts.filter((formattedPost) => {
              return this._isPostNotOlderThan(7, formattedPost.createdAt);
            });
            break;
          default:
            filterdPosts = formattedPosts;
            break;
        }
        // Sort
        let sortedPosts = filterdPosts;
        switch (activeSortOption) {
          case "latestPosts":
            sortedPosts = filterdPosts;
            break;
          case "mostLiked":
            sortedPosts = filterdPosts.sort(
              (post1, post2) => post2.likes - post1.likes
            );
            break;
          case "highestTips":
            sortedPosts = filterdPosts.sort(
              (post1, post2) => post2.totalTipAmount - post1.totalTipAmount
            );
            break;
          default:
            break;
        }
        this.setState({ filteredAndSortedPosts: sortedPosts }, () => {
          resolve();
        });
      }
    });
  };

  _isPostNotOlderThan = (allowedDiffDays, postDate) => {
    const currentDate = new Date();
    const diffTime = Math.abs(currentDate - new Date(postDate));
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (diffDays <= allowedDiffDays) {
      return true;
    } else {
      return false;
    }
  };

  // async componentDidMount() {
  //   await this._formatPosts();
  //   this._filterAndSort();
  // }

  // async componentDidUpdate(prevProps, prevState) {
  //   if (JSON.stringify(this.props.feeds) !== JSON.stringify(prevProps.feeds)) {
  //     await this._formatPosts();
  //     this._filterAndSort();
  //   }
  // }

  _formatPosts = () => {
    return new Promise((resolve, reject) => {
      const { feeds: posts } = this.props;
      let formattedPosts = [];
      if (posts) {
        formattedPosts = posts.map((post) => {
          // Generate total tip amount
          let totalTipAmount = 0;
          if (post._transactions && post._transactions.length) {
            post._transactions.forEach((transaction) => {
              if (transaction.paymentType === "tips") {
                totalTipAmount += transaction.amount;
              }
            });
          }
          return { ...post, totalTipAmount };
        });
      }

      this.setState({ formattedPosts }, () => {
        resolve();
      });
    });
  };

  _subscribe = () => {
    return;
    // const redirectTo = `/subscribe?influencerId=${this.props.match.params.id}`;
    // this.props.history.push("/register?redirectTo=" + redirectTo);
    // routerRef.push(`/subscribe?influencerId=${influencer.username}`);
  };

  _redirectToCreatorView = () => {
    this.props.showLoader("Redirecting to your view");
    this.props.history.push("feed");
  };

  _changeViewAs = (viewAsValue) => {
    this.props.showLoader("Redirecting to your view");
    this.setState({ viewAsValue }, () => {
      switch (viewAsValue) {
        case "creator": {
          this.props.history.push("feed");
          break;
        }
        case "subscriber": {
          this.props.history.push("subscriber-view");
          break;
        }
        default:
          break;
      }
    });
  };

  _loadMoreFeeds = () => {
    if (!this.state.loadMore) {
      this.setState(
        {
          loadMore: true,
        },
        () => {
          this._getInfluencerDetails();
        }
      );
    }
    return;
  };

  render() {
    const {
      posts,
      influencer,
      // subscriptionData,
      viewAsValue,
      feedPagination,
      totalFeed,
      loadMore,
    } = this.state;

    return (
      <>
        <div
          className="app scPgBg animated fadeIn"
          ref={(ref) => (this.nonSubscriberWindowView = ref)}
        >
          <InfiniteScroll
            pageStart={feedPagination.skip}
            loadMore={this._loadMoreFeeds}
            hasMore={
              this.state.activeTab[0] === "1" &&
              (posts && posts.length) < totalFeed
                ? true || false
                : false
            }
            loader={
              loadMore && posts && posts.length < totalFeed ? (
                <div className="spinnerWrap " key={0}>
                  <Spinner style={{ width: "1.8rem", height: "1.8rem" }} />{" "}
                </div>
              ) : null
            }
            useWindow={false}
          >
            <Container className="noPadding">
              <Row className="mb-2 justify-content-center mt-3 noMargin">
                <Col sm={12} md={11} lg={9} xl={8} className="noPadding">
                  {/* <Button onClick={() => this._redirectToCreatorView()} className="creatorView">
                  <i className="fa fa-chevron-left"/> Creator View
                </Button> */}

                  {/* <div className="subscriberViewWrap mb-3">
                    <Label>
                      View As
                      :{" "}
                    </Label>
                    

                    <Button onClick={() => this._redirectToSubscriberView()}>
                    {" "}
                    Subscriber View
                  </Button>
                  <Button onClick={() => this._redirectToNonSubscriberView()}>
                    Non-Subscriber View
                  </Button>
                  </div> */}

                  <div className="subscriberViewTxt">Non-Subscriber View</div>

                  <div className="d-flex justify-content-center justify-content-sm-end">
                    <FormGroup className="mb-3 mb-sm-0">
                      <Input
                        type="select"
                        name="view"
                        id="view"
                        value={viewAsValue}
                        onChange={(e) => this._changeViewAs(e.target.value)}
                      >
                        <option value="">View As</option>
                        <option value="creator">Creator</option>
                        <option value="subscriber">Subscriber</option>
                      </Input>
                    </FormGroup>
                  </div>
                  {/* user profile card  */}

                  <Card className="cardFeed profileCard">
                    <CardHeader>
                      <div className="coverImgWrap">
                        <img
                          className="coverImg"
                          src={
                            getLowResolutionLink(influencer?.coverPicture) ||
                            DEFAULT_COVER_PICTURE
                          }
                          alt="Cover Img"
                          loading="lazy"
                        />
                      </div>

                      <div className="influencerDetailsWrap">
                        <div className="d-flex justify-content-start align-items-start">
                          <div className="img100by100Wrap">
                            <img
                              src={
                                getLowResolutionLink(
                                  influencer?.profilePicture
                                ) || DEFAULT_PROFILE_PICTURE
                              }
                              onError={(e) =>
                                (e.target.src = DEFAULT_PROFILE_PICTURE)
                              }
                              alt="Profile Img"
                              className="img100by100"
                              loading="lazy"
                            />
                          </div>
                          <div className="influencerName">
                            <div className="d-flex justify-content-start align-items-center">
                              <h4>
                                {handleNameObj(influencer?.name) || "N/A"}
                              </h4>
                              {influencer?.username?.length ? (
                                <Button color="link" className="profileID">
                                  @{influencer.username}
                                </Button>
                              ) : null}
                            </div>

                            <p>
                              <i className="icon-location-pin" />
                              {influencer?.address?.country || "N/A"}
                            </p>

                            {influencer?.settings?.showFanCountOnProfile &&
                            influencer?._subCount > 0 ? (
                              <p>
                                <i className="fa fa-users" />
                                Subscriber : {influencer._subCount}
                              </p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </CardHeader>
                    <CardBody className="profileDescWrap">
                      <p className="profileDesc">
                        {influencer?.introduction?.length
                          ? influencer.introduction
                          : `Welcome to my ${APP_NAME} page! Subscribe to unlock all of my exclusive content!`}{" "}
                        {/* <i
                            className={
                              custom[0] ? "icon-arrow-up ml-2" : "icon-arrow-down ml-2"
                            }
                            style={{ marginTop: "2px" }}
                          /> */}
                      </p>
                    </CardBody>
                  </Card>

                  <div className="tabsWrap-InfluencerDetails nonSubscriberView">
                    <h4>Post</h4>
                    <Button
                      className="subscribeInfBtn"
                      disabled={!influencer?.paymentEnabled}
                      onClick={() => this._subscribe()}
                    >
                      Subscribe
                    </Button>
                    {/* 
                    <div>
                      <Button
                        id="Popover1"
                        className="customPopoverBtn"
                        onClick={this._toggleFilterPopover}
                      >
                        <img
                          src={"/assets/img/sort-icon.png"}
                          alt="Sort Icon"
                          style={{ height: "15px" }}
                          loading="lazy"
                        />
                      </Button>
                      <UncontrolledPopover
                        className="customPopover"
                        placement="bottom"
                        target="Popover1"
                        trigger="legacy"
                        isOpen={this.state.isFilterPopoverOpen}
                        toggle={this._toggleFilterPopover}
                      >
                        <PopoverBody>
                          {filterOptions.map((filterOption, filterOptionIndex) => (
                            <FormGroup
                              check
                              key={filterOptionIndex}
                              className="radio"
                              onClick={() => this._changeFilterBy(filterOption.key)}
                            >
                              <Input
                                className="form-check-input"
                                type="radio"
                                name="filterBy"
                                checked={activeFilterOption === filterOption.key}
                                value={filterOption.key}
                                onChange={() => {}}
                              />
                              <Label
                                check
                                className="form-check-label"
                                htmlFor="allTime"
                              >
                                {filterOption.label}
                              </Label>
                            </FormGroup>
                          ))}
                          <DropdownItem divider />
                          {sortOptions.map((sortOption, sortOptionIndex) => (
                            <FormGroup
                              check
                              key={sortOptionIndex}
                              className="radio"
                              onClick={() => this._changeSortBy(sortOption.key)}
                            >
                              <Input
                                className="form-check-input"
                                type="radio"
                                name="sortBy"
                                checked={activeSortOption === sortOption.key}
                                value={sortOption.key}
                                onChange={() => {}}
                              />
                              <Label
                                check
                                className="form-check-label"
                                htmlFor="allTime"
                              >
                                {sortOption.label}
                              </Label>
                            </FormGroup>
                          ))}
                        </PopoverBody>
                      </UncontrolledPopover>
                    </div> */}
                  </div>

                  {/* on clicking the above search btn, the below search bar is to be shown */}
                  <InputGroup
                    className="position-relative d-none"
                    style={{ margin: "15px 0" }}
                  >
                    <Input
                      id="appendedInputButton"
                      size="16"
                      type="text"
                      placeholder="Search"
                      style={{ paddingLeft: "30px" }}
                    />
                    <InputGroupAddon addonType="append">
                      {/* on clicking the below close btn, this search bar gets hidden */}
                      <Button
                        color="secondary"
                        style={{
                          borderTopRightRadius: "0.25rem",
                          borderBottomRightRadius: "0.25rem",
                        }}
                      >
                        <i
                          className="icon-close"
                          style={{ color: "#333", fontSize: "15px" }}
                        />
                      </Button>
                    </InputGroupAddon>

                    <i className="fa fa-search searchSubscriberIcon" />
                  </InputGroup>
                  {/* search bar end */}

                  {/* multiple posts/feeds */}
                  {posts && posts.length ? (
                    posts.map((feed, index) => (
                      <PublicFeedViewer
                        key={index}
                        feed={feed}
                        routerRef={this.props.history}
                        scrollToTop={this._scrollToTop}
                        username={this.props.match.params.id}
                        {...this.props}
                      />
                    ))
                  ) : (
                    <label>No feeds yet</label>
                  )}
                </Col>
              </Row>
            </Container>
          </InfiniteScroll>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (loaderText) => dispatch(showLoader(loaderText)),
    hideLoader: () => dispatch(hideLoader()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NonSubscriberFeedViewer);
