import React from "react";
import Skeleton from "react-loading-skeleton";
import { ListGroupItem } from "reactstrap";

const SkeletonRefferalList = ({ count }) => {
  return (
    <>
      {[...Array(count)].map((_, index) => (
        <ListGroupItem key={index}>
          <div className="d-flex">
            <div className="referralProfile">
              <Skeleton circle height={40} width={40} className="mr-2" />
            </div>

            <div className="referralInfo">
              <h5>
                <Skeleton height={15} width={80} />
              </h5>

              <p>
                <Skeleton height={15} width={80} />
              </p>
            </div>
          </div>

          <div className="d-flex align-items-end flex-column">
            <Skeleton height={20} width={45} borderRadius={5} />
            <Skeleton height={20} width={75} borderRadius={5} />
          </div>
        </ListGroupItem>
      ))}
    </>
  );
};

export default SkeletonRefferalList;
