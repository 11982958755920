import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "../node_modules/@coreui/coreui/dist/css/coreui.min.css";
import "./assets/styles/scss/App.scss";
import ProtectedRoute from "./components/ProtectedRoute";
import FullPageLoader from "./containers/FullPageLoader";
import PublicRoute from "./components/PublicRoute";
import SignupPublicPage from "./pages/SignupPublicPage";
import SignupProtectedPage from "./pages/SignupProtectedPage";
import { Toaster } from "react-hot-toast";
import ScrollHandler from "./ScrollHandler";
import ErrorNotFound from "./components/ErrorNotFound";
import RequestInvitePage from "./pages/RequestInvitePage";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import AddSubscriptionPrice from "./pages/AddSubscriptionPrice";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import { loadStripe } from "@stripe/stripe-js";
import {
  //  APP_LOGO_ICON,
  ONE_SIGNAL_APP_ID,
  STRIPE_API_KEY,
} from "./config";
import { Elements as StripeElements } from "@stripe/react-stripe-js";
import runOneSignal from "./one-signal";

const stripePromise = loadStripe(STRIPE_API_KEY);

const App = () => {
  if (process.env.REACT_APP_BACKEND_ENV === "prod") {
    console.log = () => {};
    document.addEventListener("contextmenu", (event) => event.preventDefault());
    document.onkeydown = function (event) {
      // c/C => 67 /,/ i/I => 73 /,/ u/U => 85 /,/ p/P => 80 /,/ F12 => 123 //
      const key = event.key ? event.key.toLowerCase() : null;
      if (
        key &&
        (key === "f12" ||
          (event.ctrlKey && (key === "i" || key === "u" || key === "p")))
      ) {
        return false;
      }
      return true;
    };
  }

  // const _setupTitleAndIcon = () => {
  //   try {
  //     document.title = process.env.REACT_APP_NAME;

  //     let link = document.querySelector("link[rel~='icon']");
  //     if (!link) {
  //       link = document.createElement("link");
  //       link.rel = "icon";
  //       document.getElementsByTagName("head")[0].appendChild(link);
  //     }
  //     link.href = APP_LOGO_ICON;
  //   } catch (error) {
  //     console.log({ error });
  //   }
  // };

  useEffect(() => {
    runOneSignal({ appId: ONE_SIGNAL_APP_ID });
  }, []);

  return (
    <StripeElements stripe={stripePromise}>
      <Router>
        <div data-test="component-app">
          <ScrollHandler />
          <FullPageLoader />
          <Toaster position="bottom-right" reverseOrder={false} />
          <Switch>
            <Route exact path="/login" component={LoginPage} />
            <PublicRoute
              exact
              path="/forgot-password"
              // component={ForgotPasswordPage}
              component={ForgotPasswordPage}
              redirectRoute="/feed"
            />
            <PublicRoute
              exact
              path="/signup/:refToken"
              component={SignupPublicPage}
              redirectRoute="/feed"
            />
            <PublicRoute
              exact
              path="/request-invite"
              component={RequestInvitePage}
              redirectRoute="/feed"
            />

            <ProtectedRoute
              exact
              path="/update-profile"
              component={SignupProtectedPage}
              redirectRoute="/login"
            />

            <ProtectedRoute
              exact
              path="/initialize-account"
              component={AddSubscriptionPrice}
              redirectRoute="/login"
            />

            <ProtectedRoute
              exact
              path="*"
              component={HomePage}
              redirectRoute="/login"
            />
            <Route component={ErrorNotFound} />
          </Switch>
        </div>
      </Router>
    </StripeElements>
  );
};

export default App;
