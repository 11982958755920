import React from "react";
import Skeleton from "react-loading-skeleton";

const SkeletonChat = ({ count = 2 }) => {
  return (
    <>
      {[...Array(count)].map((_, index) => (
        <div className="mb-3">
          <div className="d-flex justify-content-center mb-3">
            <Skeleton height={14} width={50} />
          </div>

          {/* sent 2 msz */}
          <div className="">
            {[...Array(2)].map((_, index) => (
              <Skeleton
                height={25}
                width={Math.floor(Math.random() * 100 + 90)}
                borderRadius={12}
                key={`sent-${index}`}
              />
            ))}
          </div>

          {/* recieved 3 msz */}
          <div className="">
            {[...Array(3)].map((_, index) => (
              <Skeleton
                height={25}
                width={Math.floor(Math.random() * 100 + 90)}
                borderRadius={12}
                key={`recieve-${index}`}
              />
            ))}
          </div>
        </div>
      ))}
    </>
  );
};

export default SkeletonChat;
