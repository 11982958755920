import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Row,
  Col,
  Label,
  Input,
  Form,
  FormGroup,
  CustomInput,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import ReactDatetime from "react-datetime";
import {
  showToast,
  TierAmountValidation,
  convertSecondsToHourMinSec,
  isSubscriptionPriceExist,
  uploadFileOnServer,
  errorHandler,
  isPermissionToAccess,
  getYesterdayDate,
  formatDateAndTime,
  extractQueryParams,
  isValidPrice,
  getRecentHourDate,
} from "../helper-methods";
import { showLoader, hideLoader } from "../redux/actions/loaderData";
import { createPPV, getPPV, editPPV } from "../http-calls";
import { CURRENCY_SYMBOL } from "../config";
import { getConfiguredString } from "../helper-methods/configureString";
import ConfigureString from "../components/ConfigureString";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AmountConfig } from "../config/appConfig";
import MediaLibraryModal from "../components/modals/MediaLibraryModal";

const CreatePayPerView = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const userData = useSelector((state) => state?.userData);

  const [formFields, setFormFields] = useState({
    title: "",
    description: "",
    coverImage: {
      previewBlob: null,
      uploadData: null,
      type: null,
      uploadUrl: null,
    },
    ppvVideo: {
      previewBlob: null,
      uploadData: null,
      type: null,
      uploadUrl: null,
    },
    ppvPreviewVideo: {
      previewBlob: null,
      uploadData: null,
      type: null,
      uploadUrl: null,
    },
    price: "",
    plusPrice: "",
    premiumPrice: "",
    scheduledAt: getRecentHourDate(),
  });

  const [duration, setDuration] = useState("");
  const [durationInHourMinSec, setDurationInHourMinSec] = useState("");
  const [ppvDetails, setPpvDetails] = useState(null);
  const [isDirty, setIsDirty] = useState({});
  const [errors, setErrors] = useState({});
  const [isPublishNow, setIsPublishNow] = useState(false);
  const [ppvPreviewVideoOption, setPpvPreviewVideoOption] = useState("upload"); // trim
  const [isCoverImageModalOpen, setIsCoverImageModalOpen] = useState(false);
  const [isPpvVideoModalOpen, setIsPpvVideoModalOpen] = useState(false);
  const [isPreviewVideoModalOpen, setIsPreviewVideoModalOpen] = useState(false);
  const [loadingState, setLoadingState] = useState({
    coverImage: false,
    ppvPreviewVideo: false,
    ppvVideo: false,
  });

  const _manageLoadingState = (key = "", value = false) => {
    setLoadingState((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const _togglePreviewVideoModal = () => {
    setIsPreviewVideoModalOpen((prev) => !prev);
  };

  const _toggleCoverImageModal = () => {
    setIsCoverImageModalOpen((prev) => !prev);
  };

  const _togglePpvVideoModal = () => {
    setIsPpvVideoModalOpen((prev) => !prev);
  };

  const _updateCoverImageVideoAndPreview = (key, file) => {
    let fileType = file?.[0]?.contentType || file?.[0]?.type;

    if (key === "coverImage" && fileType !== "image") {
      showToast("Only image file is allowed", "error");
      _toggleCoverImageModal();
      return;
    } else if (key === "ppvPreviewVideo" && fileType !== "video") {
      showToast("Only video file is allowed", "error");
      _togglePreviewVideoModal();
      return;
    } else if (
      key === "ppvVideo" &&
      fileType !== "video" &&
      fileType !== "audio"
    ) {
      showToast("Only video and audio files is allowed", "error");
      _togglePpvVideoModal();
      return;
    }

    _manageLoadingState(key, true);

    const newFormFields = { ...formFields };

    if (key) {
      if (file?.[0]?.url) {
        newFormFields[key]["previewBlob"] = "";
        newFormFields[key]["uploadData"] = null;
        newFormFields[key]["type"] = fileType;
        newFormFields[key]["uploadUrl"] = file?.[0]?.url;
      } else {
        newFormFields[key] = file?.[0];
      }
    }

    setFormFields(newFormFields);

    if (key === "coverImage") {
      _toggleCoverImageModal();
    } else if (key === "ppvPreviewVideo") {
      _togglePreviewVideoModal();
    } else {
      _togglePpvVideoModal();
    }

    _manageLoadingState(key, false);
  };

  // prefill data in case of edit ppv
  const _setFormValue = (data) => {
    setFormFields({
      title: data?.title || "",
      description: data?.description || "",
      coverImage: {
        previewBlob: null,
        uploadData: null,
        type: "image",
        uploadUrl: data?.coverImage || null,
      },
      ppvVideo: {
        previewBlob: null,
        uploadData: null,
        type: data?.contentType || null,
        uploadUrl: data?.videoUrl || null,
      },
      ppvPreviewVideo: {
        previewBlob: null,
        uploadData: null,
        type: "video",
        uploadUrl: data?.previewVideoUrl || null,
      },
      price: data?.price ? data.price.toString() : "",
      plusPrice: data?.plusPrice ? data.plusPrice.toString() : "",
      premiumPrice: data?.premiumPrice ? data.premiumPrice.toString() : "",
      scheduledAt: data?.scheduledAt ? data.scheduledAt : "",
    });
    setPpvPreviewVideoOption(data?.ppvPreviewVideoOption || "upload");
    setIsPublishNow(data?.isPublished || false);
    setDuration(data?.duration || "");
    setDurationInHourMinSec(data?.videoDuration || "");
  };

  // get ppv details in case of edit ppv and prefill data
  const _getPPVDetails = async (ppvId) => {
    try {
      const { ppvId } = extractQueryParams();

      dispatch(showLoader("Loading..."));

      const res = await getPPV(ppvId);

      setPpvDetails(res.ppv);

      _setFormValue(res.ppv);

      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
      errorHandler(error);
    }
  };

  const _callPostContentAPI = async (apiData) => {
    try {
      if (ppvDetails?._id) {
        if (apiData.scheduledAt && new Date() > new Date(apiData.scheduledAt)) {
          delete apiData.scheduledAt;
        }
        await editPPV(apiData, ppvDetails?._id);
      } else {
        await createPPV(apiData);
      }
      showToast(
        getConfiguredString(
          `ppv ${ppvDetails?._id ? "updated" : "created"} successfully`
        ),
        "success"
      );
      dispatch(hideLoader());

      history.push("/ppv");
    } catch (error) {
      dispatch(hideLoader());
      errorHandler(error);
    }
  };

  // validate formfields
  const _validateForm = ({ newFormFields, newIsDirty }) => {
    return new Promise((resolve) => {
      let isFormValid = true;
      const newErrors = {};

      Object.keys(newFormFields).forEach((key) => {
        if (newIsDirty[key]) {
          switch (key) {
            case "title": {
              if (newFormFields?.[key]?.length) {
                newIsDirty[key] = false;
                newErrors[key] = null;
              } else {
                newErrors[key] = "Required";
                isFormValid = false;
              }
              break;
            }

            case "description": {
              if (newFormFields?.[key]?.length) {
                newIsDirty[key] = false;
                newErrors[key] = null;
              } else {
                newErrors[key] = "Required";
                isFormValid = false;
              }

              break;
            }

            case "price":
            case "plusPrice":
            case "premiumPrice": {
              if (
                !String(newFormFields?.[key]).length ||
                Number(newFormFields?.[key]) === 0
              ) {
                newIsDirty[key] = false;
                newErrors[key] = null;
                break;
              }

              if (
                +newFormFields?.[key] >= +AmountConfig.minEventPrice &&
                +newFormFields?.[key] <= +AmountConfig.maximumLimit
              ) {
                if (
                  TierAmountValidation(
                    newFormFields.price,
                    newFormFields.plusPrice,
                    newFormFields.premiumPrice
                  )
                ) {
                  newIsDirty[key] = false;
                  newErrors[key] = null;
                } else {
                  newErrors[key] =
                    key === "price"
                      ? "Basic price should be greater or equal to plus and premium price"
                      : key === "plusPrice"
                      ? "Plus price should be greater or equal to than premium price. But less than basic price"
                      : "Premium price should be less or equal to plus and basic price.";
                  isFormValid = false;
                }
              } else {
                newErrors[key] = `${
                  newFormFields?.[key] > AmountConfig.maximumLimit
                    ? `Maximum ${CURRENCY_SYMBOL}${AmountConfig.maximumLimit}`
                    : `Minimum ${CURRENCY_SYMBOL}${AmountConfig.minEventPrice} or free`
                }`;
                isFormValid = false;
              }
              break;
            }

            case "scheduledAt": {
              if (!isPublishNow) {
                if (
                  ppvDetails?.status === "published" ||
                  (newFormFields[key] &&
                    new Date() < new Date(newFormFields[key]))
                ) {
                  newIsDirty[key] = false;
                  newErrors[key] = null;
                } else {
                  newErrors[key] = "*Should be future date & time";
                  isFormValid = false;
                }
              } else {
                newIsDirty[key] = false;
                newErrors[key] = null;
              }
              break;
            }

            case "ppvVideo": {
              if (
                newFormFields?.ppvVideo?.previewBlob ||
                newFormFields?.ppvVideo?.uploadUrl
              ) {
                newIsDirty[key] = false;
                newErrors[key] = null;
              } else {
                newErrors[key] = getConfiguredString("ppv Video is required");
                isFormValid = false;
              }
              break;
            }

            default: {
              break;
            }
          }
        }
      });

      setIsDirty((prev) => ({
        ...prev,
        ...newIsDirty,
      }));
      setErrors((prev) => ({
        ...prev,
        ...newErrors,
      }));
      resolve(isFormValid);
    });
  };

  // marking all formfields dirty
  const _markAllIsDirty = () => {
    return new Promise((resolve) => {
      const newIsDirty = { ...isDirty };
      const newFormFields = { ...formFields };
      Object.keys(newFormFields).forEach((key) => {
        newIsDirty[key] = true;
      });
      setIsDirty(newIsDirty);
      resolve(newIsDirty);
    });
  };

  // submits(validates) and create payload for api call
  const _submitPPVForm = async (e) => {
    try {
      if (e) e.preventDefault();

      const newFormFields = { ...formFields };
      const newIsDirty = await _markAllIsDirty();
      const isFormValid = await _validateForm({ newFormFields, newIsDirty });

      if (!isFormValid) {
        return;
      }

      if (ppvDetails) {
        dispatch(showLoader("Updating ppv"));
      } else {
        dispatch(showLoader("Creating ppv"));
      }

      const apiData = {
        title: newFormFields.title,
        description: newFormFields.description,
        ppvPreviewVideoOption: ppvPreviewVideoOption || "upload",
      };

      if (isPublishNow) {
        apiData.isPublished = true;
      } else {
        apiData.scheduledAt = new Date(
          newFormFields?.scheduledAt
        ).toISOString();
      }

      apiData.isPublic = true;

      if (+newFormFields.premiumPrice >= 0) {
        apiData.premiumPrice = +newFormFields.premiumPrice;
        if (+newFormFields.premiumPrice) {
          apiData.isPublic = false;
        }
      }
      if (+newFormFields.plusPrice >= 0) {
        apiData["plusPrice"] = +newFormFields.plusPrice;
        if (+newFormFields.plusPrice) {
          apiData["isPublic"] = false;
        }
      }
      if (+newFormFields.price >= 0) {
        apiData["price"] = +newFormFields.price;
        if (+newFormFields.price) {
          apiData["isPublic"] = false;
        }
      }

      apiData["videoDuration"] = durationInHourMinSec;

      if (newFormFields.ppvVideo?.type === "audio") {
        apiData.previewVideoUrl = "";
      }

      if (
        newFormFields.ppvVideo["uploadUrl"] ||
        newFormFields.coverImage["uploadUrl"] ||
        newFormFields.ppvPreviewVideo["uploadUrl"]
      ) {
        if (newFormFields?.ppvVideo?.["uploadUrl"]) {
          apiData["videoUrl"] = newFormFields?.ppvVideo?.["uploadUrl"];
          apiData["contentType"] = newFormFields?.ppvVideo?.["type"];
          apiData["duration"] = +duration;
        }

        if (newFormFields.coverImage["uploadUrl"]) {
          apiData["coverImage"] =
            newFormFields?.coverImage?.["uploadUrl"] || "";
        }

        if (newFormFields.ppvPreviewVideo["uploadUrl"]) {
          apiData["previewVideoUrl"] =
            newFormFields.ppvPreviewVideo["uploadUrl"];
        }

        // _callPostContentAPI(apiData);
      }

      let uploadFiles = [];

      if (
        newFormFields.ppvVideo["uploadData"] ||
        newFormFields.ppvVideo["previewBlob"]
      ) {
        newFormFields.ppvVideo["forKeyName"] = "videoUrl";
        uploadFiles.push({ ...newFormFields.ppvVideo });
      }

      if (
        newFormFields.ppvVideo?.type === "video" &&
        (newFormFields.ppvPreviewVideo["uploadData"] ||
          newFormFields.ppvPreviewVideo["previewBlob"])
      ) {
        newFormFields.ppvPreviewVideo["forKeyName"] = "previewVideoUrl";
        uploadFiles.push({ ...newFormFields.ppvPreviewVideo });
      }

      if (
        newFormFields.coverImage["previewBlob"] ||
        newFormFields.coverImage["uploadData"]
      ) {
        newFormFields.coverImage["forKeyName"] = "coverImage";
        uploadFiles.push({ ...newFormFields.coverImage });
      }

      if (uploadFiles?.length) {
        const uploadMediaRes = await uploadFileOnServer([...uploadFiles]);

        uploadMediaRes.forEach((res) => {
          apiData[res.forKeyName] = res.url;
          if (res.forKeyName === "videoUrl") {
            apiData["contentType"] = res.contentType;
            apiData["duration"] = +duration;
          }
        });
      }

      _callPostContentAPI(apiData);
    } catch (error) {
      console.log(error);
      dispatch(hideLoader());
    }
  };

  // on field value blur
  const _onBlurHandler = (key) => {
    const newFormFields = { ...formFields };
    const newIsDirty = {
      [key]: true,
    };
    _validateForm({ newFormFields, newIsDirty });
  };

  const _toggleIsPublishNow = (isPublish = false) => {
    setIsPublishNow(isPublish);
    setIsDirty((prev) => ({
      ...prev,
      scheduledAt: false,
    }));
    setErrors((prev) => ({
      ...prev,
      scheduledAt: null,
    }));
  };

  // on field value change
  const _updateFieldValue = (key, value) => {
    if (
      (key === "price" || key === "plusPrice" || key === "premiumPrice") &&
      value &&
      !isValidPrice(value)
    ) {
      return;
    }

    const newFormFields = { ...formFields };

    if (key === "scheduledAt") {
      newFormFields[key] = value?._d ? new Date(value).toISOString() : "";
      const newIsDirty = {
        [key]: true,
      };
      _validateForm({ newFormFields, newIsDirty });
    } else {
      newFormFields[key] = value;
    }

    setFormFields(newFormFields);
  };

  const _onLoadedMetadata = (event) => {
    try {
      setDuration(event.target.duration);
      setDurationInHourMinSec(
        convertSecondsToHourMinSec(event.target.duration)
      );
    } catch (error) {
      console.log({ error });
    }
  };

  // const _onChangeFile = async (event, key) => {
  //   if (!event?.target?.files?.length) {
  //     return;
  //   }

  //   const newFormFields = { ...formFields };

  //   const file = event.target.files[0];
  //   const fileType = file.type.split("/")[0];

  //   if (key === "coverImage" && fileType !== "image") {
  //     showToast("Only image file is allowed", "error");
  //     return;
  //   } else if (
  //     key === "ppvVideo" &&
  //     (fileType !== "video" || fileType !== "audio")
  //   ) {
  //     showToast("Only video & audio file is allowed", "error");
  //     return;
  //   } else if (key === "ppvPreviewVideo" && fileType !== "video") {
  //     showToast("Only video file is allowed", "error");
  //     return;
  //   }

  //   setIsPreviewLoading(true);

  //   const res = await mediaFilesHandler({
  //     newFiles: [file],
  //     isAudioUpload: true,
  //   });

  //   if (res.error) {
  //     errorHandler();
  //     setIsPreviewLoading(false);
  //     return;
  //   }

  //   newFormFields[key] = {
  //     uploadData: res?.mediaFiles?.[0]?.uploadData,
  //     previewBlob: res?.mediaFiles?.[0]?.previewBlob,
  //     type: res?.mediaFiles?.[0]?.type,
  //     uploadUrl: null,
  //   };

  //   const newIsDirty = {
  //     [key]: true,
  //   };

  //   _validateForm({ newFormFields, newIsDirty });

  //   setFormFields(newFormFields);
  //   setIsPreviewLoading(false);
  // };

  useEffect(() => {
    const { ppvId } = extractQueryParams();

    if (ppvId) {
      // edit ppv
      if (!isPermissionToAccess("PPV", "canEditOrDeletePPV")) {
        showToast("Unauthorized", "error");
        history.push("/my-profile");
        return;
      }

      _getPPVDetails();
    } else {
      // create ppv
      if (!isPermissionToAccess("PPV", "canCreatePPV")) {
        showToast("Unauthorized", "error");
        history.push("/my-profile");
        return;
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="customPgHeight animated fadeIn">
      <Container className="noPadding">
        <Row className="justify-content-center noMargin">
          <Col
            sm={12}
            md={10}
            lg={9}
            xl={8}
            className="pgPadding bg-white createEvent"
          >
            <div className="pgTitle justify-content-start">
              <Button
                className="customBackBtn"
                onClick={() => history.goBack()}
              >
                <i className="fa fa-chevron-left" />
              </Button>
              <h2>
                {ppvDetails
                  ? getConfiguredString("Edit ppv")
                  : getConfiguredString("Create ppv")}
              </h2>
            </div>

            <Form onSubmit={(e) => _submitPPVForm(e)}>
              <FormGroup>
                <Label>Title</Label>
                <Input
                  type="text"
                  value={formFields.title}
                  onChange={(e) => _updateFieldValue("title", e.target.value)}
                  onBlur={() => _onBlurHandler("title")}
                  placeholder="Enter"
                />
                <div className="form-error">
                  {errors?.title ? <p>{errors?.title}</p> : null}
                </div>
              </FormGroup>

              <FormGroup>
                <Label>Description</Label>
                {/* Max 5 lines */}
                <Input
                  type="textarea"
                  value={formFields.description}
                  onChange={(e) =>
                    _updateFieldValue("description", e.target.value)
                  }
                  onBlur={() => _onBlurHandler("description")}
                  placeholder="Enter"
                  rows="3"
                />
                <div className="form-error">
                  {errors?.description ? <p>{errors?.description}</p> : null}
                </div>
              </FormGroup>

              {/* ppv cover image */}
              <FormGroup>
                <Label>Add Cover Image</Label>
                <Label className="coverImgWrap">
                  {/* <Input
                    type="file"
                    style={{ display: "none" }}
                    accept="image/*, .heic, .heif"
                    value=""
                    name="coverImage"
                    onChange={(e) => _onChangeFile(e, "coverImage")}
                    disabled={isPreviewLoading}
                  /> */}

                  <Button
                    style={{ display: "none" }}
                    onClick={() => _toggleCoverImageModal()}
                    disabled={loadingState?.coverImage}
                  />

                  {formFields?.coverImage?.uploadUrl ||
                  formFields?.coverImage?.previewBlob ? (
                    <>
                      <img
                        src={
                          formFields?.coverImage?.uploadUrl ||
                          formFields?.coverImage?.previewBlob
                        }
                        alt="Cover Img"
                        loading="lazy"
                      />
                      <div className="changeImg">
                        {loadingState?.coverImage ? (
                          <i className="fa fa-spinner fa-spin" />
                        ) : (
                          <i className="fa fa-pencil" />
                        )}
                      </div>
                    </>
                  ) : (
                    <div className="text-center">
                      <img
                        src="/assets/img/upload.png"
                        alt="Upload"
                        className="uploadImg"
                        loading="lazy"
                      />
                      <p className="mb-0">Upload Image</p>
                    </div>
                  )}
                </Label>
              </FormGroup>

              {/* ppv video / audio content */}
              <FormGroup>
                <Label>
                  <ConfigureString keyString="ppv Video / Audio" />
                </Label>
                <Label className="coverImgWrap">
                  {/* <Input
                    type="file"
                    style={{ display: "none" }}
                    accept="video/*,audio/*"
                    value=""
                    name="eventPreviewVideo"
                    onChange={(e) => _onChangeFile(e, "ppvVideo")}
                  /> */}

                  <Button
                    style={{ display: "none" }}
                    onClick={() => _togglePpvVideoModal()}
                    disabled={loadingState?.ppvVideo}
                  />

                  {formFields.ppvVideo?.uploadUrl ||
                  formFields.ppvVideo?.previewBlob ? (
                    <>
                      {formFields.ppvVideo?.type === "video" ? (
                        <video
                          controls
                          disablePictureInPicture
                          src={
                            formFields.ppvVideo?.uploadUrl ||
                            formFields.ppvVideo?.previewBlob
                          }
                          onLoadedMetadata={_onLoadedMetadata}
                        />
                      ) : formFields.ppvVideo?.type === "audio" ? (
                        <audio
                          src={
                            formFields.ppvVideo?.uploadUrl ||
                            formFields.ppvVideo?.previewBlob
                          }
                          controls
                          controlsList="nodownload"
                        />
                      ) : (
                        <img
                          src="/assets/img/upload.png"
                          alt="Upload"
                          className="uploadImg"
                          loading="lazy"
                        />
                      )}

                      <div className="changeImg">
                        {loadingState?.ppvVideo ? (
                          <i className="fa fa-spinner fa-spin" />
                        ) : (
                          <i className="fa fa-pencil" />
                        )}
                      </div>
                    </>
                  ) : (
                    <div className="text-center">
                      <img
                        src="/assets/img/upload.png"
                        alt="Upload"
                        className="uploadImg"
                        loading="lazy"
                      />
                      <p className="mb-0">Upload Video / Audio</p>
                    </div>
                  )}
                </Label>
                <div className="form-error">
                  {errors?.ppvVideo ? <p>{errors?.ppvVideo}</p> : null}
                </div>
              </FormGroup>

              {/* ppv video preview */}
              {(formFields.ppvVideo.previewBlob ||
                formFields.ppvVideo?.uploadUrl) &&
              formFields.ppvVideo?.type === "video" ? (
                <>
                  {/* upload ppv preview video */}
                  <FormGroup>
                    <Label>Add Preview Video</Label>
                    <Label className="coverImgWrap">
                      {/* <Input
                        type="file"
                        style={{ display: "none" }}
                        accept="video/*"
                        value=""
                        name="previewVideoUrl"
                        onChange={(e) => _onChangeFile(e, "ppvPreviewVideo")}
                      /> */}

                      <Button
                        style={{ display: "none" }}
                        onClick={() => _togglePreviewVideoModal()}
                        disabled={loadingState?.ppvPreviewVideo}
                      />

                      {formFields.ppvPreviewVideo.uploadUrl ||
                      formFields.ppvPreviewVideo.previewBlob ? (
                        <>
                          <video
                            controls
                            disablePictureInPicture
                            src={
                              formFields.ppvPreviewVideo.uploadUrl ||
                              formFields.ppvPreviewVideo.previewBlob
                            }
                          />
                          <div className="changeImg">
                            {loadingState?.ppvPreviewVideo ? (
                              <i className="fa fa-spinner fa-spin" />
                            ) : (
                              <i className="fa fa-pencil" />
                            )}
                          </div>
                        </>
                      ) : (
                        <div className="text-center">
                          <img
                            src="/assets/img/upload.png"
                            alt="Upload"
                            className="uploadImg"
                            loading="lazy"
                          />
                          <p className="mb-0">Upload Video</p>
                        </div>
                      )}
                    </Label>
                  </FormGroup>
                </>
              ) : null}

              <FormGroup>
                <CustomInput
                  type="checkbox"
                  id="isPublishNow_createPayPerView"
                  label="Publish Now"
                  checked={isPublishNow}
                  onChange={(e) => {
                    _toggleIsPublishNow(e.target.checked);
                  }}
                  disabled={ppvDetails?.status === "published"}
                />
              </FormGroup>

              {/* the below field should be hidden if "Publish Now" is checked/selected */}
              <FormGroup>
                <Label>{getConfiguredString("ppv Release Schedule")}</Label>
                {/* Add a date & time selector plugin in place of the input field below */}
                <ReactDatetime
                  inputProps={{
                    className: "form-control",
                    placeholder: "Select release date & time",
                    value: formatDateAndTime(formFields.scheduledAt),
                    disabled:
                      isPublishNow || ppvDetails?.status === "published",
                  }}
                  value={
                    formFields.scheduledAt
                      ? new Date(formFields.scheduledAt)
                      : ""
                  }
                  onChange={(e) => _updateFieldValue("scheduledAt", e)}
                  isValidDate={(current) => current.isAfter(getYesterdayDate())}
                  timeConstraints={{
                    minutes: {
                      step: 15,
                    },
                  }}
                  dateFormat={true}
                  timeFormat={true}
                />
                {errors?.scheduledAt ? (
                  <div className="form-error">{errors?.scheduledAt}</div>
                ) : null}
              </FormGroup>

              {userData?.user?.multiTierSubscription ? (
                <>
                  <Label className="mb-2">Pricing</Label>
                  <Row className="noMargin PPVCustomPadding">
                    <Col xs={6} sm={4}>
                      <FormGroup>
                        <Label>Basic</Label>
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText className="dollarWrap">
                              <i className="fa fa-dollar" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder=""
                            value={formFields.price}
                            onChange={(e) =>
                              _updateFieldValue("price", e.target.value)
                            }
                            className="dollarWrapInput"
                            onBlur={() => _onBlurHandler("price")}
                          />
                        </InputGroup>
                        {errors.price ? (
                          <div className="form-error">{errors.price}</div>
                        ) : null}
                      </FormGroup>
                    </Col>

                    {/* For Support Multi tier */}
                    {/* plusMemberPrice */}
                    {userData?.user?.multiTierSubscription &&
                    isSubscriptionPriceExist("plus") ? (
                      <Col xs={6} sm={4}>
                        <FormGroup>
                          <Label>Plus</Label>
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText className="dollarWrap">
                                <i className="fa fa-dollar" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              type="text"
                              value={formFields.plusPrice}
                              onChange={(e) =>
                                _updateFieldValue("plusPrice", e.target.value)
                              }
                              onBlur={() => _onBlurHandler("plusPrice")}
                              className="dollarWrapInput"
                            />
                          </InputGroup>
                          {errors?.plusPrice ? (
                            <div className="form-error">
                              {errors?.plusPrice}
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>
                    ) : null}

                    {/* For Support Multi tier */}
                    {/* premiumMemberPrice */}
                    {userData?.user?.multiTierSubscription &&
                    isSubscriptionPriceExist("premium") ? (
                      <Col xs={6} sm={4}>
                        <FormGroup>
                          <Label>Premium</Label>
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText className="dollarWrap">
                                <i className="fa fa-dollar" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              type="text"
                              value={formFields.premiumPrice}
                              onChange={(e) =>
                                _updateFieldValue(
                                  "premiumPrice",
                                  e.target.value
                                )
                              }
                              onBlur={() => _onBlurHandler("premiumPrice")}
                              className="dollarWrapInput"
                            />
                          </InputGroup>
                          {errors?.premiumPrice ? (
                            <div className="form-error">
                              {errors?.premiumPrice}
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>
                    ) : null}
                  </Row>
                </>
              ) : (
                <>
                  <FormGroup>
                    <Label>Price</Label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText className="dollarWrap">
                          <i className="fa fa-dollar" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        value={formFields.price}
                        onChange={(e) =>
                          _updateFieldValue("price", e.target.value)
                        }
                        onBlur={() => _onBlurHandler("price")}
                        className="dollarWrapInput"
                      />
                    </InputGroup>
                    {errors?.price ? (
                      <div className="form-error">{errors.price}</div>
                    ) : null}
                  </FormGroup>
                </>
              )}
              <div className="d-flex justify-content-center mb-3">
                <Button
                  type="button"
                  className="cancelBtn mx-2"
                  onClick={() => history.push("/ppv")}
                >
                  Cancel
                </Button>
                <Button className="themeBtn saveBtn mx-2" type="submit">
                  {ppvDetails ? "Update" : "Create"}
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>

      <MediaLibraryModal
        isOpen={isCoverImageModalOpen}
        toggle={() => _toggleCoverImageModal()}
        uploadContents={(contents) =>
          _updateCoverImageVideoAndPreview("coverImage", contents)
        }
        isMultipleImages={false}
        isMultipleVideos={false}
        isMultipleAudios={false}
        isMultipleUpload={false}
        isImageUpload={true}
        isUploadLimit={true}
        imageLimit={1}
        uploadedFiles={[formFields.coverImage]}
        isWelcomeMessage={true}
        shouldUploadInModal={false}
      />
      <MediaLibraryModal
        isOpen={isPreviewVideoModalOpen}
        toggle={() => _togglePreviewVideoModal()}
        uploadContents={(contents) =>
          _updateCoverImageVideoAndPreview("ppvPreviewVideo", contents)
        }
        isMultipleImages={false}
        isMultipleVideos={false}
        isMultipleAudios={false}
        isMultipleUpload={false}
        isVideoUpload={true}
        isUploadLimit={true}
        videoLimit={1}
        uploadedFiles={[formFields.ppvPreviewVideo]}
        isWelcomeMessage={true}
        shouldUploadInModal={false}
      />

      <MediaLibraryModal
        isOpen={isPpvVideoModalOpen}
        toggle={() => _togglePpvVideoModal()}
        uploadContents={(contents) =>
          _updateCoverImageVideoAndPreview("ppvVideo", contents)
        }
        isMultipleUpload={false}
        isMultipleImages={false}
        isMultipleVideos={false}
        isMultipleAudios={false}
        isVideoUpload={true}
        isAudioUpload={true}
        isUploadLimit={true}
        uploadedFiles={[]}
        isWelcomeMessage={true}
        shouldUploadInModal={false}
      />
    </div>
  );
};

export default CreatePayPerView;
