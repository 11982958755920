import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Form,
  CustomInput,
  Row,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import ReactDatetime from "react-datetime";
import { createOffer, updateOffer } from "../../http-calls/index";
import {
  errorHandler,
  formatDateWithYear,
  getYesterdayDate,
  isSubscriptionPriceExist,
  isValidPhone,
  isValidPrice,
  showToast,
} from "../../helper-methods";
import moment from "moment";
import { CURRENCY_SYMBOL } from "../../config";
import Swal from "sweetalert2";
import colors from "../../assets/styles/scss/style.scss";
import { AmountConfig } from "../../config/appConfig";

const AddEditOfferModal = ({
  multiTierSubscription,
  isOpen,
  offerData,
  toggle,
  bundles,
  resetTableData,
  offers,
}) => {
  const [formFields, setFormFields] = useState({
    tier: "basic", //"plus", "premium"
    subscriptionPeriod: "",
    price: "",
    durationStartDate: new Date().toISOString(),
    durationEndDate: "",
    countStartDate: new Date().toISOString(),
    salesLimit: "",
    offerType: "duration",
  });
  const [errors, setErrors] = useState({});
  const [isDirty, setIsDirty] = useState({});
  const [loading, setLoading] = useState(false);

  const _isOfferLessThanSubscription = (offerPrice) => {
    const subscriptionPlan = bundles?.find(
      (each) => each?.tier === formFields?.tier
    );

    return subscriptionPlan?.price < offerPrice;
  };

  const _setModalState = (data) => {
    setFormFields({
      tier: data?.tier || "basic", //"plus", "premium"
      subscriptionPeriod: data?.subscriptionPeriod
        ? data?.subscriptionPeriod?.toString()
        : "",
      price: data?.price ? data?.price?.toString() : "",
      durationStartDate: data?.start || new Date().toISOString(),
      durationEndDate: data?.end || "",
      countStartDate: data?.start || new Date().toISOString(),
      salesLimit: data?.salesLimit ? data?.salesLimit?.toString() : "",
      offerType: data?.salesLimit ? "count" : "duration",
    });
    setErrors({});
    setIsDirty({});
    setLoading(false);
  };

  const _closeModal = () => {
    _setModalState();
    toggle();
  };

  const _markAllIsDirty = () => {
    return new Promise((resolve) => {
      const newFormFields = { ...formFields };
      const newIsDirty = { ...isDirty };
      Object.keys(newFormFields).forEach((key) => {
        newIsDirty[key] = true;
      });
      setIsDirty(newIsDirty);
      resolve(newIsDirty);
    });
  };

  const _validateFormfields = ({ newFormFields, newIsDirty }) => {
    return new Promise((resolve) => {
      let isFormValid = true;
      const newErrors = {};

      Object.keys(newFormFields).forEach((key) => {
        if (newIsDirty[key]) {
          switch (key) {
            case "tier": {
              if (newFormFields?.[key].length) {
                newErrors[key] = null;
                newIsDirty[key] = false;
              } else {
                isFormValid = false;
                newErrors[key] = "*Required";
              }
              break;
            }

            case "subscriptionPeriod": {
              if (
                newFormFields?.[key].length &&
                Number(newFormFields?.[key]) > 0 &&
                !isNaN(Number(newFormFields?.[key]))
              ) {
                newErrors[key] = null;
                newIsDirty[key] = false;
              } else {
                isFormValid = false;
                newErrors[key] = "*Required";
              }
              break;
            }

            case "price": {
              if (newFormFields?.[key]?.trim().length) {
                if (
                  isNaN(newFormFields[key]) ||
                  +newFormFields[key] < +AmountConfig.minimumLimit ||
                  +newFormFields[key] > +AmountConfig.maximumLimit
                ) {
                  isFormValid = false;
                  newErrors[key] =
                    +newFormFields[key] > +AmountConfig.maximumLimit
                      ? `Maximum ${CURRENCY_SYMBOL}${AmountConfig.maximumLimit}`
                      : `Minimum ${CURRENCY_SYMBOL}${AmountConfig.minimumLimit}`;
                } else if (_isOfferLessThanSubscription(newFormFields[key])) {
                  isFormValid = false;
                  newErrors[key] =
                    "Offer Price must be less than base price of selected tier.";
                } else {
                  newErrors[key] = null;
                  newIsDirty[key] = false;
                }
              } else {
                isFormValid = false;
                newErrors[key] = "*Required";
              }
              break;
            }

            case "durationStartDate": {
              if (newFormFields.offerType !== "duration") break;
              // reset errors and dirty of count offerType
              newErrors["salesLimit"] = null;
              newErrors["countStartDate"] = null;
              newIsDirty["salesLimit"] = false;
              newIsDirty["countStartDate"] = false;

              if (newFormFields?.[key]?.length) {
                if (
                  moment(newFormFields[key]).isSameOrAfter(
                    newFormFields?.durationEndDate
                  )
                ) {
                  newErrors[key] = "Start date should less than end date";
                  isFormValid = false;
                } else {
                  newErrors["durationEndDate"] = null;
                  newErrors[key] = null;
                  newIsDirty[key] = false;
                }
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }

            case "durationEndDate": {
              if (newFormFields.offerType !== "duration") break;
              // reset errors and dirty of count offerType
              newErrors["salesLimit"] = null;
              newErrors["countStartDate"] = null;
              newIsDirty["salesLimit"] = false;
              newIsDirty["countStartDate"] = false;

              if (newFormFields[key]?.length) {
                if (
                  moment(newFormFields?.[key]).valueOf() <=
                  moment(newFormFields?.durationStartDate).valueOf()
                ) {
                  newErrors[key] = "End date should greater than start date";
                  isFormValid = false;
                } else {
                  newErrors["durationStartDate"] = null;
                  newErrors[key] = null;
                  newIsDirty[key] = false;
                }
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }

            case "countStartDate": {
              if (newFormFields.offerType !== "count") break;
              // reset errors and dirty of duration offerType
              newErrors["durationStartDate"] = null;
              newIsDirty["durationStartDate"] = false;
              newErrors["durationEndDate"] = null;
              newIsDirty["durationEndDate"] = false;

              if (newFormFields?.[key]?.length) {
                newErrors[key] = null;
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }

            case "salesLimit": {
              if (newFormFields.offerType !== "count") break;
              // reset errors and dirty of duration offerType
              newErrors["durationStartDate"] = null;
              newIsDirty["durationStartDate"] = false;
              newErrors["durationEndDate"] = null;
              newIsDirty["durationEndDate"] = false;

              if (newFormFields?.[key]?.length) {
                if (Number(newFormFields[key]) < 0) {
                  newErrors[key] = "Value should be greater than 0";
                  isFormValid = false;
                } else {
                  newErrors[key] = null;
                  newIsDirty[key] = false;
                }
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }

            default:
          }
        }
      });

      setIsDirty((prev) => ({
        ...prev,
        ...newIsDirty,
      }));
      setErrors((prev) => ({
        ...prev,
        ...newErrors,
      }));
      resolve(isFormValid);
    });
  };

  const _onBlurFormfield = (key) => {
    const newFormFields = { ...formFields };
    const newIsDirty = {
      [key]: true,
    };
    _validateFormfields({ newFormFields, newIsDirty });
  };

  const _onChangeFormFields = (key, value) => {
    const newFormFields = { ...formFields };

    if (key === "price" && !isValidPrice(value)) {
      return;
    }

    if (key === "salesLimit" && !isValidPhone(value)) {
      return;
    }

    if (
      key === "durationStartDate" ||
      key === "durationEndDate" ||
      key === "countStartDate"
    ) {
      newFormFields[key] = value?._d ? new Date(value).toISOString() : "";
      const newIsDirty = {
        [key]: true,
      };
      _validateFormfields({ newFormFields, newIsDirty });
    } else {
      newFormFields[key] = value;
    }

    setFormFields({ ...newFormFields });
  };

  const _submitOfferData = async () => {
    try {
      const newFormFields = { ...formFields };
      const newIsDirty = await _markAllIsDirty();
      const isFormValid = await _validateFormfields({
        newFormFields,
        newIsDirty,
      });

      if (!isFormValid) {
        return;
      }

      setLoading(true);

      const payload = {
        tier: newFormFields.tier || "basic",
        subscriptionPeriod: +newFormFields.subscriptionPeriod,
        price: +(+newFormFields.price).toFixed(2),
        start:
          newFormFields.offerType === "duration"
            ? moment(newFormFields.durationStartDate).toISOString()
            : moment(newFormFields.countStartDate).toISOString(),
        salesLimit:
          newFormFields.offerType === "count"
            ? Math.floor(+newFormFields.salesLimit)
            : undefined,
        end:
          newFormFields.offerType === "duration"
            ? moment(newFormFields.durationEndDate).toISOString()
            : undefined,
      };

      if (newFormFields.offerType === "duration") {
        delete payload.salesLimit;
      } else {
        delete payload.end;
      }

      if (offerData?.id) {
        // Edit
        await updateOffer(offerData?.id, payload);

        showToast("Offer has been updated", "success");
      } else {
        const findOffer = offers?.find(
          (each) =>
            each.price === payload.price &&
            moment(each.start).isSame(moment(payload.start), "day") &&
            each.subscriptionPeriod === payload.subscriptionPeriod &&
            each.tier === payload.tier &&
            ((newFormFields.offerType === "duration" &&
              moment(each.end).isSame(moment(payload.end), "day")) ||
              (newFormFields.offerType === "count" &&
                each.salesLimit === payload.salesLimit))
        );

        if (findOffer) {
          const { isConfirmed } = await Swal.fire({
            title: "Are you sure?",
            text: `You want to create a duplicate offer that has already been created.`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: colors?.themeColor,
            cancelButtonColor: "#ccc",
            confirmButtonText: "Yes",
            cancelButtonText: "No",
          });
          if (isConfirmed !== true) {
            setLoading(false);
            return;
          }
        }

        // create new offer
        await createOffer(payload);

        showToast("Offer has been created", "success");
      }

      resetTableData();
      _closeModal();

      setLoading(false);
    } catch (error) {
      setLoading(false);
      errorHandler(error);
    }
  };

  useEffect(() => {
    if (offerData?.id) {
      _setModalState(offerData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, offerData]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        className="modal-dialog-centered modal-dialog-scrollable"
        toggle={() => _closeModal()}
      >
        <ModalHeader toggle={() => _closeModal()}>
          {offerData?.id ? "Update" : "Add"} Offer
        </ModalHeader>
        <ModalBody className="addOfferModal">
          <Form>
            <Row form>
              {multiTierSubscription && (
                <Col md={6}>
                  <FormGroup>
                    <Label>Tier</Label>
                    <Input
                      type="select"
                      name="tier"
                      value={formFields.tier}
                      onChange={(e) => {
                        _onChangeFormFields("tier", e.target.value);
                      }}
                      onBlur={() => _onBlurFormfield("tier")}
                    >
                      <option value="">Select</option>
                      <option value="basic">Basic</option>
                      {isSubscriptionPriceExist("plus") && (
                        <option value="plus">Plus</option>
                      )}
                      {isSubscriptionPriceExist("premium") && (
                        <option value="premium">Premium</option>
                      )}
                    </Input>
                    {errors?.tier ? (
                      <p className="form-error">{errors?.tier}</p>
                    ) : null}
                  </FormGroup>
                </Col>
              )}
              <Col md={6}>
                <FormGroup>
                  <Label>Period</Label>
                  <Input
                    type="select"
                    name="subscriptionPeriod"
                    value={formFields.subscriptionPeriod}
                    onChange={(e) => {
                      _onChangeFormFields("subscriptionPeriod", e.target.value);
                    }}
                    onBlur={() => _onBlurFormfield("subscriptionPeriod")}
                  >
                    <option value="">Select</option>
                    <option value="30">1 Month</option>
                    <option value="90">3 Months</option>
                    <option value="180">6 Months</option>
                    <option value="270">9 Months</option>
                    <option value="360">12 Months</option>
                  </Input>
                  {errors?.subscriptionPeriod ? (
                    <p className="form-error">{errors?.subscriptionPeriod}</p>
                  ) : null}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Price</Label>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText className="dollarWrap">
                        <i className="fa fa-dollar" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="text"
                      name="price"
                      value={formFields.price}
                      onChange={(e) => {
                        _onChangeFormFields("price", e.target.value);
                      }}
                      onBlur={() => _onBlurFormfield("price")}
                      className="dollarWrapInput"
                    />
                  </InputGroup>
                  {errors?.price ? (
                    <p className="form-error">{errors?.price}</p>
                  ) : null}
                </FormGroup>
              </Col>

              <Col md={12}>
                <div className="d-block w-100 my-2">
                  <CustomInput
                    type="radio"
                    id="duration"
                    name="duration"
                    label="Duration"
                    checked={formFields.offerType === "duration"}
                    onChange={() =>
                      _onChangeFormFields("offerType", "duration")
                    }
                  />
                </div>
                <Row>
                  <Col xs={6} className="pr-1">
                    <FormGroup>
                      <Label>From</Label>
                      <ReactDatetime
                        input={true}
                        inputProps={{
                          value: formatDateWithYear(
                            formFields.durationStartDate
                          ),
                          disabled: formFields.offerType !== "duration",
                        }}
                        dateFormat="LL"
                        timeFormat={false}
                        onChange={(e) => {
                          _onChangeFormFields("durationStartDate", e);
                        }}
                        onBlur={() => _onBlurFormfield("durationStartDate")}
                        isValidDate={(current) =>
                          current.isAfter(getYesterdayDate())
                        }
                        closeOnSelect
                      />
                      {errors?.durationStartDate ? (
                        <p className="form-error">{errors.durationStartDate}</p>
                      ) : null}
                    </FormGroup>
                  </Col>

                  <Col xs={6} className="pl-1">
                    <FormGroup>
                      <Label>To</Label>
                      <ReactDatetime
                        input={true}
                        inputProps={{
                          value: formatDateWithYear(formFields.durationEndDate),
                          disabled: formFields.offerType !== "duration",
                        }}
                        dateFormat="LL"
                        timeFormat={false}
                        onChange={(e) => {
                          _onChangeFormFields("durationEndDate", e);
                        }}
                        onBlur={() => _onBlurFormfield("durationEndDate")}
                        isValidDate={(current) =>
                          current.isAfter(formFields.durationStartDate)
                        }
                        closeOnSelect
                        className="rightAlignedCalendar"
                      />
                      {errors?.durationEndDate ? (
                        <p className="form-error">{errors.durationEndDate}</p>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Col md={12}>
                <div className="d-block w-100 my-2">
                  <CustomInput
                    type="radio"
                    id="count"
                    name="count"
                    label="Count of Sales"
                    checked={formFields.offerType === "count"}
                    onChange={() => _onChangeFormFields("offerType", "count")}
                  />
                </div>
                <Row>
                  <Col xs={6} className="pr-1">
                    <FormGroup>
                      <Label>From</Label>
                      <ReactDatetime
                        input={true}
                        inputProps={{
                          value: formatDateWithYear(formFields.countStartDate),
                          disabled: formFields.offerType !== "count",
                        }}
                        dateFormat="LL"
                        timeFormat={false}
                        onChange={(e) => {
                          _onChangeFormFields("countStartDate", e);
                        }}
                        onBlur={() => _onBlurFormfield("countStartDate")}
                        isValidDate={(current) =>
                          current.isAfter(getYesterdayDate())
                        }
                        closeOnSelect
                      />
                      {errors?.countStartDate ? (
                        <p className="form-error">{errors.countStartDate}</p>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col xs={6} className="pl-1">
                    <FormGroup>
                      <Label>Count</Label>
                      <Input
                        type="text"
                        name="salesLimit"
                        placeholder=""
                        value={formFields.salesLimit}
                        disabled={formFields.offerType !== "count"}
                        onChange={(e) => {
                          _onChangeFormFields("salesLimit", e.target.value);
                        }}
                        onBlur={() => _onBlurFormfield("salesLimit")}
                      />
                      {errors?.salesLimit ? (
                        <p className="form-error">{errors.salesLimit}</p>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button className="modalBtnCancel" onClick={() => _closeModal()}>
            Cancel
          </Button>
          <Button
            className="modalBtnSave"
            onClick={() => _submitOfferData()}
            disabled={loading}
          >
            {loading ? <i className="fa fa-spinner fa-spin mr-1" /> : null}{" "}
            {offerData?.id ? "Update" : "Add"}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AddEditOfferModal;
