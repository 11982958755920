import React, { useEffect } from "react";
import {
  Button,
  Container,
  Row,
  Col,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  CustomInput,
} from "reactstrap";
import {
  deepClone,
  errorHandler,
  formatCurrencyValue,
  isPermissionToAccess,
  showToast,
} from "../helper-methods";
import { updateProfile } from "../http-calls";
import { fetchAndModifiedUserDetails } from "../redux/actions/userData";
import AddStickerModal from "../components/modals/AddStickerModal";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useMemo } from "react";
import CustomLoader from "../components/custom/CustomLoader";
import SkeletonLoading from "../components/SkeletonLoading";

const ManageStickerPage = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const userData = useSelector((state) => state?.userData);

  const [stickers, setStickers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isShowArchivedSticker, setIsShowArchivedSticker] = useState(false);

  const [addStickerModal, setAddStickerModal] = useState({
    isOpen: false,
    data: null,
  });
  const [dropdownOpen, setDropdownOpen] = useState({
    isOpen: false,
    id: "",
  });

  const filterSticker = useMemo(() => {
    if (!stickers?.length) {
      return [];
    }

    return stickers.filter((each) => each.isActive === !isShowArchivedSticker);
  }, [stickers, isShowArchivedSticker]);

  const _updateStateStickers = () => {
    try {
      let newStickers = [...stickers];

      if (userData?.user?.stickers?.length) {
        newStickers = userData.user.stickers.reduce((acc, each) => {
          if (!acc.find((a) => a._id === each._id)) acc.push(each);
          return acc;
        }, []);
      }

      setStickers(newStickers);
    } catch (error) {
      console.log("error>>", error);
    }
  };

  useEffect(() => {
    _updateStateStickers();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.user]);

  const _fetchAndModifiedUserDetails = async () => {
    try {
      setLoading(true);

      await fetchAndModifiedUserDetails()(dispatch);

      setLoading(false);
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  const _updateProfile = (payload) => {
    return new Promise((resolve, reject) => {
      setLoading(true);

      updateProfile(payload)
        .then((res) => {
          _fetchAndModifiedUserDetails();
          resolve(res);
        })
        .catch((error) => {
          _fetchAndModifiedUserDetails();
          resolve(error);
          setLoading(false);
        });
    });
  };

  const _toggleAddStickerModal = (isOpen = false, data = null) => {
    setAddStickerModal({
      isOpen,
      data,
    });
  };

  const _updateManageSticker = (sticker, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const newStickers = deepClone([...stickers]);

        if (data?._id) {
          // for update
          const findSticker = newStickers.find((st) => st._id === data._id);

          if (findSticker) {
            findSticker.name = sticker.name;
            findSticker.price = sticker.price;
            findSticker.url = sticker.url ? sticker.url : findSticker.url;
          }
        } else {
          // for add
          sticker.isActive = true;
          newStickers.push({ ...sticker });
        }
        setStickers(newStickers);

        await _updateProfile({ stickers: newStickers });

        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  };

  const _toggleStickerStatus = async (sticker) => {
    try {
      const newStickers = deepClone([...stickers]);

      if (!newStickers?.length && !sticker?._id) {
        return;
      }

      const findSticker = newStickers?.find((st) => st._id === sticker?._id);

      if (!findSticker) {
        return;
      }

      findSticker["isActive"] = !findSticker.isActive;
      setStickers(newStickers);

      await _updateProfile({ stickers: newStickers });

      showToast(
        `Sticker ${findSticker["isActive"] ? "Active" : "Archived"}`,
        "success"
      );
    } catch (error) {
      errorHandler(error);
    }
  };

  // const _deleteSticker = async (sticker) => {
  //   try {
  //     const { stickers } = deepClone(state);

  //     if (stickers?.length && sticker && sticker._id) {
  //       const findStickerIndex = stickers.findIndex(
  //         (st) => st._id === sticker._id
  //       );

  //       if (findStickerIndex >= 0) {
  //         stickers.splice(findStickerIndex, 1);

  //         await _updateProfile({ stickers });

  //         showToast(`Cheer Icons Deleted`, "success");
  //       }
  //     }
  //   } catch (error) {
  //     errorHandler(error)
  //   }
  // };

  const _toggleDropdownOpen = (id = "") => {
    setDropdownOpen((prev) => ({
      id,
      isOpen: !prev.isOpen,
    }));
  };

  const _toggleShowStickerStatus = (isShow = false) => {
    setIsShowArchivedSticker(isShow);
  };

  useEffect(() => {
    if (!isPermissionToAccess("liveEvent", "canEditOrDeleteLiveEvent")) {
      showToast("Unauthorized", "error");
      history.push("/my-profile");
      return;
    }

    _fetchAndModifiedUserDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="customPgHeight animated fadeIn">
      <Container className="noPadding">
        <Row className="justify-content-center noMargin">
          <Col sm={12} lg={11} xl={10} className="pgPadding">
            <div className="pgTitle">
              <Button
                className="customBackBtn"
                onClick={() => history.goBack()}
              >
                <i className="fa fa-chevron-left" />
              </Button>
              <h2>Manage Cheer Icons</h2>
              {loading ? <CustomLoader className="mx-2" /> : null}

              <Button
                className="themeBtn addBtn ml-auto"
                onClick={() => _toggleAddStickerModal(true)}
              >
                Add
              </Button>
            </div>

            <div className="manageStickerWrap">
              <div className="d-flex justify-content-end">
                <CustomInput
                  type="checkbox"
                  className="mb-2"
                  id="isShowArchivedSticker__CustomCheckbox"
                  label={`Archived Cheer Icons`}
                  checked={isShowArchivedSticker}
                  onChange={(e) => _toggleShowStickerStatus(e.target.checked)}
                />
              </div>

              <Row>
                {filterSticker?.length ? (
                  filterSticker.map((sticker, index) => (
                    <Col
                      xs="6"
                      sm="4"
                      lg="3"
                      key={sticker?._id || index}
                      className="customPaddingSticker"
                    >
                      <div className="stickerWrap">
                        <img
                          className="stickerImg"
                          src={sticker?.url}
                          alt="Sticker"
                          loading="lazy"
                        />
                        <Dropdown
                          className="customDropdown customDropdown-Feed"
                          isOpen={
                            sticker._id === dropdownOpen.id &&
                            dropdownOpen.isOpen
                          }
                          disabled={loading}
                          toggle={() => {
                            _toggleDropdownOpen(sticker._id);
                          }}
                        >
                          <DropdownToggle>
                            <img
                              src="/assets/img/dots-black.png"
                              alt="Dots"
                              style={{
                                width: 19,
                                height: 4,
                              }}
                              loading="lazy"
                            />
                          </DropdownToggle>
                          <DropdownMenu right>
                            <DropdownItem>
                              <div
                                onClick={() =>
                                  _toggleAddStickerModal(true, sticker)
                                }
                              >
                                Edit
                              </div>
                            </DropdownItem>
                            <DropdownItem>
                              <div
                                onClick={() => _toggleStickerStatus(sticker)}
                              >
                                {sticker.isActive ? "Archive" : "Make Active"}
                              </div>
                            </DropdownItem>
                            {/* <DropdownItem>
                                <div
                                  onClick={() =>
                                    _deleteSticker(sticker)
                                  }
                                >
                                  Delete
                                </div>
                              </DropdownItem> */}
                          </DropdownMenu>
                        </Dropdown>

                        <div className="stickerInfo">
                          <span>{sticker.name || "N/A"}</span>
                          <span
                            style={{
                              fontWeight: 600,
                              marginLeft: 3,
                              flexShrink: 0,
                            }}
                          >
                            {sticker.price
                              ? formatCurrencyValue(sticker.price)
                              : "N/A"}
                          </span>
                        </div>
                      </div>
                    </Col>
                  ))
                ) : loading ? (
                  <SkeletonLoading type={"photoLibrary"} count={4} />
                ) : (
                  <div className="noContentFound text-center w-100">
                    There is no data to display.
                  </div>
                )}
              </Row>
            </div>
          </Col>
        </Row>
      </Container>

      <AddStickerModal
        isOpen={addStickerModal.isOpen}
        data={addStickerModal.data}
        toggle={() => _toggleAddStickerModal()}
        updateManageSticker={(payload, data) =>
          _updateManageSticker(payload, data)
        }
      />
    </div>
  );
};

export default ManageStickerPage;
