import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Button,
  Col,
  Container,
  ListGroup,
  Nav,
  NavItem,
  NavLink,
  Row,
  FormGroup,
  Input,
} from "reactstrap";
import { notificationTab } from "../config/helper-config";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CustomLoader from "../components/custom/CustomLoader";
import { errorHandler, generateCalenderDate } from "../helper-methods";
import NotificationItem from "../components/NotificationItem";
import { readAllNotifications } from "../http-calls";
import {
  fetchNotifications,
  unreadNotificationCountUpdate,
} from "../redux/actions";
import SkeletonLoading from "../components/SkeletonLoading";
import ErrorBoundary from "../components/ErrorBoundary";

const NotificationPage = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const { isLoading, notifications, hasMore, unreadNotificationCount } =
    useSelector((state) => state?.notificationData || {});

  const [activeTab, setActiveTab] = useState("all"); // comment, like, subscription, earned, invites, alerts

  const [isReadAll, setIsReadAll] = useState(false);

  const [dataPayload, setDataPayload] = useState({
    filter: {
      type: [], //"comment","like", "subscription","ppv", "alerts","invites"
    },
    skip: 0,
    limit: 20,
  });

  const _unreadNotificationCountUpdate = (count = 0) => {
    dispatch(unreadNotificationCountUpdate(count));
  };

  const _readAllNotifications = () => {
    try {
      if (!unreadNotificationCount) return;

      // if (activeTab === "all") {
      //   readAllNotifications({ category: undefined });
      // } else {
      //   readAllNotifications({
      //     category: [activeTab],
      //   });
      // }

      readAllNotifications();

      _unreadNotificationCountUpdate(0);

      setIsReadAll(true);
    } catch (error) {
      errorHandler(error);
    }
  };

  const _fetchNotifications = (payload) => {
    setIsReadAll(false);
    dispatch(fetchNotifications(payload));
  };

  //sets filtertype based on TabField then fetch respective tab's notification
  const _prepareNotificationPayload = (tabName) => {
    const newDataPayload = {
      filter: {
        type: [],
      },
      skip: 0,
      limit: 20,
    };

    switch (tabName) {
      case "all":
        break;
      case "comment":
        newDataPayload.filter.type.push("comment");
        break;
      case "like":
        newDataPayload.filter.type.push("like");
        break;
      case "subscription":
        newDataPayload.filter.type.push("subscription");
        break;
      case "earned":
        newDataPayload.filter.type.push("ppv", "tips");
        break;
      case "invites":
        newDataPayload.filter.type.push("referral");
        break;
      case "alerts":
        newDataPayload.filter.type.push("alert");
        break;

      default:
    }

    setDataPayload(newDataPayload);
    _fetchNotifications(newDataPayload);
  };

  const _onTabChange = (tabName) => {
    setActiveTab(tabName);
    _prepareNotificationPayload(tabName);
  };

  const observer = useRef(null);

  const lastElementRef = useCallback(
    (node) => {
      if (isLoading) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries?.[0]?.isIntersecting && hasMore) {
          const newDataPayload = { ...dataPayload };
          newDataPayload.skip = notifications?.length;
          setDataPayload(newDataPayload);
          _fetchNotifications(newDataPayload);
        }
      });

      if (node) {
        observer.current.observe(node);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading, hasMore]
  );

  useEffect(() => {
    _fetchNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      data-test="notification-page"
      className="customPgHeight animated fadeIn"
    >
      <Container className="noPadding">
        <Row className="justify-content-center noMargin">
          <Col sm={12} lg={10} className="pgPadding">
            <div className="pgTitle notificationPgTitle">
              <div className="d-flex align-items-center">
                <h2>Notifications</h2>
                {/* mobile dropdown view */}
                <FormGroup className="notificationSelect">
                  <Input
                    type="select"
                    name="notificationSelect"
                    value={activeTab}
                    onChange={(e) => _onTabChange(e.target.value)}
                  >
                    {notificationTab.map((tab) => (
                      <option key={tab.value} value={tab?.value}>
                        {tab?.label}
                      </option>
                    ))}
                  </Input>
                </FormGroup>

                {isLoading ? <CustomLoader className="mx-2" /> : null}
              </div>

              <div>
                <Button
                  data-test="setting-button"
                  className="customPopoverBtn"
                  style={{ padding: "0 15px" }}
                  onClick={() => history.push(`/settings?tabId=4`)}
                >
                  <i className="fa fa-gear" style={{ verticalAlign: -1 }} />
                </Button>

                {unreadNotificationCount ? (
                  <Button
                    className="themeBtn addBtn ml-2"
                    onClick={() => _readAllNotifications()}
                  >
                    Mark All Read
                  </Button>
                ) : null}
              </div>
            </div>

            {/* web tab view */}
            <div className="notificationContentWrap">
              <Nav tabs className="customTabs tab-Notification">
                {notificationTab.map((tab) => (
                  <NavItem key={`${tab?.value}-tab-key`}>
                    <NavLink
                      data-test={`${tab?.value}-tab`}
                      active={activeTab === tab?.value}
                      onClick={() => _onTabChange(tab?.value)}
                    >
                      {tab.label}
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>

              {notifications?.length ? (
                <ListGroup style={{ overflow: "hidden" }}>
                  {notifications.map((notification, index) => (
                    <Fragment key={`${notification?._id}_${index}`}>
                      <ErrorBoundary>
                        <NotificationItem
                          notification={notification}
                          prevDateHeading={generateCalenderDate(
                            notifications?.[index - 1]?.createdAt
                          )}
                          currDateHeading={generateCalenderDate(
                            notification?.createdAt
                          )}
                          isReadAll={isReadAll}
                          {...(index === notifications.length - 1
                            ? { lastElementRef }
                            : {})}
                        />
                      </ErrorBoundary>
                    </Fragment>
                  ))}
                </ListGroup>
              ) : isLoading ? (
                <SkeletonLoading type={"notificationItem"} count={10} />
              ) : (
                <div className="noContentFound">No Notifications Currently</div>
              )}

              {isLoading && hasMore ? (
                <SkeletonLoading type={"notificationItem"} count={10} />
              ) : null}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default NotificationPage;
