import React, { Component } from "react";
import {
  Button,
  Col,
  Input,
  Label,
  Row,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import {
  deepClone,
  // isValidImageFileType,
  // isValidVideoFileType,
  showToast,
} from "../helper-methods";
import { AmountConfig, DescriptionConfig } from "../config/appConfig";
import { CURRENCY_SYMBOL } from "../config";

class CreateShoutOut extends Component {
  state = {
    formFields: {
      type: "",
      description: {
        value: "",
        error: null,
        isValid: false,
        isRequired: true,
        isDirty: false,
      },
      prices: [
        {
          value: "",
          duration:
            this.props && this.props.durationType === "minute" ? 5 * 60 : 30,
          error: null,
          isValid: false,
          isRequired: true,
          isDirty: false,
        },
        {
          value: "",
          duration:
            this.props && this.props.durationType === "minute" ? 10 * 60 : 60,
          error: null,
          isValid: false,
          isRequired: true,
          isDirty: false,
        },
        {
          value: "",
          duration:
            this.props && this.props.durationType === "minute" ? 15 * 60 : 90,
          error: null,
          isValid: false,
          isRequired: true,
          isDirty: false,
        },
      ],
      containes: [
        {
          index: 0,
          value: "",
          previewBlob: null,
          blob: null,
          isValid: false,
          error: null,
          isDirty: false,
          isRequired: true,
          uploadData: null,
          uploadUrl: "",
          isEdit: false,
        },
        {
          index: 1,
          value: "",
          previewBlob: null,
          blob: null,
          isValid: false,
          error: null,
          isDirty: false,
          isRequired: false,
          uploadData: null,
          uploadUrl: "",
          isEdit: false,
        },
        {
          index: 2,
          value: "",
          previewBlob: null,
          blob: null,
          isValid: false,
          error: null,
          isDirty: false,
          isRequired: false,
          uploadData: null,
          uploadUrl: "",
          isEdit: false,
        },
      ],
    },
    isFormValid: false,
    isPricesValid: false,
    isMediaValid: false,
    isEdit: false,
    mediaError: [false, false, false],
    isAudioPlay: [false, false, false],
    isFieldChanged: false,
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.shoutOutPricings !== this.props.shoutOutPricings) {
      if (this.props.shoutOutPricings) {
        this._formData();
        this.setState({ isFieldChanged: false });
      }
    }
  }

  _setMediaError = (index = 0, value = false) => {
    const { mediaError } = this.state;
    mediaError[index] = value;
    this.setState({ mediaError });
  };

  _formData = () => {
    const { shoutOutPricings } = this.props;
    let { formFields, isEdit } = deepClone(this.state);

    if (shoutOutPricings?.id) {
      isEdit = true;
    }

    formFields.description.value = shoutOutPricings?.description || "";

    shoutOutPricings.prices.forEach((shoutPrice) => {
      formFields.prices.forEach((formPrice) => {
        if (formPrice.duration === shoutPrice.duration) {
          formPrice.value = shoutPrice.price ? shoutPrice.price.toString() : "";
        }
      });
    });

    formFields.containes.forEach((e) => {
      e.uploadData = null;
      e.blob = null;
    });

    shoutOutPricings.contents.forEach((shoutOutUrl, shoutIndex) => {
      formFields.containes[shoutIndex].uploadUrl = shoutOutUrl;
    });

    this.setState({ formFields, isEdit });
  };

  _updateFieldValue = (fieldName, value) => {
    if (!this.props.canUpdateShoutoutSettings) {
      return null;
    }

    const { formFields } = deepClone(this.state);

    if (fieldName === "description") {
      formFields[fieldName].value = value;
    } else {
      // Check if contains valid number
      if (
        isNaN(value) ||
        (value.includes(".") && value.split(".")[1].length > 2)
      ) {
        return;
      }
      formFields.prices[fieldName].value = value;
    }

    this.setState({ formFields, isFieldChanged: true }, async () => {
      if (fieldName === "description") {
        if (formFields[fieldName].isDirty) {
          await this._validateDescription();
        }
      } else {
        if (formFields.prices[fieldName].isDirty) {
          await this._validatePrice();
        }
      }
    });
  };

  _readFile = (e, index) => {
    this._setMediaError(index, false);

    const {
      formFields,
      // isEdit
    } = deepClone(this.state);

    let contain = null;
    if (e && e.target && e.target.files && e.target.files.length) {
      for (let i = 0; i < e.target.files.length; i++) {
        contain = e.target.files[i];

        // if (!isValidVideoFileType(contain) || !isValidImageFileType(contain))
        //   continue;

        formFields.containes[index].previewBlob = URL.createObjectURL(contain);
        formFields.containes[index].uploadData = contain;
        formFields.containes[index].blob = contain;
        formFields.containes[index].isEdit = true;
      }
      formFields.containes[index].isDirty = true;

      formFields.containes[index] = { ...formFields.containes[index], contain };
      this.setState({ formFields, isFieldChanged: true }, () => {
        if (formFields.containes[index].isDirty) {
          this._validateMedia();
        }
      });
    }
  };

  _markAsDirty = (fieldName) => {
    let { formFields } = deepClone(this.state);
    if (fieldName === "description") {
      formFields[fieldName].isDirty = true;
    } else {
      formFields.prices[fieldName].isDirty = true;
    }
    this.setState({ formFields }, async () => {
      await this._validatePrice();
      await this._validateDescription();
    });
  };

  _makeAllFieldDirty = () => {
    return new Promise((resolve) => {
      const { formFields } = this.state;
      formFields.description.isDirty = true;
      formFields.prices.forEach((price, index) => {
        price.isDirty = true;
      });
      formFields.containes.forEach((media, index) => {
        media.isDirty = true;
      });
      this.setState({ formFields }, () => {
        resolve();
      });
    });
  };

  _validateDescription = () => {
    return new Promise((resolve) => {
      let { formFields } = deepClone(this.state);
      let isFormValid = true;
      if (formFields.description.value?.trim().length) {
        if (
          formFields.description.value.trim().length >
            DescriptionConfig.minLetter &&
          formFields.description.value.trim().length <
            DescriptionConfig.maxLetter
        ) {
          formFields.description.isValid = true;
        } else {
          formFields.description.isValid = false;
          formFields.description.error = `*Description should have minimum ${DescriptionConfig.minLetter} characters & maximum ${DescriptionConfig.maxLetter} characters`;
          isFormValid = false;
        }
      } else {
        formFields.description.isValid = false;
        formFields.description.error = "*Required";
        isFormValid = false;
      }
      this.setState({ formFields, isFormValid }, () => {
        resolve();
      });
    });
  };

  _isValidPriceCompareWithOther = (pi) => {
    try {
      let { formFields } = deepClone(this.state);
      let isValidPrice = true;
      formFields.prices.forEach((price, index) => {
        if (index < pi) {
          if (
            price.value?.trim() &&
            Number(formFields.prices[pi].value.trim()) <=
              Number(price.value.trim())
          )
            isValidPrice = false;
        }
        if (index > pi) {
          if (
            price.value?.trim() &&
            Number(formFields.prices[pi].value.trim()) >=
              Number(price.value.trim())
          )
            isValidPrice = false;
        }
      });
      return isValidPrice;
    } catch (error) {
      console.log("error>>", error);
      return false;
    }
  };

  _isOtherPriceExist = () => {
    try {
      let { formFields } = deepClone(this.state);
      const isPriceExist = formFields.prices.find((price) =>
        price.value?.trim()
      );
      return isPriceExist ? true : false;
    } catch (error) {
      console.log("error>>", error);
      return false;
    }
  };

  _validatePrice = () => {
    return new Promise((resolve) => {
      let { formFields } = deepClone(this.state);
      const { durationType } = this.props;
      let isPricesValid = true;
      formFields.prices.forEach((price, index) => {
        if (price["isRequired"] && price["isDirty"]) {
          if (price.value?.trim().length) {
            if (
              isNaN(price.value.trim()) ||
              Number(price.value.trim()) < AmountConfig.minShoutOutPrice ||
              +price.value > +AmountConfig.maximumLimit
            ) {
              formFields.prices[index].isValid = false;
              formFields.prices[index].error =
                +price.value > +AmountConfig.maximumLimit
                  ? `*Maximum ${CURRENCY_SYMBOL}${AmountConfig.maximumLimit}`
                  : `*Minimum ${CURRENCY_SYMBOL}${AmountConfig.minShoutOutPrice}`;
              isPricesValid = false;
            } else {
              if (this._isValidPriceCompareWithOther(index)) {
                formFields.prices[index].isValid = true;
                formFields.prices[index].isDirty = false;
                formFields.prices[index].error = null;
              } else {
                formFields.prices[index].isValid = false;
                isPricesValid = false;
                switch (index) {
                  case 0: {
                    formFields.prices[index].error =
                      "Price should be less than others";
                    break;
                  }
                  case 1: {
                    const d0 = formFields.prices[0].duration;
                    const d2 = formFields.prices[2].duration;

                    formFields.prices[
                      index
                    ].error = `Price should be greater than ${
                      durationType === "minute" ? `${d0 / 60} min` : `${d0} sec`
                    } & less than ${
                      durationType === "minute" ? `${d2 / 60} min` : `${d2} sec`
                    }`;
                    break;
                  }
                  case 2: {
                    const d0 = formFields.prices[0].duration;
                    const d1 = formFields.prices[1].duration;

                    formFields.prices[
                      index
                    ].error = `Price should be greater than ${
                      durationType === "minute" ? `${d0 / 60} min` : `${d0} sec`
                    } & ${
                      durationType === "minute" ? `${d1 / 60} min` : `${d1} sec`
                    }`;
                    break;
                  }
                  default:
                }
              }
            }
          } else {
            formFields.prices[index].isValid = true;
            formFields.prices[index].isDirty = false;
            formFields.prices[index].error = null;
          }
        }
      });
      this.setState({ formFields, isPricesValid }, () => {
        resolve();
      });
    });
  };

  _customValidate = (array) => {
    var length = array.length;
    return array.every(function (price, index) {
      var nextIndex = index + 1;
      return nextIndex < length
        ? Number(price.value) <= Number(array[nextIndex].value)
        : true;
    });
  };

  _validateMedia = () => {
    return new Promise((resolve) => {
      let { formFields } = deepClone(this.state);

      let isMediaValid = true;
      formFields.containes.forEach((media, index) => {
        if (media["isRequired"]) {
          // For Required
          if (media.previewBlob || media?.uploadUrl) {
            // If Does not updated
            if (media.uploadData) {
              // If update check type
              if (media.uploadData.type.includes(this.props.mediaType)) {
                formFields.containes[index].isValid = true;
                formFields.containes[index].error = null;
              } else {
                formFields.containes[index].isValid = false;
                formFields.containes[index].error = "Invaild media type";
                isMediaValid = false;
              }
            } else {
              formFields.containes[index].isValid = true;
              formFields.containes[index].error = null;
            }
          } else {
            if (
              formFields.containes[1].uploadUrl ||
              formFields.containes[2].uploadUrl
            ) {
              formFields.containes[index].isValid = true;
              formFields.containes[index].error = null;
            } else {
              formFields.containes[index].isValid = false;
              formFields.containes[index].error = "Atleast one media required";
              isMediaValid = false;
            }
          }
        } else {
          // Not Required
          if (media.previewBlob) {
            // if Does Updated
            if (media.uploadData) {
              // Check type
              if (media.uploadData.type.includes(this.props.mediaType)) {
                formFields.containes[index].isValid = true;
              } else {
                formFields.containes[index].isValid = false;
                formFields.containes[index].error = "Invaild media type";
                isMediaValid = false;
              }
            } else {
              formFields.containes[index].isValid = true;
              formFields.containes[index].error = null;
            }
          } else {
            formFields.containes[index].isValid = true;
            formFields.containes[index].error = null;
          }
        }
      });

      this.setState({ formFields, isMediaValid }, () => {
        resolve();
      });
    });
  };

  _addOrEditData = async () => {
    this._makeAllFieldDirty();
    await this._validateDescription();
    await this._validatePrice();
    await this._validateMedia();
    const isOtherPriceExist = this._isOtherPriceExist();

    const { isFormValid, isPricesValid, isMediaValid } = this.state;

    if (!isOtherPriceExist) {
      showToast("*At least one price is required", "error");
      return;
    }

    if (isFormValid && isPricesValid && isMediaValid && isOtherPriceExist) {
      let formData = this._makeFormData();
      this.props.onSave(formData, this.props.type);
    }
  };

  _makeFormData = () => {
    let { formFields, isEdit } = deepClone(this.state);

    let formData = {
      type: this.props.type,
      description: formFields.description.value,
    };

    if (isEdit) {
      formData["id"] = this.props.shoutOutPricings.id;
    }

    let prices = formFields.prices.map((price) => {
      return {
        price: price.value ? Number(price.value) : "",
        duration: price.duration,
      };
    });

    let containes = formFields.containes.filter((contain) => {
      if (contain?.uploadData || contain?.uploadUrl) {
        if (contain?.uploadData && contain.isEdit) {
          return {
            blobObject: contain?.uploadData,
            blobURL: contain?.previewBlob,
            blob: contain?.blob,
            isEdit: contain.isEdit,
            index: contain.inddex,
          };
        } else {
          return {
            blobURL: contain?.uploadUrl,
            isEdit: contain.isEdit,
            index: contain.inddex,
          };
        }
      }

      return null;
    });

    formData.prices = prices;
    formData.containes = containes;

    return formData;
  };

  _onToggleAudioPlayPause = (index = 0) => {
    const { isAudioPlay } = this.state;
    isAudioPlay[index] = !isAudioPlay[index];
    this.setState({ isAudioPlay });
  };

  render() {
    const { formFields, mediaError, isFieldChanged } = this.state;
    const { canUpdateShoutoutSettings, saveLoading } = this.props;

    return (
      <>
        <div className="header_Settings" style={{ marginTop: 27 }}>
          <h4>{this.props.title}</h4>

          {canUpdateShoutoutSettings ? (
            <Button
              className="themeBtn addBtn"
              onClick={() => this._addOrEditData()}
              disabled={saveLoading === this.props.type || !isFieldChanged}
            >
              {saveLoading === this.props.type ? (
                <i className="fa fa-spinner fa-spin mr-1" />
              ) : null}{" "}
              Save
            </Button>
          ) : null}
        </div>

        <Input
          type="textarea"
          name="description"
          rows="4"
          autoFocus={true}
          disabled={!canUpdateShoutoutSettings}
          placeholder="Intro Text"
          value={formFields.description.value}
          onChange={(e) =>
            this._updateFieldValue("description", e.target.value)
          }
          onBlur={() => this._markAsDirty("description")}
          // **Note: the Intro text should have minimum 300 characters & maximum 500 characters
        />
        <div className="form-error">
          {formFields.description.isDirty && !formFields.description.isValid
            ? formFields.description.error
            : ""}
        </div>

        <Row className="mx-0">
          {formFields &&
            formFields.prices &&
            formFields.prices.map((price, index) => (
              <Col sm="6" md="4" key={index} className="customPaddingShoutOut">
                <div className="shoutOutPriceWrap">
                  <p>
                    Duration:{" "}
                    <span>
                      {price.duration > 90
                        ? price.duration / 60 + " min"
                        : price.duration + " sec"}
                    </span>
                  </p>

                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText className="dollarWrap">
                        <i className="fa fa-dollar" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Price"
                      value={price.value}
                      disabled={!canUpdateShoutoutSettings}
                      type="text"
                      onChange={(e) =>
                        this._updateFieldValue(index, e.target.value)
                      }
                      onBlur={() => this._markAsDirty(index)}
                      className="dollarWrapInput"
                    />
                  </InputGroup>
                </div>
                <div className="form-error">
                  {price.isDirty && !price.isValid ? price.error : ""}
                </div>
              </Col>
            ))}
        </Row>

        <h5 className="samplesTxt">Samples</h5>
        <Row className="mx-0">
          {this.props.mediaType === "audio" ? (
            <>
              {/* 1st */}
              {formFields &&
                formFields.containes &&
                formFields.containes.map((contain, index) => {
                  if (!(contain?.previewBlob || contain?.uploadUrl)) {
                    return (
                      <Col
                        md="4"
                        className="shoutOutMediaWrapCol noPadding"
                        key={index}
                      >
                        <div className="shoutOutMediaWrap">
                          {canUpdateShoutoutSettings ? (
                            <Label className="btn uploadMedia-ShoutOut">
                              <input
                                type="file"
                                style={{ display: "none" }}
                                accept="audio/*"
                                onChange={(e) => this._readFile(e, index)}
                              />
                              <i className="fa fa-microphone mr-2" />
                              <span>Upload a Sample Clip</span>
                            </Label>
                          ) : null}

                          <div className="form-error d-block text-center">
                            {contain.isDirty && !contain.isValid
                              ? contain.error
                              : ""}
                          </div>
                        </div>
                      </Col>
                    );
                  } else {
                    return (
                      <Col
                        md="4"
                        className="shoutOutMediaWrapCol noPadding"
                        key={index}
                      >
                        <div className="shoutOutMediaWrap">
                          {mediaError[index] ? (
                            <div
                              className="btn uploadMedia-ShoutOut"
                              onClick={() => this._setMediaError(index, false)}
                            >
                              <i
                                className="fa fa-refresh mr-2"
                                style={{ marginTop: -1 }}
                              />{" "}
                              Reload
                            </div>
                          ) : (
                            <div className="audioWrapper">
                              <div
                                className={`audioRipple ${
                                  this.state.isAudioPlay[index]
                                    ? "audioRippleStart"
                                    : ""
                                }`}
                              >
                                <img
                                  src="/assets/img/microphone.png"
                                  alt="Microphone"
                                  loading="lazy"
                                />
                              </div>
                              <audio
                                className="audioInShoutSetting"
                                src={contain?.previewBlob || contain?.uploadUrl}
                                controls
                                controlsList="nodownload"
                                onPlay={() =>
                                  this._onToggleAudioPlayPause(index)
                                }
                                onPause={() =>
                                  this._onToggleAudioPlayPause(index)
                                }
                                onError={(e) => {
                                  this._setMediaError(index, true);
                                }}
                              />
                            </div>
                          )}
                          <div className="form-error">
                            {contain.isDirty && !contain.isValid
                              ? contain.error
                              : ""}
                          </div>

                          {canUpdateShoutoutSettings ? (
                            <Label className="btn replaceBtn">
                              <input
                                type="file"
                                style={{ display: "none" }}
                                accept="audio/*"
                                onChange={(e) => this._readFile(e, index)}
                              />
                              Replace
                            </Label>
                          ) : null}
                        </div>
                      </Col>
                    );
                  }
                })}
            </>
          ) : (
            <>
              {formFields &&
                formFields.containes &&
                formFields.containes.map((contain, index) => {
                  if (!(contain?.previewBlob || contain?.uploadUrl)) {
                    return (
                      <Col
                        md="4"
                        className="shoutOutMediaWrapCol noPadding"
                        key={index}
                      >
                        <div className="shoutOutMediaWrap">
                          {canUpdateShoutoutSettings ? (
                            <Label className="btn uploadMedia-ShoutOut">
                              <input
                                type="file"
                                style={{ display: "none" }}
                                accept="video/*"
                                onChange={(e) => this._readFile(e, index)}
                              />
                              {this.props.type === "live" ? (
                                <img
                                  className="mr-2"
                                  width="20"
                                  src="/assets/img/live-video-icon.png"
                                  alt="Video Call"
                                  loading="lazy"
                                />
                              ) : (
                                <i
                                  className="fa fa-video-camera mr-2"
                                  style={{ marginTop: -2 }}
                                />
                              )}
                              <span>Upload a Sample Clip</span>
                            </Label>
                          ) : null}

                          <div className="form-error d-block">
                            {contain.isDirty && !contain.isValid
                              ? contain.error
                              : ""}
                          </div>
                        </div>
                      </Col>
                    );
                  } else {
                    return (
                      <Col
                        md="4"
                        className="shoutOutMediaWrapCol noPadding"
                        key={index}
                      >
                        <div className="shoutOutMediaWrap">
                          {mediaError[index] ? (
                            <div
                              className="btn uploadMedia-ShoutOut"
                              onClick={() => this._setMediaError(index, false)}
                            >
                              <i
                                className="fa fa-refresh mr-2"
                                style={{ marginTop: -1 }}
                              />{" "}
                              Reload
                            </div>
                          ) : (
                            <video
                              controls
                              controlsList="nodownload"
                              disablePictureInPicture
                              src={contain?.previewBlob || contain?.uploadUrl}
                              onError={(e) => {
                                this._setMediaError(index, true);
                              }}
                            />
                          )}
                          <div className="form-error">
                            {contain.isDirty && !contain.isValid
                              ? contain.error
                              : ""}
                          </div>

                          {canUpdateShoutoutSettings ? (
                            <Label className="btn replaceBtn">
                              <input
                                type="file"
                                accept="video/*"
                                style={{ display: "none" }}
                                onChange={(e) => this._readFile(e, index)}
                              />
                              Replace
                            </Label>
                          ) : null}
                        </div>
                      </Col>
                    );
                  }
                })}
            </>
          )}
        </Row>
      </>
    );
  }
}

export default CreateShoutOut;
