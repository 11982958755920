import React, { Component } from "react";
import AgoraRTC from "agora-rtc-sdk";
import { showToast } from "../../helper-methods";
import { hideLoader, showLoader } from "../../redux/actions/loaderData";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import moment from "moment";
import Modal from "react-modal";

// rtc object
var rtc = {
  client: null,
  joined: false,
  published: false,
  localStream: null,
  remoteStreams: [],
  params: {},
};

// Options for joining a channel
var option = {
  appID: "3e9d62445ca741d7a439dcbc69ca40cd", //id from Agora
  channel: "", // create dynamic channel name
  uid: Math.floor(Math.random() * 100),
  token: "Your token",
};

const customStyles = {
  content: {
    width: "100%",
    left: "50%",
    position: "absolute",
    transform: "translate(-50%, -50%)",
    zIndex: 1000,
  },
};

let callTimingInterval;

class ChatVideoRoomComponent extends Component {
  state = {
    showRemoteVideo: false,
    remoteVideoId: "",
    audioOn: true,
    videoOn: true,
    callTiming: "00:00",
    remainingTiming: "00:00",
    isTimeUp: false,
    remoteUser: {
      isAudioOff: false,
      isVideoOff: false,
    },
  };

  _resetState = () => {
    clearInterval(callTimingInterval);

    this.setState({
      showRemoteVideo: false,
      remoteVideoId: "",
      audioOn: true,
      videoOn: true,
      callTiming: "00:00",
      remainingTiming: "00:00",
      isTimeUp: false,
      remoteUser: {
        isAudioOff: false,
        isVideoOff: false,
      },
    });
  };

  _handleError = (error) => {
    console.error("error>>", error);
    showToast(
      error?.reason?.length || error?.info?.length
        ? error.reason || error.info
        : "Something went wrong, Try again after sometime",
      "error"
    );
    this.props.hideLoader();
  };

  _setRemainingTiming = (startCallTime, shoutoutDuration) => {
    var startTime = moment(startCallTime);
    var endTime = moment();

    var secondsDiff = endTime.diff(startTime, "seconds");

    const seconds = shoutoutDuration - secondsDiff;

    if (seconds <= 0) {
      this.setState({ isTimeUp: true });
    } else {
      if (seconds < 59) {
        this.setState({
          remainingTiming: `00:${seconds < 10 ? "0" : ""}${seconds}`,
        });
      } else {
        const mins = Math.floor(seconds / 60);
        const secs = seconds % 60;

        this.setState({
          remainingTiming: `${mins < 10 ? "0" : ""}${mins}:${
            secs < 10 ? "0" : ""
          }${secs}`,
        });
      }
    }
  };

  componentDidMount = () => {
    try {
      const { message } = this.props;
      const messageId = message?.tempMessageId || message?.messageId;

      if (message?.startCallTime && message?.shoutoutDuration)
        this._setRemainingTiming(
          message?.startCallTime,
          message?.shoutoutDuration
        );

      option.channel = messageId ? messageId : "";
      this.props.showLoader();
      rtc.client = AgoraRTC.createClient({ mode: "rtc", codec: "h264" });
      rtc.client.init(
        option.appID,
        () => {
          console.log("init success");
        },
        (error) => {
          this._handleError(error);
        }
      );

      rtc.client.on("stream-added", (evt) => {
        console.log("stream-added :>> ");
        var remoteStream = evt.stream;
        var id = remoteStream.getId();
        rtc.remoteStreams.push(remoteStream);
        if (id !== rtc.params.uid) {
          rtc.client.subscribe(remoteStream, (error) => {
            console.log("stream subscribe failed");
            this._handleError(error);
          });
        }
        console.log("stream-added remote-uid: ", id);
      });

      rtc.client.on("stream-subscribed", (evt) => {
        console.log("stream-subscribed :>> ");
        var remoteStream = evt.stream;
        var id = remoteStream.getId();
        // Add a view for the remote stream.
        // addView(id);

        this.setState(
          {
            showRemoteVideo: true,
            remoteVideoId: id,
          },
          () => {
            // Play the remote stream.
            remoteStream.play("remote_video_" + id);
            console.log("stream-subscribed remote-uid: ", id);
          }
        );
      });

      // remote user mute or unmute video/audio option handle
      rtc.client.on("mute-audio", (evt) => {
        this._toggleRemoteUserAudioVideo("isAudioOff", true);
      });
      rtc.client.on("unmute-audio", (evt) => {
        this._toggleRemoteUserAudioVideo("isAudioOff", false);
      });
      rtc.client.on("mute-video", (evt) => {
        this._toggleRemoteUserAudioVideo("isVideoOff", true);
      });
      rtc.client.on("unmute-video", (evt) => {
        this._toggleRemoteUserAudioVideo("isVideoOff", false);
      });

      rtc.client.on("peer-leave", (evt) => {
        console.log("stream-removed", evt);
        var remoteStream = evt.stream;
        var id = remoteStream.getId();
        // Stop playing the remote stream.
        remoteStream.stop("remote_video_" + id);
        this.setState({ showRemoteVideo: false, remoteVideoId: "" }, () => {
          console.log("client leaves channel success");
        });
        // Remove the view of the remote stream.
        this._removeView(id);
        // console.log("stream-removed remote-uid: ", id);
      });

      AgoraRTC.getDevices((devices) => {
        let cameras = devices.filter((device) => device.kind === "videoinput");
        console.log("cameras>>", cameras);
      });

      this._joinChannel();
    } catch (error) {
      this._handleError(error);
    }
  };

  _removeView = (id) => {
    let player = document.getElementById(`player_${id}`);
    console.log(player);
    player.remove();
  };

  _startCallTiming = () => {
    callTimingInterval = setInterval(() => {
      let splitCallTime = this.state.callTiming.split(":");
      let splitRemainingTime = this.state.remainingTiming.split(":");

      if (splitCallTime[1] < 59) {
        splitCallTime[1] =
          (Number(splitCallTime[1]) + 1 < 10 ? "0" : "") +
          (Number(splitCallTime[1]) + 1).toString();
      } else {
        if (splitCallTime[0] < 59) {
          splitCallTime[0] =
            (Number(splitCallTime[0]) + 1 < 10 ? "0" : "") +
            (Number(splitCallTime[0]) + 1).toString();
          splitCallTime[1] = "00";
        } else {
          clearInterval(callTimingInterval);
        }
      }

      let isTimeUp = false;

      if (splitRemainingTime[0] === "00" && splitRemainingTime[1] === "00") {
        isTimeUp = true;
      } else {
        if (splitRemainingTime[1] > 0) {
          splitRemainingTime[1] =
            (Number(splitRemainingTime[1]) - 1 < 10 ? "0" : "") +
            (Number(splitRemainingTime[1]) - 1).toString();
        } else {
          if (splitRemainingTime[0] > 0) {
            splitRemainingTime[0] =
              (Number(splitRemainingTime[0]) - 1 < 10 ? "0" : "") +
              (Number(splitRemainingTime[0]) - 1).toString();
            splitRemainingTime[1] = "59";
          } else {
            splitRemainingTime[0] = "00";
            splitRemainingTime[1] = "00";
          }
        }
      }

      this.setState({
        callTiming: splitCallTime.join(":"),
        remainingTiming: splitRemainingTime.join(":"),
        isTimeUp,
      });
    }, 1000);
  };

  _joinChannel = () => {
    try {
      const { message } = this.props;

      rtc.client.join(
        null,
        option.channel,
        option.uid,
        (uid) => {
          console.log(
            "join channel: " + option.channel + " success, uid: " + uid
          );
          rtc.params.uid = uid;
          rtc.localStream = AgoraRTC.createStream({
            streamID: rtc.params.uid,
            audio: true,
            video: message?.shoutoutType === "live" ? true : false,
            screen: false,
          });

          rtc.localStream.init(
            () => {
              console.log("init local stream success");
              // play stream with html element id "local_stream"
              rtc.localStream.play("local_stream");
              rtc.client.publish(rtc.localStream, (error) => {
                console.log("publish failed");
                this._handleError(error);
              });

              this._startCallTiming();

              this.props.hideLoader();
            },
            (error) => {
              console.log("init local stream failed");
              this._handleError(error);
            }
          );
        },
        (error) => {
          console.log("client join failed");
          this._handleError(error);
        }
      );
    } catch (error) {
      this._handleError(error);
    }
  };

  _leaveChannel = () => {
    rtc.client.leave(
      () => {
        console.log("this.state :>> ", this.state);
        // Stop playing the local stream
        rtc.localStream.stop();
        // Close the local stream
        rtc.localStream.close();
        this.setState({ showRemoteVideo: false, remoteVideoId: "" }, () => {
          console.log("client leaves channel success");
        });
        // Stop playing the remote streams and remove the views
        while (rtc.remoteStreams.length > 0) {
          var stream = rtc.remoteStreams.shift();
          // var id = stream.getId();
          stream.stop();
          rtc.remoteStreams.pop();
          // this._removeView(id);
        }

        this._resetState();

        this.props.removeVideoRoom();
      },
      (error) => {
        console.log("channel leave failed");
        this._handleError(error);
      }
    );
  };

  _toggleRemoteUserAudioVideo = (type, value) => {
    const { remoteUser } = this.state;
    remoteUser[type] = value;
    this.setState({ remoteUser });
  };

  _toggleAudio = (audioOn = false) => {
    if (audioOn) {
      rtc.localStream.enableAudio();
    } else {
      rtc.localStream.disableAudio();
    }
    this.setState({ audioOn });
  };

  _toggleVideo = (videoOn = false) => {
    if (videoOn) {
      rtc.localStream.enableVideo();
    } else {
      rtc.localStream.disableVideo();
    }
    this.setState({ videoOn });
  };

  // switch camera function
  // _switchCamera = () => {
  //   rtc.localStream.switchDevice(
  //     "video",
  //     "<deviceid>",
  //     console.log,
  //     console.log
  //   );
  // };

  componentWillUnmount = () => {
    this._leaveChannel();
  };

  render() {
    const {
      videoOn,
      audioOn,
      callTiming,
      remainingTiming,
      isTimeUp,
      remoteUser,
      showRemoteVideo,
    } = this.state;

    const { startCall, message } = this.props;

    return (
      <Modal
        isOpen={startCall}
        toggle={() => this._leaveChannel()}
        style={customStyles}
        ariaHideApp={false}
        className="videoCallRoomModal"
      >
        <div className="videoCallRoom">
          <div className="callInfoWrap">
            <div>
              {message?.shoutoutType === "live" ? "Video" : "Voice"} Call
            </div>
            <div>
              {callTiming} - {isTimeUp ? "Time is up" : remainingTiming}
            </div>
          </div>

          <div
            id="local_stream"
            style={
              !showRemoteVideo
                ? { width: "100%", height: "100%" }
                : {
                    width: 100,
                    height: 100,
                    position: "absolute",
                    top: 50,
                    right: 10,
                    zIndex: 9,
                  }
            }
          >
            {message?.shoutoutType === "live" && !videoOn ? (
              <div className="cameraOffTxt">
                Your Camera is OFF
              </div>
            ) : null}

            {message?.shoutoutType === "liveaudio" ? (
              <div className="cameraOffTxt" style={{top: '25%'}}>
                <i
                  className="fa fa-microphone fa-4x"
                  style={{ color: "#fff" }}
                />
              </div>
            ) : null}
          </div>

          {showRemoteVideo ? (
            <>
              <div
                id={`remote_video_${this.state.remoteVideoId}`}
                style={{ height: "100%" }}
              />

              {message?.shoutoutType === "liveaudio" ? (
                <div className="cameraOffTxt">
                  Fan Connected
                </div>
              ) : null}

              <div className="turnedOffWrap">
                {remoteUser.isAudioOff ? (
                  <div className="turnedOff">Fan has turned OFF the Audio</div>
                ) : null}

                {message?.shoutoutType === "live" && remoteUser.isVideoOff ? (
                  <div className="turnedOff">Fan has turned OFF the Video</div>
                ) : null}
              </div>
            </>
          ) : null}

          <div className="videoRoomActionBtn">
            <div>
              <div className="btnVideoRoomWrap">
                {message?.shoutoutType === "live" ? (
                  <Button
                    className="btn btnVideoRoom"
                    onClick={() => this._toggleVideo(!videoOn)}
                  >
                    {videoOn ? (
                      <i
                        className="fa fa-video-camera"
                      />
                    ) : (
                      <img
                        src="/assets/img/video-off.png"
                        alt="video-off"
                        loading="lazy"
                      />
                    )}
                  </Button>
                ) : null}
                <Button
                  className="btn btnVideoRoom"
                  onClick={() => this._toggleAudio(!audioOn)}
                >
                  {audioOn ? (
                    <i
                      className="fa fa-microphone"
                      style={{ marginTop: 1 }}
                    />
                  ) : (
                    <img
                      src="/assets/img/audio-off.png"
                      alt="audio-off"
                      loading="lazy"
                    />
                  )}
                </Button>
                <Button
                  className="btn btnVideoRoom btnEndCall"
                  onClick={() => this._leaveChannel()}
                >
                  <img
                    src="/assets/img/end-call.png"
                    alt="end-call"
                    loading="lazy"
                  />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loaderData: state.loaderData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (loaderData) => dispatch(showLoader(loaderData)),
    hideLoader: (loaderData) => dispatch(hideLoader(loaderData)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChatVideoRoomComponent);
