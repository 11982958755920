import React, { useEffect, useState } from "react";
import { Col, Container, Row, Button } from "reactstrap";
import {
  errorHandler,
  isPermissionToAccess,
  showToast,
} from "../helper-methods";
import { deletPost, editPost, getPostDetails } from "../http-calls";
import FeedViewer from "../components/FeedViewer";
import { useHistory, useParams } from "react-router-dom";
import SkeletonLoading from "../components/SkeletonLoading";
import ErrorBoundary from "../components/ErrorBoundary";

const PostDetailsPage = () => {
  const history = useHistory();

  const params = useParams();

  const [feed, setFeed] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const _fetchPostDetails = async () => {
    try {
      setIsLoading(true);
      const res = await getPostDetails(params?.id);

      setFeed(res.post);

      setIsLoading(false);
    } catch (error) {
      history.push("/feed");
      errorHandler(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!isPermissionToAccess("feed", "canViewFeed")) {
      showToast("Unauthorized", "error");
      // Assistant dont have access to view PPV
      history.push("/my-profile");
      return;
    }

    _fetchPostDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _pinToProfile = async (id, isPinned) => {
    try {
      const newFeed = { ...feed };
      newFeed.isPinned = isPinned;
      setFeed(newFeed);

      await editPost({ isPinned }, id);

      _fetchPostDetails();
    } catch (error) {
      errorHandler(error);

      _fetchPostDetails();
    }
  };

  const _deletePost = async (id) => {
    try {
      await deletPost(id);

      history.push("/feed");

      showToast("Post has been deleted", "success");
    } catch (error) {
      errorHandler(error);
    }
  };

  const _onCommentPost = (index, isAdded) => {
    try {
      const newFeed = { ...feed };

      let commentsCount = 0;
      if (isAdded) {
        if (feed?.hasOwnProperty("comments")) {
          commentsCount = feed["comments"] + 1;
        } else {
          commentsCount = 1;
        }
      } else {
        if (+feed?.comments) {
          commentsCount = feed["comments"] - 1;
        }
      }
      newFeed["comments"] = commentsCount;
      setFeed(newFeed);
    } catch (error) {
      console.log({ error });
      _fetchPostDetails();
    }
  };

  const _updateCommentsCount = ({ value }) => {
    try {
      const newFeed = feed;
      newFeed.comments = value;
      setFeed(newFeed);
    } catch (error) {
      console.log({ error });
    }
  };

  return (
    <div className="customPgHeight animated fadeIn">
      <Container className="noPadding">
        <Row className="justify-content-center noMargin">
          <Col sm={12} lg={10} xl={8} className="noPadding bg-white">
            <div className="pgTitle customMarginMob">
              <Button
                className="customBackBtn"
                onClick={() => history.goBack()}
              >
                <i className="fa fa-chevron-left" />
              </Button>
            </div>
            {feed ? (
              <ErrorBoundary>
                <FeedViewer
                  feed={feed}
                  feedIndex={0}
                  deletePost={() => _deletePost(feed._id)}
                  isEditFeed={false}
                  pinToProfile={(isPinned) => _pinToProfile(feed._id, isPinned)}
                  onComment={(index, isAdded) => _onCommentPost(index, isAdded)}
                  updateCommentsCount={({ value }) =>
                    _updateCommentsCount({ value })
                  }
                />
              </ErrorBoundary>
            ) : isLoading ? (
              <SkeletonLoading type={"post"} count={1} />
            ) : null}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PostDetailsPage;
