import React from "react";
import { Badge, Button, Table } from "reactstrap";
import {
  capitalize,
  copyToClipboard,
  formatPhoneNumber,
  joinName,
} from "../helper-methods";
import SkeletonLoading from "./SkeletonLoading";

const ReferralHistoryTable = ({
  confirmationPrompt,
  isTimeMoreThan24h,
  loadingState,
  referralHistoryList,
  isUserAccountScheduledForDelete,
}) => {
  return (
    <>
      <div className="referralHistoryTable mt-3">
        <Table responsive>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {referralHistoryList?.length ? (
              referralHistoryList?.map((history, index) => (
                <tr key={index}>
                  <td>{joinName(history.name)}</td>
                  <td>
                    {history?.email?.length ? (
                      <span
                        className="cursorPointer"
                        onClick={() => copyToClipboard(history.email)}
                      >
                        {history.email}
                      </span>
                    ) : (
                      "N/A"
                    )}
                  </td>
                  <td>
                    {history?.phone?.length ? (
                      <span
                        className="cursorPointer"
                        onClick={() => copyToClipboard(history.phone)}
                      >
                        {formatPhoneNumber(history.phone)}
                      </span>
                    ) : (
                      "N/A"
                    )}
                  </td>
                  <td>
                    <div className="d-flex align-items-end">
                      <Badge
                        color={
                          history.status !== "accepted" ? "warning" : "success"
                        }
                        className="tableDataStatus"
                      >
                        {capitalize(history.status)}
                      </Badge>
                      {!isUserAccountScheduledForDelete &&
                      history &&
                      history.status !== "accepted" &&
                      isTimeMoreThan24h(history) ? (
                        <Button
                          className="sendReminder mx-2"
                          onClick={() => confirmationPrompt(history)}
                          disabled={loadingState?.reminder === history.id}
                        >
                          {loadingState?.reminder === history.id ? (
                            <i className="fa fa-spinner fa-spin" />
                          ) : null}{" "}
                          Send Reminder
                        </Button>
                      ) : null}
                    </div>
                  </td>
                </tr>
              ))
            ) : loadingState.referralHistory ? (
              <SkeletonLoading type={"table"} rows={1} col={4} />
            ) : (
              <tr>
                <td colSpan="4" className="text-center">
                  No requests yet
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default ReferralHistoryTable;
