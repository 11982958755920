import React from "react";
import Skeleton from "react-loading-skeleton";
import { Col } from "reactstrap";

const SkeletonPhotoLibrary = ({ count }) => {
  return (
    <>
      {[...Array(count)].map((_, index) => (
        <Col xs="6" sm="4" lg="3" key={index} className="customPaddingSticker">
          <Skeleton
            height={120}
            width={"100%"}
            borderRadius={16}
            style={{ marginBottom: 7.5 }}
          />
        </Col>
      ))}
    </>
  );
};

export default SkeletonPhotoLibrary;
