import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  CustomInput,
  Label,
} from "reactstrap";
import { ReactMic } from "react-mic";
import colors from "../../assets/styles/scss/style.scss";
import { showToast } from "../../helper-methods";

const SoundRecordUploadModal = ({
  isOpen,
  toggle,
  modalHeading,
  onSave,
  uploadFile,
  isMultipleUpload,
  isUploadFile,
  isHideUploadAudioFile,
}) => {
  const [isUploadAudio, SetIsUploadAudio] = useState(false);

  const [recordedFile, setRecordedFile] = useState({
    recordredAudio: null,
    isAudioRecording: false,
  });

  const _resetModalState = () => {
    SetIsUploadAudio(false);

    setRecordedFile({
      recordredAudio: null,
      isAudioRecording: false,
    });
  };

  const _closeModal = () => {
    _resetModalState();
    toggle();
  };

  const _startRecording = () => {
    const newRecordedFile = { ...recordedFile };
    newRecordedFile["isAudioRecording"] = true;
    setRecordedFile({ ...newRecordedFile });
  };

  const _stopRecording = () => {
    const newRecordedFile = { ...recordedFile };
    newRecordedFile["isAudioRecording"] = false;
    setRecordedFile({ ...newRecordedFile });
  };

  const _onStop = (recordedBlob = null) => {
    if (recordedBlob) {
      const newRecordedFile = { ...recordedFile };
      newRecordedFile["recordredAudio"] = recordedBlob;
      setRecordedFile({ ...newRecordedFile });
    }
  };

  const _saveSoundRecord = () => {
    if (recordedFile.recordredAudio) {
      if (isUploadFile) {
        _readFile({
          target: {
            files: [
              {
                ...recordedFile.recordredAudio,
                type: "audio/*",
                isRecordredAudio: true,
              },
            ],
          },
        });
      } else {
        onSave(recordedFile.recordredAudio);
        _resetModalState();
      }
    }
  };

  const _readFile = (e) => {
    try {
      if (!e?.target?.files?.length) {
        return;
      }

      console.log(e.target.files);

      for (let file of e.target.files) {
        if (!file.type.includes("audio")) {
          showToast("Only audio file is allowed", "error");
          return;
        }
      }

      uploadFile(e);
      _closeModal();
    } catch (error) {
      console.log({ error });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => _closeModal()}
      className="modal-dialog-centered modal-dialog-scrollable"
    >
      <ModalHeader toggle={() => _closeModal()}>
        {modalHeading || "Add Audio"}
      </ModalHeader>
      <ModalBody>
        {!isHideUploadAudioFile ? (
          <div className="d-flex mb-4 justify-content-center">
            <CustomInput
              type="radio"
              id="isUploadAudio_upload_CustomRadio"
              name="isUploadAudio"
              label="Upload Audio"
              checked={isUploadAudio}
              onChange={() => SetIsUploadAudio(true)}
              className="mr-5"
            />

            <CustomInput
              type="radio"
              id="isUploadAudio_record_CustomRadio2"
              name="isUploadAudio"
              label="Record Audio"
              checked={!isUploadAudio}
              onChange={() => SetIsUploadAudio(false)}
            />
          </div>
        ) : null}

        {isUploadAudio && !isHideUploadAudioFile ? (
          <Label className="themeBtn addBtn d-block mx-auto mb-1">
            <input
              accept="audio/*"
              type="file"
              multiple={isMultipleUpload}
              onChange={(e) => _readFile(e)}
              style={{ display: "none" }}
            />
            <i className="fa fa-cloud-upload" /> Audio
          </Label>
        ) : (
          <div>
            <div className="d-flex justify-content-center">
              <p>Record Audio</p>
            </div>
            {/* Note: add the Audio Plugin here */}
            <ReactMic
              record={recordedFile.isAudioRecording}
              className="chat-oscilloscope"
              onStop={(recordedBlob) => _onStop(recordedBlob)}
              // onData={() => _onData()}
              strokeColor={colors?.themeColor}
            />

            <div>
              {recordedFile.isAudioRecording ? (
                <Button
                  onClick={() => _stopRecording()}
                  type="button"
                  className="themeBtn addBtn d-block mx-auto"
                >
                  Stop
                </Button>
              ) : (
                <Button
                  onClick={() => _startRecording()}
                  type="button"
                  className="themeBtn addBtn d-block mx-auto"
                >
                  Start
                </Button>
              )}
            </div>

            {recordedFile?.recordredAudio?.blobURL ? (
              <audio
                controls
                className="audioRecorded mt-3"
                controlsList="nodownload"
                src={
                  recordedFile?.recordredAudio?.blobURL
                    ? recordedFile.recordredAudio.blobURL
                    : ""
                }
              />
            ) : null}
          </div>
        )}
      </ModalBody>

      <ModalFooter>
        <Button
          className="modalBtnCancel"
          disabled={recordedFile.isAudioRecording}
          onClick={() => _closeModal()}
        >
          Cancel
        </Button>

        {!isUploadAudio && (
          <Button
            disabled={
              !recordedFile.recordredAudio || recordedFile.isAudioRecording
            }
            className="modalBtnSave"
            onClick={() => _saveSoundRecord()}
          >
            Save
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default SoundRecordUploadModal;
