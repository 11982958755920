import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import { APP_NAME, APP_TUTORIAL_LINK } from "../../config";
import { useSelector } from "react-redux";
import { updateProfile } from "../../http-calls";
import { errorHandler } from "../../helper-methods";
import { useDispatch } from "react-redux";
import { fetchAndModifiedUserDetails } from "../../redux/actions/userData";

const WelcomeCreatorModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const user = useSelector((state) => state?.userData?.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user && !user.isWelcomePopupShown) {
      setIsOpen(true);
    }
  }, [user]);

  const _updateProfile = async () => {
    try {
      await updateProfile({ isWelcomePopupShown: true });
      dispatch(fetchAndModifiedUserDetails());
    } catch (error) {
      errorHandler(error);
    }
  };

  const _closeModal = () => {
    setIsOpen(false);
    _updateProfile();
  };

  const _redirectToTutorialPage = () => {
    window.open(APP_TUTORIAL_LINK, "_blank");
    _closeModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={_closeModal}
      className="modal-dialog-centered welcomeModel"
    >
      <ModalBody>
        <div className="welcomeModalTxt">
          <h4>Welcome to</h4>
          <h4>{APP_NAME}</h4>
          <p>See all the new features we've added through our tutorial.</p>
        </div>
        <Button className="themeBtn loginBtn" onClick={_redirectToTutorialPage}>
          See Tutorials
        </Button>
        <Button
          color="link"
          className="skipTxt w-100 d-block"
          onClick={_closeModal}
        >
          Skip for now
        </Button>
      </ModalBody>
    </Modal>
  );
};

export default WelcomeCreatorModal;
