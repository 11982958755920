import { capitalize } from "@material-ui/core";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  Table,
  Input,
  FormGroup,
  Badge,
  CustomInput,
  Collapse,
} from "reactstrap";
import { FRONTEND_FAN_BASE_URL } from "../../config";
import { allEarningFieldNameConfig } from "../../config/statement-earning-fieldname";
import {
  capitalizeEveryFirstLetter,
  deepClone,
  errorHandler,
  formatCurrencyValue,
  formatDateAndTime,
  formatPayoutStatus,
  getFeatureStatus,
  getPayoutStatusColor,
  handleNameObj,
} from "../../helper-methods";
import { getConfiguredString } from "../../helper-methods/configureString";
import { getAllEarnings, getAllTransactionsFilters } from "../../http-calls";
import { saveLocalFilters } from "../../redux/actions";
import CustomLoader from "../custom/CustomLoader";
import CustomDateRangePicker from "../CustomDateRangePicker";
import SkeletonLoading from "../SkeletonLoading";

const EarningsTable = ({ activeTab, tabId }) => {
  const history = useHistory();

  const dispatch = useDispatch();

  const userData = useSelector((state) => state?.userData);
  const localFilters = useSelector((state) => state?.localFilters);

  const [filters, setFilters] = useState({
    filterType: "checkbox", // dropdown
    startDate: null,
    endDate: null,
    checkbox: {
      paymentType: [],
    },
    dropdown: {
      paymentType: "",
      paymentTypeId: "",
    },
  });
  const [dataPayload, setDataPayload] = useState({
    skip: 0,
    limit: 20,
  });

  const [dualDropdownValue, setDualDropdownValue] = useState({
    vault: [],
    liveEvent: [],
    payperview: [],
    referrals: [],
    shoutout: [],
  });
  const [earnings, setEarnings] = useState(false);
  const [earningsCount, setEarningsCount] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isOpenFilterCollapse, setIsOpenFilterCollapse] = useState(false);

  const isLiveEventActive = useMemo(() => getFeatureStatus("event"), []);
  const isPayPerViewActive = useMemo(() => getFeatureStatus("ppv"), []);
  const isShoutoutActive = useMemo(() => getFeatureStatus("shoutout"), []);
  const isVaultActive = useMemo(() => getFeatureStatus("vault"), []);

  const filterAllEarningFieldNameConfig = useMemo(
    () =>
      allEarningFieldNameConfig.filter((each) => {
        if (each.value === "vault" && !isVaultActive) return null;
        if (each.value === "liveEvent" && !isLiveEventActive) return null;
        if (each.value === "payperview" && !isPayPerViewActive) return null;
        if (each.value === "shoutout" && !isShoutoutActive) return null;
        return each;
      }),
    [isVaultActive, isLiveEventActive, isPayPerViewActive, isShoutoutActive]
  );

  const webObserver = useRef();
  const mobileObserver = useRef();

  const webLastElementRef = useCallback(
    (node) => {
      if (loading) return;

      if (webObserver.current) webObserver.current.disconnect();

      webObserver.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && earnings?.length < earningsCount) {
          const newDataPayload = { ...dataPayload };
          newDataPayload["skip"] = earnings?.length;
          setDataPayload(newDataPayload);
          _getAllEarnings(newDataPayload);
        }
      });

      if (node) webObserver.current.observe(node);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loading, earnings]
  );

  const mobileLastElementRef = useCallback(
    (node) => {
      if (loading) return;

      if (mobileObserver.current) mobileObserver.current.disconnect();

      mobileObserver.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && earnings?.length < earningsCount) {
          const newDataPayload = { ...dataPayload };
          newDataPayload["skip"] = earnings?.length;
          setDataPayload(newDataPayload);
          _getAllEarnings(newDataPayload);
        }
      });

      if (node) mobileObserver.current.observe(node);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loading, earnings]
  );

  const _getPaymentTypeInFormat = (paymentType) => {
    switch (paymentType) {
      case "payperview": {
        return getConfiguredString("ppv");
      }
      case "liveEvent": {
        return getConfiguredString("event");
      }
      case "shoutout": {
        return getConfiguredString("shoutout");
      }
      case "vault": {
        return getConfiguredString("vault");
      }
      default: {
        return capitalize(paymentType);
      }
    }
  };

  const _onUserNameClick = (e, earning) => {
    if (e) e.preventDefault();

    if (userData?.user?.id === earning._refferer) {
      if (earning._to.userType === "Influencer") {
        // history.push("/influencer/" + earning._to.username);
        window.open(
          `${FRONTEND_FAN_BASE_URL}/${earning._to.username}`,
          "_blank"
        );
      }
    } else if (userData?.user?.id !== earning._refferer) {
      if (earning?._from?.userType === "Fan") {
        const username = earning?._from?.username;
        history.push(`/my-profile?tabId=4&username=${username}`);
      }
    }
  };

  const _getIndividualEarning = (earning) => {
    let data = {};

    let destAmt = +earning?.amountToDestination || 0;
    let referAmt = +earning?.amountToRefferer || 0;

    if (earning?._to?._id === userData?.user?._id) {
      data["earnings"] = earning.amountToDestination
        ? formatCurrencyValue(earning.amountToDestination)
        : formatCurrencyValue(0);
      data["description"] = (
        <>
          {earning.paymentType === "tips"
            ? userData?.user?.settings?.tipText
              ? capitalize(userData.user.settings.tipText)
              : "Tip"
            : _getPaymentTypeInFormat(earning.paymentType)}{" "}
          from{" "}
          <Button
            color="link"
            style={{ width: "max-content" }}
            onClick={(e) => _onUserNameClick(e, earning)}
          >
            {((earning["_from"]?.name?.full || earning["_from"]?.name?.first) &&
              capitalizeEveryFirstLetter(
                handleNameObj(earning["_from"]?.name)
              )) ||
              `@${earning["_from"]?.username}`}
          </Button>
        </>
      );
      data["platformFee"] = formatCurrencyValue(
        earning.amount + (earning.amountToRefferer || 0) - (destAmt + referAmt)
      );
      data["attr"] = "_from";
    } else if (earning?._refferer === userData?.user?._id) {
      data["earnings"] = earning.amountToRefferer
        ? formatCurrencyValue(earning.amountToRefferer)
        : formatCurrencyValue(0);
      data["description"] = (
        <>
          {earning.paymentType === "tips"
            ? userData?.user?.settings?.tipText
              ? capitalize(userData.user.settings.tipText)
              : "Tip"
            : _getPaymentTypeInFormat(earning.paymentType)}{" "}
          to{" "}
          <Button color="link" onClick={(e) => _onUserNameClick(e, earning)}>
            {((earning["_to"]?.name?.full || earning["_to"]?.name?.first) &&
              capitalizeEveryFirstLetter(
                handleNameObj(earning["_to"]?.name)
              )) ||
              `@${earning["_to"]?.username}`}
          </Button>
        </>
      );
      data["platformFee"] = formatCurrencyValue(
        earning.amount + (earning.amountToRefferer || 0) - (destAmt + referAmt)
      );
      data["attr"] = "_to";
    }
    return data;
  };

  const _getOptionLabelName = (data, type) => {
    if (type === "referrals") {
      return data?.name?.full
        ? capitalizeEveryFirstLetter(data?.name?.full)
        : data.username;
    } else if (type === "shoutout") {
      return data._fan?.name?.full
        ? capitalizeEveryFirstLetter(data._fan?.name?.full)
        : data._fan.username;
    } else {
      return data.name || data.title;
    }
  };

  const _toggleFilterType = (value) => {
    const newFilters = { ...filters };
    newFilters.filterType = value;
    setFilters(newFilters);

    _onFiltersChange(newFilters);
  };

  const _onFiltersChange = (newFilters) => {
    dispatch(
      saveLocalFilters({ key: "earningsTable", value: deepClone(newFilters) })
    );

    const newDataPayload = { ...dataPayload };
    newDataPayload.skip = 0;
    newDataPayload.filters = {};

    if (newFilters?.filterType === "checkbox") {
      if (newFilters?.checkbox?.paymentType?.length) {
        newDataPayload.filters.paymentType = newFilters.checkbox.paymentType;
      }
    } else {
      if (newFilters?.dropdown?.paymentType?.length) {
        newDataPayload.filters.paymentType = [newFilters.dropdown.paymentType];
      }
      if (newFilters?.dropdown?.paymentTypeId?.length) {
        switch (newFilters?.dropdown?.paymentType) {
          case "vault": {
            newDataPayload.filters._folder = newFilters.dropdown.paymentTypeId;
            break;
          }
          case "liveEvent": {
            newDataPayload.filters._event = newFilters.dropdown.paymentTypeId;
            break;
          }
          case "payperview": {
            newDataPayload.filters._payperview =
              newFilters.dropdown.paymentTypeId;
            break;
          }
          case "shoutout": {
            newDataPayload.filters._shoutout =
              newFilters.dropdown.paymentTypeId;
            break;
          }
          case "referrals": {
            newDataPayload.filters._refferedUser =
              newFilters.dropdown.paymentTypeId;
            break;
          }
          default:
        }
      }
    }

    if (newFilters?.startDate && newFilters?.endDate) {
      newDataPayload.filters.startDate = newFilters.startDate;
      newDataPayload.filters.endDate = newFilters.endDate;
    }

    setDataPayload(newDataPayload);
    _getAllEarnings(newDataPayload);
  };

  const _onChangeFilters = (key, subKey, value) => {
    const newFilters = { ...filters };

    if (key === "checkbox" && subKey === "paymentType") {
      const findIndex = newFilters[key][subKey].indexOf(value);
      if (findIndex >= 0) {
        newFilters[key][subKey].splice(findIndex, 1);
      } else {
        newFilters[key][subKey].push(value);
      }
    } else {
      newFilters[key][subKey] = value;
      if (subKey === "paymentType") {
        newFilters[key].paymentTypeId = "";
      }
    }

    setFilters(newFilters);

    _onFiltersChange(newFilters);
  };

  const _onDatesChange = ({ startDate, endDate }) => {
    const newFilters = { ...filters };
    newFilters.startDate = startDate?._d ? startDate.toISOString() : null;
    newFilters.endDate = endDate?._d ? endDate.toISOString() : null;
    setFilters(newFilters);

    if (newFilters?.startDate && newFilters?.endDate) {
      _onFiltersChange(newFilters);
      return;
    }

    if (!newFilters?.startDate && !newFilters?.endDate) {
      _onFiltersChange(newFilters);
      return;
    }
  };

  const _resetFilter = () => {
    const newFilters = {
      filterType: "checkbox",
      startDate: null,
      endDate: null,
      checkbox: {
        paymentType: [],
      },
      dropdown: {
        paymentType: "",
        paymentTypeId: "",
      },
    };

    setFilters(newFilters);

    _onFiltersChange(newFilters);
  };

  const _getAllTransactionsFilters = async () => {
    try {
      const res = await getAllTransactionsFilters();

      setDualDropdownValue({
        vault: res.folders?.length ? res.folders : [],
        liveEvent: res.events?.length ? res.events : [],
        payperview: res.ppvs?.length ? res.ppvs : [],
        referrals: res.referrals?.length ? res.referrals : [],
        shoutout: res.shoutouts?.length ? res.shoutouts : [],
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  const _getAllEarnings = async (payload) => {
    try {
      setLoading(true);

      payload = deepClone(payload);

      if (payload?.hasOwnProperty("skip")) {
        payload.pagination = {
          skip: payload.skip,
          limit: payload.limit,
        };

        delete payload.skip;
        delete payload.limit;
      }
      if (payload?.hasOwnProperty("filters")) {
        payload = {
          ...payload,
          ...payload.filters,
        };

        delete payload.filters;
      }

      const res = await getAllEarnings(payload);

      setEarnings((prev) =>
        payload?.pagination?.skip ? prev.concat(res.earnings) : res.earnings
      );
      setEarningsCount(res.docCount);

      setLoading(false);
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (activeTab === tabId) {
      if (localFilters?.filters?.earningsTable) {
        setFilters(localFilters.filters.earningsTable);
        _onFiltersChange(localFilters.filters.earningsTable);
      } else {
        _getAllEarnings(dataPayload);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, tabId]);

  useEffect(() => {
    _getAllTransactionsFilters();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mt-3">
        <div className="d-inline-block align-items-center">
          <CustomDateRangePicker
            startDate={filters?.startDate}
            endDate={filters?.endDate}
            startDateId={`startDate_earning_statistics`}
            endDateId={`endDate_earning_statistics`}
            onDatesChange={({ startDate, endDate }) =>
              _onDatesChange({
                startDate,
                endDate,
              })
            }
          />
        </div>

        {loading ? (
          <CustomLoader className="mx-2" />
        ) : (
          <Button
            className="toggleBtn toggleBtnStatement"
            onClick={() => setIsOpenFilterCollapse((prev) => !prev)}
          >
            <img
              src="/assets/img/filter-icon.png"
              alt="Filter Icon"
              height="24"
              loading="lazy"
            />
          </Button>
        )}
      </div>

      <Collapse
        isOpen={isOpenFilterCollapse}
        className="customFilterMob customFilterEarnings"
      >
        <div className="d-flex">
          <CustomInput
            type="radio"
            id="customRadio_earningFilterRadio_checkbox"
            name="earningFilterRadio"
            checked={filters.filterType === "checkbox"}
            value="checkbox"
            onChange={(e) => {
              _toggleFilterType(e.target.value);
            }}
          />

          <div className="d-flex flex-wrap ml-2 w-100">
            {filterAllEarningFieldNameConfig.map((keyObj, index) => {
              return (
                <FormGroup key={keyObj.value} className="checkboxEarnings">
                  <CustomInput
                    type="checkbox"
                    id={`custom_checkbox_${index}_${keyObj.value}`}
                    disabled={filters.filterType !== "checkbox"}
                    checked={filters.checkbox?.paymentType?.includes(
                      keyObj.value
                    )}
                    onChange={(event) => {
                      _onChangeFilters("checkbox", "paymentType", keyObj.value);
                    }}
                    label={
                      keyObj.languageParseText
                        ? getConfiguredString(keyObj.languageParseText)
                        : keyObj.label
                    }
                  />
                </FormGroup>
              );
            })}
          </div>
        </div>

        <hr style={{ margin: "10px 0 30px" }} />

        <div className="d-flex">
          <CustomInput
            type="radio"
            id="customRadio_earningFilterRadio_dropdown"
            name="earningFilterRadio"
            checked={filters.filterType === "dropdown"}
            value="dropdown"
            onChange={(e) => {
              _toggleFilterType(e.target.value);
            }}
            label=""
          />

          <FormGroup className="mr-2 mr-sm-3 ml-2">
            <Input
              type="select"
              style={{ width: 135 }}
              name="earningFilterDropdown1"
              value={filters?.dropdown?.paymentType || ""}
              disabled={filters.filterType !== "dropdown"}
              onChange={(event) => {
                _onChangeFilters("dropdown", "paymentType", event.target.value);
              }}
            >
              <option value="">All</option>
              {filterAllEarningFieldNameConfig.map((keyObj) => {
                if (!keyObj.showInDropdown) return null;
                return (
                  <option key={keyObj.value} value={keyObj.value}>
                    {keyObj.languageParseText
                      ? getConfiguredString(keyObj.languageParseText)
                      : keyObj.label}
                  </option>
                );
              })}
            </Input>
          </FormGroup>

          <FormGroup>
            <Input
              type="select"
              style={{ minWidth: 80 }}
              name="earningFilterDropdown2"
              value={filters?.dropdown?.paymentTypeId || ""}
              disabled={filters.filterType !== "dropdown"}
              onChange={(event) => {
                _onChangeFilters(
                  "dropdown",
                  "paymentTypeId",
                  event.target.value
                );
              }}
            >
              <option value="">All</option>
              {dualDropdownValue?.[filters?.dropdown?.paymentType]?.length
                ? dualDropdownValue[filters?.dropdown?.paymentType].map((e) => {
                    return (
                      <option key={e.id || e._id} value={e.id || e._id}>
                        {_getOptionLabelName(e, filters?.dropdown?.paymentType)}
                      </option>
                    );
                  })
                : null}
            </Input>
          </FormGroup>
        </div>

        <Button
          className="themeBtn addBtn d-block mx-auto"
          onClick={() => _resetFilter()}
        >
          Reset
        </Button>
      </Collapse>

      <div className="referralHistoryTable earningsDataTable">
        <Table responsive>
          <thead>
            <tr>
              <th>Date</th>
              <th>Amount Paid</th>
              <th>Platform Fee</th>
              <th>Earnings</th>
              <th>Description</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {earnings?.length ? (
              React.Children.toArray(
                earnings.map((each, index) => (
                  <Fragment>
                    <tr
                      {...(index === earnings.length - 1
                        ? { ref: webLastElementRef }
                        : {})}
                    >
                      <td>{formatDateAndTime(each.time)}</td>
                      <td>
                        {each.amount
                          ? formatCurrencyValue(each.amount)
                          : formatCurrencyValue(0)}
                      </td>
                      <td>{_getIndividualEarning(each)?.platformFee}</td>
                      <td>{_getIndividualEarning(each)?.earnings}</td>
                      <td>{_getIndividualEarning(each)?.description}</td>
                      <td>
                        <Badge
                          color={getPayoutStatusColor(each.status)}
                          className="tableDataStatus"
                        >
                          {formatPayoutStatus({
                            id: each._id,
                            status: each.status,
                            reason: each.reason,
                          })}
                        </Badge>
                      </td>
                    </tr>

                    {loading && index === earnings?.length - 1 ? (
                      <SkeletonLoading type={"table"} rows={1} col={6} />
                    ) : null}
                  </Fragment>
                ))
              )
            ) : loading ? (
              <SkeletonLoading type={"table"} rows={1} col={6} />
            ) : (
              <tr>
                <td colSpan="6" className="text-center">
                  No earnings yet
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>

      <Row className="noMargin d-md-none mt-3">
        {earnings?.length ? (
          React.Children.toArray(
            earnings.map((each, index) => (
              <Fragment>
                <Col xs="12" md="6" className="noPadding">
                  <div
                    className="earningsDataWrap"
                    {...(index === earnings.length - 1
                      ? { ref: mobileLastElementRef }
                      : {})}
                  >
                    <div className="earningsData">
                      <div>
                        <p>Date</p>
                        <h5>{formatDateAndTime(each.time)}</h5>
                      </div>
                      <div>
                        <p>Amount</p>
                        <h5>{formatCurrencyValue(each.amount || 0)}</h5>
                      </div>
                      <div>
                        <p>Fee</p>
                        <h5>{_getIndividualEarning(each)?.platformFee}</h5>
                      </div>
                      <div>
                        <p>Earnings</p>
                        <h5>{_getIndividualEarning(each)?.earnings}</h5>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <div>{_getIndividualEarning(each)?.description}</div>

                      <Badge
                        color={getPayoutStatusColor(each.status)}
                        className="tableDataStatus ml-1"
                      >
                        {formatPayoutStatus({
                          id: each._id,
                          status: each.status,
                          reason: each.reason,
                        })}
                      </Badge>
                    </div>
                  </div>
                </Col>

                {index === earnings?.length - 1 && loading && (
                  <SkeletonLoading type={"earningTable"} count={3} />
                )}
              </Fragment>
            ))
          )
        ) : (
          <Col xs="12" className="noPadding">
            {loading ? (
              <SkeletonLoading type={"earningTable"} count={3} />
            ) : (
              <div className="noContentFound mt-3 mb-1">No earnings yet</div>
            )}
          </Col>
        )}
      </Row>
    </>
  );
};

export default EarningsTable;
