import { CURRENCY_SYMBOL } from ".";

export const AmountConfig = {
  maximumLimit: 100000,
  minimumLimit: 5,
  minChatPrice: 5,
  minShoutOutPrice: 1,
  minGoalPrice: 5,
  minVaultPrice: 3,
  minEventPrice: 5,
  minPPVprice: 5,
  minSubscriptionPrice: 1,
  minCheerIconPrice: 1,
};

export const DescriptionConfig = {
  minLetter: 50,
  maxLetter: 500,
};

export const fanAmountSpentConfig = [
  {
    label: `${CURRENCY_SYMBOL}0-20`,
    value: `0-20`,
    data: { min: 0, max: 20 },
  },
  {
    label: `${CURRENCY_SYMBOL}20-50`,
    value: `20-50`,
    data: { min: 20, max: 50 },
  },
  {
    label: `${CURRENCY_SYMBOL}50-100`,
    value: `50-100`,
    data: { min: 50, max: 100 },
  },
  {
    label: `${CURRENCY_SYMBOL}100+`,
    value: `100+`,
    data: { min: 100 },
  },
];

export const fanCategoryConfig = [
  {
    label: "Muted",
    value: "muted",
  },
  {
    label: "Favorited",
    value: "favourited",
  },
  {
    label: "Blocked",
    value: "blocked",
  },
  {
    label: "Expired",
    value: "expired",
  },
];
