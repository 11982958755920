/* eslint-disable no-use-before-define */
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, FormGroup, Input } from "reactstrap";
import { RegexConfig } from "../../config/RegexConfig";
import {
  errorHandler,
  isPermissionToAccess,
  showToast,
} from "../../helper-methods";
import {
  connectWithApple,
  connectWithFacebook,
  connectWithGoogle,
  updateInfluencerDetails,
} from "../../http-calls";
import {
  fetchAndModifiedUserDetails,
  hideLoader,
  showLoader,
  // updateOnlyUserData,
} from "../../redux/actions";
import GoogleLoginComponent from "../socialLogin/GoogleLoginComponent";
import FacebookLoginComponent from "../socialLogin/FacebookLoginComponent";
import AppleLoginComponent from "../socialLogin/AppleLoginComponent";

const SocialMediaAccounts = () => {
  const influencer = useSelector((state) => state?.userData?.user);
  const dispatch = useDispatch();

  const canUpdateProfileSettings = useMemo(
    () =>
      isPermissionToAccess("profileAndSettings", "canUpdateProfileSettings"),
    []
  );

  const [loading, setLoading] = useState(false);
  const [formFields, setFormFields] = useState({
    twitter: "",
    youtube: "",
    facebook: "",
    tiktok: "",
    instagram: "",
  });

  const [errors, setErrors] = useState({});
  const [isDirty, setIsDirty] = useState({});
  const [hasFormFieldChanged, setHasFormFieldChanged] = useState(false);

  const _connectWithFacebook = async (response) => {
    try {
      dispatch(showLoader(`Connecting through facebook...`));

      await connectWithFacebook({
        access_token: response.accessToken,
        mode: "web",
        userType: "Influencer",
      });

      dispatch(fetchAndModifiedUserDetails());

      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
      errorHandler(error);
    }
  };

  const _connectWithGoogle = async (response) => {
    try {
      dispatch(showLoader(`Connecting through google...`));

      await connectWithGoogle({
        access_token: response?.access_token,
        mode: "web",
        userType: "Influencer",
      });

      dispatch(fetchAndModifiedUserDetails());

      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
      errorHandler(error);
    }
  };

  const _connectWithApple = async (response) => {
    try {
      dispatch(showLoader(`Connecting through apple...`));

      await connectWithApple({
        access_token: response?.accessToken,
        mode: "web",
        userType: "Influencer",
      });

      dispatch(fetchAndModifiedUserDetails());

      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
      errorHandler(error);
    }
  };

  const _preFillData = (influencer) => {
    const newFormFields = { ...formFields };
    influencer.social.forEach((socialLink) => {
      newFormFields[socialLink?.["name"]] = socialLink?.["accountUrl"];
    });
    setFormFields(newFormFields);
  };

  const _createPayload = (formFields) => {
    let payload = [];

    for (const social in formFields) {
      if (formFields[social].length) {
        payload.push({
          name: social.trim(),
          accountUrl: formFields[social].trim(),
        });
      }
    }
    return payload;
  };

  const _markAllIsDirty = () => {
    return new Promise((resolve) => {
      const newIsDirty = { ...isDirty };
      const newFormFields = { ...formFields };
      Object.keys(newFormFields).forEach((key) => {
        newIsDirty[key] = true;
      });
      setIsDirty(newIsDirty);
      resolve(newIsDirty);
    });
  };

  const _hasSocialLinkChanged = (formFields) => {
    let isupdated = false;

    for (const plateform in formFields) {
      const plateformPresentInUserData = influencer.social.find(
        (each) => each?.name === plateform
      );

      if (!plateformPresentInUserData?._id) {
        if (formFields?.[plateform]?.length) {
          isupdated = true;
          break;
        } else {
          continue;
        }
      }

      if (
        plateformPresentInUserData?.accountUrl !==
        formFields?.[plateform]?.trim()
      ) {
        isupdated = true;
        break;
      }
    }

    setHasFormFieldChanged(isupdated);
  };

  const _validateFormFields = (newFormFields, newIsDirty) => {
    let newErrors = { ...errors };

    return new Promise((resolve) => {
      let isFormValid = true;
      Object.keys(newFormFields).forEach((key) => {
        if (!newIsDirty[key]) return;

        switch (key) {
          case "twitter": {
            if (formFields.twitter.length) {
              if (
                RegexConfig.url.test(String(formFields.twitter).toLowerCase())
              ) {
                newErrors[key] = null;
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "Link is not Valid";
                isFormValid = false;
              }
            } else {
              newErrors[key] = null;
              newIsDirty[key] = false;
            }
            break;
          }
          case "youtube": {
            if (formFields.youtube.length) {
              if (
                RegexConfig.url.test(String(formFields.youtube).toLowerCase())
              ) {
                newErrors[key] = null;
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "Link is not Valid";
                isFormValid = false;
              }
            } else {
              newErrors[key] = null;
              newIsDirty[key] = false;
            }
            break;
          }
          case "facebook": {
            if (formFields.facebook.length) {
              if (
                RegexConfig.url.test(String(formFields.facebook).toLowerCase())
              ) {
                newErrors[key] = null;
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "Link is not Valid";
                isFormValid = false;
              }
            } else {
              newErrors[key] = null;
              newIsDirty[key] = false;
            }
            break;
          }
          case "tiktok": {
            if (formFields.tiktok.length) {
              if (
                RegexConfig.url.test(String(formFields.tiktok).toLowerCase())
              ) {
                newErrors[key] = null;
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "Link is not Valid";
                isFormValid = false;
              }
            } else {
              newErrors[key] = null;
              newIsDirty[key] = false;
            }
            break;
          }
          case "instagram": {
            if (formFields.instagram.length) {
              if (
                RegexConfig.url.test(String(formFields.instagram).toLowerCase())
              ) {
                newErrors[key] = null;
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "Link is not Valid";
                isFormValid = false;
              }
            } else {
              newErrors[key] = null;
              newIsDirty[key] = false;
            }
            break;
          }

          default:
        }
      });

      setIsDirty(newIsDirty);
      setErrors(newErrors);
      resolve(isFormValid);
    });
  };

  const _onBlurHandler = (key) => {
    let newFormFields = { ...formFields };
    let newIsDirty = { ...isDirty };
    newIsDirty[key] = true;
    setIsDirty(newIsDirty);

    _validateFormFields(newFormFields, newIsDirty);
  };

  const _updateFieldValue = (key, value) => {
    let newFormFields = { ...formFields };
    newFormFields[key] = value;
    setFormFields(newFormFields);

    _hasSocialLinkChanged(newFormFields);
  };

  const _handleSubmit = async (e) => {
    try {
      if (e) {
        e.stopPropagation();
        e.preventDefault();
      }
      const newFormFields = { ...formFields };
      const newAllDirty = await _markAllIsDirty();
      const isFormValid = await _validateFormFields(newFormFields, newAllDirty);

      if (!isFormValid) {
        return errorHandler({ reason: "Fill Social Media Links Correctly" });
      }

      setLoading(true);
      const payload = _createPayload(newFormFields);
      await updateInfluencerDetails({ social: payload });
      // !using updateOnlyUSerData in dispatch give problem with isSocialConnected Field
      dispatch(fetchAndModifiedUserDetails());
      showToast("Social Links Updated", "success");
      setLoading(false);
      setHasFormFieldChanged(false);
    } catch (error) {
      setLoading(false);
      errorHandler(error);
    }
  };

  useEffect(() => {
    if (influencer?.social?.length) {
      _preFillData(influencer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [influencer]);

  return (
    <>
      <div className="header_Settings" style={{ marginTop: 27 }}>
        <h4>Socials</h4>
      </div>
      <Form onSubmit={(e) => _handleSubmit(e)}>
        <FormGroup>
          <div className="d-flex align-items-center">
            <img
              style={{ height: 40 }}
              src="/assets/img/social/twitter-icon.png"
              alt="tiktok-icon"
              className="mr-2"
              loading="lazy"
            />
            <Input
              type="text"
              placeholder="Enter"
              value={formFields.twitter}
              onChange={(e) => _updateFieldValue("twitter", e.target.value)}
              onBlur={() => _onBlurHandler("twitter")}
            />
          </div>
          {errors?.twitter ? (
            <div className="form-error">{errors.twitter}</div>
          ) : null}
        </FormGroup>

        <FormGroup>
          <div className="d-flex align-items-center">
            <img
              style={{ height: 40 }}
              src="/assets/img/social/youtube-icon.png"
              alt="tiktok-icon"
              className="mr-2"
              loading="lazy"
            />
            <Input
              type="text"
              placeholder="Enter"
              value={formFields?.youtube}
              onChange={(e) => _updateFieldValue("youtube", e.target.value)}
              onBlur={() => _onBlurHandler("youtube")}
            />
          </div>
          {errors?.youtube ? (
            <div className="form-error">{errors.youtube}</div>
          ) : null}
        </FormGroup>

        <FormGroup>
          <div className="d-flex align-items-center">
            <img
              style={{ height: 40 }}
              src="/assets/img/social/fb-icon.png"
              alt="fb-icon"
              className="mr-2"
              loading="lazy"
            />
            <Input
              type="text"
              placeholder="Enter"
              value={formFields?.facebook}
              onChange={(e) => _updateFieldValue("facebook", e.target.value)}
              onBlur={() => _onBlurHandler("facebook")}
            />
          </div>
          {errors?.facebook ? (
            <div className="form-error">{errors.facebook}</div>
          ) : null}
        </FormGroup>

        <FormGroup>
          <div className="d-flex align-items-center">
            <img
              style={{ height: 40 }}
              src="/assets/img/social/tiktok-icon.png"
              alt="tiktok-icon"
              className="mr-2"
              loading="lazy"
            />
            <Input
              type="text"
              placeholder="Enter"
              value={formFields?.tiktok}
              onChange={(e) => _updateFieldValue("tiktok", e.target.value)}
              onBlur={() => _onBlurHandler("tiktok")}
            />
          </div>
          {errors?.tiktok ? (
            <div className="form-error">{errors.tiktok}</div>
          ) : null}
        </FormGroup>

        <FormGroup>
          <div className="d-flex align-items-center">
            <img
              style={{ height: 40 }}
              src="/assets/img/social/instagram-icon.png"
              alt="instagram-icon"
              className="mr-2"
              loading="lazy"
            />
            <Input
              type="text"
              placeholder="Enter"
              value={formFields?.instagram}
              onChange={(e) => _updateFieldValue("instagram", e.target.value)}
              onBlur={() => _onBlurHandler("instagram")}
            />
          </div>
          {errors?.instagram ? (
            <div className="form-error">{errors.instagram}</div>
          ) : null}
        </FormGroup>

        <Button
          type="submit"
          className="themeBtn saveBtn"
          disabled={loading || !hasFormFieldChanged}
        >
          {loading ? <i className="fa fa-spinner fa-spin mr-1" /> : null} Save
        </Button>
      </Form>

      <hr
        style={{
          marginTop: 25,
          borderTopColor: "rgba(204, 204, 204, 0.5)",
        }}
      />

      {influencer?.isSocialConnected ? (
        <div className="text-center fs-14 mt-4 mb-2">
          Already connected with Social Account
        </div>
      ) : canUpdateProfileSettings ? (
        <div className="d-flex justify-content-center mt-4 mb-2">
          <GoogleLoginComponent
            className="googleSignUpBtn"
            btnText="Connect with Google"
            onSuccess={(res) => _connectWithGoogle(res)}
          />

          <FacebookLoginComponent
            className="fbSignUpBtn"
            btnText="Connect with Facebook"
            onSuccess={(res) => _connectWithFacebook(res)}
          />

          <AppleLoginComponent
            className="appleSignUpBtn"
            btnText="Continue with Apple"
            onSuccess={(res) => _connectWithApple("apple")}
          />
        </div>
      ) : null}
    </>
  );
};

export default SocialMediaAccounts;
