import React, { useState } from "react";
import { useEffect } from "react";
import { Col, CardBody, Card, CardHeader, Collapse } from "reactstrap";

const FaqAccordion = ({ faqs, categoryMode }) => {
  const [formattedFaqs, setFormattedFaqs] = useState([]);

  const _formatAccordian = () => {
    try {
      let newFormattedFaqs = [];

      if (categoryMode) {
        for (let categoryName in faqs) {
          faqs?.[categoryName]?.forEach((faq) => {
            faq["accordianIsOpen"] = false;
          });
          newFormattedFaqs.push({
            categoryName,
            faqs: faqs[categoryName],
          });
        }
      } else {
        faqs?.forEach((faq) => {
          faq.accordianIsOpen = false;
        });
        newFormattedFaqs = faqs;
      }

      setFormattedFaqs(newFormattedFaqs);
    } catch (error) {
      console.log({ error });
    }
  };

  const _toggleAccordion = (faqIndex, categoryIndex = null) => {
    const newFormattedFaqs = [...formattedFaqs];

    if (categoryMode) {
      newFormattedFaqs[categoryIndex].faqs[faqIndex].accordianIsOpen =
        !newFormattedFaqs[categoryIndex].faqs[faqIndex].accordianIsOpen;
    } else {
      newFormattedFaqs[faqIndex].accordianIsOpen =
        !newFormattedFaqs[faqIndex].accordianIsOpen;
    }

    setFormattedFaqs(newFormattedFaqs);
  };

  useEffect(() => {
    _formatAccordian();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [faqs, categoryMode]);

  return (
    <>
      <Col className="mb-3 mt-1 noPadding">
        {categoryMode ? (
          formattedFaqs.map((category, categoryIndex) => (
            <div key={categoryIndex}>
              <h2 className="faqSectionHeader">{category.categoryName}</h2>
              <div id="accordion">
                {category.faqs.map((faq, faqIndex) => (
                  <Card key={faqIndex}>
                    <CardHeader
                      id="headingOne"
                      onClick={() => _toggleAccordion(faqIndex, categoryIndex)}
                      aria-expanded={faq.accordianIsOpen}
                      aria-controls="collapseOne"
                    >
                      <h5 className="faqQues">{faq.title}</h5>
                    </CardHeader>
                    <Collapse
                      isOpen={faq.accordianIsOpen}
                      data-parent="#accordion"
                      id="collapseOne"
                      aria-labelledby="headingOne"
                    >
                      <CardBody>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: faq.htmlContent,
                          }}
                        />
                        {faq.videos.length
                          ? faq.videos.map((video, videoIndex) => (
                              <div
                                className="youtubeEmbedWrapper"
                                key={videoIndex}
                              >
                                <iframe
                                  title="myFrame"
                                  frameBorder="0"
                                  height="298"
                                  src={video}
                                  width="530"
                                />
                              </div>
                            ))
                          : null}
                      </CardBody>
                    </Collapse>
                  </Card>
                ))}
              </div>
            </div>
          ))
        ) : formattedFaqs.length ? (
          formattedFaqs.map((faq, faqIndex) => (
            <div id="accordion" key={faqIndex}>
              <Card>
                <CardHeader
                  id="headingOne"
                  onClick={() => _toggleAccordion(faqIndex)}
                  aria-expanded={faq.accordianIsOpen}
                  aria-controls="collapseOne"
                >
                  <h5 className="faqQues">{faq.title}</h5>
                  <i className="fa fa-chevron-down" />
                </CardHeader>
                <Collapse
                  isOpen={faq.accordianIsOpen}
                  data-parent="#accordion"
                  id="collapseOne"
                  aria-labelledby="headingOne"
                >
                  <CardBody>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: faq.htmlContent,
                      }}
                    />
                    {faq.videos.length ? (
                      <div className="youtubeEmbedWrapper">
                        <iframe
                          title="myFrame"
                          frameBorder="0"
                          height="298"
                          src={faq.videos[0]}
                          width="530"
                        />
                      </div>
                    ) : null}
                  </CardBody>
                </Collapse>
              </Card>
            </div>
          ))
        ) : (
          <h2 className="noContentFound mt-0">No Result Found</h2>
        )}
      </Col>
    </>
  );
};

export default FaqAccordion;
