import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Row, Col, Button, Label, Input, Form, FormGroup } from "reactstrap";
import { APP_NAME } from "../config";
import { countryCodes } from "../config/country-codes";
import { RegexConfig } from "../config/RegexConfig";
import {
  errorHandler,
  getDialCodeFromCountryCode,
  isValidPhone,
  showToast,
  splitFullName,
} from "../helper-methods";
import { requestReferralInvitation } from "../http-calls";
import CustomTooltip from "./custom/CustomTooltip";

const CreatorReferForm = ({
  fetchReferralHistory,
  fetchAndModifiedUserDetails,
  toggleInviteCreator,
}) => {
  const userData = useSelector((state) => state?.userData);

  const [formFields, setFormFields] = useState({
    name: "",
    email: "",
    countryCode: "US",
    phone: "",
  });
  // eslint-disable-next-line no-unused-vars
  const [isDirty, setIsDirty] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const _setFormFields = () => {
    setFormFields({
      name: "",
      email: "",
      countryCode: "US",
      phone: "",
    });
    setIsDirty({});
    setErrors({});
    setLoading(false);
  };

  const _validateFormfields = ({ newFormFields, newIsDirty }) => {
    return new Promise((resolve) => {
      let isFormValid = true;
      const newErrors = {};

      Object.keys(newFormFields).forEach((key) => {
        if (newIsDirty[key]) {
          switch (key) {
            case "name": {
              if (newFormFields[key]?.trim().length) {
                newErrors[key] = null;
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "email": {
              if (newFormFields[key]?.trim().length) {
                if (
                  RegexConfig[key].test(
                    String(newFormFields[key]).toLowerCase()
                  )
                ) {
                  newErrors[key] = null;
                  newIsDirty[key] = false;
                } else {
                  newErrors[key] = `*Invalid ${key}`;
                  isFormValid = false;
                }
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "phone": {
              if (newFormFields[key]?.trim().length) {
                if (
                  RegexConfig[key].test(
                    String(newFormFields[key]).toLowerCase()
                  )
                ) {
                  newErrors[key] = null;
                  newIsDirty[key] = false;
                } else {
                  newErrors[key] = `*Invalid ${key}`;
                  isFormValid = false;
                }
              } else {
                newErrors[key] = null;
                newIsDirty[key] = false;
              }
              break;
            }
            default:
          }
        }
      });

      setIsDirty((prev) => ({
        ...prev,
        ...newIsDirty,
      }));
      setErrors((prev) => ({
        ...prev,
        ...newErrors,
      }));
      resolve(isFormValid);
    });
  };

  const _onChangeFormfield = (key, value) => {
    if (key === "phone" && value && !isValidPhone(value)) {
      return;
    }

    const newFormFields = { ...formFields };
    newFormFields[key] = value;
    setFormFields(newFormFields);
  };

  const _onBlurFormfield = (key) => {
    const newFormFields = { ...formFields };
    const newIsDirty = {
      [key]: true,
    };
    _validateFormfields({ newFormFields, newIsDirty });
  };

  const _markAllIsDirty = () => {
    return new Promise((resolve) => {
      const newFormFields = { ...formFields };
      const newIsDirty = { ...isDirty };
      Object.keys(newFormFields).forEach((key) => {
        newIsDirty[key] = true;
      });
      setIsDirty(newIsDirty);
      resolve(newIsDirty);
    });
  };

  const _onSubmit = async (e) => {
    try {
      if (e) e.preventDefault();

      if (!userData?.user?.avaiableReferralCount) {
        return;
      }

      const newFormFields = { ...formFields };
      const newIsDirty = await _markAllIsDirty();

      const isFormValid = await _validateFormfields({
        newFormFields,
        newIsDirty,
      });

      if (!isFormValid) {
        return;
      }

      setLoading(true);

      const { firstName, lastName } = splitFullName(formFields.name);

      const payload = {
        name: {
          first: firstName,
          last: lastName,
        },
        email: formFields.email?.trim(),
      };

      if (formFields.phone?.trim().length) {
        const dial_code = getDialCodeFromCountryCode(formFields.countryCode);

        payload["phone"] = `(${dial_code})${formFields.phone.trim()}`;

        payload["countryCode"] = formFields.countryCode;
      }

      await requestReferralInvitation(payload);

      _setFormFields();

      showToast("Invitation sent successfully", "success");

      fetchReferralHistory();

      fetchAndModifiedUserDetails();

      toggleInviteCreator();

      setLoading(false);
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  return (
    <>
      <Form onSubmit={(e) => _onSubmit(e)}>
        <Row form className="noMargin inviteCreatorForm">
          <Col md={6} lg={4} className="noPadding">
            <FormGroup>
              <Label style={{fontSize: 14, marginBottom: 5}}>
                Name<span style={{ color: "#f16667" }}>*</span>
              </Label>
              <Input
                type="text"
                name="name"
                placeholder="Enter"
                value={formFields.name}
                onChange={(e) => _onChangeFormfield("name", e.target.value)}
                onBlur={() => _onBlurFormfield("name")}
                disabled={loading}
              />
              {errors?.name ? (
                <div className="form-error">{errors.name}</div>
              ) : null}

              <span className="info my-2 d-block">
                This personal information is secure and not public
              </span>
            </FormGroup>
          </Col>

          <Col md={6} lg={4} className="noPadding">
            <FormGroup>
              <Label style={{fontSize: 14, marginBottom: 5}}>
                Email
                <span style={{ color: "#f16667" }}>*</span>
              </Label>
              <Input
                type="text"
                name="email"
                placeholder="Enter"
                value={formFields.email}
                onChange={(e) => _onChangeFormfield("email", e.target.value)}
                onBlur={() => _onBlurFormfield("email")}
                disabled={loading}
              />

              {errors?.email ? (
                <div className="form-error">{errors.email}</div>
              ) : null}
            </FormGroup>
          </Col>

          <Col md={6} lg={4} className="noPadding">
            <FormGroup>
              <Label style={{fontSize: 14, marginBottom: 5}}>Phone</Label>
              <div className="countryWithCode">
                <Input
                  type="select"
                  name="selectCountryCode"
                  value={formFields.countryCode}
                  onChange={(e) =>
                    _onChangeFormfield("countryCode", e.target.value)
                  }
                  disabled={loading}
                >
                  {countryCodes.map((countryCode, countryIndex) => (
                    <option key={countryIndex} value={countryCode.code}>
                      {countryCode.name} ({countryCode.dial_code})
                    </option>
                  ))}
                </Input>
                <Input
                  type="text"
                  name="phone"
                  placeholder="Enter"
                  value={formFields.phone}
                  onChange={(e) => _onChangeFormfield("phone", e.target.value)}
                  onBlur={() => _onBlurFormfield("phone")}
                  disabled={loading}
                />
              </div>

              {errors?.phone ? (
                <div className="form-error">{errors.phone}</div>
              ) : null}
            </FormGroup>
          </Col>
          <Col md="6" lg="12">
            <Button
              type="submit"
              className="themeBtn inviteBtn"
              disabled={
                loading || !userData?.user?.avaiableReferralCount ? true : false
              }
            >
              {loading ? <i className="fa fa-spinner fa-spin" /> : null} Invite
            </Button>
            <p className="inviteCountLeft">
              {userData?.user?.avaiableReferralCount} Left
              {userData?.user?.referPercentage ? (
                <>
                  <sup
                    id="promotion_links_web_view_referPercentage_tooltip"
                    className="infoIcon"
                  >
                    <i className="fa fa-info-circle" />
                  </sup>
                  <CustomTooltip
                    text={`Earn ${userData?.user?.referPercentage}% commission for inviting and helping your friends use ${APP_NAME}!`}
                    target="promotion_links_web_view_referPercentage_tooltip"
                  />
                </>
              ) : null}
            </p>
          </Col>
        </Row>
      </Form>

      {/* promoteAccount  */}
      {userData?.user?.referPercentage ? (
        <h4 className="shareEarnTxt">
          Get paid to share {APP_NAME} with the world! Invite influencers and
          earn {userData.user.referPercentage}% on every dollar they make on the
          platform.
        </h4>
      ) : null}
    </>
  );
};

export default CreatorReferForm;
