import React from "react";
import Skeleton from "react-loading-skeleton";

const SkeletonPost = ({ count = 20 }) => {
  return (
    <>
      {[...Array(count)].map((_, index) => (
        <div className="d-flex flex-column w-100 mx-auto" key={index} style={{maxWidth: 580, marginTop: 15}}>
          {/* user Profile Pic, userName */}
          <div className="d-flex align-items-center">
            <Skeleton circle height={40} width={40} className="mr-2" />
            <div className="text-left">
              <Skeleton height={15} width={80} />
              <Skeleton height={15} width={120} />
            </div>
          </div>

          {/* post content */}
          <div className="mt-2 mb-2">
            <Skeleton height={500} borderRadius={16} />
          </div>

          {/* comment, like, tip icon, their counts; flag icon */}
          <div className="d-flex justify-content-between">
            <div className="d-flex flex-column">
              <div className="d-flex mb-1">
                <Skeleton circle height={35} width={35} className="mr-2" />
                <Skeleton circle height={35} width={35} className="mr-2" />
                <Skeleton circle height={35} width={35} />
              </div>
              <div>
                <Skeleton height={12} width={120} />
              </div>
            </div>

            <div>
              <Skeleton circle height={35} width={35} />
            </div>
          </div>

          {/* posted date */}
          <div className="d-flex justify-content-start">
            <Skeleton height={11} width={100} />
          </div>
        </div>
      ))}
    </>
  );
};

export default SkeletonPost;
