import React from "react";
import { useMemo } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Label,
  CustomInput,
} from "reactstrap";
import { copyToClipboard } from "../../helper-methods";

const ReferralShareModal = ({
  isOpen,
  toggle,
  influencerUrl,
  coupon,
  shareOnTelegram,
  shareOnTwitter,
  linkStatusLoading,
  togglePromotionTrialLinkStatus,
}) => {
  const shareableUrl = useMemo(() => {
    if (isOpen) {
      let url = influencerUrl;
      if (coupon?.couponToken) url += `?coupon=${coupon.couponToken}`;
      return url;
    }

    return "";
  }, [isOpen, influencerUrl, coupon]);

  return (
    <Modal
      className="modal-dialog-centered modal-dialog-scrollable"
      isOpen={isOpen}
      toggle={() => toggle()}
    >
      <ModalHeader toggle={() => toggle()}>Send Invite</ModalHeader>
      <ModalBody>
        <p>Share this link to promote your channel and get subscribers</p>
        <div className="d-flex align-items-center mb-1">
          <Label style={{fontSize: 14, marginBottom: 0}}>Invite Link</Label>

          {coupon ? (
            <CustomInput
              type="switch"
              className="ml-3"
              id={`referral_modal_promotion_trial_link_${coupon._id}`}
              disabled={linkStatusLoading === coupon._id}
              checked={coupon.isActive}
              onChange={(e) => togglePromotionTrialLinkStatus(coupon._id)}
            />
          ) : null}
        </div>
        <div className="inviteUrl">
          <Input type="text" value={shareableUrl} disabled readOnly />

          <Button
            className="copyClipboardBtn"
            onClick={() => copyToClipboard(shareableUrl)}
          >
            <i className="fa fa-clone" /> Copy
          </Button>
        </div>

        <Label style={{marginBottom: 5, fontSize: 14}}>Share With</Label>

        <div className="shareInvite">
          <Button
            className="btn-twitter"
            onClick={() => shareOnTwitter(coupon?.couponToken)}
          >
            <i className="fa fa-twitter" />
          </Button>
          <Button
            className="btn-telegram"
            onClick={() => shareOnTelegram(coupon?.couponToken)}
          >
            <img
              src="/assets/img/social/telegram.png"
              style={{ width: 26 }}
              alt="telegram"
              loading="lazy"
            />
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ReferralShareModal;
