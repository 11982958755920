import React, { useRef, useState } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { deepClone } from "../helper-methods";

const CoverImageCropper = ({
  imageSrc,
  locked = false,
  disabled = false,
  onCropComplete = () => {},
  crop: cropProps = {
    unit: "%",
    aspect: 21 / 7,
    x: 0,
    y: 0,
    width: 100,
    // height: 50,
  },
}) => {
  const imageRef = useRef({ current: {} });

  const [crop, setCrop] = useState(deepClone(cropProps));

  const _onImageLoaded = (image) => {
    imageRef.current = image;
  };

  const _onCropChange = (crop, percentCrop) => {
    setCrop(percentCrop);
  };

  const _onCropComplete = (crop, percentCrop) => {
    onCropComplete(percentCrop);
  };

  return (
    <>
      <div className="">
        <ReactCrop
          src={imageSrc}
          // crossorigin={"anonymous"}
          style={{ margin: "0 auto", display: "table" }}
          crop={crop}
          onImageLoaded={_onImageLoaded}
          onComplete={_onCropComplete}
          onChange={_onCropChange}
          locked={locked}
          disabled={disabled}
        />
      </div>
    </>
  );
};

export default CoverImageCropper;
