import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Input, Label } from "reactstrap";
import { defaultDescription } from "../config/helper-config";
import { errorHandler, isPermissionToAccess } from "../helper-methods";
import { updateInfluencerDetails } from "../http-calls";
import { updateOnlyUserData } from "../redux/actions";
import ConfigureString from "./ConfigureString";
import CustomTooltip from "./custom/CustomTooltip";

const VaultDescriptionCard = () => {
  const descriptionInputRef = useRef();

  const dispatch = useDispatch();

  const userData = useSelector((state) => state?.userData);

  const [vaultDescription, setVaultDescription] = useState("");
  const [isEditDescription, setIsEditDescription] = useState(false);
  const [loading, setLoading] = useState(false);

  const canEdit = useMemo(
    () => isPermissionToAccess("vault", "canEditOrArchiveVault", true),
    []
  );

  const _focusInput = () => {
    if (descriptionInputRef.current.focus) descriptionInputRef.current.focus();
  };

  const _resetState = () => {
    setVaultDescription("");
    setIsEditDescription(false);
    setLoading(false);
  };

  const _toggleIsEditDescription = (isEdit = false) => {
    setIsEditDescription(isEdit);

    if (isEdit) {
      setVaultDescription(userData?.user?.vaultDescription || "");
    } else {
      _resetState();
    }
  };

  const _saveDescription = async () => {
    try {
      setLoading(true);

      await updateInfluencerDetails({
        vaultDescription: vaultDescription?.trim(),
      });

      dispatch(
        updateOnlyUserData({
          ...userData?.user,
          vaultDescription: vaultDescription?.trim(),
        })
      );

      _toggleIsEditDescription();
    } catch (error) {
      errorHandler(error);
      setLoading(true);
    }
  };

  useEffect(() => {
    if (isEditDescription) {
      _focusInput();
    }
  }, [isEditDescription]);

  return (
    <div className="vaultDesc">
      <div className="d-flex justify-content-between align-items-center mb-1">
        <Label>
          <ConfigureString keyString="vault Description" />
          <sup id="vaultDescription_tooltip" className="infoIcon">
            <i className="fa fa-info-circle" />
          </sup>
          <CustomTooltip
            target="vaultDescription_tooltip"
            text="Customize your description to tell your fanz about awesome content and why it's a terrific idea for them to unlock access"
          />
        </Label>

        <div>
          {isEditDescription ? (
            <Button
              // className="modalBtnCancel"
              color="link"
              style={{color: '#aaa'}}
              onClick={() => _toggleIsEditDescription()}
            >
              Cancel
            </Button>
          ) : null}

          {canEdit ? (
            <Button
              // className="modalBtnSave"
              color="link"
              disabled={loading}
              onClick={() => {
                isEditDescription
                  ? _saveDescription()
                  : _toggleIsEditDescription(true);
              }}
            >
              {loading ? <i className="fa fa-spinner fa-spin mr-1" /> : null}{" "}
              {isEditDescription ? "Save" : "Edit"}
            </Button>
          ) : null}
        </div>
      </div>

      {/* the detail should not be exceed 200 characters */}
      <Input
        innerRef={descriptionInputRef}
        type="textarea"
        name="description"
        rows="4"
        autoFocus={true}
        placeholder={defaultDescription}
        value={
          isEditDescription
            ? vaultDescription
            : userData?.user?.vaultDescription || ""
        }
        disabled={!isEditDescription || loading}
        onChange={(e) => setVaultDescription(e.target.value)}
      />
    </div>
  );
};

export default VaultDescriptionCard;
