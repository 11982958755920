import React from "react";
import { useSelector } from "react-redux";
import { Button, Spinner } from "reactstrap";
import { DEFAULT_PROFILE_PICTURE } from "../../config";
import {
  capitalize,
  formatCurrencyValue,
  getLowResolutionLink,
  handleNameObj,
  isShowStartCallButton,
  parseForShowingMessage,
} from "../../helper-methods";
import SkeletonLoading from "../SkeletonLoading";
import ChatAudio from "./ChatAudio";
import ChatImage from "./ChatImage";
import ChatMultiFile from "./ChatMultiFile";
import ChatVideo from "./ChatVideo";

const MessageItem = ({
  messageData,
  lastElementRef,
  loading,
  prevDateHeading,
  currDateHeading,
  startCall,
}) => {
  const { selectedThread } = useSelector((state) => state?.chatData || {});

  const _renderMessage = (message) => {
    if (message?._shoutoutOrder) {
      const durationText = message?.shoutoutDuration
        ? message.shoutoutDuration > 90
          ? message.shoutoutDuration / 60 + " mins"
          : message.shoutoutDuration + " sec"
        : "N/A";

      const messageTypeText = message?.shoutoutType
        ? `${message.shoutoutType.includes("audio") ? "voice" : "video"} ${
            message.shoutoutType === "liveaudio" ||
            message.shoutoutType === "live"
              ? "call"
              : "message"
          }`
        : "N/A";

      return (
        <>
          {/* This is the msg(example) that creator will receive from a fan */}
          <div className="voiceVideoMsg">
            {selectedThread?.fan?.name?.full || selectedThread?.fan?.name?.first
              ? capitalize(handleNameObj(selectedThread.fan.name))
              : "Fan"}{" "}
            has requested for a {durationText} {messageTypeText} and has added
            this note:
            {/* **Note:** can we show the note added by the fan in a next line? that is why have added this div */}
            <div>{parseForShowingMessage(message.text)}</div>
          </div>
          {(message.shoutoutType === "liveaudio" ||
            message.shoutoutType === "live") &&
          isShowStartCallButton(message) ? (
            <Button
              className="themeBtn"
              onClick={() => startCall(message, "accepted")}
            >
              Start Call
            </Button>
          ) : null}
        </>
      );
    }

    // Need to decide
    // Is public
    // Has Media
    // Has Text
    let hasImage = false;
    let hasAudio = false;
    let hasVideo = false;
    let hasText = false;
    let hasTip = false;
    let hasMultipleFile = false;
    // Check if it has media
    if (message.content && message.content.length) {
      // Has media
      if (message.content.length > 1) {
        hasMultipleFile = true;
      } else {
        switch (message.content[0].mediaType) {
          case "image": {
            hasImage = true;
            break;
          }
          case "video": {
            hasVideo = true;
            break;
          }
          case "audio": {
            hasAudio = true;
            break;
          }
          default: {
          }
        }
      }
    }
    if (message.media && message.media.length) {
      if (message.media.length > 1) {
        hasMultipleFile = true;
      } else {
        switch (message.media[0].mediaType) {
          case "image": {
            hasImage = true;
            break;
          }
          case "video": {
            hasVideo = true;
            break;
          }
          case "audio": {
            hasAudio = true;
            break;
          }
          default: {
          }
        }
      }
    }

    if (message.isTip) {
      hasTip = true;
    } else {
      if (message.text && message.text.length) {
        hasText = true;
      }
    }

    return (
      <>
        {hasImage ? (
          <ChatImage message={message} fan={selectedThread?.fan} />
        ) : null}
        {hasVideo ? (
          <ChatVideo message={message} fan={selectedThread?.fan} />
        ) : null}
        {hasAudio ? (
          <ChatAudio message={message} fan={selectedThread?.fan} />
        ) : null}
        {hasMultipleFile ? (
          <ChatMultiFile message={message} fan={selectedThread?.fan} />
        ) : null}

        {hasText ? (
          <div
            className={`msg ${
              message.isMyMessage ? "bgThemeColor" : "bgOffWhite"
            }`}
          >
            <pre>{parseForShowingMessage(message.text)}</pre>
          </div>
        ) : null}
        {hasTip ? (
          <div className="tipWrap">
            {`You received a ${formatCurrencyValue(message.text)} tip`}
          </div>
        ) : null}
      </>
    );
  };

  return (
    <>
      {lastElementRef && loading && (
        <SkeletonLoading type={"conversation"} count={2} />
      )}

      <div {...(lastElementRef ? { ref: lastElementRef } : {})}>
        {currDateHeading !== prevDateHeading ? (
          <div className="chatDate">{currDateHeading}</div>
        ) : null}

        <div>
          {messageData?.isMyMessage ? (
            <div className="senderWrap">
              <div className="d-flex flex-column align-items-end">
                {_renderMessage(messageData)}
              </div>
              {/* msg sent time  */}
              <div className="sentTime">
                {messageData?.time}{" "}
                {messageData?.isError ? (
                  <i
                    className="fa fa-exclamation-circle"
                    style={{
                      color: "#f16667",
                    }}
                  />
                ) : !messageData?.isUploading ? (
                  messageData?.isRead ? (
                    <i className="fa fa-check seenTick" />
                  ) : (
                    <i className="fa fa-check greyCheck" />
                  )
                ) : null}
                {!messageData?.isError && messageData?.isUploading ? (
                  <Spinner
                    style={{
                      width: "1rem",
                      height: "1rem",
                    }}
                  />
                ) : null}
              </div>
            </div>
          ) : (
            <div className="receiverWrap">
              <div className="d-flex align-items-start">
                <img
                  className="senderImg"
                  src={
                    getLowResolutionLink(messageData?._from?.profilePicture) ||
                    getLowResolutionLink(selectedThread?.fan?.profilePicture) ||
                    DEFAULT_PROFILE_PICTURE
                  }
                  onError={(e) => (e.target.src = DEFAULT_PROFILE_PICTURE)}
                  alt="Profile Img"
                  loading="lazy"
                />

                <div className="d-flex flex-column">
                  {_renderMessage(messageData)}
                </div>
              </div>

              <div className="sentTime">{messageData?.time}</div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MessageItem;
