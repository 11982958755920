import React, { useEffect, useMemo, useState } from "react";
import { Button, Collapse, ListGroup, ListGroupItem } from "reactstrap";
import {
  errorHandler,
  formatDate,
  formatTime,
  isPermissionToAccess,
  logout,
  showToast,
} from "../../helper-methods";
import { closeAllNotifications, getAllLoginSessions } from "../../http-calls";
import CustomPrompt from "../modals/CustomPrompt";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../redux/actions";
import CustomLoader from "../custom/CustomLoader";
import { useHistory } from "react-router-dom";

const SettingsLoginSessions = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const [loginSessions, setLoginSessions] = useState([]);
  const [isSessionLoading, setIsSessionLoading] = useState(false);
  const [isPromptModalOpen, setIsPromptModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const _togglePromptModal = (value = false) => {
    setIsPromptModalOpen(value);
  };

  const _toggleCollapse = () => {
    setIsOpen((prev) => !prev);
  };

  const canUpdateSecuritySettings = useMemo(
    () =>
      isPermissionToAccess(
        "profileAndSettings",
        "canUpdateSecuritySettings",
        true
      ),
    []
  );

  const _logOutFromAllDevices = async () => {
    try {
      dispatch(showLoader("Logging Out from all devices"));
      await closeAllNotifications({ logoutType: "all" });
      logout(history, true);
      _togglePromptModal();
      showToast("Logged out from all the devices", "success");
      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
      _togglePromptModal();
      errorHandler(error);
    }
  };

  const _fetchLoginSessions = async () => {
    try {
      setIsSessionLoading(true);
      const res = await getAllLoginSessions();
      setLoginSessions(res?.devices?.length ? res.devices : []);
      setIsSessionLoading(false);
    } catch (error) {
      setIsSessionLoading(false);
      errorHandler(error);
    }
  };

  useEffect(() => {
    _fetchLoginSessions();
  }, []);

  return (
    <>
      <div
        className="header_Settings cursorPointer"
        style={{ marginTop: 30 }}
        onClick={() => _toggleCollapse()}
        aria-expanded={isOpen}
        aria-controls="exampleAccordion1"
      >
        <h4>Login Session </h4>
        <span className="fa fa-chevron-down" />
      </div>
      <Collapse
        isOpen={isOpen}
        data-parent="#exampleAccordion"
        id="exampleAccordion1"
        className="loginSessionsCollapse"
      >
        <ListGroup className="loginSessions">
          {loginSessions.length ? (
            loginSessions.map((session, index) => (
              <ListGroupItem key={index}>
                <div>
                  <h5>{session.name}</h5>
                  <p>
                    {session.ipAddress} - {session.country}
                  </p>
                </div>

                <div className="text-right">
                  {session.isLoginNow ? (
                    <p className="themeColor">Online</p>
                  ) : session?.lastRequestTime ? (
                    <>
                      <p>{formatDate(session.lastRequestTime)}</p>
                      <p>{formatTime(session.lastRequestTime)}</p>
                    </>
                  ) : null}
                </div>
              </ListGroupItem>
            ))
          ) : isSessionLoading ? (
            <CustomLoader />
          ) : (
            <p className="noContentFound">No Active Session</p>
          )}
        </ListGroup>

        {loginSessions?.length && canUpdateSecuritySettings ? (
          <Button
            className="modalBtnCancel"
            onClick={() => _togglePromptModal(true)}
          >
            Close All
          </Button>
        ) : null}
      </Collapse>

      <CustomPrompt
        isOpen={isPromptModalOpen}
        message={"Are you want to logout from all the devices?"}
        successButtonText={"Yes"}
        closeButtonText={"No"}
        onSuccess={() => _logOutFromAllDevices()}
        onDismiss={() => _togglePromptModal()}
      />
    </>
  );
};

export default SettingsLoginSessions;
