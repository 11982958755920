import { AppSwitch } from "@coreui/react";
import React, { useState, useMemo } from "react";
import { Button, CustomInput, Label } from "reactstrap";
import {
  deepClone,
  getFeatureStatus,
  errorHandler,
  isPermissionToAccess,
  replaceTipText,
} from "../../helper-methods";
import ConfigureString from "../ConfigureString";
import { useDispatch, useSelector } from "react-redux";
import {
  emailNotificationsConfig,
  pushNotificationsConfig,
} from "../../config/helper-config";
import { updateInfluencerDetails } from "../../http-calls";
import { updateOnlyUserData } from "../../redux/actions";

const SettingsNotification = () => {
  const userData = useSelector((state) => state?.userData);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const [allowNotifications, setAllowNotifications] = useState({
    push: userData?.user?.notifications?.push
      ? Object.values(userData?.user?.notifications?.push).find((item) => item)
      : false,
    email: userData?.user?.notifications?.email
      ? Object.values(userData?.user?.notifications?.email).find((item) => item)
      : false,
  });

  const [subNotifications, setSubNotifications] = useState(
    userData?.user?.notifications
  );

  const canUpdateNotificationSettings = useMemo(() => {
    return isPermissionToAccess(
      "profileAndSettings",
      "canUpdateNotificationSettings"
    );
  }, []);

  const isPayPerViewActive = getFeatureStatus("ppv");

  const _toggleChild = (parentField, childField) => {
    const newSubNotifications = deepClone(subNotifications);
    newSubNotifications[parentField][childField] =
      !subNotifications?.[parentField]?.[childField];
    setSubNotifications(newSubNotifications);
  };

  const _toggleCompleteList = (parent, value) => {
    let newSubNotifications = deepClone(subNotifications);
    for (let key in newSubNotifications?.[parent]) {
      newSubNotifications[parent][key] = value;
    }
    setSubNotifications(newSubNotifications);
  };

  const _onParentValueChange = (parentField) => {
    if (!canUpdateNotificationSettings) {
      return null;
    }

    let newAllowNotifications = { ...allowNotifications };

    const toggleValue = !newAllowNotifications[parentField];
    newAllowNotifications[parentField] = toggleValue;
    setAllowNotifications(newAllowNotifications);

    // toggle all the childs to same toggle-value
    _toggleCompleteList(parentField, toggleValue);
  };

  const _udpateInfluencerDetails = (payload) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await updateInfluencerDetails(payload);
        dispatch(updateOnlyUserData(res.user));
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };

  const _saveNotifications = async () => {
    try {
      setIsLoading(true);
      const payload = {
        notifications: subNotifications,
      };
      await _udpateInfluencerDetails(payload);
      setIsLoading(false);
    } catch (error) {
      errorHandler(error);
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="header_Settings">
        <h4>Push Notifications</h4>

        <AppSwitch
          variant={"pill"}
          className="customSwitch"
          disabled={!canUpdateNotificationSettings}
          checked={allowNotifications?.push || false}
          onChange={() => _onParentValueChange("push")}
        />
      </div>

      {pushNotificationsConfig?.map((each, index) => (
        <div className="notificationOptions" key={`push-notification-${index}`}>
          <Label htmlFor={`checkbox__pushNotification__${each?.value}`}>
            {each.value === "tip"
              ? "New " + replaceTipText(each.label)
              : each.label}
          </Label>

          <CustomInput
            id={`checkbox__pushNotification__${each?.value}`}
            type="checkbox"
            name="pushNotification"
            disabled={
              !allowNotifications?.push || !canUpdateNotificationSettings
            }
            checked={
              subNotifications?.["push"]
                ? subNotifications?.["push"]?.[each?.value]
                : false
            }
            onChange={() => _toggleChild("push", each?.value)}
          />
        </div>
      ))}

      {isPayPerViewActive ? (
        <div className="notificationOptions">
          <Label htmlFor={`checkbox__pushNotification__ppv`}>
            <ConfigureString keyString={"New ppv purchase"} />
          </Label>

          <CustomInput
            id={`checkbox__pushNotification__ppv`}
            type="checkbox"
            name="pushNotification"
            disabled={
              !allowNotifications?.push || !canUpdateNotificationSettings
            }
            checked={
              subNotifications?.push ? subNotifications?.push?.ppv : false
            }
            onChange={() => _toggleChild("push", "ppv")}
          />
        </div>
      ) : null}

      <hr
        style={{ marginTop: 25, borderTopColor: "rgba(204, 204, 204, 0.5)" }}
      />

      <div className="header_Settings" style={{ marginTop: 27 }}>
        <h4>Email Notifications</h4>

        <AppSwitch
          variant={"pill"}
          className="customSwitch"
          checked={allowNotifications?.email || false}
          disabled={!canUpdateNotificationSettings}
          onChange={() => _onParentValueChange("email")}
        />
      </div>

      {emailNotificationsConfig?.map((each, index) => (
        <div
          className="notificationOptions"
          key={`email-notification-${index}`}
        >
          <Label htmlFor={`checkbox__emailNotification__${each?.value}`}>
            {each.value === "tip"
              ? "New " + replaceTipText(each.label)
              : each.label}
          </Label>

          <CustomInput
            id={`checkbox__emailNotification__${each?.value}`}
            type="checkbox"
            name="emailNotification"
            disabled={
              !allowNotifications?.email || !canUpdateNotificationSettings
            }
            checked={
              subNotifications?.email
                ? subNotifications?.email?.[each?.value]
                : false
            }
            onChange={() => _toggleChild("email", each?.value)}
          />
        </div>
      ))}

      {isPayPerViewActive ? (
        <div className="notificationOptions">
          <Label htmlFor={`checkbox__emailNotification__ppv`}>
            <ConfigureString keyString={"New ppv purchase"} />
          </Label>

          <CustomInput
            id={`checkbox__emailNotification__ppv`}
            type="checkbox"
            name="emailNotification"
            disabled={
              !allowNotifications?.email || !canUpdateNotificationSettings
            }
            checked={
              subNotifications?.email ? subNotifications?.email?.ppv : false
            }
            onChange={() => _toggleChild("email", "ppv")}
          />
        </div>
      ) : null}

      {canUpdateNotificationSettings ? (
        <Button
          onClick={() => _saveNotifications()}
          className="themeBtn saveBtn"
          disabled={isLoading}
        >
          {isLoading ? <i className="fa fa-spinner fa-spin mr-1" /> : null} Save
        </Button>
      ) : null}
    </>
  );
};

export default SettingsNotification;
