import React, { useEffect } from "react";
import { Button } from "reactstrap";
import { FRONTEND_CREATOR_BASE_URL, SHOW_SOCIAL_ICON_ONLY } from "../../config";
import useScript from "./useScript";

const AppleLoginComponent = ({ className, btnText, onSuccess }) => {
  const _responseApple = async (response) => {
    try {
      if (response?.authorization && response?.authorization?.id_token) {
        const payload = {
          accessToken: response.authorization.id_token,
        };
        if (response.user?.name) payload.name = response.user.name;
        onSuccess(payload);
      }
    } catch (error) {
      console.error({ error });
    }
  };

  const _onClick = async () => {
    try {
      const data = await window.AppleID.auth.signIn();
      if (data) {
        _responseApple(data);
      }
    } catch (error) {
      console.error({ error });
    }
  };

  const [loaded, error] = useScript(
    `https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js`
  );

  // clientId updated (asked by iOS-dev)
  useEffect(() => {
    if (loaded && !error) {
      window.AppleID.auth.init({
        clientId: "app.ls.tfBase.web",
        // clientId: "com.LogicSquare.truefanz-creator.app.web",
        scope: "name email",
        redirectURI: FRONTEND_CREATOR_BASE_URL,
        state: "state",
        nonce: "nonce",
        usePopup: true,
      });
    }

    console.log({ loaded, error });

    return () => {};
  }, [loaded, error]);

  return loaded && !error ? (
    <Button
      className={`${
        SHOW_SOCIAL_ICON_ONLY ? "socialBtnIconOnly" : "socialLoginBtn"
      }`}
      onClick={_onClick}
    >
      {!SHOW_SOCIAL_ICON_ONLY ? btnText ? btnText : <>Continue With</> : null}
      <img src="/assets/img/apple-logo.svg" alt="Apple" loading="lazy" />
    </Button>
  ) : (
    <></>
  );
};

export default AppleLoginComponent;
