import {
  fetchAllThreads,
  getMessagesOfAThread,
  getAThreadForList,
  getUnreadMessageCount,
} from "../../http-calls";
import { getMessageParticipants } from "../../helper-methods";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";

const updateSelectedThread = createAction("updateSelectedThread");
const updateMessageKey = createAction("updateMessageKey");
const addLocalMessage = createAction("addLocalMessage");
const toggleFanChatFavourite = createAction("toggleFanChatFavourite");
const toggleFanChatArchive = createAction("toggleFanChatArchive");

const updateThreads = createAction("updateThreads");
const clearAllMessageData = createAction("clearAllMessageData");
const unreadMessageCountUpdate = createAction("unreadMessageCountUpdate");

const getAndUpdateChatThreads = createAsyncThunk(
  "chatData/getAndUpdateChatThreads",
  async (payload, thunkAPI) => {
    const res = await fetchAllThreads(payload);

    const threads = res.threads?.map((thread) => {
      const { fan, influencer } = getMessageParticipants(thread);
      return {
        ...thread,
        fan,
        influencer,
      };
    });

    return {
      threads,
      threadsCount: res.count,
      isConcat: payload?.skip > 0 ? true : false,
    };
  }
);

const getAndUpdateMessages = createAsyncThunk(
  "chatData/getAndUpdateMessages",
  async (payload, thunkAPI) => {
    const res = await getMessagesOfAThread(payload);

    const { fan, influencer } = getMessageParticipants(res?.thread);

    const thread = {
      ...res?.thread,
      fan,
      influencer,
    };

    thread._messages = thread._messages.map((each) => ({
      ...each,
      time: moment(each.timestamp).format("h:mm A"),
      isMyMessage: each._from.userType === "Influencer",
      hasMedia: each.content?.length ? true : false,
      isPublic: each?.content?.[0]?.amount > 0 ? false : true,
    }));

    thread._messages = thread._messages.reverse();

    return {
      thread,
      totalCount: res.totalCount,
      isConcat: payload?.payload?.skip > 0 ? true : false,
    };
  }
);

const addNewThreadToChatData = createAsyncThunk(
  "chatData/addNewThreadToChatData",
  async (payload, thunkAPI) => {
    const res = await getAThreadForList(payload);
    return res?.threads;
  }
);

const getUnreadMessageCountAction = createAsyncThunk(
  "chatData/getUnreadMessageCountAction",
  async (payload, thunkAPI) => {
    const res = await getUnreadMessageCount();
    return { unreadMessageCount: res?.count, unreadThreadIds: res?.threadIds };
  }
);

export {
  updateSelectedThread,
  getAndUpdateChatThreads,
  getAndUpdateMessages,
  updateMessageKey,
  addLocalMessage,
  toggleFanChatFavourite,
  toggleFanChatArchive,
  updateThreads,
  clearAllMessageData,
  unreadMessageCountUpdate,
  addNewThreadToChatData,
  getUnreadMessageCountAction,
};
