import { combineReducers, configureStore } from "@reduxjs/toolkit";
import hardSet from "redux-persist/es/stateReconciler/hardSet";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import {
  loaderDataReducer,
  userDataReducer,
  chatDataReducer,
  notificationDataReducer,
  nameConfigurableReducer,
  localFiltersReducer,
  feedDataReducer,
  videoPlayerReducer,
} from "./reducers";

export const rootReducer = combineReducers({
  userData: userDataReducer,
  loaderData: loaderDataReducer,
  chatData: chatDataReducer,
  notificationData: notificationDataReducer,
  nameConfigurable: nameConfigurableReducer,
  localFilters: localFiltersReducer,
  feedData: feedDataReducer,
  videoPlayer: videoPlayerReducer,
});

const persistConfig = {
  key: "root",
  storage,
  keyPrefix: "",
  stateReconciler: hardSet,
  blacklist: ["loaderData"],
};

const pReducer = persistReducer(persistConfig, rootReducer);

const devTools = process.env.REACT_APP_BACKEND_ENV === "prod" ? false : true;

export const store = configureStore({
  reducer: pReducer,
  devTools,
  middleware: (getDefaultMiddleware) =>
    devTools
      ? getDefaultMiddleware({ serializableCheck: false })
      : getDefaultMiddleware({ serializableCheck: false }),
});

export const persistor = persistStore(store);
