import React, { Component } from "react";
import {
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import { deepClone } from "../../helper-methods";

class CreatePollModal extends Component {
  state = {
    formFields: {
      question: {
        value: "",
        isValid: false,
        isDirty: false,
      },
      options: {
        value: [
          { text: "", count: 0 },
          { text: "", count: 0 },
        ],
        isValid: false,
        isDirty: false,
        error: null,
      },
      duration: {
        value: 0,
      },
    },
    isFormValid: false,
    redirectTo: null,
  };

  _initializeForm = () => {
    let { formFields } = this.state;

    formFields = {
      question: {
        value: "",
        isValid: false,
        isDirty: false,
      },
      options: {
        value: [
          { text: "", count: 0 },
          { text: "", count: 0 },
        ],
        isValid: false,
        isDirty: false,
      },
      duration: {
        value: 0,
      },
    };

    this.setState({
      formFields,
    });
    this.setState({ isFormValid: false });
  };

  _markAsDirty = (fieldName) => {
    const { formFields } = this.state;
    formFields[fieldName].isDirty = true;
    this.setState({ formFields });
    this._validateForm();
  };

  _updateFieldValue = (fieldName, value, index = null) => {
    const { formFields } = this.state;
    if (fieldName === "options") {
      formFields["options"]["value"][index]["text"] = value;
    } else {
      formFields[fieldName].value = value;
    }
    this.setState({ formFields });
    if (formFields[fieldName].isDirty) {
      // Validate
      this._validateForm();
    }
  };

  _validateForm = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      let isFormValid = true;
      Object.keys(formFields).forEach((fieldName, index) => {
        switch (fieldName) {
          case "question": {
            if (
              formFields.question.value &&
              formFields.question.value.trim().length
            ) {
              formFields.question.isValid = true;
            } else {
              formFields.question.isValid = false;
              isFormValid = false;
            }
            break;
          }
          case "options": {
            formFields.options.value.forEach((option) => delete option.error);

            let answersEmpty = formFields.options.value.filter(
              (option) => !option.text || !option.text.trim().length
            );
            let answersLengthExceed = formFields.options.value.filter(
              (option) => option.text && option.text.trim().length > 40
            );
            if (answersEmpty && answersEmpty.length) {
              formFields.options.isValid = false;
              answersEmpty.forEach((option) => (option.error = "*Required"));
              isFormValid = false;
            } else if (answersLengthExceed && answersLengthExceed.length) {
              formFields.options.isValid = false;
              answersLengthExceed.forEach(
                (option) => (option.error = "*Limit upto 40 character")
              );
              isFormValid = false;
            } else {
              formFields.options.isValid = true;
            }
            break;
          }
          default: {
          }
        }
      });
      this.setState({ formFields, isFormValid }, () => {
        resolve();
      });
    });
  };

  _makeAllFieldDirty = () => {
    const { formFields } = this.state;
    Object.keys(formFields).forEach((fieldName, index) => {
      formFields[fieldName].isDirty = true;
    });
  };

  _addPollOption = () => {
    const { formFields } = deepClone(this.state);
    // value: [{ 'text': '', 'count': 0 }, { 'text': '', 'count': 0 }]
    if (formFields.options?.value?.length >= 4) return;

    formFields["options"]["value"].push({ text: "", count: 0 });
    this.setState({ formFields });
  };

  _deletePollOption = (index) => {
    const { formFields } = deepClone(this.state);
    formFields["options"]["value"].splice(index, 1);
    this.setState({ formFields });
  };

  _createPoll = async (e) => {
    if (e) {
      e.preventDefault();
    }

    this._makeAllFieldDirty();
    await this._validateForm();
    const { formFields, isFormValid } = this.state;
    if (isFormValid) {
      this.props.onSave(formFields);
      this._initializeForm();
    }
  };

  _closeModal = () => {
    this._initializeForm();
    this.props.onModalToggle(2);
  };

  render() {
    const { formFields } = deepClone(this.state);
    return (
      <React.Fragment>
        {/* Modal for "Poll" */}
        <Modal
          isOpen={this.props.isOpen}
          toggle={() => this._closeModal()}
          className="modal-dialog-centered modal-dialog-scrollable"
        >
          <ModalHeader toggle={() => this._closeModal()}>
            Create Poll
          </ModalHeader>
          <ModalBody className="createPoll">
            <FormGroup>
              <Label>Poll Question</Label>
              <Input
                type="textarea"
                id=""
                rows="3"
                placeholder="Enter"
                onChange={(e) =>
                  this._updateFieldValue("question", e.target.value)
                }
                onBlur={() => this._markAsDirty("question")}
              />
              <div className="form-error">
                {formFields.question.isDirty && !formFields.question.isValid
                  ? "Question is required."
                  : ""}
              </div>
            </FormGroup>

            <FormGroup>
              <Label>Poll Duration</Label>
              <Input
                type="select"
                value={formFields["duration"]["value"]}
                onChange={(e) =>
                  this._updateFieldValue("duration", e.target.value)
                }
                name="pollDuration"
                id=""
              >
                <option value="1">1 day</option>
                <option value="3">3 days</option>
                <option value="7">7 days</option>
                <option value="30">30 days</option>
                <option value="0">No limit</option>
              </Input>
            </FormGroup>

            {/* By default, 2 options/input field will always be there. 
         If the user adds multiple fields/options & then decides to delete it, he/she can do it but there has to be 2 input field/options available all the time */}
            {/* <FormGroup>
                   <Label>Option</Label>
                   <InputGroup>
                     <Input type="text" id="" name="" placeholder="Enter" />
                     <InputGroupAddon addonType="append">
                       <Button type="button" color="ghost-danger">
                         <i className="icon-close" />
                       </Button>
                     </InputGroupAddon>
                   </InputGroup>
                 </FormGroup> */}

            <div>
              {formFields["options"]["value"].map((option, index) => (
                <React.Fragment key={index}>
                  <FormGroup>
                    <Label>Option</Label>

                    <InputGroup>
                      <Input
                        type="text"
                        value={option.text}
                        placeholder="Enter"
                        onChange={(e) =>
                          this._updateFieldValue(
                            "options",
                            e.target.value,
                            index
                          )
                        }
                        onBlur={() => this._markAsDirty("options")}
                      />
                      {formFields["options"]["value"].length > 2 ? (
                        <InputGroupAddon addonType="append">
                          <Button
                            type="button"
                            color="link"
                            onClick={() => {
                              this._deletePollOption(index);
                            }}
                          >
                            <svg
                              className="delPollOption"
                              id="blocking_5949247"
                              xmlns="http://www.w3.org/2000/svg"
                              width="256"
                              height="256"
                              viewBox="0 0 256 256"
                            >
                              <g id="object_80_">
                                <g id="Group_32" data-name="Group 32">
                                  <path
                                    id="Path_58"
                                    data-name="Path 58"
                                    d="M218.242,37.758A127.622,127.622,0,0,0,37,217.486q.376.379.756.756A127.622,127.622,0,0,0,219,38.514Q218.621,38.134,218.242,37.758ZM128,238A110,110,0,1,1,238,128,110.127,110.127,0,0,1,128,238Z"
                                  />
                                </g>
                                <g id="Group_33" data-name="Group 33">
                                  <path
                                    id="Path_59"
                                    data-name="Path 59"
                                    d="M176.8,79.205a8.81,8.81,0,0,0-12.459,0L128,115.541,91.664,79.205A8.81,8.81,0,0,0,79.2,91.665L115.54,128,79.2,164.337a8.81,8.81,0,1,0,12.4,12.514l.055-.055L128,140.461,164.336,176.8A8.81,8.81,0,0,0,176.8,164.337L140.459,128,176.8,91.665a8.811,8.811,0,0,0,0-12.46Z"
                                  />
                                </g>
                              </g>
                            </svg>
                          </Button>
                        </InputGroupAddon>
                      ) : null}
                    </InputGroup>
                    {formFields.options.error || option.error ? (
                      <div className="form-error">
                        {formFields.options.error || option.error}
                      </div>
                    ) : null}
                  </FormGroup>
                </React.Fragment>
              ))}
            </div>

            {/* an extra option/input field should get added every time when the below button is clicked */}
            {formFields.options?.value?.length < 4 ? (
              <Button
                className="themeBtn addBtn d-block mx-auto mt-4 mb-1"
                onClick={() => {
                  this._addPollOption();
                }}
              >
                <i className="fa fa-plus mr-1" /> Add Another Option
              </Button>
            ) : null}
          </ModalBody>
          <ModalFooter>
            <Button
              className="modalBtnCancel"
              onClick={() => this._closeModal()}
            >
              Cancel
            </Button>
            <Button
              className="modalBtnSave"
              onClick={(e) => this._createPoll(e)}
            >
              Save
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default CreatePollModal;
