import {
  APP_BASE_URL,
  APP_NAME,
  CONTACT_SUPPORT_URL,
  CURRENCY_SYMBOL,
  CURRENCY_TYPE,
  TERMS_OF_SERVICE_URL,
} from ".";
import { getConfiguredString } from "../helper-methods/configureString";

export const faqs = {
  categories: {
    "General Questions": [
      {
        title: `What is ${APP_NAME}?`,
        content: `Whether you're a youtuber, trainer, model, artist, public figure or influencer, ${APP_NAME} is a platform you should be using. ${APP_NAME} helps influencers and content creators take back control.
                <|<>|><|<>|>
                Gain freedom and stability with the support of your biggest fans. ${APP_NAME} enables you to get paid for your creative work.
                <|<>|><|<>|>
                See our home page for more information: [[${APP_NAME}||${APP_BASE_URL}]]
                `,
        videos: [],
      },
      {
        title: "Why Submit My Email & Password Twice?",
        content: `Why you should verify your email: A verified email address means that you will receive important notifications system alerts.
                <|<>|><|<>|>
                Your email and password give you access to your account. Sometime emails can be spelled incorrectly during registration.
                <|<>|><|<>|>
                Double verification ensures your credentials were entered correctly and ensures that you will be able to recover your account access if you forget your password and need to reset your login information.
                <|<>|><|<>|>
                Click on forgot password at the login page to initiate a password reset.
                `,
        videos: [],
      },
      {
        title: "Notification Settings",
        content: `Want to change your notifications or alert settings? Log into your ${APP_NAME} account using your current email address where you receive notifications.
                <|<>|><|<>|>
                You can find all login links in the footer of our website at [[${APP_NAME}||${APP_BASE_URL}]]. Login using your username and password then go to “Settings ⚙” and select notifications and adjust your notifications for your profile as you like.
                `,
        videos: [],
      },
      {
        title: "I Can't Login To My Account Anymore?",
        content: `First ensure you're logging into the right place. There is a creator login and a fan login. You can find the login portals at [[${APP_NAME}||${APP_BASE_URL}]] in the footer. Select the one you need.
                <|<>|><|<>|>
                If that doesn't work clear your cache or attempt to login via an incognito browser on your device. If you still can't login try to reset your password by selecting “Forgot Password” If you still can't get access into your account please submit a detailed support ticket explaining who you are, your username, account email and the exact situation you need help with.
                <|<>|><|<>|>
                Sharing details help us improve and serve our users better. [[Support||${CONTACT_SUPPORT_URL}]]
                <|<>|><|<>|>
                Our technical support reps will be able to provide you with access if your account has been flagged by mistake and there has been no breach of our [[Terms of Service||${TERMS_OF_SERVICE_URL}]]
                <|<>|><|<>|>
                <b>NOTICE:</b> Using a VPN, 3rd party apps, or proxy services will increase the likelihood of your account being flagged and even banned by our platform/system.
                `,
        videos: [],
      },
    ],
    "Posting Content": [
      {
        title: "Who Can See My Content?",
        content: `Your public photo and bio can be seen by anyone on ${APP_NAME}. It's public.
                <|<>|><|<>|>
                Only users who are paying for your subscription can see your uploaded content inside your channel. The content contained within your channel is hidden for all non-subscribers.“Followers” can view all your current posts and future posts after they have successfully made a purchase to subscribe.
                <|<>|><|<>|>
                We would ask that you read and comply with our [[Terms of Service||${TERMS_OF_SERVICE_URL}]] and community guidelines. You can customize your privacy settings inside of your account “Security Settings”
                `,
        videos: [],
      },
      {
        title: "How Do I Promote My Account?",
        content: `Creators can promote their exclusive ${APP_NAME} channel to their audience by advertising and promoting their channel link. Your link can be found here {Promotions} People are more likely to subscribe if they know that you're an active creator with great Content.
                <|<>|><|<>|>
                Place your ${APP_NAME} promotion link everywhere you can. you can email it, message it to friends, post it on snapchat and other major social media networks like Instagram and Facebook.
                <|<>|><|<>|>
                Your ${APP_NAME} account should be special content that only people who subscribe to your account get access to. Keep it exciting, rewarding, and exclusive for your “${APP_NAME} Users” and supporters.                
                `,
        videos: [],
      },
      {
        title: "Can I Have More Than (1) Account?",
        content: `No. It is prohibited to have multiple active profiles. Users who attempt to have multiple accounts will be banned.`,
        videos: [],
      },
      {
        title: `Can I Share My ${APP_NAME} Channel Link On My Other Social Platforms?`,
        content: `Absolutely! We encourage it! Sharing your channel link can help you maximize your potential earnings.
                <|<>|><|<>|>
                You should share your ${APP_NAME} channel link in your bio, posts, stories, blogs, podcasts, websites and more.
                <|<>|><|<>|>
                <b>TIP:</b> The most successful creators on the ${APP_NAME} platform are constantly and creatively inviting others to subscribe to their channel.
                <|<>|><|<>|>
                You can find your invitation and referral link here [[Promotion||/promotion ]]
                `,
        videos: [],
      },
      {
        title: "Account Paused?",
        content: `You need to be active on your profile. It's a good idea to login at least once a month so that the system does not pause or disable your account due to inactivity. If you don't use your account, the system can disable it for being stagnant.`,
        videos: [],
      },
      {
        title: "Technical restrictions for posting?",
        content: `Currently, you can post up to 25 pictures (slideshow) or one HD video per post. Linking to your own private link/site such as VIMEO or YOUTUBE is UNLIMITED. We suggest this for posting lengthy video files.
                <|<>|><|<>|>
                Images should be less than 20mb. ${APP_NAME} supports Ultra HD jpeg, png, mp4, mov, avi, mpeg4, Docs, PDFs formats and more.
                `,
        videos: [],
      },
      {
        title: "Paid Private Messages?",
        content: `You can send single or multiple ${getConfiguredString(
          "PPV"
        )} messages. Click/Tap &quot;Messages&quot; There are buttons inside the messenger section to upload media, select who to send it to, and to set the price in order for your fans to purchase / unlock it.
                <|<>|><|<>|>
                Your fans will be charged a one-time fee automatically upon confirming that they are willing to pay and unlock the content in the message you sent.
                `,
        videos: [],
      },
      {
        title: "Can I Edit My Post?",
        content: `Yes, you can click the three-dots at the top right side of your post to see all available Settings.`,
        videos: [],
      },
      {
        title: "Can I Restrict Who Sees My Posts?",
        content: `Of course! You have administrative controls to block, mute, leave notes, remove, or report users in your fans settings and security settings.
                <|<>|><|<>|>
                You can also select ranges of IP addresses that will not be able to view your profile page. You can configure these and other features in your profile security page inside your account.
                `,
        videos: [],
      },
      {
        title: "What Is Watermarking?",
        content: `${APP_NAME} gives users the ability to watermark their content as a copyright protection feature. If you add custom text, it will appear as a watermark on the bottom right corner of the uploaded content. You can configure your watermarks in your “Account Settings.”`,
        videos: [],
      },
      {
        title: "I Found My Content Somewhere Else?",
        content: `First attempt to ask the host, site, person, or organization to remove your digital assets via customer support, contact numbers, email or formal letter.
                <|<>|><|<>|>
                If the person or company does not comply or get your permissions, you can have an attorney send a Cease and Desist letter.
                <|<>|><|<>|>
                If you need help, please [[Contact Support||${CONTACT_SUPPORT_URL}]] we will do our best to assist you or your team in the matter.
                `,
        videos: [],
      },
      {
        title: "New Features?",
        content: `Some features are only given to test groups before they become available for all users. Be sure to watch for alerts and emails from the ${APP_NAME} team. We will notify you when new features are deployed.
                <|<>|><|<>|>
                Also, be sure to cast your vote on features you wish to be developed. We engineer the most popular requested features. [[Feature Request Board||${APP_BASE_URL}]]
                `,
        videos: [],
      },
      {
        title: "Can I Delete A Message?",
        content: `Yes, it is possible to un-send messages or delete content. You will see the status line on top of your dialogues list in messages for each message that you have sent. Click the 3 dots or icon and delete the message or post you choose.`,
        videos: [],
      },
      {
        title: "What Info Is Public On My Profile?",
        content: `Other users can only see the information that is displayed on your public profile. These items include: Profile name, @username, avatar with cover pictures, posts, and fans count (optional) Please read our terms of service for more information. They can be found here: [[Terms of Service||${TERMS_OF_SERVICE_URL}]]`,
        videos: [],
      },
    ],
    Commissions: [
      {
        title: "How Do Creators Get Paid?",
        content: `Creators are paid by subscribers, premium content, and referring other influencers/creators to the invite only [[${APP_NAME}||${APP_BASE_URL}]] platform.
                <|<>|><|<>|>
                Your FIRST withdrawal requires a manual account review by our merchant providers. This can take 3-7 working days to verify and complete the review.
                <|<>|><|<>|>
                AFTER your first withdrawal review is completed and approved your active earnings can be withdrawn whenever you choose directly into your connected bank account from your “Statement” tab inside of your [[${APP_NAME}||/statement]] dashboard.
                <|<>|><|<>|>
                Earnings are processed and deemed “Active” after 72hrs. This is to keep you safe and ensure that there is no fraudulent activity or charge-backs on any transactions. Currency conversions or other bank fees may apply depending on the transaction origin.
                <|<>|><|<>|>
                During account registration, you are being connected to our payout system depending on your location in the world.
                <|<>|><|<>|>
                Add your bank account or a verified wallet, make sure that your account is verified and you've set the subscription price in your “Account Settings”
                <|<>|><|<>|>
                You will NOT be able to set a subscription price until your account has been registered and the Popup in your dashboard has disappeared telling you to activate.
                <|<>|><|<>|>
                Your payout requests will be displayed in your “Statement” tab.
                <|<>|><|<>|>
                Pending Balances will show all your earnings and show you what is able to be withdrawn. Verified creators can request to withdraw active funds when they choose.
                <|<>|><|<>|>
                If you need any further help please submit a detailed ticket along with your creator username and help request to us at support. We're always glad to serve you. [[Contact Support||${CONTACT_SUPPORT_URL}]]
                `,
        videos: [],
      },
      {
        title: "My Followers Say They Can't Pay?",
        content: `You don't have to do customer support. You can advise your fans to contact us for more information at [[Support||${CONTACT_SUPPORT_URL}]]. It may be an issue with their account, funds, or credit card specifically. We will help or advise them accordingly.`,
        videos: [],
      },
      {
        title: "How Can I Get Registered Faster?",
        content: `Firstly, it is crucial to register your information accurately. When you're registering, you must submit your name and other personal information requested to activate your account. Your information is encrypted and secure by our merchant processor “Stripe.”
                <|<>|><|<>|>
                We also recommend you use profile pictures and bio information that show you, your brand, and the nature of your profile for your future fans and supporters. Including your Instagram, Facebook, or Twitter links can speed-up the verification process.
                <|<>|><|<>|>
                Please make sure that all the media and information you include in your profile corresponds to our Terms of service, and community guidelines.
                <|<>|><|<>|>
                <b>NOTE:</b> No explicit content, or sexually oriented material in your public channels to include your “about” “videos,” or “images.” Breaking terms and guidelines will result in being banned from the ${APP_NAME} community.
                `,
        videos: [],
      },
      {
        title: "How Much Can I Charge For Someone To Subscribe?",
        content: `Creators can charge between ${CURRENCY_SYMBOL}1.99 - ${CURRENCY_SYMBOL}100.00 per month for access to their ${APP_NAME} channel.
                <|<>|>
                We recommend a price between ${CURRENCY_SYMBOL}10 - ${CURRENCY_SYMBOL}30.
                <|<>|><|<>|>
                Keep in mind, you can change your subscription price whenever you choose inside of your profile settings. You know your supporters best.
                <|<>|><|<>|>
                The minimum amount you can charge for premium content in messenger, or posts is ${CURRENCY_SYMBOL}2.00 
                <|<>|>
                The minimum amount for tips, donation, or gifts is ${CURRENCY_SYMBOL}2.00
                <|<>|><|<>|>
                <b>TIP:</b> The more value you post, the more reason there is for your fans to subscribe and remain subscribed to your page. Give your fans what they want.
                `,
        videos: [],
      },
      {
        title: "How do different tiers work?",
        content: `You can have up to 3 subscription levels or tiers - Basic (the lowest), Plus & Premium (the highest).
                <|<>|><|<>|>
                <b>The Basic</b> membership should be the lowest priced membership at ${CURRENCY_SYMBOL}5.
                <|<>|>
                This is for people who do not want to spend a lot of money, and are happy to get limited FREE access to your content. They are always free to pay additionally to purchase any content they like.
                <|<>|><|<>|>
                <b>The Plus</b> tier is a mid level membership priced a little higher than the basic. This can be priced at ${CURRENCY_SYMBOL}20
                <|<>|>
                Subscribers at this tier want some exclusive content on the feed but not Live Events. 
                <|<>|><|<>|>
                <b>The Premium</b> is for the highest level membership and is priced higher at about ${CURRENCY_SYMBOL}30 per month.
                <|<>|>
                Subscribers are the most involved fans and get access to most of the content on the feed as well as Live Events.
                <|<>|><|<>|>
                These are examples of prices, you are free to price your subscriptions at any rate that you think is suitable to you and your following.
                `,
        videos: [],
      },
      {
        title: "How do bundles work?",
        content: `A Bundles allow subscribers to pay for a large period instead of a monthly fee.
                <|<>|><|<>|>
                Creators can offer 3 month, 6 month, 9 month or 12 month bundle packages. These are meant to help subscribers save money and for Influencers to get more money upfront for a long term subscription commitment.
                <|<>|><|<>|>
                The idea of pricing the bundles is generally to make it beneficial for subscribers to take a higher package for the extra commitment they make.
                <|<>|><|<>|>
                <b>See Example Bundles Below:</b>
                <|<>|><|<>|>
                3 Month Bundles
                <|<>|>
                Basic x 3 = ${CURRENCY_SYMBOL}15 | Suggested Price = ${CURRENCY_SYMBOL}13
                <|<>|>
                Plus x 3 = ${CURRENCY_SYMBOL}60 | Suggested Price = ${CURRENCY_SYMBOL}50
                <|<>|>
                Premium x 3 = ${CURRENCY_SYMBOL}90 | Suggested Price = ${CURRENCY_SYMBOL}75
                <|<>|><|<>|>
                6 Month Bundles
                <|<>|>
                3 Months Basic x 2 = ${CURRENCY_SYMBOL}26 | Suggested Price = ${CURRENCY_SYMBOL}25
                <|<>|>
                3 Months Plus x 2 = ${CURRENCY_SYMBOL}100 | Suggested Price = ${CURRENCY_SYMBOL}100
                <|<>|>
                3 Months Premium x 2 = ${CURRENCY_SYMBOL}150 | Suggested Price = ${CURRENCY_SYMBOL}150
                <|<>|><|<>|>
                9 Month Bundles
                <|<>|>
                3 Months Basic x 3 = ${CURRENCY_SYMBOL}39 | Suggested Price = ${CURRENCY_SYMBOL}36
                <|<>|>
                3 Months Plus x 3 = ${CURRENCY_SYMBOL}150 | Suggested Price = ${CURRENCY_SYMBOL}149
                <|<>|>
                3 Months Premium x 3 = ${CURRENCY_SYMBOL}225 | Suggested Price = ${CURRENCY_SYMBOL}219
                <|<>|><|<>|>
                12 Month Bundles
                <|<>|>
                3 Months Basic x 4 = ${CURRENCY_SYMBOL}52 | Suggested Price = ${CURRENCY_SYMBOL}48
                <|<>|>
                3 Months Plus x 4 = ${CURRENCY_SYMBOL}200 | Suggested Price = ${CURRENCY_SYMBOL}199
                <|<>|>
                3 Months Premium x 4 = ${CURRENCY_SYMBOL}300 | Suggested Price = ${CURRENCY_SYMBOL}299
                `,
        videos: [],
      },
      {
        title: "How Much Can I Earn?",
        content: `There is no monthly cost to <b>become a creator</b>
                <|<>|><|<>|>
                Content creators receive 80% commission on their earnings including subscriptions, paid messages and donations.
                <|<>|>
                The 20% platform fee helps cover ${APP_NAME} payment processing fees, hosting, server expenses, ${APP_NAME} employees, lawyer fees, software upkeep, customer support and more.
                <|<>|><|<>|>
                This small cost helps us maintain an amazing platform for creators. Learn more at [[${APP_NAME}||${APP_BASE_URL}]]
                `,
        videos: [],
      },
      {
        title: "Do I Get Documents For My Earnings?",
        content: `Please use your Earnings Statistics page as a personal self-employment income data source. Content creators living in the United States Of America will receive an IRS Form 1099 if they have earned more than ${CURRENCY_SYMBOL}600 ${CURRENCY_TYPE?.toUpperCase()} in the previous tax year.`,
        videos: [],
      },
      {
        title: `Can I Use ${APP_NAME} From My Country?`,
        content: `Here is a list of available countries where we operate currently:`,
        videos: [],
      },
    ],
    Affiliates: [
      {
        title: "How Does The Referral Program Work?",
        content: `You earn 5% of ALL income generated by any user that joins [[${APP_NAME}||${APP_BASE_URL}]] via your referral invitation.
                <|<>|><|<>|>
                You can refer influencers by inserting their name and email in the {Promotion} tab and pressing send. This will send them an invitation to join the exclusive ${APP_NAME} community Platform.
                `,
        videos: [],
      },
      {
        title: "How Is Commission Paid?",
        content: `We pay via direct ACH deposit to your bank account.
                <|<>|>
                Your FIRST withdrawal requires a manual account review by our merchant providers. This can take 3-7 working days to verify and complete the review.
                <|<>|><|<>|>
                AFTER your first withdrawal review is completed and approved your active earnings can be withdrawn whenever you choose directly into your connected bank account from your “Statement” tab inside of your ${APP_NAME} dashboard.
                <|<>|><|<>|>
                Earnings are processed and deemed “Active” after 72hrs. This is to keep you safe and ensure that there is no fraudulent activity or charge-backs on any transactions. Currency conversions or other bank fees may apply depending on the transaction origin.
                <|<>|><|<>|>
                NOTICE: A direct deposit can take 2-7 days depending on your country and bank used.
                <|<>|><|<>|>
                NOTICE: Accounts that do NOT pass the verification process and fail to provide documents required to verify their account information will be deactivated from the system and funds will NOT be paid.
                <|<>|><|<>|>
                NOTICE: Check and confirm your bank details are correct. If you still have NOT received your funds 10 days after requesting for a withdrawal and your bank details are correct, please [[Contact Support||${CONTACT_SUPPORT_URL}]]
                `,
        videos: [],
      },
      {
        title: "How Do I Get Paid To Refer Influencers?",
        content: `Click the “Promotions” tab inside your [[${APP_NAME}||/promotion]] dashboard. On this page you can send an invitation to your referrals by name, email and/or phone number!
                <|<>|><|<>|>
                When your referrals join ${APP_NAME} via your invitation, you will earn 5% volume every month based on your referral's earnings for life.
                <|<>|><|<>|>
                Your promotion page is also where your channel link is to promote your ${APP_NAME} channel.
                `,
        videos: [],
      },
      {
        title: "Can I Change The Person Who Referred Me?",
        content: `No. ${APP_NAME} is an exclusive invite only platform.
                <|<>|><|<>|>
                Only registered creators can share an invitation to others.
                <|<>|><|<>|>
                It is NOT possible to change the record of who's referred someone once they have successfully signed up.
                <|<>|><|<>|>
                The signup credit is awarded to the creator of the last clicked registration link. Cookies/link tags expire after 7 days. Referral credit is awarded based on the last invitation clicked.
                `,
        videos: [],
      },
    ],
  },
};
