import React from "react";
import {
  COMMUNITY_GUIDELINES_URL,
  PRIVACY_POLICY_URL,
  TERMS_OF_SERVICE_URL,
} from "../config";

const PublicFooter = () => {
  return (
    <div className="loginFooter">
      <div className="socialLinksFooter-Login">
        <div className="socialLinksFooter">
          <a
            href={TERMS_OF_SERVICE_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms
          </a>
          <a
            href={PRIVACY_POLICY_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy
          </a>
          <a
            href={COMMUNITY_GUIDELINES_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            Guidelines
          </a>
        </div>{" "}
      </div>
      <div className="poweredBy">
        Powered by{" "}
        <a
          href="https://www.logic-square.com/"
          rel="noopener noreferrer"
          target="_blank"
        >
          Logic Square
        </a>
      </div>
    </div>
  );
};

export default PublicFooter;
