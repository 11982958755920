import React from "react";
import Skeleton from "react-loading-skeleton";

const SkeletonCardManagement = ({ count }) => {
  return (
    <>
      <div className="d-flex justify-content-between mb-3">
        <div className="d-flex">
          <Skeleton height={45} width={45} className="mr-2" />
          <span className="d-flex flex-column justify-content-between">
            <Skeleton height={17} width={200} />
            <span className="d-flex justify-content-between">
              <Skeleton height={20} width={40} />
              <Skeleton height={20} width={40} />
            </span>
          </span>
        </div>

        <div className="d-flex flex-column flex-sm-row justify-content-between ">
          <Skeleton height={20} width={70} />
        </div>
      </div>

      {[...Array(count)].map((_, index) => (
        <div className="d-flex justify-content-between mb-3" key={index}>
          <div className="d-flex">
            <Skeleton height={45} width={45} className="mr-2" />
            <span className="d-flex flex-column justify-content-between">
              <Skeleton height={17} width={200} />
              <span className="d-flex justify-content-between">
                <Skeleton height={20} width={40} />
                <Skeleton height={20} width={40} />
              </span>
            </span>
          </div>

          <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
            <Skeleton height={20} width={70} />
            <Skeleton height={20} width={25} className="ml-3" />
          </div>
        </div>
      ))}
    </>
  );
};

export default SkeletonCardManagement;
