import React, { Component } from "react";

class CreatePostPage extends Component {
  render() {
    return (
      <>
        <div className="app customPgHeight animated fadeIn">
          <h1 className="mt-5 text-center" style={{ fontWeight: 800 }}>Coming Soon</h1>
        </div>
      </>
    );
  }
}

export default CreatePostPage;
