import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  Label,
  Collapse,
} from "reactstrap";
import { deepClone } from "../helper-methods";
import { saveLocalFilters } from "../redux/actions";

const PayPerViewFilters = ({
  isOpenFilterCollapse,
  filters,
  setFilters,
  onFilterChange,
  manageLoadingState,
}) => {
  const searchInterval = useRef({ current: null });
  const dispatch = useDispatch();

  //handle change in search field
  const _onChangeSearch = (value) => {
    if (searchInterval?.current) clearInterval(searchInterval.current);

    const newFilters = { ...filters };

    manageLoadingState("search", true);

    if (value?.length) {
      newFilters["search"] = value;
    } else {
      delete newFilters["search"];
    }

    setFilters(newFilters);

    searchInterval.current = setTimeout(() => {
      onFilterChange(newFilters);
    }, 1000);
  };

  const _onChangeFilter = (key, value) => {
    const newFilters = { ...filters };
    newFilters[key] = value;
    setFilters(newFilters);
    dispatch(
      saveLocalFilters({ key: "payPerView", value: deepClone(newFilters) })
    );
    onFilterChange(newFilters);
  };

  const _resetFilter = () => {
    dispatch(saveLocalFilters({ key: "payPerView", value: {} }));
    setFilters({
      views: "",
      status: "",
      postedAfter: "",
      duration: "",
      search: "",
    });
    onFilterChange();
  };

  return (
    <Collapse isOpen={isOpenFilterCollapse}>
      <div className="customFilterWrap">
        <FormGroup>
          <Label>Views</Label>
          <Input
            type="select"
            value={filters?.views}
            onChange={(e) => _onChangeFilter("views", e.target.value)}
          >
            <option value="">All</option>
            <option value="0-0">0</option>
            <option value="1-10">1 - 10</option>
            <option value="11-50">11 - 50</option>
            <option value="51-200">51 - 200</option>
            <option value="201-500">201 - 500</option>
            <option value="501-">500+</option>
          </Input>
        </FormGroup>

        <FormGroup>
          <Label>Status</Label>
          <Input
            type="select"
            value={filters?.status}
            onChange={(e) => _onChangeFilter("status", e.target.value)}
          >
            <option value="">All</option>
            <option value="scheduled">Draft</option>
            <option value="published">Published</option>
            <option value="archived">Archived</option>
          </Input>
        </FormGroup>

        <FormGroup>
          <Label>Posted</Label>
          <Input
            type="select"
            value={filters?.postedAfter}
            onChange={(e) => _onChangeFilter("postedAfter", e.target.value)}
          >
            <option value="">All</option>
            <option value="7">Last 7 days</option>
            <option value="30">Last 30 days</option>
            <option value="90">Last 3 months</option>
            <option value="365">Last 1 year</option>
          </Input>
        </FormGroup>

        <FormGroup>
          <Label>Duration</Label>
          <Input
            type="select"
            value={filters?.duration}
            onChange={(e) => _onChangeFilter("duration", e.target.value)}
          >
            <option value="">All</option>
            <option value="0-4">Short (Upto 4 mins)</option>
            <option value="4-20">Medium (4 mins - 20 mins)</option>
            <option value="20-">Long (More than 20 mins)</option>
          </Input>
        </FormGroup>

        <InputGroup className="mt-3 mt-md-1">
          <Input
            placeholder="Search"
            type="text"
            value={filters?.search || ""}
            onChange={(e) => _onChangeSearch(e.target.value)}
          />
          <InputGroupAddon addonType="append">
            <Button
              className="border-0"
              style={{
                background: "#eff0f6",
                borderTopRightRadius: 8,
                borderBottomRightRadius: 8,
              }}
              onClick={() => {}}
            >
              <i
                className="fa fa-search"
              />
            </Button>
          </InputGroupAddon>
        </InputGroup>

        <Button className="themeBtn addBtn" onClick={() => _resetFilter()}>
          Reset
        </Button>
      </div>
    </Collapse>
  );
};

export default PayPerViewFilters;
