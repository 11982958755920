import React from "react";
import Skeleton from "react-loading-skeleton";
import { Card, CardBody, Col } from "reactstrap";

const SkeletonEvent = ({ count }) => {
  return (
    <>
      {[...Array(count)].map((_, index) => (
        <Col
          xl="6"
          className="customPadding customColPaddingLiveEvent"
          key={index}
        >
          <Card className="cardFeed liveEventCard">
            <CardBody>
              <div className="customPaddingMob">
                <div
                  className="d-flex justify-content-between align-items-center"
                  style={{ marginBottom: 10 }}
                >
                  {/* title */}
                  <h5 className="liveStreamEventName mb-0">
                    <Skeleton height={14} width={Math.random() * 73 + 70} />
                  </h5>
                </div>

                {/* price */}
                <div className="eventPriceWrap">
                  <div className="eventPrice">
                    <Skeleton height={14} width={Math.random() * 73 + 70} />
                  </div>
                </div>

                {/* description */}
                <div className="eventPriceWrap">
                  <div className="eventPrice">
                    <Skeleton height={14} width={Math.random() * 73 + 70} />
                  </div>
                </div>
              </div>

              <div className="mediaPhotoWrap-Feed">
                <Skeleton height={400} borderRadius={16} />
              </div>
            </CardBody>
          </Card>
        </Col>
      ))}
    </>
  );
};

export default SkeletonEvent;
