import OneSignal from "react-onesignal";

export default async function runOneSignal({
  appId,
  allowLocalhostAsSecureOrigin = true,
  autoResubscribe = true,
  autoRegister = false,
  autoPrompt = false,
  notifyButton = false,
  requiresUserPrivacyConsent = false,
  promptOptions,
}) {
  try {
    await OneSignal.init({
      appId,
      allowLocalhostAsSecureOrigin,
      autoResubscribe,
      autoRegister,
      promptOptions: promptOptions || {
        autoPrompt,
      },
      notifyButton: {
        enable: notifyButton,
      },
    });

    OneSignal.Slidedown.promptPush();

    console.log(OneSignal);
  } catch (error) {
    console.log("error init one_signal >>>", error);
  }
}
