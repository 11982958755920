import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { getAllNotifications } from "../../http-calls";

const clearNotifications = createAction("clearNotifications");
const unreadNotificationCountUpdate = createAction(
  "unreadNotificationCountUpdate"
);
const markReadSingleNotification = createAction("markReadSingleNotification");
const incrementUnreadNotificationCount = createAction(
  "incrementUnreadNotificationCount"
);

const fetchNotifications = createAsyncThunk(
  "notificationData/fetchNotifications",
  async (payload, thunkAPI) => {
    const pagination = payload
      ? payload
      : {
          filter: { type: [] },
          skip: 0,
          limit: 20,
        };

    const res = await getAllNotifications(pagination);

    return {
      notifications: res.notifications,
      total: res.count,
      unreadNotificationCount: res.unreadNotificationCount,
      isConcat: pagination?.skip > 0 ? true : false,
    };
  }
);

export {
  clearNotifications,
  unreadNotificationCountUpdate,
  markReadSingleNotification,
  incrementUnreadNotificationCount,
  fetchNotifications,
};
