import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Button } from "reactstrap";

import { PAYMENT_GATEWAY } from "../config";
import { errorHandler } from "../helper-methods";
import { reDoUserKycLink } from "../http-calls";
import { fetchAndModifiedUserDetails } from "../redux/actions";

const ReKYCCard = () => {
  const dispatch = useDispatch();

  const userData = useSelector((state) => state?.userData);

  const [loading, setLoading] = useState(false);

  const _getAndRedirectUserReKycLink = async () => {
    try {
      setLoading(true);

      const res = await reDoUserKycLink();

      setLoading(false);

      if (!res?.kycLink?.url) {
        return;
      }

      dispatch(fetchAndModifiedUserDetails());

      // openUrlOnNewTab(res.kycLink.url);
      // let windowReference = window.open();
      // windowReference.location = res.kycLink.url;
      window.open(res.kycLink.url, "_self");
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  // check if last kyc was made before 30 mintues
  // const _hasDonedKYCRecently = () => {
  //   return (
  //     userData?.user?.hasOwnProperty("lastKYCSubmitted") &&
  //     userData?.user?.lastKYCSubmitted &&
  //     getTimeDifferenceInMinutes(userData.user.lastKYCSubmitted) < 30
  //   );
  // };

  if (
    PAYMENT_GATEWAY !== "stripe" ||
    !userData?.user?.stripeKycRequire ||
    userData?.user?.isScheduleForAccountDelete
  ) {
    return <></>;
  }

  return (
    <>
      <Alert color="danger">
        <i className="fa fa-exclamation-triangle alertIconDanger" />
        <p>
          Dear Creator, To ensure uninterrupted payments, please update the KYC
          information for account(Ignore if already done). Thank you for your
          cooperation.
        </p>
        <hr style={{ margin: "0.9rem 0" }} />
        <Button
          className="themeBtn addBtn"
          disabled={loading}
          onClick={() => _getAndRedirectUserReKycLink()}
        >
          {loading ? <i className="fa fa-spinner fa-spin mr-2" /> : null}
          Proceed
        </Button>
      </Alert>
    </>
  );
};

export default ReKYCCard;
