import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Card, CardBody, CardHeader, CardFooter } from "reactstrap";
import PostCommentSection from "./PostCommentSection";
import { BASE_URL, CURRENCY_SYMBOL, DEFAULT_COVER_PICTURE } from "../config";
import {
  decodeToken,
  deepClone,
  getPostedDateValue,
  showToast,
  capitalize,
  calculateContentType,
  reactHtmlParser,
  isSubscriptionPriceExist,
  getLowResolutionLink,
  errorHandler,
} from "../helper-methods";
import { getComments, postCommentOnFeed } from "../http-calls";
import { hideLoader, showLoader } from "../redux/actions/loaderData";
import { getToken } from "../interceptors/token-interceptor";
import { DEFAULT_PROFILE_PICTURE } from "../config/index";
import FeedViewerCarousel from "./FeedViewer/FeedViewerCarousel";
import VisibilitySensor from "react-visibility-sensor";
import PollOptionComponent from "./PollOptionComponent";
import ConfigureString from "./ConfigureString";
import ReadMoreReadLess from "./custom/ReadMoreReadLess";
import ErrorBoundary from "./ErrorBoundary";

class SubscribeFanFeedViewer extends Component {
  state = {
    dropdownOpen: false,
    decodedUserDetails: null,
    custom: [false],
    isCommentScetionOpen: false,
    userData: null,
    unlockedPostData: null,
    postPaymentConfirmationModal: {
      amount: null,
      isVisible: false,
    },
    tipModal: {
      isVisible: false,
    },
    flagModal: {
      isVisible: false,
    },
    userToken: null,
    isCommentFetching: false,
    isPlayVideoPreview: false,
    isAudioPlay: false,
  };

  constructor(props) {
    super(props);
    this.postCommentRef = React.createRef();
    this.previewVideoTimer = null;
  }

  componentDidMount() {
    // const userData = fetchAndDecodeToken();
    this._getAuthTokenFromStore();
    let { decodedUserDetails } = deepClone(this.state);
    decodedUserDetails = decodeToken(this.props.userData.token);
    this.setState({ decodedUserDetails });
  }

  _getAuthTokenFromStore = async () => {
    const userToken = await getToken();
    this.setState({ userToken });
  };

  _getAllComments = () => {
    const { feed } = this.props;
    this.setState(
      {
        isCommentFetching: true,
      },
      () => {
        getComments(feed._id)
          .then((response) => {
            if (response && response.hasOwnProperty("comments")) {
              this.setState({
                comments: response["comments"],
                isCommentFetching: false,
              });
            }
          })
          .catch((err) => {
            this.setState({
              isCommentFetching: false,
            });
            errorHandler(err);
          });
      }
    );
  };

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  _postComment = (comment, id = null) => {
    if (id === null) {
      postCommentOnFeed({
        category: "comment",
        content: comment,
        id: this.props.feed._id,
      })
        .then((res) => {
          this.postCommentRef.current._clearComment();
          this._getAllComments();
          // if (this.props.hasOwnProperty("getAllPosts")) {
          //   this.props.getAllPosts();
          // }
          showToast("Commented successfully", "success");
        })
        .catch((err) => {
          this.props.onComment(this.props.feedIndex, false);
          errorHandler(err);
        });
    } else {
      // edit comment
    }
  };

  _getLockIcon = (feed) => {
    if (feed._contents && feed._contents.length) {
      if (feed._contents.length === 1) {
        if (feed._contents[0].contentType === "image") {
          return "icon-picture";
        } else if (feed._contents[0].contentType === "audio") {
          return "icon-microphone";
        } else if (feed._contents[0].contentType === "video") {
          return "fa fa-video-camera";
        }
      } else if (feed._contents.length > 1) {
        return "icon-picture";
      }
    }
    return "";
  };

  _getLockedMessageDetails = () => {
    const { feed } = this.props;
    const { unlockedPostData, userToken } = this.state;
    if (unlockedPostData) {
      return {
        class: "",
        url: unlockedPostData._contents[0]._content.url,
        isShown: true,
        lockIcon: "",
      };
    } else {
      if (feed.isPublic) {
        return {
          class: "",
          url:
            BASE_URL +
            "/follower/content/" +
            feed._contents[0]._content +
            "/" +
            userToken,
          isShown: true,
          lockIcon: "",
        };
      } else if (feed?.hasOwnProperty("_isPaid") && feed._isPaid === 0) {
        return {
          class: "postLocked",
          url: feed._contents[0].thumbnail,
          isShown: false,
          lockIcon: this._getLockIcon(feed),
        };
      } else if (
        feed?.hasOwnProperty("_isPaid") &&
        feed._isPaid === 1 &&
        feed.paidPostContent
      ) {
        return {
          class: "",
          url: feed.paidPostContent[0].url,
          isShown: true,
          lockIcon: "",
        };
      }
      return {
        class: "",
        url: feed._contents[0].thumbnail,
        isShown: true,
      };
    }
  };

  _onLikeBtnClick = () => {
    return;
  };

  _getLikeIcon = () => {
    const { feed } = deepClone(this.props);
    if (feed?.likes) {
      // in like state
      return "fa fa-heart";
    } else {
      //in dislike state
      return "fa fa-heart-o";
    }
  };

  _dismissPaymentConfirmation = () => {
    const { postPaymentConfirmationModal } = deepClone(this.state);
    postPaymentConfirmationModal.isVisible = false;
    this.setState({ postPaymentConfirmationModal });
  };

  _showPaymentConfirmation = () => {
    const { postPaymentConfirmationModal } = deepClone(this.state);
    postPaymentConfirmationModal.amount = this.props.feed.price;
    postPaymentConfirmationModal.isVisible = true;
    this.setState({ postPaymentConfirmationModal });
  };

  _dismissTipModal = () => {
    const { tipModal } = deepClone(this.state);
    tipModal.isVisible = false;
    this.setState({ tipModal });
  };

  _showTipModal = () => {
    const { tipModal } = deepClone(this.state);
    tipModal.isVisible = true;
    this.setState({ tipModal });
  };

  _dismissFlagModal = () => {
    const { flagModal } = deepClone(this.state);
    flagModal.isVisible = false;
    this.setState({ flagModal });
  };

  _showFlagModal = () => {
    const { flagModal } = deepClone(this.state);
    flagModal.isVisible = true;
    this.setState({ flagModal });
  };

  _onFlagConfirmation = () => {
    showToast("Successfully flagged", "success");
    this._dismissFlagModal();
  };

  _onTipSuccess = () => {
    const { feed } = deepClone(this.props);
    showToast(
      feed._influencer &&
        feed._influencer.settings &&
        feed._influencer.settings.tipText &&
        feed._influencer.settings.tipText.length
        ? capitalize(feed._influencer.settings.tipText)
        : "Tip paid successfully",
      "success"
    );
    this._dismissTipModal();
  };

  _unlockPost = () => {
    return;
  };

  _subscribe = () => {
    return;
    // const { influencer, routerRef } = this.props;
    // routerRef.push(`/subscribe?influencerId=${influencer.username}`);
  };

  _goToPostDetailsPage = () => {
    return;
    // this.props.history.push("/post/" + this.props.feed.id);
    // localStorage.setItem("feed", JSON.stringify(this.props.feed));
  };

  _calculateContent = (contents, type) => {
    let types = calculateContentType(contents, type);

    if (types > 1) {
      return types + " " + capitalize(type) + "s";
    } else if (types === 1) {
      return types + " " + capitalize(type);
    } else return null;
  };

  _startStopPreviewVideo = (post, isPlay) => {
    if (post && post.previewVideoUrl) {
      let { isPlayVideoPreview } = this.state;
      if (isPlay) {
        isPlayVideoPreview = true;
      } else {
        isPlayVideoPreview = false;
      }
      this.setState({ isPlayVideoPreview });
    }
  };

  _onToggleAudioPlayPause = (isAudioPlay = false) => {
    if (!isAudioPlay) {
      if (this.audio) {
        this.audio.pause();
      }
    }
    this.setState({ isAudioPlay });
  };

  _getFeedPriceHTML = (post, isSubscriptionRequired) => {
    if (!post) return;

    const { userData } = this.props;

    const isMultiTierSubscription = userData?.user?.multiTierSubscription;

    const isPlusValid = isSubscriptionPriceExist("plus");
    const isPremiumValid = isSubscriptionPriceExist("premium");

    if (isMultiTierSubscription) {
      return (
        <>
          {isPremiumValid && (
            <>
              <Button
                disabled={post._influencer && !post._influencer.paymentEnabled}
                className="subscribeBtn"
                onClick={() => {
                  if (isSubscriptionRequired) {
                    this._subscribe();
                  } else {
                    this._showPaymentConfirmation();
                  }
                }}
              >
                {CURRENCY_SYMBOL}
                {post.premiumPrice ? post.premiumPrice : "Free "} for Premium
              </Button>

              <span
                className="d-block text-center fs-13"
                style={{ marginBottom: 2 }}
              >
                Upgrade to Premium
              </span>
            </>
          )}

          {isPlusValid && (
            <>
              <Button
                disabled={post._influencer && !post._influencer.paymentEnabled}
                className="subscribeBtn subscribeBtnOutline"
                onClick={() => {
                  if (isSubscriptionRequired) {
                    this._subscribe();
                  } else {
                    this._showPaymentConfirmation();
                  }
                }}
              >
                {CURRENCY_SYMBOL}
                {post.plusPrice ? post.plusPrice : "Free "} for Plus
              </Button>
              <span
                className="d-block text-center fs-13"
                style={{ marginBottom: 2 }}
              >
                Upgrade to Plus
              </span>
            </>
          )}

          <Button
            disabled={post._influencer && !post._influencer.paymentEnabled}
            className="subscribeBtn subscribeBtnOutline"
            onClick={() => {
              if (isSubscriptionRequired) {
                this._subscribe();
              } else {
                this._showPaymentConfirmation();
              }
            }}
          >
            {CURRENCY_SYMBOL}
            {post.price ? post.price : 0} for Basic
          </Button>
        </>
      );
    }

    return (
      <Button
        disabled={post._influencer && !post._influencer.paymentEnabled}
        className="subscribeBtn subscribeBtnOutline"
        onClick={() => {
          if (isSubscriptionRequired) {
            this._subscribe();
          } else {
            this._showPaymentConfirmation();
          }
        }}
      >
        {CURRENCY_SYMBOL}
        {post.price ? post.price : 0} Unlock
      </Button>
    );
  };

  _onChangeVisibility = (isVisible, post) => {
    clearTimeout(this.previewVideoTimer);
    if (post && post.previewVideoUrl) {
      if (isVisible) {
        this.previewVideoTimer = setTimeout(() => {
          this._startStopPreviewVideo(post, true);
        }, 4000);
      } else {
        this._startStopPreviewVideo(post, false);
      }
    }
  };

  _goToSharedPostDetailsPage = (type) => {
    try {
      switch (type) {
        case "_folder": {
          this.props.history.push(
            "/vault/folder/" + this.props.feed._folder._id
          );
          break;
        }
        case "_payperview": {
          this.props.history.push("/ppv/" + this.props.feed._payperview._id);
          break;
        }
        case "_event": {
          this.props.history.push("/live-events");
          break;
        }
        default:
      }
    } catch (error) {
      console.log("%c error>> %o", "color: red", error);
    }
  };

  _renderPost = () => {
    let { feed: post, subscriptionData } = deepClone(this.props);

    const {
      // decodedUserDetails,
      // userData,
      // userToken,
      isCommentScetionOpen,
      comments,
      unlockedPostData,
      isCommentFetching,
      isPlayVideoPreview,
    } = deepClone(this.state);
    let isSubscriptionRequired = false;
    if (
      subscriptionData &&
      subscriptionData.hasOwnProperty("isSubscribed") &&
      subscriptionData.hasOwnProperty("isSubscriptionExpired")
    ) {
      if (
        !subscriptionData.isSubscribed ||
        subscriptionData.isSubscriptionExpired
      ) {
        isSubscriptionRequired = true;
      }
    }
    if (unlockedPostData) {
      // If unlockedPostData is available, use it instead of post data from props
      post = unlockedPostData;
    }
    const isPublic = post.isPublic;
    let isLocked = false;
    if (!isPublic) {
      if (post.hasOwnProperty("_isPaid")) {
        if (post._isPaid > 0) {
          isLocked = false;
        } else {
          isLocked = true;
        }
      } else {
        isLocked = true;
      }
    }
    if (post.category && post.category === "general") {
      if (this.props.feed?.hasOwnProperty("_folder")) {
        return (
          <>
            <Card className="cardFeed">
              <CardHeader onClick={() => this._goToPostDetailsPage()}>
                {post?._influencer ? (
                  <div className="d-flex align-items-center">
                    <img
                      src={
                        getLowResolutionLink(
                          post?._influencer?.profilePicture
                        ) || DEFAULT_PROFILE_PICTURE
                      }
                      onError={(e) => (e.target.src = DEFAULT_PROFILE_PICTURE)}
                      alt="Profile Img"
                      className="userImg mr-1"
                      loading="lazy"
                    />
                    <div>
                      {/* <h4>{post._influencer.name?.full || ""}</h4> */}
                      <Button color="link" className="profileID">
                        @{post._influencer.username || "N/A"}
                      </Button>
                    </div>
                  </div>
                ) : null}

                <div className="d-flex flex-column align-items-end">
                  {!this.props.hasOwnProperty("showVaultPostTextInHeader") ||
                  this.props.showVaultPostTextInHeader ? (
                    <p>
                      <ConfigureString keyString={"vault post"} />
                    </p>
                  ) : null}
                  {/* post time */}
                  <div className="postTime-Feed">
                    {getPostedDateValue(post.startDate)}
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                {post?.text?.length ? (
                  <div className="customPaddingMob">
                    <ReadMoreReadLess
                      className="readMore"
                      text={reactHtmlParser(post.text)}
                    />
                  </div>
                ) : null}

                <div
                  className={
                    "mediaPhotoWrap-Feed" +
                    (!isPublic && isLocked ? " postLocked" : "")
                  }
                >
                  <img
                    className="mediaPhoto-Feed"
                    onClick={() => {
                      this._openMediaModal(
                        post._folder.preview && post._folder.preview.length
                          ? post._folder.preview
                          : DEFAULT_COVER_PICTURE
                      );
                    }}
                    src={post?._folder?.preview || DEFAULT_COVER_PICTURE}
                    onError={(e) => (e.target.src = DEFAULT_COVER_PICTURE)}
                    alt="Feed Img"
                    loading="lazy"
                  />
                  {!post._folder.isPublic ? (
                    <div className="postLockedOverlay">
                      <img
                        src={"/assets/img/lock-icon.png"}
                        alt="Lock Icon"
                        className="iconLock"
                        loading="lazy"
                      />

                      <div
                        style={{
                          width: 210,
                          marginBottom: -3,
                          paddingLeft: 3,
                        }}
                      >
                        <i className="icon-picture iconMedia" />
                      </div>

                      <Button disabled={true} className="paymentBtnTier">
                        Locked
                      </Button>
                    </div>
                  ) : (
                    <div className="postLockedOverlay">
                      <Button className="paymentBtnTier">Open Folder</Button>
                    </div>
                  )}
                </div>
              </CardBody>
              {!this.props.hasOwnProperty("showInteractionPanel") ||
              this.props.showInteractionPanel ? (
                <CardFooter
                  className={
                    "footerFeed " +
                    (isSubscriptionRequired ? "disabled-feed-options " : "")
                  }
                >
                  <Button
                    color="ghost-secondary"
                    onClick={() => this._onLikeBtnClick()}
                  >
                    <i className={this._getLikeIcon()} />
                    <span>{post.likes || 0}</span>
                  </Button>

                  <Button color="ghost-secondary">
                    <i
                      className={`fa ${
                        post.comments ? "fa-comment" : "fa-comment-o"
                      }`}
                    />
                    <span>{post.comments || 0}</span>
                  </Button>
                  <Button
                    color="ghost-secondary"
                    onClick={this._showTipModal}
                    disabled={
                      post._influencer && !post._influencer.paymentEnabled
                    }
                  >
                    <i className="fa fa-dollar" />
                  </Button>
                  <Button color="ghost-secondary" onClick={this._showFlagModal}>
                    <i className="fa fa-flag-o" />
                  </Button>
                </CardFooter>
              ) : null}
            </Card>
            <PostCommentSection
              onLikeComment={(id, isLiked) => this._likeComment(id, isLiked)}
              ref={this.postCommentRef}
              comments={comments}
              isOpen={isCommentScetionOpen}
              postComment={(text) => this._postComment(text)}
              getComments={() => this._getAllComments()}
              isCommentFetching={isCommentFetching}
            />
          </>
        );
      } else if (this.props.feed?.hasOwnProperty("_event")) {
        return (
          <>
            <Card className="cardFeed">
              <CardHeader>
                {post?._influencer ? (
                  <div className="d-flex align-items-center">
                    <img
                      src={
                        getLowResolutionLink(
                          post?._influencer?.profilePicture
                        ) || DEFAULT_PROFILE_PICTURE
                      }
                      onError={(e) => (e.target.src = DEFAULT_PROFILE_PICTURE)}
                      alt="Profile Img"
                      className="userImg mr-1"
                      loading="lazy"
                    />
                    <div>
                      {/* <h4>{post._influencer.name?.full || ""}</h4> */}
                      <Button color="link" className="profileID">
                        @{post._influencer.username || "N/A"}
                      </Button>
                    </div>
                  </div>
                ) : null}

                <div className="text-right">
                  {this.props.feed?.hasOwnProperty("isPinned") &&
                  this.props.feed.isPinned ? (
                    <svg
                      className="pinnedPost"
                      style={{ marginBottom: 3 }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="253.712"
                      height="256"
                      viewBox="0 0 253.712 256"
                    >
                      <path
                        id="office-push-pin_73169"
                        d="M252.92,73.317,181.538,1.935a6.6,6.6,0,0,0-9.338,0l-.3.3A23.157,23.157,0,0,0,168,29.83L93.315,93.93a31.166,31.166,0,0,0-42.782,1.226l-.448.448a6.6,6.6,0,0,0,0,9.338l42.764,42.764L51.2,189.353c-.833.857-20.547,21.172-33.508,37.338C5.349,242.082,2.908,244.9,2.782,245.047a6.6,6.6,0,0,0,9.319,9.319c.11-.1,2.865-2.49,18.356-14.91,16.164-12.961,36.478-32.676,37.4-33.575L109.44,164.3l40.474,40.474a6.6,6.6,0,0,0,9.338,0l.448-.448a31.166,31.166,0,0,0,1.226-42.782l64.1-74.689a23.157,23.157,0,0,0,27.592-3.894l.3-.3A6.6,6.6,0,0,0,252.92,73.317Z"
                        transform="translate(-1.143 0)"
                      />
                    </svg>
                  ) : null}
                  <p>
                    <ConfigureString keyString={"event"} />
                  </p>
                </div>
              </CardHeader>
              <CardBody>
                <div className="customPaddingMob">
                  {post?._event?.name?.length ? (
                    <div className="customPaddingMob">
                      <ReadMoreReadLess
                        className="readMore"
                        text={reactHtmlParser(post._event.name)}
                      />
                    </div>
                  ) : null}

                  <div className="sharedContent">
                    <div className="customPaddingMob">
                      <h5 className="liveStreamEventName">
                        {post?._event?.name}
                      </h5>

                      {post?._event?.description?.length ? (
                        <ReadMoreReadLess
                          className="readMore"
                          text={post._event.description}
                        />
                      ) : null}
                    </div>
                    <div className="mediaPhotoWrap-Feed">
                      <img
                        className="mediaPhoto-Feed"
                        src={post?._event?.coverImage || DEFAULT_COVER_PICTURE}
                        alt="Feed Img"
                        onError={(e) => (e.target.src = DEFAULT_COVER_PICTURE)}
                        loading="lazy"
                      />
                      {!post._event.isPublic ? (
                        <div className="postLockedOverlay">
                          <img
                            src={"/assets/img/lock-icon.png"}
                            alt="Lock Icon"
                            className="iconLock"
                            loading="lazy"
                          />

                          <Button disabled={true} className="paymentBtnTier">
                            Locked
                          </Button>
                        </div>
                      ) : null}{" "}
                      {/* Cancelled Event  */}
                      {post._event.hasOwnProperty("status") &&
                      post._event.status === "cancelled" ? (
                        <div className="cancelledEventWrap">
                          <div>
                            <ConfigureString keyString="event Cancelled" />
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </CardBody>
              {!this.props.hasOwnProperty("showInteractionPanel") ||
              this.props.showInteractionPanel ? (
                <CardFooter className="footerFeed">
                  <Button
                    color="ghost-secondary"
                    onClick={() => this._onLikeBtnClick()}
                  >
                    <i className={this._getLikeIcon()} />
                    <span>{post.likes || 0}</span>
                  </Button>

                  <Button color="ghost-secondary">
                    <i
                      className={`fa ${
                        post.comments ? "fa-comment" : "fa-comment-o"
                      }`}
                    />
                    <span>{post.comments || 0}</span>
                  </Button>
                  <Button color="ghost-secondary" onClick={this._showTipModal}>
                    <i className="fa fa-dollar" />
                  </Button>
                  <Button color="ghost-secondary">
                    <i className="fa fa-flag-o" />
                  </Button>
                </CardFooter>
              ) : null}
            </Card>
            <PostCommentSection
              feedIndex={this.props.feedIndex}
              onComment={(index, isAdded) =>
                this.props.onComment(index, isAdded)
              }
              onLikeComment={(id, isLiked) => this._likeComment(id, isLiked)}
              feed={this.props.feed}
              ref={this.postCommentRef}
              comments={comments}
              isOpen={isCommentScetionOpen}
              postComment={(text) => this._postComment(text)}
              getComments={() => this._getAllComments()}
              user={this.props.user}
              isCommentFetching={isCommentFetching}
            />
          </>
        );
      } else if (this.props.feed?.hasOwnProperty("_payperview")) {
        return (
          <>
            <Card className="cardFeed">
              <CardHeader>
                {post?._influencer ? (
                  <div className="d-flex align-items-center">
                    <img
                      src={
                        getLowResolutionLink(
                          post?._influencer?.profilePicture
                        ) || DEFAULT_PROFILE_PICTURE
                      }
                      onError={(e) => (e.target.src = DEFAULT_PROFILE_PICTURE)}
                      alt="Profile Img"
                      className="userImg mr-1"
                      loading="lazy"
                    />
                    <div>
                      {/* <h4>{post._influencer.name?.full || ""}</h4> */}
                      <Button color="link" className="profileID">
                        @{post._influencer.username || "N/A"}
                      </Button>
                    </div>
                  </div>
                ) : null}

                <div className="d-flex flex-column align-items-end">
                  <p>
                    <ConfigureString keyString={"ppv"} />
                  </p>
                  {/* post time */}
                  <div className="postTime-Feed">
                    {this.props.feed?.hasOwnProperty("isPinned") &&
                    this.props.feed.isPinned ? (
                      <>
                        {" "}
                        <i className="fa fa-thumb-tack" aria-hidden="true" />
                        <br />{" "}
                      </>
                    ) : null}
                    {getPostedDateValue(this.props.feed.startDate)}
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                {post?.text?.length ? (
                  <div className="customPaddingMob">
                    <ReadMoreReadLess
                      className="readMore"
                      text={reactHtmlParser(post.text)}
                    />
                  </div>
                ) : null}

                <div
                  className={
                    "embed-responsive embed-responsive-16by9 mediaVideoWrap-Feed " +
                    (!isPublic && isLocked ? " postLocked" : "")
                  }
                >
                  <div className="customPaddingMob">
                    {post?._payperview?.title ? (
                      <div className="ppvVideoName">
                        {post._payperview.title}
                      </div>
                    ) : null}

                    {post?._payperview?.description?.length ? (
                      <ReadMoreReadLess
                        className="readMore"
                        text={post._payperview.description}
                      />
                    ) : null}
                  </div>

                  {/* <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/zpOULjyy-n8?rel=0" allowfullscreen></iframe> */}
                  {!post._payperview.isPublic ? (
                    <>
                      <div className="shadowOverlayImg" />

                      <img
                        className="mediaPhoto-Feed"
                        src={
                          post?._payperview?.coverImage || DEFAULT_COVER_PICTURE
                        }
                        alt="Cover Img"
                        loading="lazy"
                      />
                      <div className="postLockedOverlay">
                        <img
                          src={"/assets/img/lock-icon.png"}
                          alt="Lock Icon"
                          className="iconLock"
                          loading="lazy"
                        />
                        <div
                          style={{
                            width: 210,
                            marginBottom: -3,
                            paddingLeft: 3,
                          }}
                        >
                          <i className="fa fa-video-camera iconMedia" />
                        </div>
                        <Button
                          onClick={() =>
                            this._goToSharedPostDetailsPage("_payperview")
                          }
                          className="paymentBtnTier"
                        >
                          Locked
                        </Button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="shadowOverlayImg" />
                      <img
                        className="mediaPhoto-Feed"
                        src={
                          post?._payperview?.coverPicture ||
                          DEFAULT_COVER_PICTURE
                        }
                        alt="Cover Img"
                        loading="lazy"
                      />
                      <Button
                        className="videoPlayBtn"
                        onClick={() =>
                          this._goToSharedPostDetailsPage("_payperview")
                        }
                      >
                        <i className="fa fa-play" />
                      </Button>
                    </>
                  )}
                </div>
              </CardBody>
              {!this.props.hasOwnProperty("showInteractionPanel") ||
              this.props.showInteractionPanel ? (
                <CardFooter className="footerFeed">
                  <Button
                    color="ghost-secondary"
                    onClick={() => this._onLikeBtnClick()}
                  >
                    <i className={this._getLikeIcon()} />
                    <span>{post.likes || 0}</span>
                  </Button>

                  <Button color="ghost-secondary">
                    <i
                      className={`fa ${
                        post.comments ? "fa-comment" : "fa-comment-o"
                      }`}
                    />
                    <span>{post.comments || 0}</span>
                  </Button>
                  <Button color="ghost-secondary" onClick={this._showTipModal}>
                    <i className="fa fa-dollar" />
                  </Button>
                  <Button color="ghost-secondary">
                    <i className="fa fa-flag-o" />
                  </Button>
                </CardFooter>
              ) : null}
            </Card>
            <PostCommentSection
              feedIndex={this.props.feedIndex}
              onComment={(index, isAdded) =>
                this.props.onComment(index, isAdded)
              }
              onLikeComment={(id, isLiked) => this._likeComment(id, isLiked)}
              feed={this.props.feed}
              ref={this.postCommentRef}
              comments={comments}
              isOpen={isCommentScetionOpen}
              postComment={(text) => this._postComment(text)}
              getComments={() => this._getAllComments()}
              user={this.props.user}
              isCommentFetching={isCommentFetching}
            />
          </>
        );
      } else if (post._contents && post._contents.length > 1) {
        return (
          <>
            <Card className="cardFeed">
              <CardHeader onClick={() => this._goToPostDetailsPage()}>
                {post?._influencer ? (
                  <div className="d-flex align-items-center">
                    <img
                      src={
                        getLowResolutionLink(
                          post?._influencer?.profilePicture
                        ) || DEFAULT_PROFILE_PICTURE
                      }
                      onError={(e) => (e.target.src = DEFAULT_PROFILE_PICTURE)}
                      alt="Profile Img"
                      className="userImg mr-1"
                      loading="lazy"
                    />
                    <div>
                      {/* <h4>{post._influencer.name?.full || ""}</h4> */}
                      <Button color="link" className="profileID">
                        @{post._influencer.username || "N/A"}
                      </Button>
                    </div>
                  </div>
                ) : null}

                {/* post time */}
                <div className="postTime-Feed">
                  {getPostedDateValue(post.startDate)}
                </div>
              </CardHeader>
              <CardBody>
                {post?.text?.length ? (
                  <div className="customPaddingMob">
                    <ReadMoreReadLess
                      className="readMore"
                      text={reactHtmlParser(post.text)}
                    />
                  </div>
                ) : null}

                <div
                  className={
                    "mediaPhotoWrap-Feed" + (isLocked ? " postLocked" : "")
                  }
                >
                  {!isPublic && isLocked ? (
                    <>
                      {post && post.previewVideoUrl && isPlayVideoPreview ? (
                        <div className="postPreviewVideo">
                          {/* post preview video */}
                          <video
                            width="100%"
                            height="100%"
                            className=""
                            loop={true}
                            autoPlay={true}
                            onMouseOut={() => this._startStopPreviewVideo(post)}
                            src={post.previewVideoUrl}
                          />
                          <div className="videoPreviewTag">Video Preview</div>
                        </div>
                      ) : null}

                      <div
                        className="postLockedOverlay"
                        onMouseOver={() =>
                          this._startStopPreviewVideo(post, true)
                        }
                      ></div>

                      {/* <h3>
                        <i className="icon-picture"/>{" "}
                        Locked Post
                      </h3> */}

                      {/* <img
                        src={"/assets/img/lock-icon.png"}
                        alt="Lock Icon"
                        className="iconLock"
                        loading="lazy"
                      /> */}

                      {/* <h4>
                        {isSubscriptionRequired
                          ? "Subscribe to unlock"
                          : "Pay to unlock"}
                      </h4> */}

                      <div className="subscribeBtnsWrap">
                        <div
                          style={{
                            width: 210,
                            marginBottom: -3,
                            paddingLeft: 3,
                          }}
                        >
                          {this._calculateContent(post._contents, "image") && (
                            <>
                              <i
                                className="icon-picture iconMedia mr-1"
                                style={{ verticalAlign: -1 }}
                              />
                              <span className="fs-14 mr-2">
                                {this._calculateContent(
                                  post._contents,
                                  "image"
                                )}
                              </span>
                            </>
                          )}

                          {this._calculateContent(post._contents, "video") && (
                            <>
                              <i className="fa fa-video-camera iconMedia mr-1" />
                              <span className="fs-14 mr-2">
                                {this._calculateContent(
                                  post._contents,
                                  "video"
                                )}
                              </span>
                            </>
                          )}

                          {this._calculateContent(post._contents, "audio") && (
                            <>
                              <i
                                className="icon-microphone iconMedia mr-1"
                                style={{ verticalAlign: -1 }}
                              />
                              <span className="fs-14 mr-2">
                                {this._calculateContent(
                                  post._contents,
                                  "audio"
                                )}
                              </span>
                            </>
                          )}
                        </div>

                        {this._getFeedPriceHTML(post, isSubscriptionRequired)}
                      </div>
                    </>
                  ) : (
                    <>
                      <ErrorBoundary>
                        {/* multi post carousel */}
                        <FeedViewerCarousel
                          contents={post?._contents}
                          feed={post}
                        />
                      </ErrorBoundary>
                    </>
                  )}
                </div>
              </CardBody>
              <CardFooter
                className={
                  "footerFeed " +
                  (isSubscriptionRequired ? "disabled-feed-options " : "")
                }
              >
                <Button
                  color="ghost-secondary"
                  onClick={() => this._onLikeBtnClick()}
                >
                  <i className={this._getLikeIcon()} />
                  <span>{post.likes || 0}</span>
                </Button>

                <Button color="ghost-secondary">
                  <i
                    className={`fa ${
                      post.comments ? "fa-comment" : "fa-comment-o"
                    }`}
                  />
                  <span>{post.comments || 0}</span>
                </Button>
                <Button
                  disabled={
                    post._influencer && !post._influencer.paymentEnabled
                  }
                  color="ghost-secondary"
                  onClick={this._showTipModal}
                >
                  <i className="fa fa-dollar" />
                </Button>
                <Button color="ghost-secondary" onClick={this._showFlagModal}>
                  <i className="fa fa-flag-o" />
                </Button>
              </CardFooter>
            </Card>
            <PostCommentSection
              onLikeComment={(id, isLiked) => this._likeComment(id, isLiked)}
              ref={this.postCommentRef}
              comments={comments}
              isOpen={isCommentScetionOpen}
              postComment={(text) => this._postComment(text)}
              getComments={() => this._getAllComments()}
              isCommentFetching={isCommentFetching}
            />
          </>
        );
      } else if (
        post._contents &&
        post._contents.length === 1 &&
        !post._contents[0].hasOwnProperty("_bsontype")
      ) {
        if (post._contents[0].contentType === "image") {
          // contains only one image
          return (
            <>
              <Card className="cardFeed">
                <CardHeader onClick={() => this._goToPostDetailsPage()}>
                  {post?._influencer ? (
                    <div className="d-flex align-items-center">
                      <img
                        src={
                          getLowResolutionLink(
                            post?._influencer?.profilePicture
                          ) || DEFAULT_PROFILE_PICTURE
                        }
                        onError={(e) =>
                          (e.target.src = DEFAULT_PROFILE_PICTURE)
                        }
                        alt="Profile Img"
                        className="userImg mr-1"
                        loading="lazy"
                      />
                      <div>
                        {/* <h4>{post._influencer.name?.full || ""}</h4> */}
                        <Button color="link" className="profileID">
                          @{post._influencer.username || "N/A"}
                        </Button>
                      </div>
                    </div>
                  ) : null}

                  {/* post time */}
                  <div className="postTime-Feed">
                    {getPostedDateValue(post.startDate)}
                  </div>
                </CardHeader>
                <CardBody>
                  {post?.text?.length ? (
                    <div className="customPaddingMob">
                      <ReadMoreReadLess
                        className="readMore"
                        text={reactHtmlParser(post.text)}
                      />
                    </div>
                  ) : null}

                  <div
                    className={
                      "mediaPhotoWrap-Feed" +
                      (!isPublic && isLocked ? " postLocked" : "")
                    }
                  >
                    <img
                      className="mediaPhoto-Feed"
                      onClick={() => {
                        this._openMediaModal(
                          isPublic
                            ? post._contents[0]._content.url
                            : !isLocked
                            ? post._contents[0]._content.url
                            : null,
                          "image"
                        );
                      }}
                      src={
                        isPublic
                          ? post?._contents?.[0]?._content?.url
                          : !isPublic && !isLocked
                          ? post?._contents?.[0]?._content?.url
                          : post?._contents?.[0]?.thumbnail ||
                            DEFAULT_COVER_PICTURE
                      }
                      alt="Feed Img"
                      loading="lazy"
                    />
                    {!isPublic && isLocked ? (
                      <div className="postLockedOverlay">
                        <h3>
                          <i className="icon-picture" /> Locked Post
                        </h3>

                        <img
                          src={"/assets/img/lock-icon.png"}
                          alt="Lock Icon"
                          className="iconLock"
                          loading="lazy"
                        />

                        <div
                          style={{
                            width: 210,
                            marginBottom: -3,
                            paddingLeft: 3,
                          }}
                        >
                          {this._calculateContent(post._contents, "image") && (
                            <>
                              <i
                                className="icon-picture iconMedia mr-1"
                                style={{ verticalAlign: -1 }}
                              />
                              <span className="fs-14 mr-2">
                                {this._calculateContent(
                                  post._contents,
                                  "image"
                                )}
                              </span>
                            </>
                          )}
                        </div>

                        {this._getFeedPriceHTML(post, isSubscriptionRequired)}
                      </div>
                    ) : null}{" "}
                  </div>
                </CardBody>
                <CardFooter
                  className={
                    "footerFeed " +
                    (isSubscriptionRequired ? "disabled-feed-options " : "")
                  }
                >
                  <Button
                    color="ghost-secondary"
                    onClick={() => this._onLikeBtnClick()}
                  >
                    <i className={this._getLikeIcon()} />
                    <span>{post.likes || 0}</span>
                  </Button>

                  <Button color="ghost-secondary">
                    <i
                      className={`fa ${
                        post.comments ? "fa-comment" : "fa-comment-o"
                      }`}
                    />
                    <span>{post.comments || 0}</span>
                  </Button>
                  <Button
                    color="ghost-secondary"
                    onClick={this._showTipModal}
                    disabled={
                      post._influencer && !post._influencer.paymentEnabled
                    }
                  >
                    <i className="fa fa-dollar" />
                  </Button>
                  <Button color="ghost-secondary" onClick={this._showFlagModal}>
                    <i className="fa fa-flag-o" />
                  </Button>
                </CardFooter>
              </Card>
              <PostCommentSection
                onLikeComment={(id, isLiked) => this._likeComment(id, isLiked)}
                ref={this.postCommentRef}
                comments={comments}
                isOpen={isCommentScetionOpen}
                postComment={(text) => this._postComment(text)}
                getComments={() => this._getAllComments()}
                isCommentFetching={isCommentFetching}
              />
            </>
          );
        } else if (
          post._contents[0].contentType === "audio" &&
          !post._contents[0].hasOwnProperty("_bsontype")
        ) {
          // contains only one audio file
          return (
            <>
              <Card className="cardFeed">
                <CardHeader onClick={() => this._goToPostDetailsPage()}>
                  {post?._influencer ? (
                    <div className="d-flex align-items-center">
                      <img
                        src={
                          getLowResolutionLink(
                            post?._influencer?.profilePicture
                          ) || DEFAULT_PROFILE_PICTURE
                        }
                        onError={(e) =>
                          (e.target.src = DEFAULT_PROFILE_PICTURE)
                        }
                        alt="Profile Img"
                        className="userImg mr-1"
                        loading="lazy"
                      />
                      <div>
                        {/* <h4>{post._influencer.name?.full || ""}</h4> */}
                        <Button color="link" className="profileID">
                          @{post._influencer.username || "N/A"}
                        </Button>
                      </div>
                    </div>
                  ) : null}

                  {/* post time */}
                  <div className="postTime-Feed">
                    {getPostedDateValue(post.startDate)}
                  </div>
                </CardHeader>
                <CardBody>
                  {post?.text?.length ? (
                    <div className="customPaddingMob">
                      <ReadMoreReadLess
                        className="readMore"
                        text={reactHtmlParser(post.text)}
                      />
                    </div>
                  ) : null}

                  <div
                    className={
                      "mediaPhotoWrap-Feed" + !isPublic && isLocked
                        ? " postLocked"
                        : ""
                    }
                    style={{
                      minHeight: !this._getLockedMessageDetails().isShown
                        ? "235px"
                        : "unset",
                    }}
                  >
                    <div
                      className={
                        "audioWrapper " +
                        (!isPublic && isLocked ? "audioLocked" : "")
                      }
                    >
                      {/* add class audioRippleStart, when play audio */}
                      <div
                        className={`audioRipple ${
                          this.state.isAudioPlay ? "audioRippleStart" : ""
                        }`}
                      >
                        <img
                          src="/assets/img/microphone.png"
                          alt="Microphone"
                          loading="lazy"
                        />
                      </div>

                      <VisibilitySensor
                        onChange={(isVisible) =>
                          !isVisible ? this._onToggleAudioPlayPause(false) : ""
                        }
                      >
                        <audio
                          // className="audioWidth"
                          ref={(audio) => {
                            this.audio = audio;
                          }}
                          controls
                          onPlay={() => this._onToggleAudioPlayPause(true)}
                          onPause={() => this._onToggleAudioPlayPause(false)}
                          controlsList="nodownload"
                          src={
                            post._contents[0]?._content?.url
                              ? post._contents[0]._content.url
                              : ""
                          }
                        />
                      </VisibilitySensor>
                    </div>

                    {!this._getLockedMessageDetails().isShown ? (
                      <div className="postLockedOverlay">
                        {/* <h3>
                          <i className="icon-microphone" /> Locked Message
                        </h3> */}

                        <img
                          src={"/assets/img/lock-icon.png"}
                          alt="Lock Icon"
                          className="iconLock"
                          loading="lazy"
                        />

                        {/* <h4>
                          {isSubscriptionRequired
                            ? "Subscribe to unlock"
                            : "Pay to unlock"}
                        </h4> */}

                        <div
                          style={{
                            width: 210,
                            marginBottom: -3,
                            paddingLeft: 3,
                          }}
                        >
                          {this._calculateContent(post._contents, "audio") && (
                            <>
                              <i
                                className="icon-microphone iconMedia mr-1"
                                style={{ verticalAlign: -1 }}
                              />
                              <span className="fs-14 mr-2">
                                {this._calculateContent(
                                  post._contents,
                                  "audio"
                                )}
                              </span>
                            </>
                          )}
                        </div>

                        {this._getFeedPriceHTML(post, isSubscriptionRequired)}
                      </div>
                    ) : null}
                  </div>
                </CardBody>
                <CardFooter
                  className={
                    "footerFeed " +
                    (isSubscriptionRequired ? "disabled-feed-options " : "")
                  }
                >
                  <Button
                    color="ghost-secondary"
                    onClick={() => this._onLikeBtnClick()}
                  >
                    <i className={this._getLikeIcon()} />
                    <span>{post.likes || 0}</span>
                  </Button>

                  <Button color="ghost-secondary">
                    <i
                      className={`fa ${
                        post.comments ? "fa-comment" : "fa-comment-o"
                      }`}
                    />
                    <span>{post.comments || 0}</span>
                  </Button>
                  <Button
                    color="ghost-secondary"
                    onClick={this._showTipModal}
                    disabled={
                      post._influencer && !post._influencer.paymentEnabled
                    }
                  >
                    <i className="fa fa-dollar" />
                  </Button>
                  <Button color="ghost-secondary" onClick={this._showFlagModal}>
                    <i className="fa fa-flag-o" />
                  </Button>
                </CardFooter>
              </Card>
              <PostCommentSection
                onLikeComment={(id, isLiked) => this._likeComment(id, isLiked)}
                ref={this.postCommentRef}
                comments={comments}
                isOpen={isCommentScetionOpen}
                postComment={(text) => this._postComment(text)}
                getComments={() => this._getAllComments()}
                isCommentFetching={isCommentFetching}
              />
            </>
          );
        } else if (
          post._contents[0].contentType === "video" &&
          !post._contents[0].hasOwnProperty("_bsontype")
        ) {
          // contains only one video
          return (
            <>
              <Card className="cardFeed">
                <CardHeader onClick={() => this._goToPostDetailsPage()}>
                  {post?._influencer ? (
                    <div className="d-flex align-items-center">
                      <img
                        src={
                          getLowResolutionLink(
                            post?._influencer?.profilePicture
                          ) || DEFAULT_PROFILE_PICTURE
                        }
                        onError={(e) =>
                          (e.target.src = DEFAULT_PROFILE_PICTURE)
                        }
                        alt="Profile Img"
                        className="userImg mr-1"
                        loading="lazy"
                      />
                      <div>
                        {/* <h4>{post._influencer.name?.full || ""}</h4> */}
                        <Button color="link" className="profileID">
                          @{post._influencer.username || "N/A"}
                        </Button>
                      </div>
                    </div>
                  ) : null}

                  {/* post time */}
                  <div className="postTime-Feed">
                    {getPostedDateValue(post.startDate)}
                  </div>
                </CardHeader>
                <CardBody>
                  {post?.text?.length ? (
                    <div className="customPaddingMob">
                      <ReadMoreReadLess
                        className="readMore"
                        text={reactHtmlParser(post.text)}
                      />
                    </div>
                  ) : null}

                  <div
                    className={
                      "embed-responsive embed-responsive-16by9 mediaVideoWrap-Feed " +
                      (!isPublic && isLocked ? " postLocked" : "")
                    }
                  >
                    {/* <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/zpOULjyy-n8?rel=0" allowfullscreen></iframe> */}

                    {!isPublic && isLocked ? (
                      <>
                        <VisibilitySensor
                          onChange={(isVisible) =>
                            this._onChangeVisibility(isVisible, post)
                          }
                        >
                          {post &&
                          post.previewVideoUrl &&
                          isPlayVideoPreview ? (
                            <>
                              {/* post preview video */}
                              <video
                                onEnded={() =>
                                  this._startStopPreviewVideo(post)
                                }
                                onError={() =>
                                  this._startStopPreviewVideo(post)
                                }
                                autoPlay={true}
                                src={post.previewVideoUrl}
                              />
                              <div className="videoPreviewTag-Feed">
                                Video Preview
                              </div>
                            </>
                          ) : (
                            <>
                              <img
                                className="mediaPhoto-Feed"
                                src={
                                  post?._contents?.[0]?.thumbnail ||
                                  DEFAULT_COVER_PICTURE
                                }
                                onError={(e) =>
                                  (e.target.src = DEFAULT_COVER_PICTURE)
                                }
                                style={{ filter: "blur(20px)" }}
                                alt="Cover Img"
                                loading="lazy"
                              />
                            </>
                          )}
                        </VisibilitySensor>

                        <div className="postLockedOverlay" />

                        <div className="subscribeBtnsWrap">
                          <div
                            style={{
                              width: 210,
                              marginBottom: -3,
                              paddingLeft: 3,
                            }}
                          >
                            {this._calculateContent(
                              post._contents,
                              "video"
                            ) && (
                              <>
                                <i className="fa fa-video-camera iconMedia mr-1" />
                                <span className="fs-14 mr-2">
                                  {this._calculateContent(
                                    post._contents,
                                    "video"
                                  )}
                                </span>
                              </>
                            )}
                          </div>

                          {this._getFeedPriceHTML(post, isSubscriptionRequired)}
                        </div>
                      </>
                    ) : (
                      <video
                        className="embed-responsive-item"
                        src={
                          isPublic
                            ? post._contents[0]._content.url
                            : !isPublic && !isLocked
                            ? post._contents[0]._content.url
                            : post._contents[0].thumbnail
                        }
                        poster={
                          post._contents[0].thumbnail || DEFAULT_COVER_PICTURE
                        }
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = post._contents[0]._content.url;
                        }}
                        controls
                        controlsList="nodownload"
                        disablePictureInPicture
                      />
                    )}
                  </div>
                </CardBody>
                <CardFooter
                  className={
                    "footerFeed " +
                    (isSubscriptionRequired ? "disabled-feed-options " : "")
                  }
                >
                  <Button
                    color="ghost-secondary"
                    onClick={() => this._onLikeBtnClick()}
                  >
                    <i className={this._getLikeIcon()} />
                    <span>{post.likes || 0}</span>
                  </Button>

                  <Button color="ghost-secondary">
                    <i
                      className={`fa ${
                        post.comments ? "fa-comment" : "fa-comment-o"
                      }`}
                    />
                    <span>{post.comments || 0}</span>
                  </Button>
                  <Button
                    color="ghost-secondary"
                    onClick={this._showTipModal}
                    disabled={
                      post._influencer && !post._influencer.paymentEnabled
                    }
                  >
                    <i className="fa fa-dollar" />
                  </Button>
                  <Button color="ghost-secondary" onClick={this._showFlagModal}>
                    <i className="fa fa-flag-o" />
                  </Button>
                </CardFooter>
              </Card>
              <PostCommentSection
                onLikeComment={(id, isLiked) => this._likeComment(id, isLiked)}
                ref={this.postCommentRef}
                comments={comments}
                isOpen={isCommentScetionOpen}
                postComment={(text) => this._postComment(text)}
                getComments={() => this._getAllComments()}
                isCommentFetching={isCommentFetching}
              />
            </>
          );
        }
      } else if (post._contents && post._contents.length === 0) {
        // text only post
        return (
          <>
            <Card className="cardFeed">
              <CardHeader onClick={() => this._goToPostDetailsPage()}>
                {post?._influencer ? (
                  <div className="d-flex align-items-center">
                    <img
                      src={
                        getLowResolutionLink(
                          post?._influencer?.profilePicture
                        ) || DEFAULT_PROFILE_PICTURE
                      }
                      onError={(e) => (e.target.src = DEFAULT_PROFILE_PICTURE)}
                      alt="Profile Img"
                      className="userImg mr-1"
                      loading="lazy"
                    />
                    <div>
                      {/* <h4>{post._influencer.name?.full || ""}</h4> */}
                      <Button color="link" className="profileID">
                        @{post._influencer.username || "N/A"}
                      </Button>
                    </div>
                  </div>
                ) : null}

                {/* post time */}
                <div className="postTime-Feed">
                  {getPostedDateValue(post.startDate)}
                </div>
              </CardHeader>
              <CardBody>
                {post?.text?.length ? (
                  <div className="customPaddingMob">
                    <ReadMoreReadLess
                      className="readMore"
                      text={reactHtmlParser(post.text)}
                    />
                  </div>
                ) : null}
              </CardBody>
              <CardFooter
                className={
                  "footerFeed " +
                  (isSubscriptionRequired ? "disabled-feed-options " : "")
                }
              >
                <Button
                  color="ghost-secondary"
                  onClick={() => this._onLikeBtnClick()}
                >
                  <i className={this._getLikeIcon()} />
                  <span>{post.likes || 0}</span>
                </Button>

                <Button color="ghost-secondary">
                  <i
                    className={`fa ${
                      post.comments ? "fa-comment" : "fa-comment-o"
                    }`}
                  />
                  <span>{post.comments || 0}</span>
                </Button>
                <Button
                  color="ghost-secondary"
                  onClick={this._showTipModal}
                  disabled={
                    post._influencer && !post._influencer.paymentEnabled
                  }
                >
                  <i className="fa fa-dollar" />
                </Button>
                <Button color="ghost-secondary" onClick={this._showFlagModal}>
                  <i className="fa fa-flag-o" />
                </Button>
              </CardFooter>
            </Card>
            <PostCommentSection
              onLikeComment={(id, isLiked) => this._likeComment(id, isLiked)}
              ref={this.postCommentRef}
              comments={comments}
              isOpen={isCommentScetionOpen}
              postComment={(text) => this._postComment(text)}
              getComments={() => this._getAllComments()}
              isCommentFetching={isCommentFetching}
            />
          </>
        );
      }
    } else if (post.category && post.category === "poll") {
      return (
        <>
          <Card className="cardFeed">
            <CardHeader onClick={() => this._goToPostDetailsPage()}>
              {post?._influencer ? (
                <div className="d-flex align-items-center">
                  <img
                    src={
                      getLowResolutionLink(post?._influencer?.profilePicture) ||
                      DEFAULT_PROFILE_PICTURE
                    }
                    onError={(e) => (e.target.src = DEFAULT_PROFILE_PICTURE)}
                    alt="Profile Img"
                    className="userImg mr-1"
                    loading="lazy"
                  />
                  <div>
                    {/* <h4>{post._influencer.name?.full || ""}</h4> */}
                    <Button color="link" className="profileID">
                      @{post._influencer.username || "N/A"}
                    </Button>
                  </div>
                </div>
              ) : null}

              {/* post time */}
              <div className="postTime-Feed">
                {getPostedDateValue(post.startDate)}
              </div>
            </CardHeader>
            <CardBody>
              <div className="customPaddingMob">
                {post?.description?.length ? (
                  <>
                    <div className="customPaddingMob">
                      <ReadMoreReadLess
                        className="readMore"
                        text={reactHtmlParser(post.description)}
                      />
                    </div>
                    <br />
                  </>
                ) : null}

                {post?.text?.length ? (
                  <div className="customPaddingMob">
                    <ReadMoreReadLess
                      className="readMore"
                      text={reactHtmlParser(post.text)}
                    />
                  </div>
                ) : null}

                <div className="customPaddingMob">
                  {/* poll options should always be wrapped inside the below div */}
                  <div className="pollOptionWrap">
                    {post.options && post.options.length
                      ? post.options.map((option, optionIndex) => (
                          <PollOptionComponent
                            key={optionIndex}
                            post={post}
                            option={option}
                            feed={this.props.feed}
                            feedIndex={this.props.feedIndex}
                            optionIndex={optionIndex}
                            selectPollOption={this.props.selectPollOption}
                          />
                        ))
                      : null}
                  </div>
                  {/* total vote count */}
                  {post._opinions && post._opinions.length ? (
                    <div className="totalVotes">
                      {post._opinions.length
                        ? `${post._opinions.length} ${
                            post._opinions.length > 1 ? "Votes" : "Vote"
                          }`
                        : "0 Vote"}
                    </div>
                  ) : null}
                </div>
              </div>
            </CardBody>
            <CardFooter
              className={
                "footerFeed " +
                (isSubscriptionRequired ? "disabled-feed-options " : "")
              }
            >
              <Button
                color="ghost-secondary"
                onClick={() => this._onLikeBtnClick()}
              >
                <i className={this._getLikeIcon()} />
                <span>{post.likes || 0}</span>
              </Button>

              <Button color="ghost-secondary">
                <i
                  className={`fa ${
                    post.comments ? "fa-comment" : "fa-comment-o"
                  }`}
                />
                <span>{post.comments || 0}</span>
              </Button>

              <Button color="ghost-secondary" onClick={this._showFlagModal}>
                <i className="fa fa-flag-o" />
              </Button>
            </CardFooter>
          </Card>
          <PostCommentSection
            onLikeComment={(id, isLiked) => this._likeComment(id, isLiked)}
            ref={this.postCommentRef}
            comments={comments}
            isOpen={isCommentScetionOpen}
            postComment={(text) => this._postComment(text)}
            getComments={() => this._getAllComments()}
            isCommentFetching={isCommentFetching}
          />
        </>
      );
    }
    return <div />;
  };

  _defaultSelectedPollAnswer = (option) => {
    const { feed } = this.props;
    if (feed && feed._opinions && feed._opinions.length) {
      if (feed._opinions[0].option === option._id) {
        return true;
      }
    }
    return false;
  };

  _openMediaModal = (showMediaLink, shownContentType) => {
    if (shownContentType === "image" && showMediaLink && showMediaLink.length) {
      this.setState({
        showMediaLink,
        isMediaModalOpen: true,
        shownContentType,
      });
    }
  };

  _closeMediaModal = () => {
    this.setState({ isMediaModalOpen: false, showMediaLink: "" });
  };

  render() {
    // const {
    //   postPaymentConfirmationModal,
    //   tipModal,
    //   flagModal,
    //   showMediaLink,
    //   isMediaModalOpen,
    //   shownContentType,
    //   isCommentFetching
    // } = this.state;
    // const { feed } = this.props;

    return (
      <>
        {this._renderPost()}
        {/* <PaymentModal
          isVisible={postPaymentConfirmationModal.isVisible}
          amount={postPaymentConfirmationModal.amount}
          onPaymentConfirmation={() => this._unlockPost()}
          onDismiss={() => this._dismissPaymentConfirmation()}
        />
        <PostTipPaymentDialog
          isVisible={tipModal.isVisible}
          feed={feed}
          onTipSuccess={() => this._onTipSuccess()}
          onDismiss={() => this._dismissTipModal()}
        />
        <ReportPostModal
          isVisible={flagModal.isVisible}
          feed={feed}
          onSuccess={this._onFlagConfirmation}
          onDismiss={this._dismissFlagModal}
        /> */}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscribeFanFeedViewer);
