import React, { useMemo, useState } from "react";
import {
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  TabContent,
  TabPane,
  Button,
  Card,
  CardHeader,
  CardBody,
} from "reactstrap";
import {
  reactHtmlParser,
  formatNumberInShort,
  extractQueryParams,
  capitalize,
  errorHandler,
  formatSocialLink,
  getLowResolutionLink,
  getWindowDimensions,
  handleNameObj,
} from "../helper-methods";
import { fetchContentSummary } from "../http-calls";
import { DEFAULT_PROFILE_PICTURE } from "../config/index";
import ProfileCarousel from "../components/ProfileCarousel";
import MyProfilePosts from "../components/MyProfilePosts";
import ProfilePhotoLibrary from "../components/ProfilePhotoLibrary";
import ProfileVideoLibrary from "../components/ProfileVideoLibrary";
import FollowerListTab from "../components/FollowerListTab";
import KYCAlertCard from "../components/KYCAlertCard";
import { APP_NAME } from "../config/index";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { fetchAndModifiedUserDetails } from "../redux/actions";
import { useDispatch } from "react-redux";
import ErrorBoundary from "../components/ErrorBoundary";
import SkeletonLoading from "../components/SkeletonLoading";
import ReadMoreReadLess from "../components/custom/ReadMoreReadLess";
import ReKYCCard from "../components/ReKYCCard";

const MyProfilePage = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const userData = useSelector((state) => state?.userData);

  const [summary, setSummary] = useState({
    fans: 0,
    images: 0,
    posts: 0,
    videos: 0,
  });
  const [activeTab, setActiveTab] = useState("1");
  const [loading, setLoading] = useState(false);
  const [activeCarouselIndex, setActiveCarouselIndex] = useState(0);
  const [screenWidth, setScreenWidth] = useState(0);

  const _toggleActiveTab = (tabId = "1", isSaveTab = true) => {
    if (tabId === activeTab) return;

    if (isSaveTab) {
      history.replace(`/my-profile?tabId=${tabId}`);
    }

    setActiveTab(tabId);

    // _loadSepcificContents(activeTab)
  };

  const _fetchContentSummary = async () => {
    try {
      setLoading(true);

      const res = await fetchContentSummary();

      dispatch(fetchAndModifiedUserDetails());

      setSummary({
        fans: res?.fans || 0,
        images: res?.images || 0,
        posts: res?.posts || 0,
        videos: res?.videos || 0,
      });
      setLoading(false);
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  const _changeWidth = (event) => {
    const { screenWidth } = getWindowDimensions();
    if (screenWidth <= 1000) {
      setScreenWidth(screenWidth);
    }
  };

  useEffect(() => {
    const { tabId } = extractQueryParams();

    _fetchContentSummary();

    if (+tabId && +tabId > 0 && +tabId < 5) {
      _toggleActiveTab(tabId, false);
    }

    _changeWidth();

    window.addEventListener("resize", _changeWidth, true);

    return () => {
      window.removeEventListener("resize", _changeWidth, true);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _renderSocialButtons = () => {
    if (!userData?.user?.social?.length) {
      return null;
    }

    const socialButtonsJsx = userData.user.social.map((social, socialIndex) => {
      const link = formatSocialLink(social.accountUrl);
      switch (social.name) {
        case "twitter": {
          return (
            <a
              href={link}
              rel="noopener noreferrer"
              target="_blank"
              key={socialIndex}
            >
              <Button size="sm" className="btn-twitter btn-brand mr-2">
                <i className="fa fa-twitter" />
              </Button>
            </a>
          );
        }
        case "facebook": {
          return (
            <a
              href={link}
              rel="noopener noreferrer"
              target="_blank"
              key={socialIndex}
            >
              <Button size="sm" className="btn-facebook btn-brand mr-2">
                <i className="fa fa-facebook" />
              </Button>
            </a>
          );
        }
        case "tiktok": {
          return (
            <a
              href={link}
              rel="noopener noreferrer"
              target="_blank"
              key={socialIndex}
            >
              <Button size="sm" disabled className="btn-tiktok btn-brand mr-2">
                <img
                  src="/assets/img/tiktok.png"
                  alt="TikTok"
                  className="tiktok"
                  loading="lazy"
                />
              </Button>
            </a>
          );
        }
        case "instagram": {
          return (
            <a
              href={link}
              rel="noopener noreferrer"
              target="_blank"
              key={socialIndex}
            >
              <Button size="sm" className="btn-instagram btn-brand mr-2">
                <i className="fa fa-instagram" />
              </Button>
            </a>
          );
        }
        case "youtube": {
          return (
            <a
              href={link}
              rel="noopener noreferrer"
              target="_blank"
              key={socialIndex}
            >
              <Button size="sm" className="btn-youtube btn-brand mr-2">
                <i className="fa fa-youtube" />
              </Button>
            </a>
          );
        }
        default:
      }
      return null;
    });
    return <>{socialButtonsJsx}</>;
  };

  const profileCarouselData = useMemo(() => {
    if (!userData?.user?.carousel?.length) return [];

    const carouselCrop = userData?.user?.carouselCrop?.length
      ? userData?.user?.carouselCrop
      : [];

    const newCarousel = [];

    userData?.user?.carousel?.forEach((each, index) => {
      const carouselObj = {
        ...each,
        id: index,
      };

      if (each.contentType === "image") {
        const findCropObj = carouselCrop?.find(
          (each) => each.carouselIndex === index
        );

        if (findCropObj) {
          carouselObj.crop = findCropObj;
        }
      }

      newCarousel.push(carouselObj);
    });

    return newCarousel;
  }, [userData?.user?.carousel, userData?.user?.carouselCrop]);

  return (
    <div className="customPgHeight animated fadeIn">
      <Container className="noPadding">
        <Row className="noMargin justify-content-center">
          <Col sm={12} md={11} xl={12} className="noPadding bg-white">
            {/* stripe kyc alert card */}
            <KYCAlertCard />

            <ReKYCCard />

            {/* user profile card  */}
            <Card className="cardFeed myProfileCard">
              <CardHeader>
                {/*<div className="coverImgWrap">
                    <img
                    className="coverImg"
                    src={
                      userData?.user?.coverPicture || DEFAULT_COVER_PICTURE
                    }
                    onError={(e) => (e.target.src = DEFAULT_COVER_PICTURE)}
                    alt="Cover Img"
                    loading="lazy"
                  /> 
                </div> */}

                {/* Profile Carousel */}
                <ErrorBoundary>
                  <ProfileCarousel
                    activeIndex={activeCarouselIndex}
                    items={profileCarouselData}
                    onChangeActiveIndex={(newIndex) =>
                      setActiveCarouselIndex(newIndex)
                    }
                    screenWidth={screenWidth}
                  />
                </ErrorBoundary>

                <div className="influencerDetailsWrap">
                  <div className="d-flex flex-column flex-sm-row flex-shrink-0">
                    <img
                      src={
                        getLowResolutionLink(userData?.user?.profilePicture) ||
                        DEFAULT_PROFILE_PICTURE
                      }
                      onError={(e) => (e.target.src = DEFAULT_PROFILE_PICTURE)}
                      alt="Profile Img"
                      className="img100by100"
                      loading="lazy"
                    />

                    <div className="ml-0 ml-sm-2 mt-1 mt-sm-2">
                      {userData?.user?.name?.full ||
                      userData?.user?.name?.first ? (
                        <h4>{capitalize(handleNameObj(userData.user.name))}</h4>
                      ) : null}
                      {userData?.user?.username?.length ? (
                        <Button color="link" className="profileID">
                          @{userData.user.username}
                        </Button>
                      ) : null}
                    </div>
                  </div>

                  <div className="profileInfoWrap">
                    {userData?.user?.address?.country ? (
                      <div>
                        {loading ? (
                          <SkeletonLoading
                            type={"box"}
                            height={15}
                            width={30}
                            count={1}
                          />
                        ) : (
                          <h5>{userData.user?.address?.country || "N/A"}</h5>
                        )}
                        <p>Location</p>
                      </div>
                    ) : null}

                    <div
                      className="cursorPointer"
                      onClick={() => {
                        _toggleActiveTab("1");
                      }}
                    >
                      {loading ? (
                        <SkeletonLoading
                          type={"box"}
                          height={15}
                          width={30}
                          count={1}
                        />
                      ) : (
                        <h5>{formatNumberInShort(summary?.posts || 0)}</h5>
                      )}
                      <p>{summary?.posts > 1 ? "Posts" : "Post"}</p>
                    </div>
                    <div
                      className="cursorPointer"
                      onClick={() => {
                        _toggleActiveTab("4");
                      }}
                    >
                      {loading ? (
                        <SkeletonLoading
                          type={"box"}
                          height={15}
                          width={30}
                          count={1}
                        />
                      ) : (
                        <h5>{formatNumberInShort(summary?.fans || 0)}</h5>
                      )}
                      <p>Fanz</p>
                    </div>
                  </div>
                </div>
              </CardHeader>

              <CardBody>
                {userData?.user?.social?.length ? (
                  <div className="d-flex mt-1 mb-3">
                    {_renderSocialButtons()}
                  </div>
                ) : null}

                {userData?.user?.introduction ? (
                  <div className="profileDesc">
                    <ReadMoreReadLess
                      className="readMore"
                      text={reactHtmlParser(userData.user.introduction)}
                    />
                  </div>
                ) : (
                  <div className="profileDesc">
                    {`Welcome to my ${APP_NAME} page! Subscribe to unlock all of my exclusive content!`}
                  </div>
                )}

                <Button
                  className="themeBtn editProfile"
                  onClick={() => history.push(`/settings`)}
                >
                  Edit Profile
                </Button>

                <Button
                  className="themeBtn"
                  onClick={() => history.push(`/settings?tabId=2`)}
                >
                  Manage Subscriptions
                </Button>
              </CardBody>
            </Card>

            <div className="customTabsMyProfile">
              <Nav tabs className="customTabs">
                <NavItem>
                  <NavLink
                    active={activeTab === "1"}
                    onClick={() => {
                      _toggleActiveTab("1");
                    }}
                  >
                    <img
                      src="/assets/img/feed-active.png"
                      alt="Feed"
                      className="tabActiveIcon"
                    />

                    <img
                      src="/assets/img/feed-inactive.png"
                      alt="Feed"
                      className="tabInactiveIcon"
                    />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    active={activeTab === "2"}
                    onClick={() => {
                      _toggleActiveTab("2");
                    }}
                  >
                    <img
                      src="/assets/img/img-gallery-active.png"
                      alt="Img"
                      className="tabActiveIcon"
                    />

                    <img
                      src="/assets/img/img-gallery-inactive.png"
                      alt="Img"
                      className="tabInactiveIcon"
                    />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    active={activeTab === "3"}
                    onClick={() => {
                      _toggleActiveTab("3");
                    }}
                  >
                    <img
                      src="/assets/img/video-gallery-active.png"
                      alt="Video"
                      className="tabActiveIcon"
                    />

                    <img
                      src="/assets/img/video-gallery-inactive.png"
                      alt="Video"
                      className="tabInactiveIcon"
                    />
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    active={activeTab === "4"}
                    onClick={() => {
                      _toggleActiveTab("4");
                    }}
                  >
                    <img
                      src="/assets/img/subscriber-active.png"
                      alt="Subscriber"
                      className="tabActiveIcon"
                    />

                    <img
                      src="/assets/img/subscriber-inactive.png"
                      alt="Subscriber"
                      className="tabInactiveIcon"
                    />
                  </NavLink>
                </NavItem>
              </Nav>
            </div>

            <TabContent
              activeTab={activeTab}
              className="customTabContent customTabContentMyProfile"
            >
              {/* 1st TAB (of Feeds) */}
              <TabPane tabId="1">
                <ErrorBoundary>
                  <MyProfilePosts
                    tabId="1"
                    activeTab={activeTab}
                    summary={summary}
                  />
                </ErrorBoundary>
              </TabPane>
              {/* 2nd TAB (of Photos) */}
              <TabPane tabId="2">
                <ErrorBoundary>
                  <ProfilePhotoLibrary
                    tabId="2"
                    activeTab={activeTab}
                    summary={summary}
                  />
                </ErrorBoundary>
              </TabPane>
              {/* 3rd TAB (of Videos) */}
              <TabPane tabId="3">
                <ErrorBoundary>
                  <ProfileVideoLibrary
                    tabId="3"
                    activeTab={activeTab}
                    summary={summary}
                  />
                </ErrorBoundary>
              </TabPane>
              {/* 4th TAB (of Fans) */}
              <TabPane tabId="4">
                <ErrorBoundary>
                  <FollowerListTab tabId="4" activeTab={activeTab} />
                </ErrorBoundary>
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MyProfilePage;
