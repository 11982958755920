import { store as REDUX_STORE } from "../redux/store";
import _deepClone from "clone-deep";
import toast from "react-hot-toast";
import { cloudinaryImageUpload, removeDeviceOnLogout } from "../http-calls";
import * as jwt_decode from "jwt-decode";
import { clearAllMessageData } from "../redux/actions/chatData";
import moment from "moment";
import {
  BASE_URL,
  AWS_IMAGE_BUCKET_NAME,
  AWS_VIDEO_BUCKET_NAME,
  AWS_AUDIO_BUCKET_NAME,
  AWS_PUBLIC_IMAGE_BUCKET_NAME,
  AWS_DOC_BUCKET_NAME,
  FRONTEND_FAN_BASE_URL,
  DEFAULT_COVER_PICTURE,
  CONTACT_SUPPORT_URL,
  CURRENCY_TYPE,
  UPLOAD_LIMIT,
} from "../config";
import S3BucketUploader from "../s3-bucket-uploader";
import { getToken } from "../interceptors/token-interceptor";
import { countryCodes } from "../config/country-codes";
import {
  countryWiseVerification,
  countryWiseOptionalDocument,
} from "../config/country-wise-verification";
import { RegexConfig } from "../config/RegexConfig";
import ReactHtmlParser from "react-html-parser";
import { PostManager } from "../post-manager";
import { UploadQueueManager } from "../upload-queue-manager";
import { MonthsWithDays, instantPaymentCountry } from "../config/helper-config";
import React from "react";
import DeviceMetaData from "../device-metadata";
import CustomTooltip from "../components/custom/CustomTooltip";
import {
  clearAllFeedData,
  clearNotifications,
  clearUserData,
  deleteNameConfigurable,
  hideLoader,
  showLoader,
} from "../redux/actions";
import { UPLOAD_LIMIT_TEXT } from "../config";
import heic2any from "heic2any";
import OneSignalHelper from "../one-signal/onesignalHelper";
import { Button } from "reactstrap";

export const logout = async (navRef, isDeviceLogoutRequired) => {
  try {
    REDUX_STORE.dispatch(showLoader("Logging out your account..."));

    if (isDeviceLogoutRequired) {
      try {
        await removeDeviceOnLogout();
      } catch (error) {
        console.log({ error });
      }
    }

    REDUX_STORE.dispatch(clearUserData());
    REDUX_STORE.dispatch(clearAllFeedData());
    REDUX_STORE.dispatch(clearAllMessageData());
    REDUX_STORE.dispatch(clearNotifications());
    REDUX_STORE.dispatch(deleteNameConfigurable());
    REDUX_STORE.dispatch(hideLoader());

    if (navRef) navRef.replace("/login");
    // else window.location.reload();
  } catch (error) {
    console.log({ error });
    REDUX_STORE.dispatch(hideLoader());
  }
};

/**
 *
 * @param {string} tier - basic or plus or premium
 * @returns - boolean - is Subscription price exist or not
 */
export const isSubscriptionPriceExist = (tier = "") => {
  try {
    const { userData } = REDUX_STORE.getState();

    const bundles = userData?.user?.bundles || [];

    switch (tier) {
      case "plus": {
        const plusBundle = bundles.find((each) => each?.tier === "plus");
        const isPlusValid =
          plusBundle?.price ||
          plusBundle?.threeMonthsPrice ||
          plusBundle?.sixMonthsPrice ||
          plusBundle?.nineMonthsPrice ||
          plusBundle?.twelveMonthsPrice;
        return isPlusValid ? true : false;
      }
      case "premium": {
        const premiumBundle = bundles.find((each) => each?.tier === "premium");
        const isPremiumValid =
          premiumBundle?.price ||
          premiumBundle?.threeMonthsPrice ||
          premiumBundle?.sixMonthsPrice ||
          premiumBundle?.nineMonthsPrice ||
          premiumBundle?.twelveMonthsPrice;
        return isPremiumValid ? true : false;
      }
      case "basic": {
        const basicBundle = bundles.find((each) => each?.tier === "basic");
        const isBasicValid =
          basicBundle?.price ||
          basicBundle?.threeMonthsPrice ||
          basicBundle?.sixMonthsPrice ||
          basicBundle?.nineMonthsPrice ||
          basicBundle?.twelveMonthsPrice;
        return isBasicValid ? true : false;
      }
      default: {
        return false;
      }
    }
  } catch (error) {
    console.log("error>>", error);
    return false;
  }
};

export const deepClone = (data) => {
  return _deepClone(data);
};

export const sleepTime = (n) => new Promise((r) => setTimeout(() => r(), n));

export const scrollToTop = () => {
  window.scrollTo(0, 0);
};

export const showToast = (message, type = "error", duration = 4000) => {
  toast.dismiss();
  toast[type](message, { duration });
};

export const copyToClipboard = (text) => {
  try {
    navigator.clipboard.writeText(text);
    showToast("Copied", "success");
  } catch (error) {
    console.log({ error });
  }
};

export const extractQueryParams = () => {
  let {
    location: { search: queryParamString },
  } = window;
  let params = {};
  if (queryParamString.length > 1 && queryParamString.indexOf("?") > -1) {
    queryParamString = queryParamString.replace("?", "");
    queryParamString = decodeURIComponent(queryParamString);
    if (queryParamString.indexOf("&") === -1) {
      // Contains only one param
      const paramParts = queryParamString.split("=");
      params[paramParts[0]] = paramParts[1];
    } else {
      // Contains multiple params
      const queryParams = queryParamString.split("&");
      queryParams.forEach((queryParam) => {
        const paramParts = queryParam.split("=");
        params[paramParts[0]] = paramParts[1];
      });
    }
  }
  return params;
};

export const capitalize = (s) => {
  if (!s || typeof s !== "string") return "";

  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const formatCurrencyValue = (data) => {
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: CURRENCY_TYPE?.toUpperCase(),
  });
  let currency = formatter.format(data);
  if (currency && currency.split(".")[1] === "00") {
    return currency.split(".")[0]; /* $2,500 */
  }
  return currency; /* $2,500.15 */
};

export const formatNumberInShort = (num, digits = 1) => {
  const lookup = [
    { value: 1e12, symbol: "T" },
    { value: 1e9, symbol: "B" },
    { value: 1e6, symbol: "M" },
    { value: 1e3, symbol: "k" },
    { value: 1, symbol: "" },
  ];
  var item = lookup.find(function (item) {
    return num >= item.value;
  });
  if (item) {
    num = (num / item.value).toFixed(digits);
    if (num && num.split(".")[1] > 0) {
      return `${num} ${item.symbol}`;
    }
    return `${num.split(".")[0]} ${item.symbol}`;
  }
  return "0";
};

export const b64toBlob = (b64Data, contentType, sliceSize) => {
  contentType = contentType || "";
  sliceSize = sliceSize || 512;

  let byteCharacters = atob(b64Data);
  let byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    let slice = byteCharacters.slice(offset, offset + sliceSize);

    let byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    let byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const convertb64Image = (ImageURL, fileName) => {
  // Split the base64 string in data and contentType
  let block = ImageURL.split(";");
  // Get the content type
  let contentType = block[0].split(":")[1]; // In this case "image/gif"
  // get the real base64 content of the file
  let realData = block[1].split(",")[1]; // In this case "iVBORw0KGg...."

  // Convert to blob
  let blob = b64toBlob(realData, contentType);

  // Create a FormData and append the file
  let fd = new FormData();
  fd.append("file", blob, fileName);
  return fd;
};

export const onUploadProgress = (evt, onProgressCallback) => {
  let uploadPercentage = parseInt((evt.loaded * 100) / evt.total);
  onProgressCallback(uploadPercentage, evt.key);
};

export const onComplete = (error, success) => {
  console.log("error, success :", error, success);
};

/**
 * To communicate through events
 */
const EventEmitter = {
  events: {},
  dispatch: function (event, data = null) {
    // Check if the specified event is exist / subscribed by anyone
    if (!this.events[event]) {
      // Doesn't exist, so just return
      return;
    } else {
      // Exists
      // Process all bound callbacks
      this.events[event].forEach((callback) => callback(data));
    }
  },
  subscribe: function (event, callback) {
    // Check if the specified event is exist / subscribed by anyone
    if (!this.events[event]) {
      // Not subscribed yet, so make it an array so that further callbacks can be pushed
      this.events[event] = [];
    }
    // Push the current callback
    this.events[event].push(callback);
  },
};

export const onFilePercentageChange = (callback) => {
  EventEmitter.subscribe("upload-file-percentage-change", callback);
};

export const uploadPhotoToCloudinary = (
  photo,
  type = "blob",
  fileName = null,
  fileType = "image",
  isLargeFile = false,
  onProgressCallback = (uploadPercentage, key) => {
    EventEmitter.dispatch("upload-file-percentage-change", {
      percentage: uploadPercentage,
      key,
    });
  },
  isFakeCall = false
) => {
  return new Promise(async (resolve, reject) => {
    if (isFakeCall) {
      resolve("");
    } else {
      if (type === "blob") {
        const extension =
          fileType === "image"
            ? ".jpg"
            : fileType === "audio"
            ? ".mp3"
            : fileType === "doc"
            ? photo.name
            : ".mp4";

        fileName = `${new Date().toISOString()}_${
          photo.name ? photo.name : extension
        }`;

        // const formData = new FormData();
        if (
          fileType === "video" ||
          fileType === "image" ||
          fileType === "audio" ||
          fileType === "doc"
        ) {
          // formData.append("mediafile", photo, fileName);
          const token = await getToken();

          try {
            const config = await S3BucketUploader.getCreds(
              BASE_URL + "/awstempcreds",
              token
            );
            //  Initialize S3 Uploader
            const s3Uploader = new S3BucketUploader(config);
            const s3Response = await s3Uploader.uploadFile(
              photo,
              onComplete,
              (e) => onUploadProgress(e, onProgressCallback),
              fileType
            );
            resolve(s3Response.Location);
          } catch (error) {
            reject(error);
          }
        } else {
          const formData = new FormData();
          formData.append("file", photo, fileName);
          cloudinaryImageUpload(
            formData,
            fileType !== "audio" ? fileType : "video",
            isLargeFile
          )
            .then((cloudinaryResponse) => {
              resolve(cloudinaryResponse.secure_url);
            })
            .catch((err) => {
              reject(err);
            });
        }
      } else {
        // Check if filename provided
        if (!fileName || !fileName.length) {
          // Not provided, so generate random one
          fileName =
            Math.random().toString(36).substring(2) + fileType === "image"
              ? ".jpg"
              : fileType === "audio"
              ? ".mp3"
              : ".mp4";
        }
        // this.convertb64Image(photo, fileName);
        if (fileType === "video") {
          const token = await getToken();

          try {
            const config = await S3BucketUploader.getCreds(
              BASE_URL + "/awstempcreds",
              token
            );
            //  Initialize S3 Uploader
            const s3Uploader = new S3BucketUploader(config);
            const s3Response = await s3Uploader.uploadFile(
              convertb64Image(photo, fileName),
              onComplete,
              (e) => onUploadProgress(e, onProgressCallback),
              fileType
            );

            resolve(s3Response);
          } catch (error) {
            reject(error);
          }
        } else {
          cloudinaryImageUpload(convertb64Image(photo, fileName)).then(
            (cloudinaryResponse) => {
              resolve(cloudinaryResponse.secure_url);
            }
          );
        }
      }
    }
  });
};

export const getAWSBucketName = (type, isPublic = false) => {
  if (isPublic) {
    switch (type) {
      case "image":
        return AWS_PUBLIC_IMAGE_BUCKET_NAME;
      default:
        return null;
    }
  } else {
    switch (type) {
      case "image":
        return AWS_IMAGE_BUCKET_NAME;
      case "video":
        return AWS_VIDEO_BUCKET_NAME;
      case "audio":
        return AWS_AUDIO_BUCKET_NAME;
      case "doc":
        return AWS_DOC_BUCKET_NAME;
      default:
        return null;
    }
  }
};

export const decodeToken = (token) => {
  return jwt_decode(token);
};

export const transformArrayToObject = (sourceArray, objectKeyPropertyName) => {
  let resultantObject = {};
  if (sourceArray && sourceArray.length) {
    sourceArray.forEach((elem) => {
      resultantObject[elem[objectKeyPropertyName]] = elem;
    });
    return resultantObject;
  }
};

export const isObjectEmpty = (sourceObject = {}) => {
  return Object.keys(sourceObject).length === 0;
};

export const getPostedDateValue = (postedDate) => {
  if (moment().isSame(postedDate, "year")) {
    return moment(postedDate).format("MMM DD");
  } else {
    return moment(postedDate).format("MMM DD, YYYY");
  }
};

export const getSocialLink = (arr, name) => {
  const social = arr && arr.filter((item) => item.name === name);
  if (social && social.length) {
    return social[0].accountUrl;
  }
  return "";
};

export const getPostedTimeValue = (postedDate) => {
  return moment(postedDate).format("hh:mm A");
};

export const fetchAndDecodeToken = () => {
  let state = REDUX_STORE.getState();
  if (state && state.userData && state.userData.token) {
    return decodeToken(state.userData.token);
  }
};

export const checkIsIPV4 = (entry) => {
  const blocks = entry.split(".");
  if (blocks.length === 4) {
    return blocks.every(function (block) {
      return parseInt(block, 10) >= 0 && parseInt(block, 10) <= 255;
    });
  }
  return false;
};

export const capitalizeEveryFirstLetter = (text = "") => {
  if (!text) return "";

  const modifiedText = text
    .toLowerCase()
    .split(" ")
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(" ");
  return modifiedText;
};
export const sortByDateAndMonth = (arr) => {
  arr.sort(function (a, b) {
    return (
      a["_id"]["year"] - b["_id"]["year"] ||
      a["_id"]["month"] - b["_id"]["month"]
    );
  });
  return arr;
};

export const sortByLatestData = (arr, attr) => {
  return arr.sort((t1, t2) => {
    return new Date(t2[attr]) - new Date(t1[attr]);
  });
};
export const sortByOldestData = (arr, attr) => {
  return arr.sort((t1, t2) => {
    return new Date(t1[attr]) - new Date(t2[attr]);
  });
};

export const calculateTotal = (arr, attr) => {
  let totalAmount = arr.reduce((acc, each) => {
    return (acc = Number(acc) + Number(each[attr]));
  }, 0);
  return totalAmount.toFixed(2);
};

export const sum = (a = 0, b = 0) => (Number(a) + Number(b)).toFixed(2);

export const getCountryName = (countryCode) => {
  const searchedCountries = countryCodes.filter(
    (country) => country.code === countryCode
  );
  if (searchedCountries.length) {
    return capitalizeEveryFirstLetter(searchedCountries[0].name);
  } else {
    return "United States";
  }
};

export const countryDemandsForVerification = (country, fieldName) => {
  let docs = countryWiseVerification[country];
  if (docs && docs.length) {
    if (docs.indexOf(fieldName) !== -1) {
      return true;
    }
  }
  return false;
};

export const isDocumentOptional = (country, fieldName) => {
  let docs = countryWiseOptionalDocument[country];
  if (docs && docs.length) {
    if (docs.indexOf(fieldName) !== -1) {
      return true;
    }
  }
  return false;
};

export const findWarningType = (influencer) => {
  if (influencer?.paymentEnabled) {
    if (!influencer.hasBank && !influencer.payoutEnabled) {
      return {
        bank: true,
        doc: false,
      };
    } else if (influencer.hasBank && !influencer.payoutEnabled) {
      return {
        bank: false,
        doc: true,
      };
    }
  }
  return {
    bank: false,
    doc: false,
  };
};

export const getRequiredDocuments = (stripeResponse) => {
  if (stripeResponse?.error) {
    if (stripeResponse?.requirements?.disabled_reason?.length) {
      let arrName = stripeResponse.requirements.disabled_reason.split(".")[1];

      let reasons = stripeResponse.requirements[arrName];

      let validReasons = reasons.filter((reason) => {
        return (
          reason === "individual.address.city" ||
          reason === "individual.address.postal_code" ||
          reason === "individual.address.state" ||
          reason === "individual.address.line1" ||
          reason === "individual.address.country" ||
          reason === "individual.ssn_last_4" ||
          reason === "individual.id_number" ||
          reason === "individual.verification.additional_document" ||
          reason === "individual.verification.document"
        );
      });

      return validReasons;
    }
  }
  return [];
};

export const convertDate = (date = null) => {
  if (!date) {
    return;
  } else {
    if (
      typeof date === "string" &&
      moment(date, "YYYY-MM-DD, h:mm a", true).isValid()
    ) {
      return date;
    } else {
      return moment(new Date(date)).format("YYYY-MM-DD, h:mm a");
    }
  }
};

export const changeDateFormat = (date = null) => {
  if (!date) {
    return;
  } else if (RegexConfig.isoDate.test(date)) {
    return date;
  } else {
    let changedFormattedDate = date.replace(/-/g, "/");

    return changedFormattedDate;
  }
};

export const getTotalSum = (items, prop) => {
  let totalAmount = 0;
  if (items && items.length) {
    totalAmount = items.reduce((a, b) => {
      return Number(Number(a) + Number(b[prop]));
    }, 0);
  }
  return totalAmount ? formatCurrencyValue(totalAmount) : "$0";
};

export const getTimeDifferenceInMinutes = (end, start = moment(new Date())) => {
  return moment.duration(start.diff(end)).asMinutes();
};

export const covertDateAndTimeToUTC = (date, time) => {
  let d = changeDateFormat(date + " " + time);
  return new Date(d).toISOString();
};

export const getResolution = () => {
  // const width = window.screen.width;
  // if (width < 576) {
  //   return "360";
  // } else if (width >= 576 && width < 768) {
  //   return "480";
  // } else if (width >= 768 && width < 1200) {
  //   return "720";
  // }
  // return "1080";
  // if (width < 1200) {
  //   return "720";
  // } else return "1080";

  // high resolution file even for small devices
  return "1080";
};

export const getThumbnailLink = (link, type = "video") => {
  if (!link.length) {
    return;
  }
  let bucketName = getAWSBucketName(type);

  let splitBucketName = bucketName.split("-");

  let splittedUrls = link.split(bucketName);

  if (!splittedUrls.length || splittedUrls.length === 1) {
    return link;
  }

  let splittedSecondParts = splittedUrls[1].split("/");

  let videoId = splittedSecondParts[splittedSecondParts.length - 1];

  splittedSecondParts[splittedSecondParts.length - 1] =
    videoId + "/Thumbnails/" + videoId + ".0000000.jpg";

  splittedUrls[1] = splittedSecondParts.join("/");

  bucketName =
    splitBucketName[0] +
    "-compressed-" +
    splitBucketName[1] +
    "-" +
    splitBucketName[2];

  let thumbnailLink = splittedUrls.join(bucketName);

  return thumbnailLink;
};

export const formatNumber = (number) => {
  let formatedNumber = new Intl.NumberFormat().format(
    Number(number).toFixed(2)
  );
  return formatedNumber;
};

export const TierAmountValidation = (basic, plus, premium) => {
  if (
    Number(basic) >= Number(plus) &&
    Number(basic) >= Number(premium) &&
    Number(plus) >= Number(premium)
  ) {
    return true;
  } else return false;
};

export const CheckPastEvent = (date) => {
  if (moment(date).add(2, "h") > moment()) {
    return false;
  }

  return true;
};

export const isFromSafari = () => {
  if (
    window.navigator.vendor &&
    window.navigator.vendor.indexOf("Apple") > -1 &&
    window.navigator.userAgent &&
    window.navigator.userAgent.indexOf("CriOS") === -1 &&
    window.navigator.userAgent.indexOf("FxiOS") === -1
  ) {
    return true;
  } else return false;
};

export const calculateContentType = (contents, type) => {
  let filteredContent = contents.filter(
    (content) => content.contentType === type
  );
  return filteredContent.length;
};

const twoDigitNumber = (num) => {
  return `${num < 10 ? `0${num}` : num}`;
};

/**
 * convert seconds into hh:mm:ss format
 *
 * @param {Number} seconds
 */
export const convertSecondsToHourMinSec = (seconds) => {
  if (!seconds) return "";

  let sec = Math.floor(seconds);

  if (sec < 60) {
    return `00:${twoDigitNumber(sec)}`;
  } else {
    let min = Math.floor(sec / 60);
    sec = sec % 60;
    if (min < 60) {
      return `${twoDigitNumber(min)}:${twoDigitNumber(sec)}`;
    } else {
      let hour = Math.floor(min / 60);
      min = min % 60;
      if (hour < 24) {
        return `${twoDigitNumber(hour)}:${twoDigitNumber(min)}:${twoDigitNumber(
          sec
        )}`;
      } else {
        return "";
      }
    }
  }
};

/**
 * convert hh:mm:ss into seconds
 *
 * @param {String} hourMinSec - hh:mm:ss
 */
export const convertHourMinSecToSeconds = (hourMinSec) => {
  let time = hourMinSec.split(":");

  let hour = Number(time[0]);
  let min = Number(time[1]);
  let sec = time[2] ? Number(time[2]) : 0;

  return (hour > 0 ? hour * 60 * 60 : 0) + (min > 0 ? min * 60 : 0) + sec;
};

export const minusOneInHourMinSec = (hourMinSec) => {
  let time = hourMinSec.split(":");
  let hour = Number(time[0]);
  let min = Number(time[1]);
  let sec = Number(time[2]);

  if (sec) {
    sec -= 1;
  } else {
    if (min) {
      min -= 1;
      sec = "59";
    } else {
      if (hour) {
        hour -= 1;
        min = "59";
        sec = "59";
      }
    }
  }

  return `${twoDigitNumber(hour)}:${twoDigitNumber(min)}:${twoDigitNumber(
    sec
  )}`;
};

export const getFontSizeBasedOnImage = (width, height) => {
  let fontSize;

  if (width >= 5000) {
    fontSize = 150;
  } else if (width >= 3000 && width >= 1500) {
    fontSize = 100;
  } else if (width >= 1024 && width >= 1280) {
    fontSize = 35;
  } else if (width >= 960 && width >= 1024) {
    fontSize = 30;
  } else if (width >= 500 && width <= 960) {
    fontSize = 25;
  } else if (width >= 300 && width <= 500) {
    fontSize = 15;
  } else {
    fontSize = 10;
  }

  return fontSize;
};

export const addWaterMark = (file, watermarkText) => {
  if (file) {
    return new Promise(function (resolve, reject) {
      let rawImage = new Image();
      rawImage.addEventListener("load", function () {
        resolve(rawImage);
      });
      rawImage.src = URL.createObjectURL(file);
    })
      .then(function (rawImage) {
        // Convert image to webp ObjectURL via a canvas blob
        return new Promise(function (resolve, reject) {
          let canvas = document.createElement("canvas");
          let ctx = canvas.getContext("2d");
          canvas.width = rawImage.width;
          canvas.height = rawImage.height;
          let fontSize = getFontSizeBasedOnImage(canvas.width, canvas.height);
          ctx.drawImage(rawImage, 0, 0);
          ctx.fillStyle = "black";
          ctx.font = fontSize + "px monospace";
          let width = ctx.measureText(watermarkText || "").width;
          ctx.fillRect(
            rawImage.width - width,
            canvas.height - fontSize,
            width,
            parseInt(fontSize, 10)
          );
          ctx.fillStyle = "#fff";
          ctx.fillText(
            watermarkText || "",
            rawImage.width - width,
            canvas.height - 5
          );
          ctx.restore();
          canvas.toBlob(function (blob) {
            resolve({ blob, url: URL.createObjectURL(blob) });
          });
        });
      })
      .catch((error) => {
        console.log("error addWaterMark fn >>>", error);
      });
  }
};

export const getWindowDimensions = () => {
  try {
    const screenWidth =
      window?.innerWidth ||
      document?.documentElement?.clientWidth ||
      document?.body.clientWidth;
    const screenHeight =
      window?.innerHeight ||
      document?.documentElement?.clientHeight ||
      document?.body?.clientHeight;

    return {
      screenWidth,
      screenHeight,
    };
  } catch (error) {
    return {};
  }
};

export const parseForTextField = (text) => {
  try {
    if (!text) return "";

    return text.replace(/\r?<br\/>/g, "\n");
  } catch (error) {
    console.log({ error });
    return "";
  }
};

export const reactHtmlParser = (text = "") => {
  try {
    if (!text) return "";

    let parseText = text ? ReactHtmlParser(text) : "";
    if (parseText) {
      parseText = parseText.map((each) => {
        if (typeof each === "string") {
          return each;
        }
        return "\n";
      });
      parseText = parseText.join(" ");
    }

    // return string text
    return parseText;
  } catch (error) {
    console.log({ error });
    return "";
  }
};

export const mentionUserInputParse = (text = "") => {
  if (text?.trim()?.length) {
    text = parseForTextField(text);
    text = text.split(" ");
    text = React.Children.toArray(
      text.map((each) => {
        if (
          each[0] === "@" &&
          RegexConfig["username"].test(String(each.slice(1)).toLowerCase())
        ) {
          return (
            <>
              <a
                className="tagNamePost"
                href={`${FRONTEND_FAN_BASE_URL}/${each.slice(1)}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {each}
              </a>{" "}
            </>
          );
        }

        return `${each} `;
      })
    );
    return text;
  }
  return "";
};

export const extractTagUsername = (text = "") => {
  let tagUsernameArray = [];

  if (text?.trim()?.length) {
    let state = REDUX_STORE.getState();

    let ownUsername = `@${state?.userData?.user?.username}`;

    text = text.split(" ");
    text.forEach((each) => {
      if (
        each[0] === "@" &&
        each !== ownUsername &&
        RegexConfig["username"].test(String(each.slice(1)).toLowerCase())
      ) {
        tagUsernameArray.push(each.slice(1));
      }
    });
  }

  return tagUsernameArray;
};

export const formatPhoneNumber = (phone) => {
  if (phone) {
    if (phone.includes(")")) {
      let phoneSplit = phone.split(")");
      return `${phoneSplit[0].slice(1)} ${phoneSplit[1]}`;
    } else {
      return phone;
    }
  }
  return "";
};

export const getPhoneNumberFromBrackets = (number) => {
  let phone = "";
  if (number) {
    if (number.includes("(") && number.includes(")")) {
      phone = number.split(")")[1];
    } else {
      phone = number;
    }
  }
  return phone;
};

export const getPhoneCodeFromBrackets = (number) => {
  let phone = "";
  if (number && number.includes("(") && number.includes(")")) {
    phone = number.split(")")[0].slice(1);
  }
  return phone;
};

export const getCountryCodeFromDialCode = (dial_code) => {
  const countryCode =
    countryCodes.find((obj) => obj.dial_code === dial_code)?.code || "US";

  return countryCode;
};

export const getDialCodeFromCountryCode = (country_code) => {
  const dialCode =
    countryCodes.find((obj) => obj.code === country_code)?.dial_code || "+1";

  return dialCode;
};

export const calculateWeekByTime = (timeStamp) => {
  var todayDate = moment();
  var pastDate = moment(timeStamp);
  var diffDays = todayDate.diff(pastDate, "days");
  const w = Math.floor(diffDays / 7);
  return `${w}w`;
};

export const formatTimeFromNow = (timeStamp) => {
  const formatDayOrWeek = (d) => {
    if (d >= 7) {
      const w = Math.floor(d / 7);
      return `${w}w`;
    }
    return `${d}d`;
  };

  const relativeTime = {
    future: "in %s",
    past: "%s ago",
    s: "Just now",
    ss: "%ss",
    m: "1m",
    mm: "%dm",
    h: "1h",
    hh: "%dh",
    d: "1d",
    dd: (d) => formatDayOrWeek(d),
    M: (d) => calculateWeekByTime(timeStamp),
    MM: (d) => calculateWeekByTime(timeStamp),
    y: "1y",
    yy: "%dy",
  };

  moment.updateLocale("en", {
    relativeTime,
  });

  return moment(timeStamp).fromNow(true);
};

export const generateCalenderDate = (timeStamp) => {
  // get from-now for this date
  // var fromNow = moment( timeStamp ).fromNow();
  let fromNow = moment().isSame(timeStamp, "month") ? "This Month" : "Earlier";

  moment.updateLocale("en", {
    calendar: {
      sameDay: "[Today]",
      lastDay: "[Yesterday]",
      lastWeek: "[This Week]",
      nextDay: "[Tomorrow]",
      nextWeek: "D MMMM YYYY",
      sameElse: function () {
        return "[" + fromNow + "]";
      },
      // sameElse: "[Earlier]",
    },
  });
  return moment(new Date(timeStamp)).calendar();
};

export const generateCalenderDateForMedia = (timeStamp) => {
  let fromNow = moment().isSame(timeStamp, "month")
    ? "This Month"
    : moment().isSame(timeStamp, "year")
    ? moment(timeStamp).format("MMMM")
    : moment(timeStamp).format("MMMM YYYY");

  moment.updateLocale("en", {
    calendar: {
      sameDay: "[Today]",
      lastDay: "[Yesterday]",
      lastWeek: "[This Week]",
      nextDay: "[Tomorrow]",
      nextWeek: "D MMMM YYYY",
      sameElse: function () {
        return "[" + fromNow + "]";
      },
    },
  });

  return moment(new Date(timeStamp)).calendar();
};

// program to get the file extension
export const getFileExtension = (filename) => {
  // get file extension
  const extension = filename.split(".").pop();
  return extension;
};

/**
 * uploadFiles is Object Array;
 * object key is;
 * - uploadData
 * - previewBlob
 * - type
 * - forKeyName (optional) return same value for file matching
 *
 * @param {Array} uploadFiles - file Object Array
 * @returns Array Object; object key is;
 * - name
 * - url
 * - contentType
 * - size
 * - thumbnail
 * - forKeyName (return if provided)
 */
export const uploadFileOnServer = (uploadFiles) => {
  return new Promise((resolve) => {
    console.log("uploadFiles>>", uploadFiles);

    const uploadedFiles = [];

    if (uploadFiles && uploadFiles.length) {
      let postID = PostManager.addMediaFilesCount(uploadFiles.length);

      PostManager.onAllMediaFilesUploadCompleted(postID, async (id) => {
        if (id.postID === postID) {
          await sleepTime(500);
          PostManager.deletePostID(postID);
          resolve(uploadedFiles);
        } else {
          return;
        }
      });

      uploadFiles.forEach((uploadFile) => {
        let mediaData = {};

        if (!uploadFile?.type) {
          uploadFile.type = uploadFile?.uploadData?.type?.split("/")[0];
        }

        console.log(uploadFile, uploadFile?.uploadData?.type?.split("/")[0]);

        if (uploadFile?.type === "video") {
          mediaData = {
            blobObject: uploadFile["uploadData"],
            blobURL: uploadFile["previewBlob"],
          };
        } else if (uploadFile?.type === "image") {
          mediaData = {
            file: uploadFile["uploadData"],
            blobObject: uploadFile["previewBlob"],
          };
        } else if (uploadFile?.type === "audio") {
          mediaData = {
            file: uploadFile["uploadData"],
            blobObject: { blob: uploadFile["uploadData"] },
          };
        } else if (uploadFile?.type === "doc") {
          mediaData = {
            file: uploadFile["uploadData"],
            blobObject: uploadFile["uploadData"],
          };
        }

        console.log("mediaData", mediaData);

        const uploadId = UploadQueueManager.addMediaToQueue(
          mediaData,
          uploadFile.type
        );

        // Listen for upload complete
        UploadQueueManager.onUploadComplete(uploadId, async (mediaResponse) => {
          PostManager.onSingleMediaFileUploadCompleted(postID);
          console.log("mediaResponse", mediaResponse, mediaResponse.fileUrl);
          // Upload complete
          // Get content id from backend
          uploadedFiles.push({
            name: uploadFile.uploadData.name,
            url: mediaResponse.fileUrl,
            contentType: uploadFile.type,
            size: uploadFile.uploadData.size,
            forKeyName: uploadFile.forKeyName,
            thumbnail: mediaResponse.thumbnail,
            extension: uploadFile?.uploadData?.name
              ? getFileExtension(uploadFile.uploadData.name)
              : mediaResponse.data?.mediaType,
            duration: uploadFile.duration || null,
          });
        });
      });
    }
  });
};

/**
 * used for get status -> feature is active or not
 *
 * @param {string} featureName - vault / ppv / event / shoutout
 * @returns - boolean
 */
export const getFeatureStatus = (featureName) => {
  if (!"vault-ppv-event-shoutout"?.includes(featureName)) {
    return true;
  }

  const { nameConfigurable } = REDUX_STORE.getState();
  return nameConfigurable?.[featureName]?.isActive;
};

export const isValidVideoFileType = (file) => {
  if (
    file.type.includes("video") &&
    (file.type.includes("quicktime") || file.name.includes(".qt"))
  ) {
    showToast(
      "We currently do not support qt video files. Please use other formats like MP4.",
      "error"
    );
    return false;
  }

  return true;
};

export const isValidImageFileType = (file) => {
  if (
    file.type.includes("image") &&
    (file.type.includes("heif") || file.name.includes(".HEIC"))
  ) {
    showToast(
      "We currently do not support HEIC image files. Please use other formats like JPG.",
      "error"
    );
    return false;
  }

  return true;
};

export const formatDateAndTime = (date, isHideSameYear = false) => {
  if (!date) return "";

  if (isHideSameYear && moment().isSame(date, "year"))
    return moment(new Date(date)).format("MMM DD - hh:mm A");

  return moment(new Date(date)).format("MMM DD, YYYY - hh:mm A");
};

export const formatDate = (date) => {
  if (!date) return "";

  if (moment().isSame(date, "year"))
    return moment(new Date(date)).format("MMM DD");

  return moment(new Date(date)).format("MMM DD, YYYY");
};

export const formatDateWithYear = (date) => {
  if (!date) return "";

  return moment(new Date(date)).format("MMM DD, YYYY");
};

export const formatTime = (date) => {
  if (!date) return "";

  return moment(new Date(date)).format("hh:mm A");
};

/**
 * this function accept time in T format "2020-12-03T13:41:17.672Z"
 * Will tell if the time is beyond 24 hours
 * and Will return boolean value
 *
 * @param {Date} time - 2020-12-03T13:41:17.672Z
 *
 * @return {Boolean} true // false
 */
export const isTimeMoreThan24h = (time) => {
  let isGreater = false;
  let hours = Math.abs(new Date() - new Date(time)) / 36e5;
  // The subtraction returns the difference between the two dates in milliseconds.
  // 36e5 is the scientific notation for 60*60*1000,
  // dividing by which converts the milliseconds difference into hours.
  if (hours > 24) {
    isGreater = true;
  }

  return isGreater;
};

export const formatDecimalNumber = (number, decimal = 2) => {
  if (number && !isNaN(number)) {
    number = (+number).toFixed(decimal);
    if (number && number.split(".")[1] === "00") {
      return number.split(".")[0]; /* 50 */
    }
    return number; /* 50.50 */
  }
  return 0;
};

/**
 * @param {string} method - all / byDebitCard / byBankAccount
 * @param {string} country - "US", "CA", "SG", "UK"
 * @returns
 */
export const checkInstantPaymentValidCountry = (method, country) => {
  // country - 'US','CA','SG','UK'
  return method && country
    ? instantPaymentCountry[method]?.includes(country)
    : false;
};

export const getDeviceDetails = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const deviceDetails = {};
      deviceDetails["name"] =
        DeviceMetaData.getBrowser() + " on " + DeviceMetaData.getOs();
      // let ipData = await getIpData();
      // deviceDetails["ipAddress"] = ipData["ip"];
      // deviceDetails["ipCountry"] = ipData["country_name"];
      deviceDetails["ipAddress"] = "0.0.0.0";
      deviceDetails["ipCountry"] = "United States";
      // Get device id
      // First try to get from one signal
      let onesignalDeviceId = null;
      onesignalDeviceId = await OneSignalHelper.getDeviceId();
      if (onesignalDeviceId) {
        deviceDetails["allowNotification"] = true;
        deviceDetails["deviceId"] = onesignalDeviceId;
      } else {
        deviceDetails["allowNotification"] = false;
        deviceDetails["deviceId"] = DeviceMetaData.getDeviceId();
      }
      resolve(deviceDetails);
    } catch (error) {
      showToast(
        "Please use a supported browser like Google Chrome, Safari, Internet Explore / Edge",
        "error"
      );
      reject(error);
    }
  });
};

// always resolve
export const getOneSignalDeviceId = async () => {
  return new Promise(async (resolve) => {
    try {
      const onesignalDeviceId = await OneSignalHelper.getDeviceId();
      resolve(onesignalDeviceId || null);
    } catch (error) {
      resolve(null);
    }
  });
};

export const errorHandler = (error) => {
  console.log("error>>", error);
  showToast(
    error?.reason?.length
      ? error?.reason
      : "Server Error. Try again after sometime."
  );
};

export const getSrcUrl = (item, resolution) => {
  const resolutionConfig = {
    "360w": "threeSixZero",
    "480w": "fourEightZero",
    "720w": "sevenTwoZero",
    "1080w": "oneZeroEightZero",
  };

  const newResolution = resolution
    ? resolution
    : process.env.REACT_APP_BACKEND_ENV === "prod"
    ? "720w"
    : "360w";

  let url = DEFAULT_COVER_PICTURE;
  if (item) {
    if (item.previewBlob) {
      url = item.previewBlob;
    } else if (item._contentId) {
      // url in item is string or object
      if (item._contentId.url?.length) {
        url = item._contentId.url?.replace("1080w", newResolution);
      } else {
        url = item._contentId.url?.[resolutionConfig[newResolution]];
      }
    } else if (item._content) {
      if (item._content.url) {
        url = item._content.url?.replace("1080w", newResolution);
      } else {
        url = item._content.url?.[resolutionConfig[newResolution]];
      }
    } else if (item.url) {
      if (item?.url?.length) {
        url = item.url?.replace("1080w", newResolution);
      } else {
        url = item.url?.[resolutionConfig[newResolution]];
      }
    }
  }
  return url;

  //!do not remove
  // let url = DEFAULT_COVER_PICTURE;
  // if (item) {
  //   if (item.previewBlob) {
  //     url = item.previewBlob;
  //   } else if (item._contentId && item._contentId.url) {
  //     url = item._contentId.url?.replace("1080w", newResolution);
  //   } else if (item._content && item._content.url) {
  //     url = item._content.url?.replace("1080w", newResolution);
  //   } else if (item.url) {
  //     url = item.url?.replace("1080w", newResolution);
  //   }
  // }
  // return url;
};

export const imageLoadFallBack = (item) => {
  let url = DEFAULT_COVER_PICTURE;
  if (item) {
    if (item.previewBlob) {
      url = item.previewBlob;
    } else if (item._contentId && item._contentId.url) {
      url = item._contentId.url;
    } else if (item._content && item._content.url) {
      url = item._content.url;
    } else if (item.url) {
      url = item.url;
    }
  }
  return url;
};

export const openUrlOnNewTab = (url) => {
  window.open(url, "_blank");
};

export const getMessageParticipants = (thread) => {
  if (!thread?._participants) {
    return {};
  }

  const influencer = thread._participants.find(
    (p) => p.userType === "Influencer"
  );
  const fan = thread._participants.find((p) => p.userType === "Fan");

  return { fan, influencer };
};

/**
 *
 * @param {string} key - permission page
 * @param {string} value - permission type
 * @param {boolean} isCheckAccountDelete - optional `default: false`
 * @returns
 */
export const isPermissionToAccess = (
  key,
  value,
  isCheckAccountDelete = false
) => {
  try {
    const state = REDUX_STORE.getState();
    const { userData } = deepClone(state);

    if (
      (isCheckAccountDelete && userData?.user?.isScheduleForAccountDelete) ||
      (userData?.userAssistant?.isAssistant &&
        !userData?.userAssistant?.permissionSettings?.[key]?.[value])
    ) {
      return false;
    }

    return true;
  } catch (error) {
    console.log({ error });
    return false;
  }
};

export const generateCalenderDateForChat = (timeStamp) => {
  if (!timeStamp) return "";

  moment.updateLocale("en", {
    calendar: {
      lastDay: "[Yesterday]",
      sameDay: "[Today]",
      nextDay: "[Tomorrow]",
      lastWeek: "dddd",
      nextWeek: "D MMMM YYYY",
      sameElse: "D MMMM YYYY",
    },
  });

  return moment(new Date(timeStamp)).calendar();
};

export const parseForShowingMessage = (text) => {
  if (!text?.length) return "";

  return reactHtmlParser(text);
};

export const isShowStartCallButton = (message) => {
  if (!message?.startCallTime) {
    return true;
  }

  const endTime = moment(message.startCallTime).add(
    message.shoutoutDuration || 300,
    "seconds"
  );

  return moment().isBefore(endTime);
};

export const formatFileSize = (size) => {
  if (!+size) {
    return "";
  }

  const MB = (+size / 1024) * (1 / 1024);
  if (MB > 500) {
    const GB = +MB / 1024;
    return GB ? `${+formatDecimalNumber(GB) || 0.01} GB` : "N/A";
  } else {
    return MB ? `${+formatDecimalNumber(MB) || 0.01} MB` : "N/A";
  }
};

export const isValidPrice = (value) => {
  if (
    isNaN(value) ||
    +value < 0 ||
    (value.includes(".") && value.split(".")[1]?.length > 2)
  )
    return false;

  return true;
};

export const isValidPhone = (value) => {
  if (isNaN(value) || +value < 0 || value.includes(".")) return false;

  return true;
};

export const joinName = (name) => {
  return name?.last?.length
    ? capitalize(name.first + " " + name.last)
    : capitalize(name.first);
};

export const getPayoutStatusColor = (status) => {
  switch (status) {
    case "released":
    case "paid": {
      return "success";
    }
    case "pending": {
      return "pending";
    }
    case "admin-rejected":
    case "failed": {
      return "danger";
    }
    default: {
      return "default";
    }
  }
};

export const formatPayoutStatus = ({ id, status, reason }) => {
  if (!status) return "-";

  if (status === "admin-rejected") {
    return (
      <a href={CONTACT_SUPPORT_URL} target="_blank" rel="noopener noreferrer">
        Contact Support
      </a>
    );
  }

  if (status === "failed") {
    return (
      <>
        <a href={CONTACT_SUPPORT_URL} target="_blank" rel="noopener noreferrer">
          Failed{" "}
        </a>
        {reason ? (
          <>
            <sup
              id={`formatPayoutStatus_failed_payout_reason_${id}`}
              className="infoIcon"
            >
              <i className="fa fa-info-circle" />
            </sup>

            <CustomTooltip
              text={reason}
              target={`formatPayoutStatus_failed_payout_reason_${id}`}
            />
          </>
        ) : null}
      </>
    );
  }

  if (status?.includes("-")) {
    status = status.split("-").join(" ");
  }

  return capitalizeEveryFirstLetter(status);
};

export const getYesterdayDate = () => {
  return moment().subtract(1, "day");
};

export const formatSocialLink = (link = "") => {
  if (!link) return;

  if (link.indexOf("http://") === -1 && link.indexOf("https://") === -1) {
    link = "http://" + link;
  }

  return link;
};

export const openSocialLink = (link = "") => {
  if (!link) return;

  window.open(formatSocialLink(link), "_blank");
};

export const replaceTipText = (text = "") => {
  try {
    if (!text?.trim()?.length) return "";

    text = text.trim();

    let tipText = "Tip";

    const state = REDUX_STORE.getState();

    if (state?.userData?.user?.settings?.tipText) {
      tipText =
        capitalizeEveryFirstLetter(state.userData.user.settings.tipText) ||
        "Tip";
    }

    if (tipText !== "Tip") {
      text = text.replace(/tip/gi, tipText);
    }

    return text;
  } catch (error) {
    return text;
  }
};

export const isPostNotOlderThan = (allowedDiffDays, postDate) => {
  const currentDate = new Date();
  const diffTime = Math.abs(currentDate - new Date(postDate));
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  return diffDays <= allowedDiffDays ? true : false;
};

export const splitPhoneNumber = (phoneNumber) => {
  let temp = [];
  if (phoneNumber?.includes("(") && phoneNumber?.includes(")")) {
    temp = phoneNumber.split(")");
    return { phoneNumber: temp[1] };
  } else {
    return { phoneNumber };
  }
};

export const getDialCode = (code) => {
  if (!code) return { dial_code: "+1" };

  let country = countryCodes.find((countryCode) => {
    return countryCode.code === code;
  });

  return country?.dial_code ? country : { dial_code: "+1" };
};

export const totalCommentsCount = (comments = []) => {
  try {
    if (!comments?.length) return 0;

    let count = comments.length;

    comments.forEach((each) => {
      if (each?._subComments?.length) {
        count += each._subComments.length;
      }
    });

    return count || 0;
  } catch (error) {
    return 0;
  }
};

export const splitFullName = (fullName = "") => {
  if (!fullName || typeof fullName !== "string") return {};

  const splitName = fullName.trim().split(" ");

  return {
    firstName: splitName?.[0]?.trim() || "",
    lastName: splitName?.slice(1)?.join(" ").trim() || "",
  };
};

export const getLikeIconClassName = (feed) => {
  if (feed?._isLiked) {
    // in like state
    return "fa fa-heart";
  }

  //in dislike state
  return "fa fa-heart-o";
};

export const getCommentIconClassName = (feed) => {
  if (feed?.comments) {
    //  comment state
    return "fa fa-comment";
  }

  //  no comment state
  return "fa fa-comment-o";
};

export const getSuggestedResolution = () => {
  try {
    const { screenWidth, screenHeight } = getWindowDimensions();

    // Adjust the video resolution based on screen size
    if (screenWidth < 640 || screenHeight < 480) {
      // Set low resolution
      return "threeTwoZero";
    } else if (screenWidth < 1280 || screenHeight < 720) {
      // Set medium resolution
      return "fiveFourZero";
    } else {
      // Set high resolution
      return "oneZeroEightZero";
    }
  } catch (error) {
    return "oneZeroEightZero";
  }
};

export const getRecentHourDate = () => {
  return moment().add(60 - (moment().minute() % 30), "minutes");
};

export const getAspectRatioClass = (ratio = 0) => {
  if (ratio === 1) {
    // ratio = 1
    return "squareVideoWrap";
  } else if (ratio > 1 || !ratio) {
    // ratio > 1
    return "landscapeVideoWrap"; // default
  } else {
    // ratio < 1
    return "portraitVideoWrap";
  }
};

export const getUrlParams = (url = window.location.href) => {
  try {
    let urlStr = url.split("?")[1];
    return new URLSearchParams(urlStr);
  } catch (error) {
    return null;
  }
};

export const convertHexToRgbColor = (hexColor) => {
  try {
    const r = parseInt(hexColor.slice(1, 3), 16);
    const g = parseInt(hexColor.slice(3, 5), 16);
    const b = parseInt(hexColor.slice(5), 16);

    return { r, g, b };
  } catch (error) {
    console.log({ error });
    return {};
  }
};

export const getLowResolutionLink = (link = "") => {
  return link
    ?.replace("1080w", "360w")
    ?.replace("720w", "360w")
    ?.replace("540w", "360w");
};

export const handleBrokenImageURL = () => {
  let timer = null,
    count = 0;

  return ({ event, url, fallbackImg }) => {
    if (timer) clearTimeout(timer);

    if (!event) return;

    if (!url) {
      event.target.src = fallbackImg || "";
      return;
    }

    if (count > 30) {
      event.target.src = fallbackImg || "";
      return;
    }

    timer = setTimeout(() => {
      event.target.src = url;
      count++;
    }, 2000);
  };
};

export const shareableLink = ({ id = "" }) => {
  try {
    const { userData } = REDUX_STORE.getState();

    if (!id || !userData?.user?.username) {
      errorHandler({ reason: "Something went wrong." });
      return;
    }

    const link = FRONTEND_FAN_BASE_URL;

    copyToClipboard(`${link}/public/${userData?.user?.username}/${id}`);
  } catch (error) {
    console.log({ error });
  }
};

/**
 * @param {FILE object} file
 * @returns heic -> jpeg converted image blob
 */
export const handleHeicImg = (file) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (!file.type) {
        reject("Invalid file type.");
      }

      const imgBlob = URL.createObjectURL(file);
      const blobRes = await fetch(imgBlob);

      // convert response to blob
      const blob = await blobRes.blob();

      // convert to PNG - response is blob
      const conversionResult = await heic2any({ blob, toType: "image/jpeg" });

      resolve(conversionResult);
    } catch (error) {
      if (error.code === 1) {
        // file is already browser readable
        resolve(file);
      } else {
        console.log("error handleHeicImg fn >>", error);
        reject(error);
      }
    }
  });
};

/**
 *
 * @param {object} data
 * @param {object[]} data.newFiles array of files to be uploaded
 * @param {object[]} data.oldFiles array of previous files
 * @param {string} data.pageName where this fn is being used, validation may vary accordingly
 * @param {boolean} data.isAudioUpload used in MediaUploadModal Component
 * @param {boolean} data.isPdfUpload used in MediaUploadModal Component
 * @returns
 */
export const mediaFilesHandler = ({
  newFiles = [],
  oldFiles = [],
  pageName = "",
  isAudioUpload = false,
  isPdfUpload = false,
}) => {
  return new Promise(async (resolve) => {
    const prevFiles = deepClone(oldFiles);

    try {
      const { userData } = REDUX_STORE?.getState() || {};

      if (!newFiles?.length) {
        resolve({ mediaFiles: prevFiles, error: true });
      }

      let showFileSizeWarning = true;
      let showNonMediaFilesWarning = true;

      for (let i = 0; i < newFiles.length; i++) {
        if (pageName === "feedPage" && prevFiles?.length >= 15) {
          showToast("15 media is allowed at a time", "success");
          break;
        }

        const fileType = newFiles?.[i]?.type?.split("/")[0];
        const fileSize = newFiles?.[i]?.size;

        if (fileSize >= UPLOAD_LIMIT) {
          if (showFileSizeWarning) {
            showToast(
              `Files above ${UPLOAD_LIMIT_TEXT} are currently not supported`,
              "error"
            );
            showFileSizeWarning = false;
          }
          continue;
        }

        switch (fileType) {
          case "image": {
            try {
              let image = newFiles?.[i];

              const isImgHeicType =
                newFiles?.[i]?.type?.includes("heif") ||
                newFiles?.[i]?.type?.includes("heic");

              if (isImgHeicType) {
                // blob type object will be recieved
                image = await handleHeicImg(newFiles?.[i]);
              }

              if (userData?.user?.settings?.waterMarkText?.trim()?.length) {
                const markedFile = await addWaterMark(
                  image,
                  userData?.user?.settings?.waterMarkText
                );

                image = {
                  previewBlob: markedFile.url,
                  uploadData: markedFile.blob,
                  type: fileType,
                  contentType: fileType,
                };
              } else {
                // image is heicType and no watermark text
                if (isImgHeicType) {
                  image = {
                    previewBlob: URL.createObjectURL(image),
                    uploadData: newFiles?.[i],
                    type: fileType,
                    contentType: fileType,
                  };
                } else {
                  image = {
                    previewBlob: URL.createObjectURL(newFiles?.[i]),
                    uploadData: newFiles?.[i],
                    type: fileType,
                    contentType: fileType,
                  };
                }
              }
              prevFiles.push(image);
            } catch (error) {
              console.log(error);
            } finally {
              break;
            }
          }

          case "audio": {
            if (pageName !== "feedPage" && !isAudioUpload) {
              showToast("Audio file not supported", "error");
              break;
            }

            let sound = {};

            if (newFiles[i].isRecordredAudio) {
              sound["previewBlob"] = newFiles[i].blobURL;
              sound["uploadData"] = { ...newFiles[i], type: "audio/*" };
              sound["type"] = "audio/*";
              sound["isRecordredAudio"] = true;
            } else {
              sound = {
                previewBlob: URL.createObjectURL(newFiles[i]),
                uploadData: newFiles[i],
                type: fileType,
                contentType: fileType,
              };
            }

            prevFiles.push(sound);
            break;
          }

          case "video": {
            let video = {
              previewBlob: URL.createObjectURL(newFiles[i]),
              uploadData: newFiles[i],
              type: fileType,
              contentType: fileType,
            };

            prevFiles.push(video);
            break;
          }

          case "application": {
            if (pageName === "feedPage" || !isPdfUpload) {
              showToast("Upload only video or images files", "error");
              break;
            }

            if (!newFiles?.[i]?.type?.includes("pdf")) {
              showToast("Only pdf type document is allowed.", "error");
              break;
            }

            let document = {
              previewBlob: URL.createObjectURL(newFiles[i]),
              uploadData: newFiles[i],
              type: "doc",
              contentType: "doc",
            };

            prevFiles.push(document);
            break;
          }

          default:
            if (showNonMediaFilesWarning) {
              showToast("Please upload only media files.", "error");
              showNonMediaFilesWarning = false;
            }
            break;
        }
      }

      resolve({ mediaFiles: prevFiles, error: false });
    } catch (error) {
      console.log("error mediaFileshandler fn >>", error);
      resolve({ mediaFiles: prevFiles, error: true });
    }
  });
};

export const hideDescription = ({ id, description, charCount = 20 }) => {
  if (!description?.length) {
    return "--";
  }

  return (
    <>
      {description?.slice(0, charCount)}

      {description?.length > charCount ? (
        <>
          <Button
            color="link"
            className="edit ms-2"
            style={{ padding: 0 }}
            id={id}
          >
            {`...`}
          </Button>

          <CustomTooltip
            target={id}
            text={description?.length ? description : "--"}
          />
        </>
      ) : null}
    </>
  );
};

export const generateDates = (month, year) => {
  if (!month) month = moment().format("M");
  if (!year) year = moment().subtract(18, "years").year();

  const dates = [];
  if (moment([year]).isLeapYear() && month === "2") {
    for (let i = 1; i <= 29; i++) {
      dates.push(i);
    }
    return dates;
  }

  for (let i = 1; i <= MonthsWithDays[+month]; i++) {
    dates.push(i);
  }
  return dates;
};

export const birthYearWithMinimumAge = (leastAge) => {
  const years = [];
  let currentYear = new Date().getFullYear();
  let start = currentYear - leastAge;

  for (let i = start; years.length + leastAge < 100; i--) {
    years.push(i);
  }
  return years;
};

export const handleNameObj = (nameObj) => {
  if (nameObj?.full) return nameObj?.full;

  let name = "";
  if (nameObj?.first) {
    name = nameObj?.first;
    if (nameObj?.last) {
      name = name + " " + nameObj.last;
    }
  }
  return name;
};

export const reduceToSevenTwoZeroRes = (link = "") => {
  if (!link?.length) return "";

  const resolution =
    process.env.REACT_APP_BACKEND_ENV === "prod" ? "720w" : "360w";

  return link?.replace("1080w", resolution);
};
