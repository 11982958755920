import React, { useState } from "react";
import { useMemo } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, ListGroup, ListGroupItem } from "reactstrap";
import {
  capitalize,
  capitalizeEveryFirstLetter,
  errorHandler,
  formatCurrencyValue,
  getFeatureStatus,
} from "../../helper-methods";
import { getConfiguredString } from "../../helper-methods/configureString";
import {
  getEarningStatistics,
  getIndividualEarningStats,
} from "../../http-calls";
import ConfigureString from "../ConfigureString";
import CustomLoader from "../custom/CustomLoader";
import CustomDateRangePicker from "../CustomDateRangePicker";
import StatementStatsTableModal from "../modals/StatementStatsTableModal";

const EarningStatistics = ({ activeTab, tabId, toggleActiveTab }) => {
  const userData = useSelector((state) => state?.userData);

  const [filters, setFilters] = useState({
    startDate: null,
    endDate: null,
  });
  const [earningsStats, setEarningsStats] = useState({});
  const [loading, setLoading] = useState(false);
  const [statementStatsTableModal, setStatementStatsTableModal] = useState({
    isOpen: false,
    data: null,
    modalHeader: "",
  });

  const calculateTotalEarnings = useMemo(() => {
    const total = Object.values(earningsStats).reduce(
      (acc, next) => acc + next,
      0
    );

    return formatCurrencyValue(total || 0);
  }, [earningsStats]);

  const isLiveEventActive = useMemo(() => getFeatureStatus("event"), []);
  const isPayPerViewActive = useMemo(() => getFeatureStatus("ppv"), []);
  const isVaultActive = useMemo(() => getFeatureStatus("vault"), []);

  /**
   * summaryType - vault // ppv // event // referral
   *
   * @param {String} summaryType: String / required
   */
  const _getIndividualEarningStats = (summaryType) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true);

        const payload = {};

        if (filters?.startDate && filters?.endDate) {
          payload.startDate = filters.startDate;
          payload.endDate = filters.endDate;
        }

        const res = await getIndividualEarningStats(summaryType, payload);

        setLoading(false);

        resolve(res);
      } catch (error) {
        setLoading(false);

        reject(error);
      }
    });
  };

  /**
   * openFor - vault // ppv // event // referral
   *
   * @param {boolean} isOpen
   * @param {string} openFor - String / optional
   */
  const _toggleStatementStatsTableModal = async (
    isOpen = false,
    openFor = ""
  ) => {
    try {
      let data = [];
      let modalHeader = "";

      if (isOpen && openFor) {
        const res = await _getIndividualEarningStats(openFor);

        data = res?.earnings?.length ? res.earnings.slice(0, 5) : [];

        modalHeader = capitalize(getConfiguredString(openFor));
      }

      setStatementStatsTableModal({
        isOpen,
        data,
        modalHeader,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  const _getEarningStatistics = async (newFilters) => {
    try {
      setLoading(true);

      const payload = {};

      if (newFilters?.startDate && newFilters?.endDate) {
        payload.startDate = newFilters.startDate;
        payload.endDate = newFilters.endDate;
      }

      const res = await getEarningStatistics(payload);

      setEarningsStats(res.earningsStats);

      setLoading(false);
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  const _onDatesChange = ({ startDate, endDate }) => {
    const newFilters = { ...filters };
    newFilters.startDate = startDate?._d ? startDate.toISOString() : null;
    newFilters.endDate = endDate?._d ? endDate.toISOString() : null;
    setFilters(newFilters);

    if (newFilters?.startDate && newFilters?.endDate) {
      _getEarningStatistics(newFilters);
      return;
    }

    if (!newFilters?.startDate && !newFilters?.endDate) {
      _getEarningStatistics(newFilters);
      return;
    }
  };

  useEffect(() => {
    if (activeTab === tabId) {
      _getEarningStatistics();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, tabId]);

  return (
    <>
      <div className="earningSummaryDateRange mt-3">
        <CustomDateRangePicker
          startDate={filters?.startDate}
          endDate={filters?.endDate}
          startDateId={`startDate_earning_statistics`}
          endDateId={`endDate_earning_statistics`}
          onDatesChange={({ startDate, endDate }) =>
            _onDatesChange({
              startDate,
              endDate,
            })
          }
        />

        {loading ? <CustomLoader className="mx-2" /> : null}
      </div>

      <ListGroup className="earningStatsSummary">
        <ListGroupItem>
          Subscriptions
          <div>
            {formatCurrencyValue(earningsStats?.subscriptionEarning || 0)}
          </div>
        </ListGroupItem>
        <ListGroupItem>
          Messages
          <div>{formatCurrencyValue(earningsStats?.messageEarning || 0)}</div>
        </ListGroupItem>
        <ListGroupItem>
          Posts
          <div>{formatCurrencyValue(earningsStats?.postEarning || 0)}</div>
        </ListGroupItem>

        {isVaultActive && (
          <ListGroupItem>
            <ConfigureString keyString={"vault"} />
            <div>
              {earningsStats?.vaultEarning ? (
                <Button
                  color="link"
                  className="p-0 themeColor"
                  disabled={loading}
                  onClick={() => _toggleStatementStatsTableModal(true, "vault")}
                >
                  {formatCurrencyValue(earningsStats.vaultEarning)}
                </Button>
              ) : (
                formatCurrencyValue(0)
              )}
            </div>
          </ListGroupItem>
        )}
        {isPayPerViewActive && (
          <ListGroupItem>
            <ConfigureString keyString="ppv" />
            <div>
              {earningsStats?.payperviewEarning ? (
                <Button
                  color="link"
                  className="p-0 themeColor"
                  disabled={loading}
                  onClick={() => _toggleStatementStatsTableModal(true, "ppv")}
                >
                  {formatCurrencyValue(earningsStats.payperviewEarning)}
                </Button>
              ) : (
                formatCurrencyValue(0)
              )}
            </div>
          </ListGroupItem>
        )}
        {isLiveEventActive && (
          <ListGroupItem>
            <ConfigureString keyString="event" />
            <div>
              {earningsStats?.liveEventEarning ? (
                <Button
                  color="link"
                  className="p-0 themeColor"
                  disabled={loading}
                  onClick={() => _toggleStatementStatsTableModal(true, "event")}
                >
                  {formatCurrencyValue(earningsStats.liveEventEarning)}
                </Button>
              ) : (
                formatCurrencyValue(0)
              )}
            </div>
          </ListGroupItem>
        )}
        <ListGroupItem>
          Referrals
          <div>
            {earningsStats?.refferalEarning ? (
              <Button
                color="link"
                className="p-0 themeColor"
                disabled={loading}
                onClick={() =>
                  _toggleStatementStatsTableModal(true, "referral")
                }
              >
                {formatCurrencyValue(earningsStats.refferalEarning)}
              </Button>
            ) : (
              formatCurrencyValue(0)
            )}
          </div>
        </ListGroupItem>
        <ListGroupItem>
          {userData?.user?.settings?.tipText
            ? capitalizeEveryFirstLetter(userData.user.settings.tipText)
            : "Tip"}

          <div>
            {earningsStats && earningsStats.tipsEarning
              ? formatCurrencyValue(earningsStats.tipsEarning)
              : formatCurrencyValue(0)}
          </div>
        </ListGroupItem>
        <ListGroupItem>
          <span
            style={{
              fontWeight: 600,
              color: "#333",
              fontSize: 16,
            }}
          >
            Total
          </span>

          <div style={{ fontSize: 16 }}>{calculateTotalEarnings}</div>
        </ListGroupItem>
      </ListGroup>

      <StatementStatsTableModal
        isOpen={statementStatsTableModal.isOpen}
        data={statementStatsTableModal.data}
        modalHeader={statementStatsTableModal.modalHeader}
        toggle={() => _toggleStatementStatsTableModal()}
        seeMore={() => toggleActiveTab("1")}
      />
    </>
  );
};

export default EarningStatistics;
