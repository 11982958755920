import React from "react";
import ConfigureString from "../ConfigureString";

const MessagesGuideText = () => {
  return (
    <div>
      <p className="mb-2">
        Tap/click on a user to open a chat with them. You can also search, view
        stats, and launch a new mass message by selecting one of the icons in
        the top right corner.
      </p>
      <p className="mb-2">
        <ConfigureString
          keyString={`
            Mass messages will be sent to each selected fan individually. 
            Additionally, you can choose to set a price on your mass message content making it a 'ppv' message.
          `}
        />
      </p>
      <p className="mb-2">
        <ConfigureString
          keyString={`ppv messages require your fanz to purchase the content you send them before they can unlock access to view it.`}
        />
      </p>
      <p className="mb-2">
        <ConfigureString
          keyString={`ppv messages are a fantastic way to earn additional income while rewarding your biggest supporters with content.`}
        />
      </p>
    </div>
  );
};

export default MessagesGuideText;
