import { capitalizeEveryFirstLetter } from ".";
import { store as REDUX_STORE } from "../redux/store";

/**
 * @param {*} type => value which may have been modified by admin
 * @returns => respective modified/configured vaule
 */
const _getNewNameConfigurableText = (type) => {
  try {
    const { nameConfigurable } = REDUX_STORE.getState();
    if (nameConfigurable?.[type]?.name?.trim().length) {
      return capitalizeEveryFirstLetter(nameConfigurable[type].name.trim());
    }
    return "";
  } catch (error) {
    console.log("error >>", error);
    return "";
  }
};

/**
 * @param {*} templateString => string which contains vaules which may have been configured by admin
 * @returns => string with configured vaules
 */
export const getConfiguredString = (templateString) => {
  if (!templateString) return "";

  if (templateString?.toLowerCase()?.includes("vault")) {
    let newNameConfigurable = _getNewNameConfigurableText("vault");
    templateString = templateString.replace(
      /vault/gi,
      newNameConfigurable ? newNameConfigurable : "Vault"
    );
  } else if (templateString?.toLowerCase()?.includes("ppv")) {
    let newNameConfigurable = _getNewNameConfigurableText("ppv");
    templateString = templateString.replace(
      /ppv/gi,
      newNameConfigurable ? newNameConfigurable : "Pay Per View"
    );
  } else if (templateString?.toLowerCase()?.includes("event")) {
    let newNameConfigurable = _getNewNameConfigurableText("event");
    templateString = templateString.replace(
      /event/gi,
      newNameConfigurable ? newNameConfigurable : "Live Event"
    );
  } else if (templateString?.toLowerCase()?.includes("shoutout")) {
    let newNameConfigurable = _getNewNameConfigurableText("shoutout");
    templateString = templateString.replace(
      /shoutout/gi,
      newNameConfigurable ? newNameConfigurable : "Shout Out"
    );
  }

  return templateString;
};
