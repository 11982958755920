import { AppSwitch } from "@coreui/react";
import React, { useState, useMemo } from "react";
import { Button, FormGroup, Input, Label } from "reactstrap";
import {
  deepClone,
  errorHandler,
  getFeatureStatus,
  isPermissionToAccess,
} from "../../helper-methods";
import { getConfiguredString } from "../../helper-methods/configureString";
import { useDispatch, useSelector } from "react-redux";
import { securitySettingsTooltipsConfig } from "../../config/helper-config";
import { updateInfluencerDetails } from "../../http-calls";
import { updateOnlyUserData } from "../../redux/actions";
import CustomTooltip from "../custom/CustomTooltip";

const SettingsSecurity = ({ saveSettingsData }) => {
  const userData = useSelector((state) => state?.userData);
  const dispatch = useDispatch();

  const isShoutoutActive = getFeatureStatus("shoutout");

  const [settings, setSettings] = useState(userData?.user?.settings || {});

  // const [latestSelectedBlockCountry, setLatestSelectedBlockCountry] = useState(
  //   userData?.user?.blockCountries[0] || "united states"
  // );
  // const [isCustomError, setIsCustomError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const canUpdateSecuritySettings = useMemo(() => {
    return isPermissionToAccess(
      "profileAndSettings",
      "canUpdateSecuritySettings"
    );
  }, []);

  const _onValueChange = (attrName, val) => {
    if (!canUpdateSecuritySettings) {
      return null;
    }

    const newSettings = deepClone(settings);

    newSettings[attrName] = val;
    setSettings(newSettings);
  };

  // const _changeBlockCountry = (country) => {
  //   if (!canUpdateSecuritySettings) {
  //     return null;
  //   }

  //   const newSettings = deepClone(settings);
  //   newSettings["blockCountries"] = [];
  //   newSettings["blockCountries"][0] =
  //     country && country?.length ? country : "";

  //   setSettings(newSettings);
  //   setLatestSelectedBlockCountry(country);
  // };

  const _udpateInfluencerDetails = (payload) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await updateInfluencerDetails(payload);
        dispatch(updateOnlyUserData(res.user));
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };

  const _saveSettings = async () => {
    try {
      setIsLoading(true);
      const payload = {
        settings,
      };
      await _udpateInfluencerDetails(payload);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      errorHandler(error);
    }
  };

  return (
    <>
      <div className="settingOptionsWrap">
        <div className="header_Settings">
          <h4>Profile Policy</h4>
        </div>

        {securitySettingsTooltipsConfig?.map((each, index) =>
          each?.value === "isFaceTimeEnabled" ? (
            isShoutoutActive ? (
              <div
                className="notificationOptions"
                key={`${each.value}-${index}`}
              >
                <Label>
                  {getConfiguredString("shoutout")} via FaceApp
                  <sup
                    id={`setting_security_${each?.value}`}
                    className="infoIcon"
                  >
                    <i className="fa fa-info-circle" />
                  </sup>
                  <CustomTooltip
                    text={`Turn this ON to enable ${getConfiguredString(
                      "shoutout"
                    )} video call via FaceApp.`}
                    target={`setting_security_${each?.value}`}
                  />
                </Label>

                <AppSwitch
                  variant={"pill"}
                  disabled={!canUpdateSecuritySettings}
                  className="customSwitch"
                  checked={settings ? settings[each?.value] : false}
                  onChange={() =>
                    _onValueChange(each?.value, !settings[each?.value])
                  }
                />
              </div>
            ) : null
          ) : (
            <div className="notificationOptions" key={`${each.value}-${index}`}>
              <Label>
                {each?.label}
                {each?.tooltipValue?.length ? (
                  <>
                    <sup
                      id={`setting_security_${each?.value}`}
                      className="infoIcon"
                    >
                      <i className="fa fa-info-circle" />
                    </sup>
                    <CustomTooltip
                      text={each?.tooltipValue}
                      target={`setting_security_${each?.value}`}
                    />
                  </>
                ) : null}
              </Label>

              <AppSwitch
                variant={"pill"}
                disabled={!canUpdateSecuritySettings}
                className="customSwitch"
                checked={settings ? settings[each?.value] : false}
                onChange={() =>
                  _onValueChange(each?.value, !settings[each?.value])
                }
              />
            </div>
          )
        )}

        <hr
          style={{
            marginTop: 25,
            borderTopColor: "rgba(204, 204, 204, 0.5)",
          }}
        />

        {/* <div className="header_Settings" style={{ marginTop: 27 }}>
          <h4>Geoblocking</h4>
        </div>

        <FormGroup>
          <Label>By Country</Label>
          <select
            className="form-control"
            disabled={!canUpdateSecuritySettings}
            value={
              latestSelectedBlockCountry && latestSelectedBlockCountry?.length
                ? latestSelectedBlockCountry
                : ""
            }
            onChange={(e) => _changeBlockCountry(e.target.value)}
          >
            <option value="">Select Country</option>
            {countryCodes?.map((countryCode, countryIndex) => (
              <option
                key={countryIndex}
                value={countryCode?.name?.toLowerCase()}
              >
                {countryCode?.name}
              </option>
            ))}
          </select>
        </FormGroup> */}

        {/* Watermarks Section */}
        <div className="header_Settings" style={{ marginTop: 30 }}>
          <h4>Watermarks</h4>
        </div>

        <FormGroup>
          <Label>Watermark Custom Text</Label>
          <Input
            type="text"
            value={settings ? settings?.waterMarkText : ""}
            onChange={(e) => _onValueChange("waterMarkText", e.target.value)}
          />
        </FormGroup>

        {canUpdateSecuritySettings ? (
          <Button
            onClick={() => _saveSettings()}
            className="themeBtn saveBtn"
            disabled={isLoading}
          >
            {isLoading ? <i className="fa fa-spinner fa-spin mr-1" /> : null}{" "}
            Save
          </Button>
        ) : null}

        <hr
          style={{
            marginTop: 25,
            borderTopColor: "rgba(204, 204, 204, 0.5)",
          }}
        />
      </div>
    </>
  );
};

export default SettingsSecurity;
