import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Spinner,
  UncontrolledAlert,
  Alert,
} from "reactstrap";
import {
  showToast,
  findWarningType,
  formatCurrencyValue,
  isPermissionToAccess,
  errorHandler,
  extractQueryParams,
} from "../helper-methods";
import { getInfluencerBalance } from "../http-calls";
import BankInfoUpdateModal from "../components/modals/BankInfoUpdateModal";
import { fetchAndModifiedUserDetails } from "../redux/actions/userData";
import RequestWithdrawalModal from "../components/modals/RequestWithdrawalModal";
import { install as installResizeObserver } from "resize-observer";
import KYCAlertCard from "../components/KYCAlertCard";
import { APP_NAME } from "../config/index";
import EarningsGraph from "../components/statementTabs/EarningsGraph";
import EarningsTable from "../components/statementTabs/EarningsTable";
import PayoutRequests from "../components/statementTabs/PayoutRequests";
import EarningStatistics from "../components/statementTabs/EarningStatistics";
import { useMemo } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { hideLoader, showLoader } from "../redux/actions";
import ErrorBoundary from "../components/ErrorBoundary";
import ReKYCCard from "../components/ReKYCCard";

const StatementPage = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const userData = useSelector((state) => state?.userData);

  const [balance, setBalance] = useState({
    availableToWithdraw: 0,
    availableSoon: 0,
    inTransitToBank: 0,
  });
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [requestWithdrawalModal, setRequestWithdrawalModal] = useState({
    isOpen: false,
  });
  const [bankInfoModal, setBankInfoModal] = useState({
    isOpen: false,
  });

  const canMakePayoutRequest = useMemo(
    () => isPermissionToAccess("statement", "canMakePayoutRequest", true),
    []
  );

  const _toggleActiveTab = (tabId = "1") => {
    if (activeTab === tabId) return;

    history.replace(`/statement?tabId=${tabId}`);

    setActiveTab(tabId);
  };

  const _updateUserDetails = async () => {
    try {
      dispatch(showLoader("Updating Profile"));
      await fetchAndModifiedUserDetails()(dispatch);
      dispatch(hideLoader());
    } catch (error) {
      errorHandler(error);
      dispatch(hideLoader());
    }
  };

  const _toggleRequestWithdrawalModal = (isOpen = false) => {
    setRequestWithdrawalModal({
      isOpen,
    });
  };

  const _toggleBankInfoModal = (isOpen = false) => {
    setBankInfoModal({
      isOpen,
    });
  };

  const _getInfluencerBalance = async () => {
    try {
      setLoading(true);

      const res = await getInfluencerBalance();

      setBalance({
        availableToWithdraw: res?.availableToWithdrawal,
        availableSoon: res?.availableSoon,
        inTransitToBank: res?.transitToBank,
      });

      setLoading(false);
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isPermissionToAccess("statement", "canViewStatement")) {
      showToast("Unauthorized", "error");
      // Assistant dont have access to view Feed
      history.push("/my-profile");
    }

    // we have to install resize observer manually as it gives error
    // apexchart problem - resize observe used in apexchart
    installResizeObserver();

    dispatch(fetchAndModifiedUserDetails());

    _getInfluencerBalance();

    const { tabId } = extractQueryParams();

    if (+tabId && +tabId > 0 && +tabId < 5) {
      _toggleActiveTab(tabId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="customPgHeight animated fadeIn">
      <Container className="noPadding">
        <Row className="noMargin">
          <Col sm={12} className="pgPadding bg-white pb-4">
            {/* stripe kyc alert card */}
            <KYCAlertCard />

            <ReKYCCard />

            <div className="pgTitle">
              <h2>Statement</h2>
            </div>

            {findWarningType(userData?.user)?.bank ? (
              <UncontrolledAlert color="warning">
                <i className="fa fa-bell alertIconWarning" />
                {`${APP_NAME} cannot transfer payments to you currently. Please add a bank account to withdraw your funds.`}{" "}
                <hr style={{ margin: "0.8rem 0" }} />
                <Button
                  className="themeBtn addBtn mt-1"
                  onClick={() => _toggleBankInfoModal(true)}
                >
                  Add Bank Account
                </Button>
              </UncontrolledAlert>
            ) : null}

            {/* creator rank */}
            {userData?.user?.rankText ? (
              <Alert className="creatorRank">
                <h4 className="alert-heading">Well done!</h4>
                <p>
                  You are top {userData.user.rankText}% of all creators
                  globally!
                </p>
              </Alert>
            ) : null}

            <Row className="mb-2 mb-md-4 customMarginStatement">
              <Col xs="6" md="4" className="customPaddingStatement">
                <div className="balanceStatus">
                  <p>Available soon</p>
                  <img
                    src={"/assets/img/dollar.svg"}
                    alt="Dollar"
                    loading="lazy"
                  />
                  <h5>
                    {loading ? (
                      <Spinner type="grow" />
                    ) : (
                      formatCurrencyValue(balance?.availableSoon || 0)
                    )}
                  </h5>
                </div>
              </Col>
              <Col xs="6" md="4" className="customPaddingStatement">
                <div className="balanceStatus">
                  <p>In transit to bank</p>
                  <img src={"/assets/img/bank.svg"} alt="Bank" loading="lazy" />
                  <h5>
                    {loading ? (
                      <Spinner type="grow" />
                    ) : (
                      formatCurrencyValue(balance?.inTransitToBank || 0)
                    )}
                  </h5>
                </div>
              </Col>
              <Col xs="12" md="4">
                <div className="balanceStatus">
                  <p>Available to withdraw</p>
                  <h5 className="mt-3 mt-md-4">
                    {loading ? (
                      <Spinner type="grow" />
                    ) : (
                      formatCurrencyValue(balance?.availableToWithdraw || 0)
                    )}
                  </h5>

                  {canMakePayoutRequest && balance?.availableToWithdraw > 0 ? (
                    <Button onClick={() => _toggleRequestWithdrawalModal(true)}>
                      Request Withdrawal
                    </Button>
                  ) : null}
                </div>
              </Col>
            </Row>

            <Nav tabs className="customTabs customTabsStatement">
              <NavItem>
                <NavLink
                  active={activeTab === "1"}
                  onClick={() => {
                    _toggleActiveTab("1");
                  }}
                >
                  <span>Earnings Summary</span>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="242.399"
                      height="256"
                      viewBox="0 0 242.399 256"
                    >
                      <g
                        id="bar-chart_478544_1_"
                        data-name="bar-chart_478544 (1)"
                        transform="translate(-6.801)"
                      >
                        <g id="Group_43" data-name="Group 43">
                          <g id="Group_42" data-name="Group 42">
                            <path
                              id="Path_64"
                              data-name="Path 64"
                              d="M244.2,246H233.233V86.768a13.456,13.456,0,0,0-13.441-13.441H195.28a13.456,13.456,0,0,0-13.441,13.441V246H153.993V158.913a13.456,13.456,0,0,0-13.441-13.441H116.04A13.456,13.456,0,0,0,102.6,158.913V246H74.753V200.9a13.456,13.456,0,0,0-13.441-13.441H36.8A13.456,13.456,0,0,0,23.358,200.9V246H11.8a5,5,0,0,0,0,10H244.2a5,5,0,1,0,0-10ZM64.752,246H33.358V200.9a3.445,3.445,0,0,1,3.441-3.441H61.311a3.445,3.445,0,0,1,3.441,3.441V246Zm79.24,0H112.6V158.913a3.445,3.445,0,0,1,3.441-3.441h24.512a3.445,3.445,0,0,1,3.441,3.441Zm79.241,0H191.839V86.768a3.445,3.445,0,0,1,3.441-3.441h24.512a3.445,3.445,0,0,1,3.441,3.441V246Z"
                            />
                          </g>
                        </g>
                        <g id="Group_45" data-name="Group 45">
                          <g id="Group_44" data-name="Group 44">
                            <path
                              id="Path_65"
                              data-name="Path 65"
                              d="M233.221,5.258A4.983,4.983,0,0,0,227.975.012c-.08,0-.16-.012-.242-.012h-30.25a5,5,0,0,0,0,10h18.678L166.733,59.429l-18.64-18.64a5,5,0,0,0-7.071,0L51.137,130.674a5,5,0,0,0,7.071,7.071L144.557,51.4l18.64,18.64a5,5,0,0,0,7.072,0l52.964-52.964V33.977a5,5,0,0,0,10,0V5.5C233.233,5.418,233.225,5.339,233.221,5.258Z"
                            />
                          </g>
                        </g>
                        <g id="Group_47" data-name="Group 47">
                          <g id="Group_46" data-name="Group 46">
                            <circle
                              id="Ellipse_1"
                              data-name="Ellipse 1"
                              cx="5"
                              cy="5"
                              r="5"
                              transform="translate(32.82 146.655)"
                            />
                          </g>
                        </g>
                      </g>
                    </svg>
                  </div>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  active={activeTab === "2"}
                  onClick={() => {
                    _toggleActiveTab("2");
                  }}
                >
                  <span>Earnings</span>
                  <div>
                    <i className="fa fa-dollar" />
                  </div>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  active={activeTab === "3"}
                  onClick={() => {
                    _toggleActiveTab("3");
                  }}
                >
                  <span>Payout Requests</span>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="256"
                      height="256"
                      viewBox="0 0 256 256"
                    >
                      <g id="bank_522554" transform="translate(0 0)">
                        <g id="Group_55" data-name="Group 55">
                          <g id="Group_54" data-name="Group 54">
                            <path
                              id="Path_81"
                              data-name="Path 81"
                              d="M127.989,34.9a23.284,23.284,0,1,0,23.284,23.284A23.308,23.308,0,0,0,127.989,34.9Zm0,34.932a11.648,11.648,0,1,1,11.648-11.648A11.663,11.663,0,0,1,127.989,69.83Z"
                            />
                            <path
                              id="Path_82"
                              data-name="Path 82"
                              d="M250.182,104.727a5.8,5.8,0,0,0,5.818-5.8V75.655a5.808,5.808,0,0,0-2.847-5L139.341,3.066c-.051-.034-.1-.063-.153-.091a22.563,22.563,0,0,0-22.534.08L2.847,70.655a5.807,5.807,0,0,0-2.847,5v23.27a5.8,5.8,0,0,0,5.818,5.8H23.273V209.459A23.294,23.294,0,0,0,0,232.729v17.453A5.815,5.815,0,0,0,5.818,256H250.182A5.815,5.815,0,0,0,256,250.182V232.729a23.294,23.294,0,0,0-23.273-23.27V104.727Zm-5.818,128v11.636H11.636V232.727a11.651,11.651,0,0,1,11.636-11.636H232.727A11.651,11.651,0,0,1,244.363,232.727ZM34.909,209.454V104.727H58.182V209.454Zm34.909,0V104.727h34.909V209.454Zm46.545,0V104.727h23.273V209.454Zm34.909,0V104.727h34.909V209.454Zm46.546,0V104.727h23.273V209.454ZM11.636,93.091V78.949l110.79-65.818a11.075,11.075,0,0,1,11.057-.04l110.88,65.858V93.091Z"
                            />
                          </g>
                        </g>
                      </g>
                    </svg>
                  </div>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  active={activeTab === "4"}
                  onClick={() => {
                    _toggleActiveTab("4");
                  }}
                >
                  <span>Earning Statistics</span>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="192.139"
                      height="256"
                      viewBox="0 0 192.139 256"
                    >
                      <path
                        id="document_8001186"
                        d="M222.605,49.9,174.169,1.465A5,5,0,0,0,170.633,0H53.575A21.669,21.669,0,0,0,31.93,21.644v212.71A21.67,21.67,0,0,0,53.575,256h148.85a21.669,21.669,0,0,0,21.645-21.645V53.436a5,5,0,0,0-1.464-3.537ZM175.633,17.071,207,48.436H187.278a11.658,11.658,0,0,1-11.645-11.645ZM202.425,246H53.575A11.659,11.659,0,0,1,41.93,234.355V21.644A11.658,11.658,0,0,1,53.575,10H165.633V36.791a21.669,21.669,0,0,0,21.645,21.645H214.07V234.355A11.658,11.658,0,0,1,202.425,246ZM192.884,93.246a5,5,0,0,1-5,5h-85.9a5,5,0,0,1,0-10h85.9A5,5,0,0,1,192.884,93.246Zm0,77.718a5,5,0,0,1-5,5h-85.9a5,5,0,0,1,0-10h85.9A5,5,0,0,1,192.884,170.964Zm0-38.859a5,5,0,0,1-5,5h-85.9a5,5,0,0,1,0-10h85.9A5,5,0,0,1,192.884,132.1Zm0,77.718a5,5,0,0,1-5,5h-85.9a5,5,0,0,1,0-10h85.9A5,5,0,0,1,192.884,209.823ZM82.862,80.874H68.116a5,5,0,0,0-5,5v14.745a5,5,0,0,0,5,5H82.862a5,5,0,0,0,5-5V85.874a5,5,0,0,0-5-5Zm-5,14.745H73.116V90.874h4.746Zm5,101.832H68.116a5,5,0,0,0-5,5V217.2a5,5,0,0,0,5,5H82.862a5,5,0,0,0,5-5V202.45a5,5,0,0,0-5-5Zm-5,14.746H73.116V207.45h4.746Zm5-53.6H68.116a5,5,0,0,0-5,5v14.745a5,5,0,0,0,5,5H82.862a5,5,0,0,0,5-5V163.592a5,5,0,0,0-5-5Zm-5,14.745H73.116v-4.745h4.746Zm5-53.6H68.116a5,5,0,0,0-5,5v14.745a5,5,0,0,0,5,5H82.862a5,5,0,0,0,5-5V124.733a5,5,0,0,0-5-5Zm-5,14.746H73.116v-4.745h4.746Z"
                        transform="translate(-31.93)"
                      />
                    </svg>
                  </div>
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent
              activeTab={activeTab}
              className="customTabContent"
              style={{ overflow: "unset" }}
            >
              {/* earning summary with graph */}
              <TabPane tabId="1">
                <ErrorBoundary>
                  <EarningsGraph tabId="1" activeTab={activeTab} />
                </ErrorBoundary>
              </TabPane>

              {/* earnings tab */}
              <TabPane tabId="2">
                <ErrorBoundary>
                  <EarningsTable tabId="2" activeTab={activeTab} />
                </ErrorBoundary>
              </TabPane>

              {/* "Payout Requests" TAB starts  */}
              <TabPane tabId="3">
                <ErrorBoundary>
                  <PayoutRequests tabId="3" activeTab={activeTab} />
                </ErrorBoundary>
              </TabPane>

              {/* "Earning Statistics" TAB starts  */}
              <TabPane tabId="4">
                <ErrorBoundary>
                  <EarningStatistics
                    tabId="4"
                    activeTab={activeTab}
                    toggleActiveTab={_toggleActiveTab}
                  />
                </ErrorBoundary>
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Container>

      <RequestWithdrawalModal
        history={history}
        isOpen={requestWithdrawalModal.isOpen}
        availableToWithdraw={balance?.availableToWithdraw}
        canMakePayoutRequest={canMakePayoutRequest}
        toggle={() => _toggleRequestWithdrawalModal()}
        resetTabData={() => _getInfluencerBalance()}
        userData={userData}
      />

      <BankInfoUpdateModal
        isOpen={bankInfoModal.isOpen}
        onDismiss={() => _toggleBankInfoModal()}
        onAccountUpdate={_updateUserDetails}
        accountInfo={userData.accountInfo}
        user={userData?.user}
      />
    </div>
  );
};

export default StatementPage;
