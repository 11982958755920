import React from "react";
import Skeleton from "react-loading-skeleton";

const SkeletonSusbscription = ({ count }) => {
  return (
    <>
      {[...Array(count)].map((_, index) => (
        <div key={index} className="mb-4 w-100">
          {/* influencer pic, username, pause/resume button */}
          <div className="d-flex justify-content-between align-items-center mb-1">
            <div>
              <Skeleton circle height={60} width={60} className="mb-2" />
              <Skeleton height={14} width={Math.random() * 73 + 70} />
            </div>

            <Skeleton height={30} width={130} borderRadius={5} />
          </div>

          {/* Social media account */}
          <span className="d-flex mb-2">
            <Skeleton height={30} width={30} className="mr-2" />
            <Skeleton height={30} width={30} className="mr-2" />
            <Skeleton height={30} width={30} className="mr-2" />
            <Skeleton height={30} width={30} className="mr-2" />
            <Skeleton height={30} width={30} />
          </span>

          {/* subscription prices(basic, plus, premium), see bundle button */}
          <span className="d-flex mb-3">
            <Skeleton height={30} width={50} className="mr-2" />
            <Skeleton height={30} width={50} className="mr-2" />
            <Skeleton height={30} width={50} />
          </span>

          {/* description */}
          <Skeleton
            height={13}
            width={Math.random() * 140 + 130}
            className="float-left mb-2"
          />

          {/* start, end date */}
          <div className="d-flex justify-content-between w-100">
            <span>
              <Skeleton height={14} width={50} />
              <Skeleton height={16} width={90} />
            </span>

            <span>
              <Skeleton height={14} width={50} />
              <Skeleton height={16} width={90} />
            </span>

            <Skeleton
              height={30}
              width={130}
              borderRadius={5}
              className="d-sm-none d-xs-none d-md-block d-lg-block"
            />
          </div>
        </div>
      ))}
    </>
  );
};

export default SkeletonSusbscription;
