import React from "react";
import Skeleton from "react-loading-skeleton";
import { ListGroupItem } from "reactstrap";

const SkeletonChatFanList = ({ count }) => {
  return (
    <>
      {[...Array(count)].map((_, index) => (
        <ListGroupItem key={`SkeletonChatFanList_${index}`}>
          <div className="d-flex align-items-center">
            <Skeleton circle height={50} width={50} />

            <div className="mx-2">
              <Skeleton
                height={14}
                width={Math.floor(Math.random() * 80 + 40)}
                className="mr-3"
              />
              <Skeleton
                height={14}
                width={Math.floor(Math.random() * 80 + 40)}
                className="mr-3"
              />
            </div>
          </div>
        </ListGroupItem>
      ))}
    </>
  );
};

export default SkeletonChatFanList;
