import React, { useState, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import {
  getFeatureStatus,
  extractQueryParams,
  isPermissionToAccess,
} from "../helper-methods";
import { fetchAndModifiedUserDetails } from "../redux/actions/userData";
import KYCAlertCard from "../components/KYCAlertCard";
import ConfigureString from "../components/ConfigureString";
import { useHistory } from "react-router-dom";
import SettingsProfileSection from "../components/settingsComponents/SettingsProfileSection";
import SettingsAddress from "../components/settingsComponents/SettingsAddress";
import SocialMediaAccounts from "../components/settingsComponents/SocialMediaAccounts";
import SettingsBankDetails from "../components/settingsComponents/SettingsBankDetails";
import SettingsDonationTip from "../components/settingsComponents/SettingsDonationTip";
import SettingsShoutOut from "../components/settingsComponents/SettingsShoutOut";
import SettingsLoginSessions from "../components/settingsComponents/SettingsLoginSessions";
import SubscriptionWelcomeMessage from "../components/settingsComponents/SubscriptionWelcomeMessage";
import SettingsSubscription from "../components/settingsComponents/SettingsSubscription";
import ChangePassword from "../components/settingsComponents/ChangePassword";
import SettingsNotification from "../components/settingsComponents/SettingsNotification";
import SettingsSecurity from "../components/settingsComponents/SettingsSecurity";
import ErrorBoundary from "../components/ErrorBoundary";
import ReKYCCard from "../components/ReKYCCard";

const SettingsPage = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState("1");

  const canUpdateSecuritySettings = useMemo(() => {
    return isPermissionToAccess(
      "profileAndSettings",
      "canUpdateSecuritySettings"
    );
  }, []);

  const _toggleActiveTab = (tabId = "1", isSaveTab = true) => {
    if (tabId === activeTab) return;

    if (isSaveTab) {
      history.replace(`/settings?tabId=${tabId}`);
    }

    setActiveTab(tabId);
  };

  useEffect(() => {
    dispatch(fetchAndModifiedUserDetails());

    const extractQuery = extractQueryParams();

    if (
      extractQuery?.hasOwnProperty("tabId") &
      (extractQuery["tabId"] > 0) &
      (extractQuery["tabId"] < 6)
    )
      _toggleActiveTab(extractQuery["tabId"]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="customPgHeight animated fadeIn">
      <Container className="noPadding">
        <Row className="mb-3 justify-content-center noMargin">
          <Col sm={12} lg={11} className="pgPadding">
            {/* stripe kyc alert card */}
            <KYCAlertCard />

            <ReKYCCard />

            <div className="pgTitle justify-content-start">
              <Button
                className="customBackBtn"
                onClick={() => history.goBack()}
              >
                <i className="fa fa-chevron-left" />
              </Button>

              <h2>Settings</h2>
            </div>

            <div className="bg-white p-0 p-sm-3" style={{ borderRadius: 10 }}>
              <Nav
                tabs
                className="customTabs customTabs-Notifications settingsTab"
              >
                <NavItem>
                  <NavLink
                    active={activeTab === "1"}
                    onClick={() => _toggleActiveTab("1")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width="60"
                      height="60"
                      viewBox="0 0 60 60"
                    >
                      <defs>
                        <clipPath id="clip-path">
                          <rect
                            id="Rectangle_4"
                            data-name="Rectangle 4"
                            width="60"
                            height="60"
                            transform="translate(271 213)"
                            fill="#333"
                            stroke="#707070"
                            strokeWidth="1"
                          />
                        </clipPath>
                      </defs>
                      <g
                        id="Mask_Group_10"
                        data-name="Mask Group 10"
                        transform="translate(-271 -213)"
                        clipPath="url(#clip-path)"
                      >
                        <g
                          id="_01_align_center"
                          data-name="01 align center"
                          transform="translate(271 213)"
                        >
                          <path
                            id="Path_6"
                            data-name="Path 6"
                            d="M52.5,60h-5V47.393A7.4,7.4,0,0,0,40.108,40H19.892A7.4,7.4,0,0,0,12.5,47.393V60h-5V47.393A12.408,12.408,0,0,1,19.893,35H40.107A12.408,12.408,0,0,1,52.5,47.393Z"
                            fill="#333"
                          />
                          <path
                            id="Path_7"
                            data-name="Path 7"
                            d="M30,30A15,15,0,1,1,45,15,15,15,0,0,1,30,30ZM30,5A10,10,0,1,0,40,15,10,10,0,0,0,30,5Z"
                            fill="#333"
                          />
                        </g>
                      </g>
                    </svg>

                    <span>Profile</span>
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    active={activeTab === "2"}
                    onClick={() => _toggleActiveTab("2")}
                  >
                    <svg
                      className="iconSubscription"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width="256"
                      height="256"
                      viewBox="0 0 256 256"
                    >
                      <defs>
                        <clipPath id="clip-path">
                          <rect
                            id="Rectangle_27"
                            data-name="Rectangle 27"
                            width="256"
                            height="256"
                            transform="translate(716 1045)"
                            fill="#ffc7c7"
                          />
                        </clipPath>
                      </defs>
                      <g
                        id="Mask_Group_24"
                        data-name="Mask Group 24"
                        transform="translate(-716 -1045)"
                        clipPath="url(#clip-path)"
                      >
                        <path
                          id="return-investment_4339712"
                          d="M33.08,143.368a8,8,0,0,1-11.313,0L2.344,123.945a8,8,0,0,1,11.314-11.314l6.157,6.157A108.594,108.594,0,0,1,186.278,36.376,108.865,108.865,0,0,1,225.6,80.392a8,8,0,0,1-14.376,7.025A92.589,92.589,0,0,0,35.984,117.838l5.206-5.206A8,8,0,0,1,52.5,123.946Zm220.577-11.313-19.423-19.423a8,8,0,0,0-11.314,0L203.5,132.055a8,8,0,0,0,11.314,11.314l5.206-5.206A92.589,92.589,0,0,1,44.774,168.584,8,8,0,0,0,30.4,175.609a108.6,108.6,0,0,0,174.378,29.167,107.759,107.759,0,0,0,31.411-67.564l6.157,6.157a8,8,0,0,0,11.313-11.314Zm-125.68,60.82a8,8,0,0,0,8-8v-4.687a31.142,31.142,0,0,0,14.394-8.406,29.271,29.271,0,0,0,8.392-21.432,26.839,26.839,0,0,0-10.4-20.616c-5.15-4.217-11.4-6.976-17.452-9.644-12.081-5.33-17.405-8.363-17.688-15.034a13.053,13.053,0,0,1,3.761-9.619,15.11,15.11,0,0,1,10.85-4.648A14.589,14.589,0,0,1,140.855,98.8,8,8,0,0,0,155.1,91.509a30.57,30.57,0,0,0-19.12-15.622V71.125a8,8,0,0,0-16,0v4.694a31.226,31.226,0,0,0-14.54,8.548,28.91,28.91,0,0,0-8.2,21.368c.733,17.312,15.417,23.79,27.215,28.995,12.553,5.539,18.08,8.738,18.315,16.13a13.4,13.4,0,0,1-3.892,9.791,15.012,15.012,0,0,1-10.717,4.561,14.59,14.59,0,0,1-13.017-8.012,8,8,0,1,0-14.242,7.292,30.57,30.57,0,0,0,19.073,15.61v4.775a8,8,0,0,0,8,8Z"
                          transform="translate(716 1045)"
                        />
                      </g>
                    </svg>

                    <span>Subscription</span>
                  </NavLink>
                </NavItem>

                {getFeatureStatus("shoutout") ? (
                  <NavItem>
                    <NavLink
                      active={activeTab === "3"}
                      onClick={() => {
                        _toggleActiveTab("3");
                      }}
                    >
                      <i className="fa fa-bullhorn" />
                      <i className="fa fa-bullhorn activeTabIcon" />
                      <span>
                        <ConfigureString keyString="shoutout" />
                      </span>
                    </NavLink>
                  </NavItem>
                ) : null}

                <NavItem>
                  <NavLink
                    active={activeTab === "4"}
                    onClick={() => _toggleActiveTab("4")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      id="Outline"
                      viewBox="0 0 24 24"
                      width="512"
                      height="512"
                    >
                      <path d="M22.555,13.662l-1.9-6.836A9.321,9.321,0,0,0,2.576,7.3L1.105,13.915A5,5,0,0,0,5.986,20H7.1a5,5,0,0,0,9.8,0h.838a5,5,0,0,0,4.818-6.338ZM12,22a3,3,0,0,1-2.816-2h5.632A3,3,0,0,1,12,22Zm8.126-5.185A2.977,2.977,0,0,1,17.737,18H5.986a3,3,0,0,1-2.928-3.651l1.47-6.616a7.321,7.321,0,0,1,14.2-.372l1.9,6.836A2.977,2.977,0,0,1,20.126,16.815Z" />
                    </svg>

                    <span>Notifications</span>
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    active={activeTab === "5"}
                    onClick={() => _toggleActiveTab("5")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="217.998"
                      height="256"
                      viewBox="0 0 217.998 256"
                    >
                      <g id="surface1" transform="translate(0 0)">
                        <path
                          id="Path_86"
                          data-name="Path 86"
                          d="M217.744,69.459l-.008-.2c-.111-2.459-.186-5.061-.23-7.955a27.132,27.132,0,0,0-25.547-26.58c-29.433-1.643-52.2-11.24-71.658-30.2l-.166-.158a16.383,16.383,0,0,0-22.266,0l-.166.158C78.248,23.484,55.478,33.082,26.045,34.726A27.131,27.131,0,0,0,.5,61.306C.455,64.181.379,66.783.268,69.26l-.012.463C-.316,99.736-1.027,137.09,11.469,170.994A128.916,128.916,0,0,0,42.4,219.168c15.547,15.172,35.91,27.217,60.523,35.8a18.746,18.746,0,0,0,2.48.676,18.32,18.32,0,0,0,7.2,0,18.8,18.8,0,0,0,2.49-.68c24.583-8.6,44.925-20.648,60.458-35.818a129.1,129.1,0,0,0,30.928-48.185C219.023,136.953,218.314,99.529,217.744,69.459Zm-25.357,96.3c-13.179,35.734-40.084,60.275-82.25,75.021a3.773,3.773,0,0,1-.476.129,3.371,3.371,0,0,1-1.32,0,3.741,3.741,0,0,1-.473-.127c-42.21-14.718-69.132-39.246-82.3-74.982C14.047,134.549,14.7,100.26,15.275,70.01l0-.168c.117-2.6.2-5.322.238-8.314a12.059,12.059,0,0,1,11.365-11.8c16.656-.93,31.289-4.148,44.734-9.84a117.037,117.037,0,0,0,36.455-24.492,1.385,1.385,0,0,1,1.859,0,117.081,117.081,0,0,0,36.455,24.492c13.445,5.691,28.078,8.91,44.736,9.84a12.056,12.056,0,0,1,11.363,11.8c.045,3.007.123,5.73.24,8.312C203.3,100.127,203.937,134.443,192.386,165.761Zm0,0"
                        />
                        <path
                          id="Path_87"
                          data-name="Path 87"
                          d="M109,64.2A63.8,63.8,0,1,0,172.8,128,63.871,63.871,0,0,0,109,64.2Zm0,112.576A48.777,48.777,0,1,1,157.773,128,48.832,48.832,0,0,1,109,176.781Zm0,0"
                        />
                        <path
                          id="Path_88"
                          data-name="Path 88"
                          d="M127.334,108.2,97.7,137.83l-8.043-8.043a7.511,7.511,0,0,0-10.623,10.621L92.39,153.763a7.511,7.511,0,0,0,10.621,0l34.945-34.945A7.511,7.511,0,1,0,127.334,108.2Zm0,0"
                        />
                      </g>
                    </svg>

                    <span>Security</span>
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent
                activeTab={activeTab}
                className="customTabContent customTabContent-Notifications"
              >
                <TabPane tabId="1">
                  <ErrorBoundary>
                    <SettingsProfileSection />
                  </ErrorBoundary>

                  <ErrorBoundary>
                    <SettingsAddress />
                  </ErrorBoundary>

                  <ErrorBoundary>
                    <SettingsBankDetails />
                  </ErrorBoundary>

                  <ErrorBoundary>
                    <SocialMediaAccounts />
                  </ErrorBoundary>
                </TabPane>

                <TabPane tabId="2">
                  <ErrorBoundary>
                    <SettingsSubscription />
                  </ErrorBoundary>

                  <ErrorBoundary>
                    <SubscriptionWelcomeMessage />
                  </ErrorBoundary>

                  <ErrorBoundary>
                    <SettingsDonationTip />
                  </ErrorBoundary>
                </TabPane>

                {/* "Shout Out" TAB starts  */}
                <TabPane tabId="3">
                  <ErrorBoundary>
                    <SettingsShoutOut />
                  </ErrorBoundary>
                </TabPane>

                {/* "Notifications" TAB starts  */}
                <TabPane tabId="4">
                  <ErrorBoundary>
                    <SettingsNotification />
                  </ErrorBoundary>
                </TabPane>

                {/* "Security" TAB starts  */}
                <TabPane tabId="5">
                  <ErrorBoundary>
                    <SettingsSecurity />
                  </ErrorBoundary>

                  {canUpdateSecuritySettings ? (
                    <>
                      <ErrorBoundary>
                        <ChangePassword />
                      </ErrorBoundary>
                    </>
                  ) : null}

                  {/* Login Session */}
                  <ErrorBoundary>
                    <SettingsLoginSessions />
                  </ErrorBoundary>
                </TabPane>
              </TabContent>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SettingsPage;
