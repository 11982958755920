import React, { useState } from "react";
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { isDocumentOptional, errorHandler } from "../../helper-methods";
import { updateBank } from "../../http-calls";

const BankInfoUpdateModal = ({ isOpen, user, onDismiss, onAccountUpdate }) => {
  const [formFields, setFormFields] = useState({
    accountNumber: "",
    confirmAccountNumber: "",
    routingNumber: "",
    confirmRoutingNumber: "",
  });
  const [isDirty, setIsDirty] = useState({});
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const _markAllIsDirty = () => {
    return new Promise((resolve) => {
      const newFormFields = { ...formFields };
      const newIsDirty = { ...isDirty };
      Object.keys(newFormFields).forEach((key) => {
        newIsDirty[key] = true;
      });
      setIsDirty(newIsDirty);
      resolve(newIsDirty);
    });
  };

  const _validateFormfields = ({ newFormFields, newIsDirty }) => {
    return new Promise((resolve) => {
      let isFormValid = true;
      const newErrors = {};

      Object.keys(newFormFields).forEach((key) => {
        if (newIsDirty[key]) {
          switch (key) {
            case "accountNumber": {
              if (newFormFields[key]?.trim().length) {
                if (newFormFields[key]?.trim() === user?.bank?.accountNumber) {
                  newErrors[key] = "New account number should be different.";
                  isFormValid = false;
                } else {
                  newErrors[key] = null;
                  newIsDirty[key] = false;
                }
              } else {
                newErrors[key] = "Provide valid account number";
                isFormValid = false;
              }
              break;
            }

            case "confirmAccountNumber": {
              if (newFormFields[key]?.trim().length) {
                if (newFormFields.accountNumber === newFormFields[key]) {
                  newErrors[key] = null;
                  newIsDirty[key] = false;
                } else {
                  newErrors[key] = "Account number doesn't match";
                  isFormValid = false;
                }
              } else {
                newErrors[key] = "Please re-enter account number";
                isFormValid = false;
              }
              break;
            }

            case "routingNumber": {
              if (
                (isDocumentOptional(
                  user?.address?.country,
                  "BANK_ACCOUNT_ROUTING_NUMBER"
                ) &&
                  !newFormFields.routingNumber.length) ||
                newFormFields?.routingNumber?.length
              ) {
                newErrors[key] = null;
                newIsDirty[key] = false;
              } else {
                isFormValid = false;
                newErrors[key] = "Provide valid routing number";
              }
              break;
            }

            case "confirmRoutingNumber": {
              if (newFormFields?.confirmRoutingNumber?.length) {
                if (newFormFields.routingNumber.length) {
                  if (
                    formFields.routingNumber !== formFields.confirmRoutingNumber
                  ) {
                    isFormValid = false;
                    newErrors[key] = "Routing number doesn't match";
                  } else {
                    newErrors[key] = null;
                    newIsDirty[key] = false;
                  }
                }
              } else if (
                isDocumentOptional(
                  user?.address?.country,
                  "BANK_ACCOUNT_ROUTING_NUMBER"
                ) &&
                !newFormFields?.[key]?.length
              ) {
                newErrors[key] = null;
                newIsDirty[key] = false;
              } else {
                isFormValid = false;
                newErrors[key] = "Please re-enter routing number";
              }
              break;
            }
            default:
          }
        }
      });

      setIsDirty((prev) => ({
        ...prev,
        ...newIsDirty,
      }));
      setErrors((prev) => ({
        ...prev,
        ...newErrors,
      }));
      resolve(isFormValid);
    });
  };

  const _onChangeFormfield = (key, value) => {
    const newFormFields = { ...formFields };
    newFormFields[key] = value;
    setFormFields(newFormFields);
  };

  const _onBlurFormfield = (key) => {
    const newFormFields = { ...formFields };
    const newIsDirty = {
      [key]: true,
    };
    _validateFormfields({ newFormFields, newIsDirty });
  };

  const _resetState = () => {
    const newFormFields = { ...formFields };
    Object.keys(newFormFields).forEach((key) => {
      newFormFields[key] = "";
    });
    setFormFields(newFormFields);
    setErrors({});
    setIsDirty({});
  };

  const _onDismiss = () => {
    _resetState();
    onDismiss();
  };

  const _onAccountUpdate = () => {
    onAccountUpdate();
    _onDismiss();
  };

  const _updateBankInfo = async () => {
    try {
      setIsLoading(true);

      const newFormFields = { ...formFields };
      const newIsDirty = await _markAllIsDirty();
      const isFormValid = await _validateFormfields({
        newFormFields,
        newIsDirty,
      });

      if (!isFormValid) {
        setIsLoading(false);
        return;
      }

      await updateBank({
        routingNo: newFormFields.routingNumber,
        accountNo: newFormFields.accountNumber,
      });

      _onAccountUpdate();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      errorHandler(error);
      _onDismiss();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => _onDismiss()}
      className="modal-dialog-centered modal-dialog-scrollable"
    >
      <ModalHeader toggle={() => _onDismiss()}>
        {user?.bank?.accountNumber ? "Change" : "Add"} Bank Info
      </ModalHeader>
      <ModalBody className="bankInfoModal">
        <FormGroup>
          <Label>Account Number</Label>
          <Input
            type="text"
            name="text-input"
            placeholder=""
            value={formFields?.accountNumber}
            onChange={(e) =>
              _onChangeFormfield("accountNumber", e.target.value)
            }
            onBlur={() => _onBlurFormfield("accountNumber")}
          />

          {errors?.accountNumber ? (
            <p className="form-error pl-0">{errors?.accountNumber}</p>
          ) : null}
        </FormGroup>

        <FormGroup>
          <Label>Confirm Account Number</Label>
          <Input
            type="text"
            name="text-input"
            placeholder=""
            value={formFields.confirmAccountNumber}
            onChange={(e) =>
              _onChangeFormfield("confirmAccountNumber", e.target.value)
            }
            onBlur={() => _onBlurFormfield("confirmAccountNumber")}
          />
          {errors?.confirmAccountNumber ? (
            <p className="form-error pl-0">{errors?.confirmAccountNumber}</p>
          ) : null}
        </FormGroup>

        <FormGroup>
          <Label>Routing Number | Sort Code | IFSC | Other</Label>
          <Input
            type="text"
            name="text-input"
            placeholder=""
            value={formFields?.routingNumber}
            onChange={(e) =>
              _onChangeFormfield("routingNumber", e.target.value)
            }
            onBlur={() => _onBlurFormfield("routingNumber")}
          />
          {errors?.routingNumber ? (
            <p className="form-error pl-0">{errors?.routingNumber}</p>
          ) : null}
        </FormGroup>

        <FormGroup className="mb-2">
          <Label>Confirm Routing Number | Sort Code | IFSC | Other</Label>
          <Input
            type="text"
            name="text-input"
            placeholder=""
            value={formFields?.confirmRoutingNumber}
            onChange={(e) =>
              _onChangeFormfield("confirmRoutingNumber", e.target.value)
            }
            onBlur={() => _onBlurFormfield("confirmRoutingNumber")}
          />
          {errors?.confirmRoutingNumber ? (
            <p className="form-error pl-0">{errors?.confirmRoutingNumber}</p>
          ) : null}
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        <Button className="modalBtnCancel" onClick={() => _onDismiss()}>
          Cancel
        </Button>
        <Button
          className="modalBtnSave"
          disabled={isLoading}
          onClick={() => _updateBankInfo()}
        >
          {isLoading ? <i className="fa fa-spinner fa-spin mr-1" /> : null} Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default BankInfoUpdateModal;
