import React, { useState } from "react";
import { useEffect } from "react";
import {
  Button,
  CustomInput,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
} from "reactstrap";
import ReactDatetime from "react-datetime";
import {
  errorHandler,
  formatDateAndTime,
  getYesterdayDate,
  isValidPrice,
  showToast,
} from "../helper-methods";
import { updateVaultFolder } from "../http-calls";
import CustomTooltip from "./custom/CustomTooltip";
import { getConfiguredString } from "../helper-methods/configureString";
import { CURRENCY_SYMBOL } from "../config";
import { AmountConfig } from "../config/appConfig";

const EditVaultDetails = ({ vaultData, setVaultData, canEdit }) => {
  const [formFields, setFormFields] = useState({
    name: "",
    description: "",
    price: "",
    scheduledAt: "",
  });
  // eslint-disable-next-line no-unused-vars
  const [isDirty, setIsDirty] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [isPublishNow, setIsPublishNow] = useState(false);

  const _validateFormfields = ({ newFormFields, newIsDirty }) => {
    return new Promise((resolve) => {
      let isFormValid = true;
      const newErrors = {};

      Object.keys(newFormFields).forEach((key) => {
        if (newIsDirty[key]) {
          switch (key) {
            case "name": {
              if (newFormFields[key]?.trim().length) {
                newErrors[key] = null;
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "price": {
              if (newFormFields[key]?.trim().length) {
                if (
                  !isNaN(newFormFields[key]) &&
                  +newFormFields[key] >= +AmountConfig.minVaultPrice &&
                  +newFormFields[key] <= +AmountConfig.maximumLimit
                ) {
                  newErrors[key] = null;
                  newIsDirty[key] = false;
                } else {
                  newErrors[key] =
                    +newFormFields[key] > +AmountConfig.maximumLimit
                      ? `*Maximum ${CURRENCY_SYMBOL}${AmountConfig.maximumLimit}`
                      : `*Minimum ${CURRENCY_SYMBOL}${AmountConfig.minVaultPrice}`;
                  isFormValid = false;
                }
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "scheduledAt": {
              if (!isPublishNow) {
                if (
                  newFormFields[key] &&
                  new Date() < new Date(newFormFields[key])
                ) {
                  newIsDirty[key] = false;
                  newErrors[key] = null;
                } else {
                  newErrors[key] = "*Should be future date & time";
                  isFormValid = false;
                }
              } else {
                newIsDirty[key] = false;
                newErrors[key] = null;
              }
              break;
            }
            default:
          }
        }
      });

      setIsDirty((prev) => ({
        ...prev,
        ...newIsDirty,
      }));
      setErrors((prev) => ({
        ...prev,
        ...newErrors,
      }));
      resolve(isFormValid);
    });
  };

  const _toggleIsPublishNow = (isPublish = false) => {
    setIsPublishNow(isPublish);
    setIsDirty((prev) => ({
      ...prev,
      scheduledAt: false,
    }));
    setErrors((prev) => ({
      ...prev,
      scheduledAt: null,
    }));
  };

  const _onChangeFormfield = (key, value) => {
    if (key === "price" && value && !isValidPrice(value)) {
      return;
    }

    const newFormFields = { ...formFields };

    if (key === "scheduledAt") {
      newFormFields[key] = value?._d ? new Date(value).toISOString() : "";
      const newIsDirty = {
        [key]: true,
      };
      _validateFormfields({ newFormFields, newIsDirty });
    } else {
      newFormFields[key] = value;
    }

    setFormFields(newFormFields);
  };

  const _onBlurFormfield = (key) => {
    const newFormFields = { ...formFields };
    const newIsDirty = {
      [key]: true,
    };
    _validateFormfields({ newFormFields, newIsDirty });
  };

  const _markAllIsDirty = () => {
    return new Promise((resolve) => {
      const newFormFields = { ...formFields };
      const newIsDirty = { ...isDirty };
      Object.keys(newFormFields).forEach((key) => {
        newIsDirty[key] = true;
      });
      setIsDirty(newIsDirty);
      resolve(newIsDirty);
    });
  };

  const _onSaveVault = async () => {
    try {
      const newFormFields = { ...formFields };
      const newIsDirty = await _markAllIsDirty();

      const isFormValid = await _validateFormfields({
        newFormFields,
        newIsDirty,
      });

      if (!isFormValid) {
        return;
      }

      setLoading(true);

      const payload = {
        name: formFields.name?.trim(),
        description: formFields.description?.trim(),
        price: +formFields.price?.trim(),
      };

      if (isPublishNow) {
        payload.isPublished = true;
      } else {
        payload.scheduledAt = new Date(
          newFormFields?.scheduledAt
        ).toISOString();
      }

      const res = await updateVaultFolder(payload, vaultData?._id);

      setVaultData(res.folder);

      showToast("Details has been updated", "success");

      setLoading(false);
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  const _setFormFields = (data = null) => {
    setFormFields({
      name: data?.name || "",
      description: data?.description || "",
      price: data?.price ? data.price.toString() : "",
      scheduledAt: data?.scheduledAt ? data.scheduledAt : "",
    });
    setIsDirty({});
    setErrors({});
    setLoading(false);
    setIsPublishNow(data?.isPublished || false);
  };

  useEffect(() => {
    if (vaultData) {
      _setFormFields(vaultData);
    }
  }, [vaultData]);

  return (
    <div className="vaultFolderDetails">
      <FormGroup>
        <Label>
          Title
          <sup id="EditVaultDetails_title" className="infoIcon">
            <i className="fa fa-info-circle" />
          </sup>
          <CustomTooltip
            text={`This is your folders title/headline. Your fanz will see this in bold. Be sure to title your folder name something that represents the content that is inside.`}
            target="EditVaultDetails_title"
          />
        </Label>
        <Input
          type="text"
          name="title"
          value={formFields.name}
          onChange={(e) => _onChangeFormfield("name", e.target.value)}
          onBlur={() => _onBlurFormfield("name")}
          disabled={loading}
        />
        {errors?.name ? <div className="form-error">{errors.name}</div> : null}
      </FormGroup>

      <FormGroup>
        <Label>
          Description
          <sup id="EditVaultDetails_description" className="infoIcon">
            <i className="fa fa-info-circle" />
          </sup>
          <CustomTooltip
            text={`Customize your description to tell your fanz about awesome content and why it's a terrific idea for them to unlock access.`}
            target="EditVaultDetails_description"
          />
        </Label>
        <Input
          type="textarea"
          rows="4"
          name="description"
          value={formFields.description}
          onChange={(e) => _onChangeFormfield("description", e.target.value)}
          onBlur={() => _onBlurFormfield("description")}
          disabled={loading}
        />
        {errors?.description ? (
          <div className="form-error">{errors.description}</div>
        ) : null}
      </FormGroup>

      <FormGroup>
        <Label>{getConfiguredString("vault Price")}</Label>
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText className="dollarWrap">
              <i className="fa fa-dollar" />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            type="text"
            name="price"
            value={formFields.price}
            onChange={(e) => _onChangeFormfield("price", e.target.value)}
            onBlur={() => _onBlurFormfield("price")}
            disabled={loading}
            className="dollarWrapInput"
          />
        </InputGroup>
        {errors?.price ? (
          <div className="form-error">{errors.price}</div>
        ) : null}
      </FormGroup>

      {!vaultData?.isPublished ? (
        <>
          <FormGroup>
            <CustomInput
              type="checkbox"
              id="isPublishNow_editVaultDetails"
              label="Publish Now"
              checked={isPublishNow}
              onChange={(e) => {
                _toggleIsPublishNow(e.target.checked);
              }}
            />
          </FormGroup>

          {/* the below field should be hidden if "Publish Now" is checked/selected */}
          <FormGroup>
            <Label>{getConfiguredString("vault Release Schedule")}</Label>
            {/* Add a date & time selector plugin in place of the input field below */}
            <ReactDatetime
              inputProps={{
                className: "form-control",
                placeholder: "Select release date & time",
                value: formatDateAndTime(formFields.scheduledAt),
                disabled: isPublishNow,
              }}
              value={
                formFields.scheduledAt ? new Date(formFields.scheduledAt) : ""
              }
              onChange={(e) => _onChangeFormfield("scheduledAt", e)}
              isValidDate={(current) => current.isAfter(getYesterdayDate())}
              timeConstraints={{
                minutes: {
                  step: 15,
                },
              }}
              dateFormat={true}
              timeFormat={true}
            />
            {errors?.scheduledAt ? (
              <div className="form-error">{errors?.scheduledAt}</div>
            ) : null}
          </FormGroup>
        </>
      ) : null}

      {canEdit ? (
        <div className="d-flex justify-content-center">
          <Button
            className="cancelBtn m-2"
            onClick={() => _setFormFields(vaultData)}
            disabled={loading}
          >
            Reset
          </Button>

          <Button
            className="themeBtn saveBtn m-2"
            onClick={() => _onSaveVault()}
            disabled={loading}
          >
            {loading ? <i className="fa fa-spinner fa-spin mr-1" /> : null} Save
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default EditVaultDetails;
