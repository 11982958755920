import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormGroup,
  CustomInput,
} from "reactstrap";
import { deepClone, convertDate } from "../../helper-methods";
import moment from "moment";
import SelectDateTimeModal from "./SelectDateTimeModal";

class ExpirationPeriodModal extends Component {
  state = {
    expirationPeriod: 0,
    isOpen: false,
    showDate: "",
    isCustom: false,
    customDays: [0, 1, 3, 7, 30],
  };

  initializeExpiryPeriod = () => {
    this.setState({
      expirationPeriod: 0,
      isOpen: false,
      showDate: "",
      isCustom: false,
      isSave: false,
    });
  };

  _changeExpirationPeriod = (period) => {
    let { isCustom, expirationPeriod } = deepClone(this.state);
    expirationPeriod = period;

    if (period === -1) {
      isCustom = true;
      this.setState({ expirationPeriod, isCustom }, () => {
        this._openDatePicker();
      });
    } else {
      isCustom = false;
      this.setState({ expirationPeriod, isCustom });
    }
  };

  _openDatePicker = () => {
    this.setState({ isOpen: true });
  };

  _saveDate = (data) => {
    let { isOpen, expirationPeriod, showDate, isSave } = deepClone(this.state);
    expirationPeriod = data;
    isOpen = false;
    isSave = true;
    showDate = convertDate(data);
    this.setState({ expirationPeriod, isOpen, showDate, isSave });
  };

  _closeDateModal = () => {
    this.setState({ isOpen: false });
  };

  _closeThisModal = () => {
    const { isSave } = deepClone(this.state);
    if (!isSave) {
      this.setState({ showDate: "", expirationPeriod: 0 });
    }

    this.props.onCancel();
  };

  _saveExpirationPeriod = () => {
    const { expirationPeriod } = deepClone(this.state);
    if (expirationPeriod && typeof expirationPeriod === "number") {
      this.props.onSave(
        false,
        moment()
          .add(Number(expirationPeriod), "day")
          .endOf("day")
          .format("YYYY-MM-DD, h:mm a"),
        expirationPeriod
      );
    } else if (
      expirationPeriod &&
      expirationPeriod.length &&
      typeof expirationPeriod === "string"
    ) {
      this.props.onSave(true, expirationPeriod, null);
    } else {
      this.props.onSave();
    }
  };

  render() {
    const { expirationPeriod, isOpen, isCustom, showDate, customDays } =
      deepClone(this.state);
    return (
      <React.Fragment>
        <Modal
          isOpen={this.props.isOpen}
          toggle={() => this._closeThisModal()}
          className="modal-dialog-centered modal-dialog-scrollable"
        >
          <ModalHeader toggle={() => this._closeThisModal()}>
            Expiration Period
          </ModalHeader>
          <ModalBody>
            <FormGroup check inline className="expirationRadioBtn">
              <CustomInput
                className="form-check-input"
                type="radio"
                id="customRadioInput_expirationPeriodModal_1"
                name="inline-radios"
                onChange={() => this._changeExpirationPeriod(1)}
                checked={expirationPeriod === 1}
                value="1"
                label="1 Day"
              />
            </FormGroup>
            <FormGroup check inline className="expirationRadioBtn">
              <CustomInput
                className="form-check-input"
                type="radio"
                id="customRadioInput_expirationPeriodModal_3"
                name="inline-radios"
                onChange={() => this._changeExpirationPeriod(3)}
                checked={expirationPeriod === 3}
                value="3"
                label="3 Days"
              />
            </FormGroup>
            <FormGroup check inline className="expirationRadioBtn">
              <CustomInput
                type="radio"
                id="customRadioInput_expirationPeriodModal_7"
                name="inline-radios"
                checked={expirationPeriod === 7}
                onChange={() => this._changeExpirationPeriod(7)}
                value="7"
                label="7 Days"
              />
            </FormGroup>
            <FormGroup check inline className="expirationRadioBtn">
              <CustomInput
                type="radio"
                id="customRadioInput_expirationPeriodModal_30"
                name="inline-radios"
                checked={expirationPeriod === 30}
                onChange={() => this._changeExpirationPeriod(30)}
                value="30"
                label="30 Days"
              />
            </FormGroup>
            <FormGroup check inline className="expirationRadioBtn">
              <CustomInput
                type="radio"
                id="customRadioInput_expirationPeriodModal_0"
                name="inline-radios"
                checked={expirationPeriod === 0}
                onChange={() => this._changeExpirationPeriod(0)}
                value="0"
                label="No limit"
              />
            </FormGroup>
            <FormGroup check inline className="expirationRadioBtn">
              <CustomInput
                className="mr-2"
                type="radio"
                id="customRadioInput_expirationPeriodModal_selectDate"
                name="inline-radios"
                checked={!customDays.includes(expirationPeriod)}
                onChange={() => this._changeExpirationPeriod(-1)}
                value="0"
                label="Select Date"
              />

              {isCustom ? (
                <span className="customExpirationDate">{showDate}</span>
              ) : null}
            </FormGroup>

            <SelectDateTimeModal
              isOpen={isOpen}
              onSave={(data) => this._saveDate(data)}
              onCancel={() => this._closeDateModal()}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              className="modalBtnCancel"
              onClick={() => this._closeThisModal()}
            >
              Cancel
            </Button>
            <Button
              className="modalBtnSave"
              onClick={() => this._saveExpirationPeriod()}
            >
              Save
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ExpirationPeriodModal;
