import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Card, CardBody, CardHeader, CardFooter } from "reactstrap";
import { DEFAULT_COVER_PICTURE } from "../config";
import {
  deepClone,
  getCommentIconClassName,
  getLowResolutionLink,
  getPostedDateValue,
  handleNameObj,
  reactHtmlParser,
} from "../helper-methods";
import { hideLoader, showLoader } from "../redux/actions/loaderData";
import { DEFAULT_PROFILE_PICTURE } from "../config/index";
import VisibilitySensor from "react-visibility-sensor";
import ConfigureString from "./ConfigureString";
import ReadMoreReadLess from "./custom/ReadMoreReadLess";
import { getConfiguredString } from "../helper-methods/configureString";

class PublicFeedViewer extends Component {
  state = {
    decodedUserDetails: null,
    custom: [false],
    userData: null,
    tipModal: {
      isVisible: false,
    },
    flagModal: {
      isVisible: false,
    },
    isAudioPlay: false,
  };

  componentDidMount() {
    let { decodedUserDetails } = deepClone(this.state);

    this.setState({ decodedUserDetails });
  }

  _toogleCommentSection = () => {
    this._subscribe();
  };

  _onLikeBtnClick = () => {
    this._subscribe();
  };

  _showTipModal = () => {
    this._subscribe();
  };

  _showFlagModal = () => {
    this._subscribe();
  };

  _subscribe = () => {
    this.props.scrollToTop();
  };

  _onToggleAudioPlayPause = (isAudioPlay = false) => {
    if (!isAudioPlay) {
      if (this.audio) {
        this.audio.pause();
      }
    }
    this.setState({ isAudioPlay });
  };

  _getFirstContent = (content) => {
    if (content?.contentType || content?._content?.contentType) {
      switch (content?.contentType || content?._content?.contentType) {
        case "image": {
          return (
            <img
              className="mediaPhoto-Feed"
              src={content?._content?.url}
              onError={(e) => (e.target.src = DEFAULT_COVER_PICTURE)}
              alt="post Cover Img"
              loading="lazy"
            />
          );
        }
        case "video": {
          return (
            <video
              src={content?._content?.url}
              poster={content?.thumbnail || DEFAULT_COVER_PICTURE}
              controls
              controlsList="nodownload"
              disablePictureInPicture
            />
          );
        }
        case "audio": {
          return (
            <div className="audioWrapper">
              {/* add class audioRippleStart, when play audio */}
              <div
                className={`audioRipple ${
                  this.state.isAudioPlay ? "audioRippleStart" : ""
                }`}
              >
                <img
                  src="/assets/img/microphone.png"
                  alt="Microphone"
                  loading="lazy"
                />
              </div>

              <VisibilitySensor
                onChange={(isVisible) =>
                  !isVisible ? this._onToggleAudioPlayPause(false) : ""
                }
              >
                <audio
                  ref={(audio) => {
                    this.audio = audio;
                  }}
                  controls
                  onPlay={() => this._onToggleAudioPlayPause(true)}
                  onPause={() => this._onToggleAudioPlayPause(false)}
                  controlsList="nodownload"
                  src={content?._content?.url}
                />
              </VisibilitySensor>
            </div>
          );
        }
        default: {
          return (
            <img
              className="mediaPhoto-Feed"
              src={DEFAULT_COVER_PICTURE}
              alt="cover-img"
              loading="lazy"
            />
          );
        }
      }
    }
    return (
      <img
        className="mediaPhoto-Feed"
        src={DEFAULT_COVER_PICTURE}
        alt="cover-img"
        loading="lazy"
      />
    );
  };

  _renderPost = () => {
    let { feed: post } = deepClone(this.props);

    let isPublic = false,
      isLocked = true;

    if (post.category && post.category === "general") {
      if (this.props.feed?.hasOwnProperty("_folder")) {
        return (
          <>
            <Card className="cardFeed">
              <CardHeader>
                <div className="d-flex justify-content-start">
                  <div className="userImgWrap">
                    <img
                      src={
                        getLowResolutionLink(
                          post._influencer?.profilePicture
                        ) || DEFAULT_PROFILE_PICTURE
                      }
                      onError={(e) => (e.target.src = DEFAULT_PROFILE_PICTURE)}
                      alt="Profile Img"
                      className="userImg"
                      loading="lazy"
                    />
                  </div>
                  <div>
                    <h4>{handleNameObj(post._influencer?.name)}</h4>
                    <Button color="link" className="profileID">
                      @{post._influencer?.username || "N/A"}
                    </Button>
                  </div>
                </div>

                <div className="d-flex flex-column align-items-end">
                  <p>
                    <ConfigureString keyString={"vault Post"} />
                  </p>
                  {/* post time */}
                  <div className="postTime-Feed">
                    {getPostedDateValue(post.startDate)}
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                {post?.text?.length ? (
                  <div className="customPaddingMob">
                    <ReadMoreReadLess
                      className="readMore"
                      text={reactHtmlParser(post.text)}
                    />
                  </div>
                ) : null}

                <div className={"mediaPhotoWrap-Feed postLocked"}>
                  <img
                    className="mediaPhoto-Feed"
                    src={post._folder?.preview || DEFAULT_COVER_PICTURE}
                    alt="Feed Img"
                    loading="lazy"
                  />
                  <div className="postLockedOverlay">
                    <img
                      src={"/assets/img/lock-icon.png"}
                      alt="Lock Icon"
                      className="iconLock"
                      loading="lazy"
                    />

                    <div style={{ width: 210, marginBottom: 1 }}>
                      <img
                        src={"/assets/img/business.png"}
                        alt="Folder Icon"
                        className="imgMedia"
                        loading="lazy"
                      />
                    </div>

                    <Button
                      className="themeBtn makePaymentBtn"
                      disabled={
                        post._influencer && !post._influencer.paymentEnabled
                      }
                      onClick={() => this._subscribe()}
                    >
                      Subscribe
                    </Button>
                  </div>{" "}
                </div>
              </CardBody>
              <CardFooter className="footerFeed">
                <Button
                  color="ghost-secondary"
                  onClick={() => this._onLikeBtnClick()}
                >
                  <i className={"fa fa-heart-o"} />
                  <span>{post.likes || 0}</span>
                </Button>

                <Button
                  color="ghost-secondary"
                  onClick={() => this._toogleCommentSection()}
                >
                  <i className={getCommentIconClassName(post)} />{" "}
                  <span>{post.comments || 0}</span>
                </Button>
                <Button color="ghost-secondary" onClick={this._showTipModal}>
                  <i className="fa fa-dollar" />
                </Button>
                <Button color="ghost-secondary" onClick={this._showFlagModal}>
                  <i className="fa fa-flag-o" />
                </Button>
              </CardFooter>
            </Card>
          </>
        );
      } else if (this.props.feed?.hasOwnProperty("_event")) {
        return (
          <>
            <Card className="cardFeed">
              <CardHeader>
                <div className="d-flex justify-content-start">
                  <div className="userImgWrap">
                    <img
                      src={
                        getLowResolutionLink(
                          post._influencer?.profilePicture
                        ) || DEFAULT_PROFILE_PICTURE
                      }
                      onError={(e) => (e.target.src = DEFAULT_PROFILE_PICTURE)}
                      alt="Profile Img"
                      className="userImg"
                      loading="lazy"
                    />
                  </div>
                  <div>
                    <h4>{handleNameObj(post._influencer?.name)}</h4>
                    <Button color="link" className="profileID">
                      @{post._influencer?.username || "N/A"}
                    </Button>
                  </div>
                </div>

                <div className="d-flex flex-column align-items-end">
                  <p>
                    <ConfigureString keyString={"vault Post"} />
                  </p>
                  {/* post time */}
                  <div className="postTime-Feed">
                    {getPostedDateValue(post.startDate)}
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                {post?.text?.length ? (
                  <div className="customPaddingMob">
                    <ReadMoreReadLess
                      className="readMore"
                      text={reactHtmlParser(post.text)}
                    />
                  </div>
                ) : null}

                <div className={"mediaPhotoWrap-Feed postLocked"}>
                  <img
                    className="mediaPhoto-Feed"
                    src={post._event?.coverImage || DEFAULT_COVER_PICTURE}
                    alt="Feed Img"
                    loading="lazy"
                  />
                  <div className="postLockedOverlay">
                    <img
                      src={"/assets/img/lock-icon.png"}
                      alt="Lock Icon"
                      className="iconLock"
                      loading="lazy"
                    />

                    <div style={{ width: 210, marginBottom: 1 }}>
                      <img
                        src={"/assets/img/business.png"}
                        alt="Folder Icon"
                        className="imgMedia"
                        loading="lazy"
                      />
                    </div>

                    <Button
                      className="themeBtn makePaymentBtn"
                      disabled={
                        post._influencer && !post._influencer.paymentEnabled
                      }
                      onClick={() => this._subscribe()}
                    >
                      Subscribe
                    </Button>
                  </div>{" "}
                </div>
              </CardBody>
              <CardFooter className="footerFeed">
                <Button
                  color="ghost-secondary"
                  onClick={() => this._onLikeBtnClick()}
                >
                  <i className={"fa fa-heart-o"} />
                  <span>{post.likes || 0}</span>
                </Button>

                <Button
                  color="ghost-secondary"
                  onClick={() => this._toogleCommentSection()}
                >
                  <i className={getCommentIconClassName(post)} />{" "}
                  <span>{post.comments || 0}</span>
                </Button>
                <Button color="ghost-secondary" onClick={this._showTipModal}>
                  <i className="fa fa-dollar" />
                </Button>
                <Button color="ghost-secondary" onClick={this._showFlagModal}>
                  <i className="fa fa-flag-o" />
                </Button>
              </CardFooter>
            </Card>
          </>
        );
      } else if (this.props.feed?.hasOwnProperty("_payperview")) {
        return (
          <>
            <Card className="cardFeed">
              <CardHeader>
                <div className="d-flex justify-content-start">
                  <div className="userImgWrap">
                    <img
                      src={
                        getLowResolutionLink(
                          post._influencer?.profilePicture
                        ) || DEFAULT_PROFILE_PICTURE
                      }
                      onError={(e) => (e.target.src = DEFAULT_PROFILE_PICTURE)}
                      alt="Profile Img"
                      className="userImg"
                    />
                  </div>
                  <div>
                    <h4>{handleNameObj(post._influencer?.name)}</h4>
                    <Button color="link" className="profileID">
                      @{post._influencer?.username || "N/A"}
                    </Button>
                  </div>
                </div>

                <div className="d-flex flex-column align-items-end">
                  <p>{getConfiguredString("PPV")}</p>
                  {/* post time */}
                  <div className="postTime-Feed">
                    {getPostedDateValue(post.startDate)}
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                {post?.text?.length ? (
                  <div className="customPaddingMob">
                    <ReadMoreReadLess
                      className="readMore"
                      text={reactHtmlParser(post.text)}
                    />
                  </div>
                ) : null}

                <div className={"mediaPhotoWrap-Feed postLocked"}>
                  <img
                    className="mediaPhoto-Feed"
                    src={post._payperview?.coverImage || DEFAULT_COVER_PICTURE}
                    alt="Feed Img"
                    loading="lazy"
                  />
                  <div className="postLockedOverlay">
                    <img
                      src={"/assets/img/lock-icon.png"}
                      alt="Lock Icon"
                      className="iconLock"
                      loading="lazy"
                    />

                    <div style={{ width: 210, marginBottom: 1 }}>
                      <img
                        src={"/assets/img/business.png"}
                        alt="Folder Icon"
                        className="imgMedia"
                        loading="lazy"
                      />
                    </div>

                    <Button
                      className="themeBtn makePaymentBtn"
                      disabled={
                        post._influencer && !post._influencer.paymentEnabled
                      }
                      onClick={() => this._subscribe()}
                    >
                      Subscribe
                    </Button>
                  </div>{" "}
                </div>
              </CardBody>
              <CardFooter className="footerFeed">
                <Button
                  color="ghost-secondary"
                  onClick={() => this._onLikeBtnClick()}
                >
                  <i className={"fa fa-heart-o"} />
                  <span>{post.likes || 0}</span>
                </Button>

                <Button
                  color="ghost-secondary"
                  onClick={() => this._toogleCommentSection()}
                >
                  <i className={getCommentIconClassName(post)} />{" "}
                  <span>{post.comments || 0}</span>
                </Button>
                <Button color="ghost-secondary" onClick={this._showTipModal}>
                  <i className="fa fa-dollar" />
                </Button>
                <Button color="ghost-secondary" onClick={this._showFlagModal}>
                  <i className="fa fa-flag-o" />
                </Button>
              </CardFooter>
            </Card>
          </>
        );
      } else if (post._contents && post._contents.length > 1) {
        return (
          <>
            <Card className="cardFeed">
              <CardHeader>
                <div className="d-flex justify-content-start">
                  <div className="userImgWrap">
                    <img
                      src={
                        getLowResolutionLink(
                          post._influencer?.profilePicture
                        ) || DEFAULT_PROFILE_PICTURE
                      }
                      onError={(e) => (e.target.src = DEFAULT_PROFILE_PICTURE)}
                      alt="Profile Img"
                      className="userImg"
                      loading="lazy"
                    />
                  </div>
                  <div>
                    <h4>{handleNameObj(post._influencer?.name)}</h4>
                    <Button color="link" className="profileID">
                      @{post._influencer?.username || "N/A"}
                    </Button>
                  </div>
                </div>
                {/* post time */}
                <div className="postTime-Feed">
                  {getPostedDateValue(post.startDate)}
                </div>
              </CardHeader>
              <CardBody>
                {post?.text?.length ? (
                  <div className="customPaddingMob">
                    <ReadMoreReadLess
                      className="readMore"
                      text={reactHtmlParser(post.text)}
                    />
                  </div>
                ) : null}

                <div className={"mediaPhotoWrap-Feed postLocked"}>
                  {post?.isPublic && post?._contents?.length ? (
                    this._getFirstContent(post?._contents?.[0])
                  ) : (
                    <img
                      className="mediaPhoto-Feed"
                      src={DEFAULT_COVER_PICTURE}
                      alt="Cover Img"
                      loading="lazy"
                    />
                  )}

                  <div className="postLockedOverlay">
                    <img
                      src={"/assets/img/lock-icon.png"}
                      alt="Lock Icon"
                      className="iconLock"
                      loading="lazy"
                    />

                    <div style={{ width: 210, marginBottom: -3 }}>
                      <i className="icon-picture iconMedia" />
                    </div>

                    <Button
                      className="themeBtn makePaymentBtn"
                      disabled={
                        post._influencer && !post._influencer.paymentEnabled
                      }
                      onClick={() => this._subscribe()}
                    >
                      Subscribe
                    </Button>
                  </div>
                </div>
              </CardBody>
              <CardFooter className="footerFeed">
                <Button
                  color="ghost-secondary"
                  onClick={() => this._onLikeBtnClick()}
                >
                  <i className={"fa fa-heart-o"} />
                  <span>{post.likes || 0}</span>
                </Button>

                <Button
                  color="ghost-secondary"
                  onClick={() => this._toogleCommentSection()}
                >
                  <i className={getCommentIconClassName(post)} />{" "}
                  <span>{post.comments || 0}</span>
                </Button>
                <Button color="ghost-secondary" onClick={this._showTipModal}>
                  <i className="fa fa-dollar" />
                </Button>
                <Button color="ghost-secondary" onClick={this._showFlagModal}>
                  <i className="fa fa-flag-o" />
                </Button>
              </CardFooter>
            </Card>
          </>
        );
      } else if (
        post._contents &&
        post._contents.length === 1 &&
        !post._contents[0].hasOwnProperty("_bsontype")
      ) {
        if (post._contents[0].contentType === "image") {
          // contains only one image
          return (
            <>
              <Card className="cardFeed">
                <CardHeader>
                  <div className="d-flex justify-content-start">
                    <div className="userImgWrap">
                      <img
                        src={
                          getLowResolutionLink(
                            post._influencer?.profilePicture
                          ) || DEFAULT_PROFILE_PICTURE
                        }
                        onError={(e) =>
                          (e.target.src = DEFAULT_PROFILE_PICTURE)
                        }
                        alt="Profile Img"
                        className="userImg"
                        loading="lazy"
                      />
                    </div>
                    <div>
                      <h4>{handleNameObj(post._influencer?.name)}</h4>
                      <Button color="link" className="profileID">
                        @{post._influencer?.username || "N/A"}
                      </Button>
                    </div>
                  </div>
                  {/* post time */}
                  <div className="postTime-Feed">
                    {getPostedDateValue(post.startDate)}
                  </div>
                </CardHeader>
                <CardBody>
                  {post?.text?.length ? (
                    <div className="customPaddingMob">
                      <ReadMoreReadLess
                        className="readMore"
                        text={reactHtmlParser(post.text)}
                      />
                    </div>
                  ) : null}

                  <div className={"mediaPhotoWrap-Feed postLocked"}>
                    {post?.isPublic && post?._contents?.length ? (
                      this._getFirstContent(post?._contents?.[0])
                    ) : (
                      <img
                        className="mediaPhoto-Feed"
                        src={DEFAULT_COVER_PICTURE}
                        alt="Cover Img"
                        loading="lazy"
                      />
                    )}
                    <div className="postLockedOverlay">
                      <img
                        src={"/assets/img/lock-icon.png"}
                        alt="Lock Icon"
                        className="iconLock"
                        loading="lazy"
                      />

                      <div style={{ width: 210, marginBottom: -3 }}>
                        <i className="icon-picture iconMedia" />
                      </div>

                      <Button
                        className="themeBtn makePaymentBtn"
                        disabled={
                          post._influencer && !post._influencer.paymentEnabled
                        }
                        onClick={() => this._subscribe()}
                      >
                        Subscribe
                      </Button>
                    </div>{" "}
                  </div>
                </CardBody>
                <CardFooter className="footerFeed">
                  <Button
                    color="ghost-secondary"
                    onClick={() => this._onLikeBtnClick()}
                  >
                    <i className={"fa fa-heart-o"} />
                    <span>{post.likes || 0}</span>
                  </Button>

                  <Button
                    color="ghost-secondary"
                    onClick={() => this._toogleCommentSection()}
                  >
                    <i className={getCommentIconClassName(post)} />{" "}
                    <span>{post.comments || 0}</span>
                  </Button>
                  <Button color="ghost-secondary" onClick={this._showTipModal}>
                    <i className="fa fa-dollar" />
                  </Button>
                  <Button color="ghost-secondary" onClick={this._showFlagModal}>
                    <i className="fa fa-flag-o" />
                  </Button>
                </CardFooter>
              </Card>
            </>
          );
        } else if (
          post._contents[0].contentType === "audio" &&
          !post._contents[0].hasOwnProperty("_bsontype")
        ) {
          // contains only one audio file
          return (
            <>
              <Card className="cardFeed">
                <CardHeader>
                  <div className="d-flex justify-content-start">
                    <div className="userImgWrap">
                      <img
                        src={
                          getLowResolutionLink(
                            post._influencer?.profilePicture
                          ) || DEFAULT_PROFILE_PICTURE
                        }
                        onError={(e) =>
                          (e.target.src = DEFAULT_PROFILE_PICTURE)
                        }
                        alt="Profile Img"
                        className="userImg"
                        loading="lazy"
                      />
                    </div>
                    <div>
                      <h4>{handleNameObj(post._influencer?.name)}</h4>
                      <Button color="link" className="profileID">
                        @{post._influencer?.username || "N/A"}
                      </Button>
                    </div>
                  </div>
                  {/* post time */}
                  <div className="postTime-Feed">
                    {getPostedDateValue(post.startDate)}
                  </div>
                </CardHeader>
                <CardBody>
                  {post?.text?.length ? (
                    <div className="customPaddingMob">
                      <ReadMoreReadLess
                        className="readMore"
                        text={reactHtmlParser(post.text)}
                      />
                    </div>
                  ) : null}
                  <div className={"mediaPhotoWrap-Feed postLocked"}>
                    {post?.isPublic && post?._contents?.length ? (
                      this._getFirstContent(post?._contents?.[0])
                    ) : (
                      <img
                        className="mediaPhoto-Feed"
                        src={DEFAULT_COVER_PICTURE}
                        alt="Cover Img"
                        loading="lazy"
                      />
                    )}

                    <div className="postLockedOverlay">
                      <img
                        src={"/assets/img/lock-icon.png"}
                        alt="Lock Icon"
                        className="iconLock"
                        loading="lazy"
                      />

                      <div style={{ width: 210, marginBottom: -3 }}>
                        <i className="icon-microphone iconMedia" />
                      </div>

                      <Button
                        className="themeBtn makePaymentBtn"
                        disabled={
                          post._influencer && !post._influencer.paymentEnabled
                        }
                        onClick={() => this._subscribe()}
                      >
                        Subscribe
                      </Button>
                    </div>
                  </div>
                </CardBody>
                <CardFooter className="footerFeed">
                  <Button
                    color="ghost-secondary"
                    onClick={() => this._onLikeBtnClick()}
                  >
                    <i className={"fa fa-heart-o"} />
                    <span>{post.likes || 0}</span>
                  </Button>

                  <Button
                    color="ghost-secondary"
                    onClick={() => this._toogleCommentSection()}
                  >
                    <i className={getCommentIconClassName(post)} />{" "}
                    <span>{post.comments || 0}</span>
                  </Button>
                  <Button color="ghost-secondary" onClick={this._showTipModal}>
                    <i className="fa fa-dollar" />
                  </Button>
                  <Button color="ghost-secondary" onClick={this._showFlagModal}>
                    <i className="fa fa-flag-o" aria-hidden="true" />
                  </Button>
                </CardFooter>
              </Card>
            </>
          );
        } else if (
          post._contents[0].contentType === "video" &&
          !post._contents[0].hasOwnProperty("_bsontype")
        ) {
          // contains only one video
          return (
            <>
              <Card className="cardFeed">
                <CardHeader>
                  <div className="d-flex justify-content-start">
                    <div className="userImgWrap">
                      <img
                        src={
                          getLowResolutionLink(
                            post._influencer?.profilePicture
                          ) || DEFAULT_PROFILE_PICTURE
                        }
                        onError={(e) =>
                          (e.target.src = DEFAULT_PROFILE_PICTURE)
                        }
                        alt="Profile Img"
                        className="userImg"
                        loading="lazy"
                      />
                    </div>
                    <div>
                      <h4>{handleNameObj(post._influencer?.name)}</h4>
                      <Button color="link" className="profileID">
                        @{post._influencer?.username || "N/A"}
                      </Button>
                    </div>
                  </div>
                  {/* post time */}
                  <div className="postTime-Feed">
                    {getPostedDateValue(post.startDate)}
                  </div>
                </CardHeader>
                <CardBody>
                  {post?.text?.length ? (
                    <div className="customPaddingMob">
                      <ReadMoreReadLess
                        className="readMore"
                        text={reactHtmlParser(post.text)}
                      />
                    </div>
                  ) : null}

                  <div
                    className={
                      "embed-responsive embed-responsive-16by9 mediaVideoWrap-Feed " +
                      (!isPublic && isLocked ? " postLocked" : "")
                    }
                  >
                    {post?.isPublic && post?._contents?.length ? (
                      this._getFirstContent(post?._contents?.[0])
                    ) : (
                      <img
                        className="mediaPhoto-Feed"
                        src={DEFAULT_COVER_PICTURE}
                        alt="Cover Img"
                        loading="lazy"
                      />
                    )}

                    <div className="postLockedOverlay">
                      <img
                        src={"/assets/img/lock-icon.png"}
                        alt="Lock Icon"
                        className="iconLock"
                        loading="lazy"
                      />

                      <div style={{ width: 210, marginBottom: -3 }}>
                        <i className="fa fa-video-camera iconMedia" />
                      </div>

                      <Button
                        className="themeBtn makePaymentBtn"
                        disabled={
                          post._influencer && !post._influencer.paymentEnabled
                        }
                        onClick={() => this._subscribe()}
                      >
                        Subscirbe
                      </Button>
                    </div>
                  </div>
                </CardBody>
                <CardFooter className="footerFeed">
                  <Button
                    color="ghost-secondary"
                    onClick={() => this._onLikeBtnClick()}
                  >
                    <i className={"fa fa-heart-o"} />
                    <span>{post.likes || 0}</span>
                  </Button>

                  <Button
                    color="ghost-secondary"
                    onClick={() => this._toogleCommentSection()}
                  >
                    <i className={getCommentIconClassName(post)} />{" "}
                    <span>{post.comments || 0}</span>
                  </Button>
                  <Button color="ghost-secondary" onClick={this._showTipModal}>
                    <i className="fa fa-dollar" />
                  </Button>
                  <Button color="ghost-secondary" onClick={this._showFlagModal}>
                    <i className="fa fa-flag-o" />
                  </Button>
                </CardFooter>
              </Card>
            </>
          );
        }
      } else if (post._contents && post._contents.length === 0) {
        // text only post
        return (
          <>
            <Card className="cardFeed">
              <CardHeader>
                <div className="d-flex justify-content-start">
                  <div className="userImgWrap">
                    <img
                      src={
                        getLowResolutionLink(
                          post._influencer?.profilePicture
                        ) || DEFAULT_PROFILE_PICTURE
                      }
                      onError={(e) => (e.target.src = DEFAULT_PROFILE_PICTURE)}
                      alt="Profile Img"
                      className="userImg"
                      loading="lazy"
                    />
                  </div>
                  <div>
                    <h4>{handleNameObj(post._influencer?.name)}</h4>
                    <Button color="link" className="profileID">
                      @{post._influencer?.username || "N/A"}
                    </Button>
                  </div>
                </div>
                {/* post time */}
                <div className="postTime-Feed">
                  {getPostedDateValue(post.startDate)}
                </div>
              </CardHeader>
              <CardBody>
                {post?.text?.length ? (
                  <div className="customPaddingMob">
                    <ReadMoreReadLess
                      className="readMore"
                      text={reactHtmlParser(post.text)}
                    />
                  </div>
                ) : null}
              </CardBody>
              <CardFooter className="footerFeed">
                <Button
                  color="ghost-secondary"
                  onClick={() => this._onLikeBtnClick()}
                >
                  <i className={"fa fa-heart-o"} />
                  <span>{post.likes || 0}</span>
                </Button>

                <Button
                  color="ghost-secondary"
                  onClick={() => this._toogleCommentSection()}
                >
                  <i className={getCommentIconClassName(post)} />{" "}
                  <span>{post.comments || 0}</span>
                </Button>
                <Button color="ghost-secondary" onClick={this._showTipModal}>
                  <i className="fa fa-dollar" />
                </Button>
                <Button color="ghost-secondary" onClick={this._showFlagModal}>
                  <i className="fa fa-flag-o" />
                </Button>
              </CardFooter>
            </Card>
          </>
        );
      }
    } else if (post.category && post.category === "poll") {
      return (
        <>
          <Card className="cardFeed">
            <CardHeader>
              <div className="d-flex justify-content-start">
                <div className="userImgWrap">
                  <img
                    src={
                      getLowResolutionLink(post._influencer?.profilePicture) ||
                      DEFAULT_PROFILE_PICTURE
                    }
                    onError={(e) => (e.target.src = DEFAULT_PROFILE_PICTURE)}
                    alt="Profile Img"
                    className="userImg"
                    loading="lazy"
                  />
                </div>
                <div>
                  <h4>{handleNameObj(post._influencer?.name)}</h4>
                  <Button color="link" className="profileID">
                    @{post._influencer?.username || "N/A"}
                  </Button>
                </div>
              </div>
              {/* post time */}
              <div className="postTime-Feed">
                {getPostedDateValue(post.startDate)}
              </div>
            </CardHeader>
            <CardBody>
              <div className="customPaddingMob">
                {post?.description?.length ? (
                  <>
                    <div className="customPaddingMob">
                      <ReadMoreReadLess
                        className="readMore"
                        text={reactHtmlParser(post.description)}
                      />
                    </div>
                    <br />
                  </>
                ) : null}

                {post?.text?.length ? (
                  <div className="customPaddingMob">
                    <ReadMoreReadLess
                      className="readMore"
                      text={reactHtmlParser(post.text)}
                    />
                  </div>
                ) : null}
              </div>

              {/* poll options should always be wrapped inside the below div */}
              <div
                className="postLockedOverlay position-relative"
                style={{ height: 375 }}
              >
                <img
                  src={"/assets/img/lock-icon.png"}
                  alt="Lock Icon"
                  className="iconLock"
                  loading="lazy"
                />

                <div style={{ width: 210, marginBottom: -3 }}>
                  <i className="icon-picture iconMedia" />
                </div>

                <Button
                  className="themeBtn makePaymentBtn"
                  disabled={
                    post._influencer && !post._influencer.paymentEnabled
                  }
                  onClick={() => this._subscribe()}
                >
                  Subscribe
                </Button>
              </div>
            </CardBody>
            <CardFooter className="footerFeed">
              <Button
                color="ghost-secondary"
                onClick={() => this._onLikeBtnClick()}
              >
                <i className={"fa fa-heart-o"} />
                <span>{post.likes || 0}</span>
              </Button>

              <Button
                color="ghost-secondary"
                onClick={() => this._toogleCommentSection()}
              >
                <i className={getCommentIconClassName(post)} />{" "}
                <span>{post.comments || 0}</span>
              </Button>
              <Button color="ghost-secondary" onClick={this._showFlagModal}>
                <i className="fa fa-flag-o" />
              </Button>
            </CardFooter>
          </Card>
        </>
      );
    }
    return <div />;
  };

  _defaultSelectedPollAnswer = (option) => {
    const { feed } = this.props;
    if (feed && feed._opinions && feed._opinions.length) {
      if (feed._opinions[0].option === option._id) {
        return true;
      }
    }
    return false;
  };

  render() {
    return <>{this._renderPost()}</>;
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PublicFeedViewer);
