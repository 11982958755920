import React from "react";
import { Alert } from "reactstrap";
import { APP_LOGO } from "../../config";
import ReadMoreReadLess from "./ReadMoreReadLess";

const CustomPushNotification = ({
  title,
  description,
  onDismiss = () => {},
}) => {
  const _onDismiss = () => {
    onDismiss();
  };

  if (!title || !description) return <></>;

  return (
    <Alert className="customPushNotification" isOpen={true} toggle={_onDismiss}>
      <img src={APP_LOGO} alt="logo" />

      <div>
        <h4 className="alert-heading">{title}</h4>
        {/* <p>{description}</p> */}
        <ReadMoreReadLess text={description} count={120} />
      </div>
    </Alert>
  );
};

export default CustomPushNotification;
