import React, { useEffect, useMemo, useState } from "react";
import { Button, Table, CustomInput } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllBundles,
  getAllOffers,
  updateInfluencerDetails,
  updateOffer,
} from "../../http-calls/index";
import TierComponent from "../TierComponent";
import {
  showToast,
  capitalize,
  formatDate,
  errorHandler,
} from "../../helper-methods";
import CustomPrompt from "../modals/CustomPrompt";
import { isPermissionToAccess } from "../../helper-methods";
import { updateOnlyUserData } from "../../redux/actions";
import AddEditOfferModal from "../modals/AddEditOfferModal";
import CustomLoader from "../../components/custom/CustomLoader";

const SettingsSubscription = () => {
  const influencer = useSelector((state) => state?.userData?.user);
  const dispatch = useDispatch();

  const [bundles, setBundles] = useState([]);
  const [offers, setOffers] = useState([]);
  const [offerModal, setOfferModal] = useState({
    isOpen: false,
    data: null,
  });
  const [promptModal, setPromptModal] = useState({
    isOpen: false,
    data: "",
  });
  const [loadingState, setLoadingState] = useState({
    bundleData: false,
    offerData: false,
  });
  const [isFreeSubAllowUpdating, setIsFreeSubAllowUpdating] = useState(false);

  const canUpdateSubscriptionSettings = useMemo(() => {
    return isPermissionToAccess(
      "profileAndSettings",
      "canUpdateSubscriptionSettings",
      true
    );
  }, []);

  const _manageLoadingState = (key = "", value = false) => {
    setLoadingState((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const _toggleOfferModal = (isOpen = false, data = {}) => {
    setOfferModal({ isOpen, data });
  };

  const _togglePromptModal = (isOpen = false, data = null) => {
    setPromptModal({ isOpen, data });
  };

  const _udpateInfluencerDetails = (payload) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await updateInfluencerDetails(payload);
        dispatch(updateOnlyUserData(res.user));
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };

  const _toggleFreeSubscription = async (value) => {
    try {
      setIsFreeSubAllowUpdating(true);
      await _udpateInfluencerDetails({ isFreeSubscription: value });
      setIsFreeSubAllowUpdating(false);
    } catch (error) {
      setIsFreeSubAllowUpdating(false);
      errorHandler(error);
    }
  };

  const _toggleOfferStatus = async (data) => {
    try {
      const payload = {
        isActive: !data?.isActive,
      };

      const newOffers = [...offers];
      const findOffer = newOffers.find((each) => each?._id === data?._id);
      if (findOffer) {
        findOffer.isActive = payload.isActive;
        setOffers(newOffers);
      }

      await updateOffer(data._id, payload);

      _getAllOffers();

      showToast("Offer has been updated", "success");
    } catch (error) {
      errorHandler(error);
      _getAllOffers();
    }
  };

  const _deleteOffer = async (data) => {
    try {
      _togglePromptModal();

      const payload = {
        isDeleted: true,
      };

      const newOffers = [...offers];
      const findOfferIndex = newOffers.findIndex(
        (each) => each?._id === data?._id
      );
      if (findOfferIndex >= 0) {
        newOffers.splice(findOfferIndex, 1);
        setOffers(newOffers);
      }

      await updateOffer(data._id, payload);

      _getAllOffers();

      showToast("Offer has been deleted", "success");

      _togglePromptModal();
    } catch (error) {
      errorHandler(error);
      _getAllOffers();
    }
  };

  const _getAllbundles = async () => {
    try {
      _manageLoadingState("bundleData", true);

      const res = await getAllBundles();

      setBundles(res?.bundles?.length ? res.bundles : []);

      _manageLoadingState("bundleData", false);
    } catch (error) {
      _manageLoadingState("bundleData", false);
      errorHandler(error);
    }
  };

  const _getAllOffers = async () => {
    try {
      const res = await getAllOffers();
      setOffers(res?.offers?.length ? res.offers : []);
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    _getAllbundles();
    _getAllOffers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="settingOptionsWrap">
        {!influencer?.isScheduleForAccountDelete &&
        influencer?.isFreeSubscriptionsAllow ? (
          <div className="header_Settings mb-4">
            <h4>Allow Free Subscription</h4>

            <CustomInput
              type="switch"
              className="customSwitch freeSubscriptionSwitch"
              id="freeSubscriptionSwitch"
              disabled={isFreeSubAllowUpdating}
              checked={influencer?.isFreeSubscription || false}
              onChange={(e) => _toggleFreeSubscription(e.target.checked)}
            />
          </div>
        ) : null}

        <div className="header_Settings">
          <h4>
            {influencer?.multiTierSubscription ? "Tiers" : "Subscription"}
          </h4>
        </div>

        {influencer?.multiTierSubscription ? (
          bundles?.length ? (
            bundles.map((bundle, index) => (
              <TierComponent
                key={index}
                bundles={bundles}
                bundle={bundle}
                onSave={() => _getAllbundles()}
              />
            ))
          ) : loadingState?.bundleData ? (
            <div className="d-flex justify-content-center">
              <CustomLoader />
            </div>
          ) : null
        ) : bundles?.length ? (
          bundles.map((bundle, index) => {
            if (bundle?.tier === "basic") {
              return (
                <TierComponent
                  key={index}
                  bundles={bundles}
                  bundle={bundle}
                  onSave={() => _getAllbundles()}
                />
              );
            }
            return null;
          })
        ) : loadingState?.bundleData ? (
          <div className="d-flex justify-content-center">
            <CustomLoader />
          </div>
        ) : null}

        <div
          className="header_Settings"
          style={{ marginTop: 35, marginBottom: 15 }}
        >
          <h4>Offers</h4>

          {canUpdateSubscriptionSettings ? (
            <Button
              className="themeBtn addBtn"
              onClick={() => _toggleOfferModal(true)}
            >
              Add
            </Button>
          ) : null}
        </div>

        <Table responsive className="subscriptionTable">
          <thead>
            <tr>
              {influencer?.multiTierSubscription && <th>Tier</th>}
              <th>Start & End Date</th>
              <th>No. of Sales</th>
              <th>Cost</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>
            {offers?.length ? (
              offers.map((each, index) => (
                <tr key={index}>
                  {influencer?.multiTierSubscription && (
                    <td>{capitalize(each.tier) || ""}</td>
                  )}
                  {each.start && each.end ? (
                    <td>
                      <span>{formatDate(each.start)}</span>
                      {" - "}
                      <span>{formatDate(each.end)}</span>
                    </td>
                  ) : (
                    <td>{formatDate(each.start)}</td>
                  )}
                  <td>{each.salesLimit ? each.salesLimit : 0}</td>
                  <td>{each.price ? each.price : 0}</td>
                  <td>
                    <div className="d-flex">
                      <Button
                        color="link"
                        size="sm"
                        disabled={!canUpdateSubscriptionSettings}
                        onClick={() => _toggleOfferModal(true, each)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="255.999"
                          height="256"
                          viewBox="0 0 255.999 256"
                        >
                          <g
                            id="edit_2985043"
                            transform="translate(-29.627 -29.709)"
                          >
                            <g id="Group_48" data-name="Group 48">
                              <path
                                id="Path_66"
                                data-name="Path 66"
                                d="M54.7,214.759a9.852,9.852,0,0,1-6.965-16.817L201.7,43.984a9.851,9.851,0,0,1,13.931,13.931L61.669,211.873A9.825,9.825,0,0,1,54.7,214.759Z"
                              />
                            </g>
                            <g id="Group_49" data-name="Group 49">
                              <path
                                id="Path_67"
                                data-name="Path 67"
                                d="M39.492,285.709a9.966,9.966,0,0,1-2.074-.222,9.852,9.852,0,0,1-7.566-11.7l15.221-70.949a9.852,9.852,0,0,1,19.266,4.133L49.117,277.921a9.854,9.854,0,0,1-9.625,7.788Z"
                              />
                            </g>
                            <g id="Group_50" data-name="Group 50">
                              <path
                                id="Path_68"
                                data-name="Path 68"
                                d="M110.431,270.487a9.852,9.852,0,0,1-6.965-16.817L257.424,99.717a9.851,9.851,0,1,1,13.931,13.931L117.4,267.6a9.824,9.824,0,0,1-6.97,2.887Z"
                              />
                            </g>
                            <g id="Group_51" data-name="Group 51">
                              <path
                                id="Path_69"
                                data-name="Path 69"
                                d="M39.472,285.709a9.853,9.853,0,0,1-2.059-19.487L108.362,251a9.852,9.852,0,1,1,4.133,19.266L41.546,285.487a9.643,9.643,0,0,1-2.074.222Z"
                              />
                            </g>
                            <g id="Group_52" data-name="Group 52">
                              <path
                                id="Path_70"
                                data-name="Path 70"
                                d="M236.523,144.4a9.8,9.8,0,0,1-6.965-2.887L173.829,85.782A9.851,9.851,0,0,1,187.76,71.851l55.728,55.728a9.852,9.852,0,0,1-6.965,16.817Z"
                              />
                            </g>
                            <g id="Group_53" data-name="Group 53">
                              <path
                                id="Path_71"
                                data-name="Path 71"
                                d="M264.389,116.535a9.854,9.854,0,0,1-6.97-16.822,29.554,29.554,0,0,0-41.8-41.792,9.854,9.854,0,0,1-13.936-13.936,49.26,49.26,0,0,1,69.664,69.664,9.805,9.805,0,0,1-6.96,2.887Z"
                              />
                            </g>
                          </g>
                        </svg>
                      </Button>
                      <Button
                        color="link"
                        size="sm"
                        disabled={!canUpdateSubscriptionSettings}
                        onClick={() => _togglePromptModal(true, each)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="225"
                          height="256"
                          viewBox="0 0 225 256"
                        >
                          <g id="bin_484662" transform="translate(-15.5)">
                            <g id="Group_35" data-name="Group 35">
                              <g id="Group_34" data-name="Group 34">
                                <path
                                  id="Path_60"
                                  data-name="Path 60"
                                  d="M218,30H180.5V22.5A22.526,22.526,0,0,0,158,0H98A22.526,22.526,0,0,0,75.5,22.5V30H38a22.5,22.5,0,0,0-7,43.881L44.38,235.368A22.632,22.632,0,0,0,66.8,256H189.2a22.633,22.633,0,0,0,22.423-20.635L225,73.88A22.5,22.5,0,0,0,218,30ZM90.5,22.5A7.509,7.509,0,0,1,98,15h60a7.509,7.509,0,0,1,7.5,7.5V30h-75ZM196.672,234.123A7.544,7.544,0,0,1,189.2,241H66.8a7.543,7.543,0,0,1-7.473-6.874L46.147,75H209.853ZM218,60H38a7.5,7.5,0,0,1,0-15H218a7.5,7.5,0,0,1,0,15Z"
                                />
                              </g>
                            </g>
                            <g id="Group_37" data-name="Group 37">
                              <g id="Group_36" data-name="Group 36">
                                <path
                                  id="Path_61"
                                  data-name="Path 61"
                                  d="M97.985,218.036l-7.5-121a7.5,7.5,0,0,0-14.972.928l7.5,121a7.5,7.5,0,1,0,14.972-.929Z"
                                />
                              </g>
                            </g>
                            <g id="Group_39" data-name="Group 39">
                              <g id="Group_38" data-name="Group 38">
                                <path
                                  id="Path_62"
                                  data-name="Path 62"
                                  d="M128,90a7.5,7.5,0,0,0-7.5,7.5v121a7.5,7.5,0,0,0,15,0V97.5A7.5,7.5,0,0,0,128,90Z"
                                />
                              </g>
                            </g>
                            <g id="Group_41" data-name="Group 41">
                              <g id="Group_40" data-name="Group 40">
                                <path
                                  id="Path_63"
                                  data-name="Path 63"
                                  d="M173.464,90.015a7.5,7.5,0,0,0-7.949,7.021l-7.5,121a7.5,7.5,0,0,0,14.971.928l7.5-121A7.5,7.5,0,0,0,173.464,90.015Z"
                                />
                              </g>
                            </g>
                          </g>
                        </svg>
                      </Button>
                      <Button
                        color="link"
                        size="sm"
                        disabled={!canUpdateSubscriptionSettings}
                        onClick={() => _toggleOfferStatus(each)}
                      >
                        {each.isActive ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            id="Outline"
                            viewBox="0 0 24 24"
                            width="512"
                            height="512"
                          >
                            <path d="M6.5,0A3.5,3.5,0,0,0,3,3.5v17a3.5,3.5,0,0,0,7,0V3.5A3.5,3.5,0,0,0,6.5,0ZM8,20.5a1.5,1.5,0,0,1-3,0V3.5a1.5,1.5,0,0,1,3,0Z" />
                            <path d="M17.5,0A3.5,3.5,0,0,0,14,3.5v17a3.5,3.5,0,0,0,7,0V3.5A3.5,3.5,0,0,0,17.5,0ZM19,20.5a1.5,1.5,0,0,1-3,0V3.5a1.5,1.5,0,0,1,3,0Z" />
                          </svg>
                        ) : (
                          <i className="fa fa-play" />
                        )}
                      </Button>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="text-center">
                  No Offers Found
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>

      <AddEditOfferModal
        multiTierSubscription={influencer?.multiTierSubscription}
        isOpen={offerModal.isOpen}
        offerData={offerModal.data}
        offers={offers}
        bundles={bundles}
        toggle={() => _toggleOfferModal()}
        resetTableData={() => _getAllOffers()}
      />

      <CustomPrompt
        isOpen={promptModal?.isOpen}
        metadata={promptModal?.data}
        message="Are you sure want to delete?"
        successButtonText={"Yes"}
        closeButtonText={"No"}
        onSuccess={(data) => _deleteOffer(data)}
        onDismiss={() => _togglePromptModal()}
      />
    </>
  );
};

export default SettingsSubscription;
