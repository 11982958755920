import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Col,
  DropdownItem,
  FormGroup,
  CustomInput,
  PopoverBody,
  Row,
  UncontrolledPopover,
} from "reactstrap";
import {
  contentFilterOptionsConfig,
  contentSortOptionsConfig,
} from "../config/helper-config";
import {
  deepClone,
  errorHandler,
  formatNumberInShort,
  isPostNotOlderThan,
  replaceTipText,
} from "../helper-methods";
import { fetchSepcificContents } from "../http-calls";
import SkeletonLoading from "./SkeletonLoading";

const ProfilePhotoLibrary = ({ tabId, activeTab, summary }) => {
  const history = useHistory();

  const observer = useRef();

  const [data, setData] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [mediaError, setMediaError] = useState([]);
  const [dataPayload, setDataPayload] = useState({
    skip: 0,
    limit: 20,
  });
  const [loading, setLoading] = useState(false);
  const [isFilterPopoverOpen, setisFilterPopoverOpen] = useState(false);
  const [activeFilterOption, setActiveFilterOption] = useState("allTime"); // Other options:  ['last90days', 'last30days', 'last7Days']
  const [activeSortOption, setActiveSortOption] = useState("latestPosts"); // Other options:  ['mostLiked', 'highestTips']

  const filterAndSortFeeds = useMemo(() => {
    if (!data?.length) {
      return;
    }

    // Filter
    let filterdPosts = deepClone([...data]);

    switch (activeFilterOption) {
      case "last90days":
        filterdPosts = filterdPosts.filter((formattedPost) => {
          return isPostNotOlderThan(90, formattedPost?._post?.createdAt);
        });
        break;
      case "last30days":
        filterdPosts = filterdPosts.filter((formattedPost) => {
          return isPostNotOlderThan(30, formattedPost?._post?.createdAt);
        });
        break;
      case "last7Days":
        filterdPosts = filterdPosts.filter((formattedPost) => {
          return isPostNotOlderThan(7, formattedPost?._post?.createdAt);
        });
        break;
      default:
    }

    // Sort
    let sortedPosts = filterdPosts;
    switch (activeSortOption) {
      case "latestPosts":
        sortedPosts = filterdPosts;
        break;
      case "mostLiked":
        sortedPosts = filterdPosts.sort(
          (post1, post2) => post2?._post?.likes - post1?._post?.likes
        );
        break;
      case "highestTips":
        sortedPosts = filterdPosts.sort(
          (post1, post2) => post2?._post?.earning - post1?._post?.earning
        );
        break;
      default:
    }

    return sortedPosts?.length ? sortedPosts : [];
  }, [data, activeFilterOption, activeSortOption]);

  const _fetchSepcificContents = async (payload) => {
    try {
      setLoading(true);

      const res = await fetchSepcificContents({ payload, type: "image" });

      setData((prev) =>
        payload?.skip ? prev.concat(res.contents) : res.contents
      );
      setDataCount(res.count);

      setLoading(false);
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  const _setMediaError = (index = 0, value = false) => {
    setMediaError((prev) => {
      prev[index] = value;
      return prev;
    });
  };

  const lastElementRef = useCallback(
    (node) => {
      if (loading) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && data?.length < dataCount) {
          const newDataPayload = { ...dataPayload };
          newDataPayload["skip"] = data?.length || 0;
          setDataPayload(newDataPayload);
          _fetchSepcificContents(newDataPayload);
        }
      });

      if (node) observer.current.observe(node);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loading, data]
  );

  useEffect(() => {
    if (tabId === activeTab) {
      _fetchSepcificContents(dataPayload);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabId, activeTab]);

  return (
    <Row className="noMargin">
      <Col sm={12} className="noPadding">
        <div className="filterWrap-Feed">
          <p>
            {formatNumberInShort(dataCount) || 0}{" "}
            {dataCount > 1 ? "Photos" : "Photo"}
          </p>

          <Button
            id="Popover2"
            className="customPopoverBtn"
            onClick={() => setisFilterPopoverOpen((prev) => !prev)}
          >
            <img
              src={"/assets/img/filter-icon.png"}
              alt="Filter"
              loading="lazy"
            />
          </Button>
          <UncontrolledPopover
            className="customPopover"
            placement="bottom"
            target="Popover2"
            trigger="legacy"
            isOpen={isFilterPopoverOpen}
            toggle={() => setisFilterPopoverOpen((prev) => !prev)}
          >
            <PopoverBody>
              {contentFilterOptionsConfig.map((each, index) => (
                <FormGroup check key={each.value} className="radio">
                  <CustomInput
                    className="form-check-input"
                    id={`ProfilePhotoLibrary_activeFilterOption_${each.value}`}
                    type="radio"
                    name="activeFilterOption"
                    checked={activeFilterOption === each.value}
                    value={each.value}
                    onChange={() => setActiveFilterOption(each.value)}
                    label={each.label}
                  />
                </FormGroup>
              ))}

              <DropdownItem divider />

              {contentSortOptionsConfig.map((each, index) => (
                <FormGroup check key={each.value} className="radio">
                  <CustomInput
                    className="form-check-input"
                    id={`ProfilePhotoLibrary_activeSortOption_${each.value}`}
                    type="radio"
                    name="activeSortOption"
                    checked={activeSortOption === each.value}
                    value={each.value}
                    onChange={() => setActiveSortOption(each.value)}
                    label={replaceTipText(each.label)}
                  />
                </FormGroup>
              ))}
            </PopoverBody>
          </UncontrolledPopover>
        </div>
        {/* filterWrap-Feed */}

        <div className="manageStickerWrap px-0 pt-3">
          <Row className="noMargin">
            {/* gallery images */}
            {filterAndSortFeeds?.length ? (
              filterAndSortFeeds.map((each, index) => (
                <Fragment key={index}>
                  <Col
                    xs="6"
                    sm="4"
                    lg="3"
                    className="customPaddingSticker mb-3"
                    onClick={() =>
                      mediaError[index]
                        ? _setMediaError(index, false)
                        : history.push(`/post/${each._post._id}`)
                    }
                  >
                    <div
                      {...(index === filterAndSortFeeds.length - 1
                        ? { ref: lastElementRef }
                        : {})}
                    >
                      {mediaError[index] ? (
                        <div className="reloadMedia">
                          <span>
                            <i
                              className="fa fa-refresh mr-1"
                              style={{ marginTop: -1 }}
                            />
                            Reload
                          </span>
                        </div>
                      ) : (
                        <img
                          className="galleryImg"
                          src={`${each.url}`}
                          onError={(e) => {
                            _setMediaError(index, true);
                          }}
                          alt="pic"
                          loading="lazy"
                        />
                      )}
                    </div>
                  </Col>

                  {index === filterAndSortFeeds.length - 1 && loading && (
                    <SkeletonLoading type={"photoLibrary"} count={12} />
                  )}
                </Fragment>
              ))
            ) : loading ? (
              <SkeletonLoading type={"photoLibrary"} count={12} />
            ) : (
              <div className="noContentFound text-center w-100">
                No photos found
              </div>
            )}
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default ProfilePhotoLibrary;
