import React from "react";
import { Row, Col, Container } from "reactstrap";
import { CONTACT_SUPPORT_URL } from "../config";

const TrainingPage = () => {
  return (
    <>
      <div className="customPgHeight animated fadeIn">
        <Container fluid className="noPadding">
          <Row className="mx-0">
            <Col sm={12} className="pgPadding">
              <iframe
                title="mediarocketpro"
                className="iframeTrainingPg"
                src={CONTACT_SUPPORT_URL}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default TrainingPage;
