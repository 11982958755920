export const autoHeightStyleChat = {
  height: 47,
  minHeight: 47,
  maxHeight: 110,
  color: "#333",
  fontSize: 14,
  padding: "12px 42px 12px 12px",
  width: "100%",
  borderRadius: 24,
  backgroundColor: "#f2f2f2",
  borderColor: "#f2f2f2",
  outline: 0,
};
