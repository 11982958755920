export const permissionSettingsConfig = [
  {
    heading: { label: "Feed", string: "feed" },
    values: [
      { label: "Can view feed", string: "canViewFeed", preset: "lite/manager" },
      {
        label: "Can create post/poll",
        string: "canCreatePostOrPoll",
        preset: "lite/manager",
      },
      { label: "Can edit/delete post", string: "canEditOrDeletePost" },
      {
        label: "Can add/delete comments",
        string: "canAddDeleteOrComments",
        preset: "manager",
      },
    ],
  },
  {
    heading: {
      label: "event",
      string: "liveEvent",
    },
    values: [
      {
        label: "Can view event",
        string: "canViewLiveEvent",
        preset: "lite/manager",
      },
      {
        label: "Can create event",
        string: "canCreateLiveEvent",
        preset: "lite/manager",
      },
      {
        label: "Can edit/delete event",
        string: "canEditOrDeleteLiveEvent",
      },
      {
        label: "Can start event",
        string: "canStartLiveEvent",
        preset: "manager",
      },
      {
        label: "Can comment on event",
        string: "canCommentsOnLiveEvent",
        preset: "lite/manager",
      },
    ],
  },
  {
    heading: {
      label: "PPV",
      string: "PPV",
    },
    values: [
      { label: "Can view PPV", string: "canViewPPV", preset: "lite/manager" },
      {
        label: "Can create PPV",
        string: "canCreatePPV",
        preset: "lite/manager",
      },
      { label: "Can edit/delete PPV", string: "canEditOrDeletePPV" },
      {
        label: "Can comment on PPV",
        string: "canCommentsOnPPV",
        preset: "lite/manager",
      },
    ],
  },
  {
    heading: {
      label: "Vault",
      string: "vault",
    },
    values: [
      {
        label: "Can view vault",
        string: "canViewVault",
        preset: "lite/manager",
      },
      {
        label: "Can create vault",
        string: "canCreateVault",
        preset: "lite/manager",
      },
      {
        label: "Can edit/archive vault",
        string: "canEditOrArchiveVault",
      },
    ],
  },
  {
    heading: { label: "Messaging", string: "messaging" },
    values: [
      {
        label: "Can view messages",
        string: "canViewMessaging",
        preset: "lite/manager",
      },
      {
        label: "Can send messages",
        string: "canSendMessaging",
        preset: "lite/manager",
      },
      {
        label: "Can send group messages",
        string: "canSendGroupMessaging",
        preset: "manager",
      },
      {
        label: "Can see message stats",
        string: "canseeMessageStats",
        preset: "manager",
      },
    ],
  },
  {
    heading: { label: "Profile & Settings", string: "profileAndSettings" },
    values: [
      {
        label: "Can update profile settings",
        string: "canUpdateProfileSettings",
        preset: "manager",
      },
      {
        label: "Can update subscription settings",
        string: "canUpdateSubscriptionSettings",
        preset: "manager",
      },
      { label: "Can update bank account", string: "canUpdateBankAccount" },
      {
        label: "Can update Shoutout settings",
        string: "canUpdateShoutoutSettings",
        preset: "manager",
      },
      {
        label: "Can update notification settings",
        string: "canUpdateNotificationSettings",
        preset: "manager",
      },
      {
        label: "Can update security settings",
        string: "canUpdateSecuritySettings",
      },
    ],
  },
  {
    heading: {
      label: "Shout Out",
      string: "shoutOuts",
    },
    values: [
      {
        label: "Can see orders",
        string: "canViewOrders",
        preset: "lite/manager",
      },
      {
        label: "Can reply to orders",
        string: "canReplyOrders",
        preset: "lite/manager",
      },
      { label: "Can go on call", string: "canGoOnCall", preset: "manager" },
    ],
  },
  {
    heading: { label: "Store", string: "store" },
    values: [
      { label: "Can see products", string: "canViewProducts" },
      { label: "Can edit products", string: "canEditProducts" },
      { label: "Can add products", string: "canAddProducts" },
      { label: "Can see orders", string: "canViewOrders" },
      { label: "Can edit order status", string: "canEditOrderStatus" },
      { label: "Can issue refund", string: "canIssueRefund" },
    ],
  },
  {
    heading: { label: "My Team", string: "myTeam" },
    values: [
      { label: "Can see members", string: "canViewMembers", preset: "manager" },
      { label: "Can add new members", string: "canAddNewMembers" },
      { label: "Can update other members", string: "canUpdateMembers" },
    ],
  },
  {
    heading: { label: "Statement", string: "statement" },
    values: [
      {
        label: "Can view statement",
        string: "canViewStatement",
        preset: "manager",
      },
      {
        label: "Can make payout request",
        string: "canMakePayoutRequest",
        preset: "manager",
      },
    ],
  },
  {
    heading: { label: "Promotion", string: "Promotion" },
    values: [
      {
        label: "Can create invites",
        string: "canCreateInvites",
        preset: "lite/manager",
      },
    ],
  },
];
