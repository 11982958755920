import { useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

const ScrollHandler = ({ location }) => {
  useEffect(() => {
    if (location && location.hash) {
      const element = document.querySelector(location.hash);

      setTimeout(() => {
        if (element) {
          element.scrollIntoView({
            behavior: element ? "smooth" : "auto",
          });
        }
      }, 100);
    }
  }, [location]);

  return null;
};

ScrollHandler.propTypes = {
  location: PropTypes.shape({
    hash: PropTypes.string,
  }).isRequired,
};

export default withRouter(ScrollHandler);
