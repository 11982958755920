import React, { Component } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import StripeElement from "../StripeElement";
import { errorHandler, showToast } from "../../helper-methods";
import { addPayoutCardAndMakeDefault } from "../../http-calls";

export class AddDebitCardModal extends Component {
  state = {
    loading: false,
  };

  _closeModal = () => {
    this._hideLoader();
    this.props.toggle();
  };

  _onCardAdded = async (payload) => {
    try {
      this._showLoader();

      // Add card
      await addPayoutCardAndMakeDefault(payload.token.id);

      showToast("Card has been added", "success");

      this.props.onAccountUpdate();

      // Card added
      this._closeModal();

      // Process payment
      // this.props.onPaymentConfirmation();
    } catch (error) {
      errorHandler(error);
      this._hideLoader();
    }
  };

  _showLoader = () => {
    if (this.props.isManageLoading) {
      this.props.manageLoading(true);
    }
    this.setState({ loading: true });
  };

  _hideLoader = () => {
    if (this.props.isManageLoading) {
      this.props.manageLoading(false);
    }
    this.setState({ loading: false });
  };

  _onStripeError = () => {
    this._hideLoader();
  };

  _onSaveCard = () => {
    this._addCard();
  };

  render() {
    const { isOpen, userData } = this.props;

    const { loading } = this.state;

    return (
      <Modal
        isOpen={isOpen}
        toggle={() => this._closeModal()}
        className="modal-dialog-centered modal-dialog-scrollable"
      >
        <ModalHeader toggle={() => this._closeModal()}>
          {userData?.user?.payoutCard ? "Change Card" : "Add Card"}
        </ModalHeader>

        <ModalBody>
          <div className="stripeWrapper mb-4">
            <>
              <StripeElement
                {...this.props}
                onCardAdded={(payload) => this._onCardAdded(payload)}
                onStripeError={() => this._onStripeError()}
                showLoader={() => this._showLoader()}
                hidePostalCode={false}
                hideLoader={() => this._hideLoader()}
                addCard={(ref) => (this._addCard = ref)}
                className="stripeCard"
              />
            </>
          </div>
        </ModalBody>

        <ModalFooter>
          <Button className="modalBtnCancel" onClick={() => this._closeModal()}>
            Cancel
          </Button>
          <Button
            className="modalBtnSave"
            onClick={() => this._onSaveCard()}
            disabled={loading}
          >
            {loading ? <i className="fa fa-spinner fa-spin mr-1" /> : null}{" "}
            {userData?.user?.payoutCard ? "Change Card" : "Add Card"}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default AddDebitCardModal;
