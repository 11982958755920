import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  InputGroupText,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
} from "reactstrap";
import { CURRENCY_SYMBOL } from "../../config";
import { deepClone } from "../../helper-methods";
import { AmountConfig } from "../../config/appConfig";

class SetGoalAmountModal extends Component {
  state = {
    formFields: {
      goalAmount: {
        value: "",
        error: null,
        isDirty: false,
      },
      minimumPaymentForGoal: {
        value: "",
        error: null,
        isDirty: false,
      },
    },
  };

  _setModalForm = (goalAmount = "", minimumPaymentForGoal = "") => {
    this.setState({
      formFields: {
        goalAmount: {
          value: goalAmount,
          error: null,
          isDirty: false,
        },
        minimumPaymentForGoal: {
          value: minimumPaymentForGoal,
          error: null,
          isDirty: false,
        },
      },
    });
  };

  _closeModal = () => {
    this._setModalForm();
    this.props.onClose();
  };

  componentDidUpdate = (prevProps) => {
    const { goalAmount, minimumPaymentForGoal, isOpen } = this.props;

    if (
      isOpen &&
      goalAmount &&
      minimumPaymentForGoal &&
      isOpen !== prevProps.isOpen
    ) {
      this._setModalForm(
        goalAmount.toString(),
        minimumPaymentForGoal.toString()
      );
    }
  };

  _validateForm = () => {
    return new Promise((resolve) => {
      const { formFields } = this.state;

      let isFormValid = true;

      Object.keys(formFields).forEach((key) => {
        if (formFields[key].isDirty) {
          switch (key) {
            case "goalAmount": {
              formFields[key].value = formFields[key].value?.trim()
                ? formFields[key].value.trim()
                : "";

              if (formFields[key].value) {
                if (
                  !isNaN(formFields[key].value) &&
                  +formFields[key].value >= AmountConfig.minGoalPrice &&
                  +formFields[key].value <= AmountConfig.maximumLimit
                ) {
                  formFields[key].error = null;
                  formFields[key].isDirty = false;
                } else {
                  formFields[key].error =
                    +formFields[key].value > AmountConfig.maximumLimit
                      ? `Maximum ${CURRENCY_SYMBOL}${AmountConfig.maximumLimit}`
                      : `*Minimum ${CURRENCY_SYMBOL}${AmountConfig.minGoalPrice}`;
                  isFormValid = false;
                }
              } else {
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "minimumPaymentForGoal": {
              formFields[key].value = formFields[key].value?.trim()
                ? formFields[key].value.trim()
                : "";

              if (formFields[key].value) {
                if (
                  !isNaN(formFields[key].value) &&
                  Number(formFields[key].value) >= AmountConfig.minGoalPrice
                ) {
                  if (
                    formFields["goalAmount"].value &&
                    !isNaN(formFields["goalAmount"].value)
                  ) {
                    if (
                      Number(formFields["goalAmount"].value) >
                      Number(formFields[key].value)
                    ) {
                      formFields[key].error = null;
                      formFields[key].isDirty = false;
                    } else {
                      formFields[
                        key
                      ].error = `*Minimum price should be less than goal price ${CURRENCY_SYMBOL}${formFields["goalAmount"].value}`;
                      isFormValid = false;
                    }
                  } else {
                    formFields[key].error = null;
                    formFields[key].isDirty = false;
                  }
                } else {
                  formFields[
                    key
                  ].error = `*Minimum ${CURRENCY_SYMBOL}${AmountConfig.minGoalPrice}`;
                  isFormValid = false;
                }
              } else {
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            default:
          }
        }
      });

      this.setState({ formFields }, () => resolve(isFormValid));
    });
  };

  _updateFieldValue = (key, value) => {
    let { formFields } = this.state;

    if (key === "goalAmount" || key === "minimumPaymentForGoal") {
      if (
        isNaN(value) ||
        (value.includes(".") && value.split(".")[1].length > 2)
      )
        return;
    }

    formFields[key].value = value;
    formFields[key].isDirty = true;

    this.setState({ formFields }, () => this._validateForm());
  };

  _makeAllFieldDirty = () => {
    return new Promise((resolve) => {
      const { formFields } = this.state;
      Object.keys(formFields).forEach((key) => {
        formFields[key].isDirty = true;
      });
      this.setState({ formFields }, () => resolve(true));
    });
  };

  _saveGoalPrice = async () => {
    await this._makeAllFieldDirty();

    const isFormValid = await this._validateForm();

    if (isFormValid) {
      const { formFields } = this.state;

      const goalAmount = Number(formFields.goalAmount.value);
      const minimumPaymentForGoal = Number(
        formFields.minimumPaymentForGoal.value
      );

      this.props.onSave(goalAmount, minimumPaymentForGoal);

      this._closeModal();
    }
  };

  render() {
    const { formFields } = deepClone(this.state);

    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={() => this._closeModal()}
        className="modal-dialog-centered modal-dialog-scrollable"
      >
        <ModalHeader toggle={() => this._closeModal()}>Set Goal</ModalHeader>
        <ModalBody className="setGoal">
          <div className="mb-4">
            <Label>Goal Amount</Label>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText className="dollarWrap">
                  <i className="fa fa-dollar" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                value={formFields.goalAmount.value}
                type="text"
                name="goalAmount"
                placeholder="Enter the goal price"
                className="dollarWrapInput"
                onChange={(e) =>
                  this._updateFieldValue("goalAmount", e.target.value)
                }
              />
            </InputGroup>
            {formFields.goalAmount.error ? (
              <div className="form-error">{formFields.goalAmount.error}</div>
            ) : null}
          </div>
          <div className="mb-2">
            <Label>Minimum Amount</Label>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText className="dollarWrap">
                  <i className="fa fa-dollar" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                value={formFields.minimumPaymentForGoal.value}
                type="text"
                name="minimumPaymentForGoal"
                placeholder="Enter the minimum price"
                className="dollarWrapInput"
                onChange={(e) =>
                  this._updateFieldValue(
                    "minimumPaymentForGoal",
                    e.target.value
                  )
                }
              />
            </InputGroup>
            {formFields.minimumPaymentForGoal.error ? (
              <div className="form-error">
                {formFields.minimumPaymentForGoal.error}
              </div>
            ) : null}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button className="modalBtnCancel" onClick={() => this._closeModal()}>
            Cancel
          </Button>
          <Button
            className="modalBtnSave"
            onClick={() => this._saveGoalPrice()}
          >
            Save
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default SetGoalAmountModal;
