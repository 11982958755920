import React, { Fragment } from "react";
import Skeleton from "react-loading-skeleton";

const SkeletonEarningTable = ({ count }) => {
  return (
    <>
      {[...Array(count)].map((_, index) => (
        <Fragment key={index}>
          <div className="earningsDataWrap">
            <div className="earningsData">
              <div>
                <p>
                  <Skeleton height={14} width={35} />
                </p>
                <h5>
                  <Skeleton height={14} width={70} />
                </h5>
              </div>
              <div>
                <p>
                  <Skeleton height={14} width={50} />
                </p>
                <h5>
                  <Skeleton height={14} width={30} />
                </h5>
              </div>
              <div>
                <p>
                  <Skeleton height={14} width={50} />
                </p>
                <h5>
                  <Skeleton height={14} width={30} />
                </h5>
              </div>
              <div>
                <p>
                  <Skeleton height={14} width={70} />
                </p>
                <h5>
                  <Skeleton height={14} width={50} />
                </h5>
              </div>
            </div>

            <div className="d-flex justify-content-between align-items-center">
              <div className="mr-1">
                <Skeleton width={Math.floor(Math.random() * 120 + 100)} />
              </div>

              <Skeleton height={30} width={110} borderRadius={50} />
            </div>
          </div>
        </Fragment>
      ))}
    </>
  );
};

export default SkeletonEarningTable;
