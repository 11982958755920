import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  Label,
  Input,
  CustomInput,
  UncontrolledPopover,
  PopoverBody,
  FormGroup,
  DropdownItem,
} from "reactstrap";
import {
  showToast,
  isTimeMoreThan24h,
  copyToClipboard,
  isPermissionToAccess,
  errorHandler,
  openUrlOnNewTab,
  deepClone,
  isPostNotOlderThan,
  sortByLatestData,
  sortByOldestData,
} from "../helper-methods";
import {
  deleteCoupon,
  getAllCoupons,
  resendReferralInvitation,
  resendReferralInvitationToAll,
  updateActiveInactiveCoupon,
} from "../http-calls/index";
import {
  ADD_PROMOTION_COUPON_LINK,
  APP_NAME,
  FRONTEND_FAN_BASE_URL,
} from "../config";
import ReferralShareModal from "../components/modals/ReferralShareModal";
import CustomPrompt from "../components/modals/CustomPrompt";
import AddFreeTrialLinkModal from "../components/modals/AddFreeTrialLinkModal";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomTooltip from "../components/custom/CustomTooltip";
import CreatorReferForm from "../components/CreatorReferForm";
import {
  fetchAndModifiedUserDetails,
  fetchReferralHistory,
} from "../redux/actions";
import CustomLoader from "../components/custom/CustomLoader";
import ReferralHistoryTable from "../components/ReferralHistoryTable";
import ReferralHistoryList from "../components/ReferralHistoryList";
import ErrorBoundary from "../components/ErrorBoundary";
import {
  contentFilterOptionsConfig,
  referralHistoryStatusConfig,
} from "../config/helper-config";

const PromotionLinks = () => {
  const history = useHistory();

  const userData = useSelector((state) => state?.userData);

  const dispatch = useDispatch();

  const [influencerUrl, setInfluencerUrl] = useState("");
  const [customPrompt, setCustomPrompt] = useState({
    isOpen: false,
    message: "",
    successButtonText: "",
    selectedId: null,
  });
  const [referralShareModal, setReferralShareModal] = useState({
    isOpen: false,
    coupon: null,
  });
  const [isShowInviteCreatorForm, setIsShowInviteCreatorForm] = useState(false);
  const [isOpenAddFreeTrialLinkModal, setIsOpenAddFreeTrialLinkModal] =
    useState(false);
  const [coupons, setCoupons] = useState([]);
  const [loadingState, setLoadingState] = useState({
    referralHistory: false,
  });
  const [isFilterPopoverOpen, setIsFilterPopoverOpen] = useState(false);
  const [activeDayRangeOption, setActiveDayRangeOption] = useState("allTime"); // Other options:  ['last90days', 'last30days', 'last7Days']
  const [activeStatusOption, setActiveStatusOption] = useState("pending"); // Other options:  ['accpeted', 'oldest', 'newest', 'reminder']

  const _isTimeMoreThan24h = (userData) => {
    const lastReminderSent = userData.lastReminderSent || userData.createdAt;

    return isTimeMoreThan24h(lastReminderSent);
  };

  const isShowSendReminderToAll = useMemo(() => {
    let filterdReferralHistory = deepClone([...userData?.referralHistory]);

    filterdReferralHistory = filterdReferralHistory.filter(
      (each) => each?.status === "pending" && _isTimeMoreThan24h(each)
    );

    return filterdReferralHistory?.length ? true : false;
  }, [userData?.referralHistory]);

  const filteredAndSortedReferralHistory = useMemo(() => {
    if (!userData?.referralHistory) return [];

    // Filter
    let filterdReferralHistory = deepClone([...userData?.referralHistory]);

    switch (activeDayRangeOption) {
      case "last90days": {
        filterdReferralHistory = filterdReferralHistory.filter((each) => {
          return isPostNotOlderThan(90, each.createdAt);
        });
        break;
      }

      case "last30days": {
        filterdReferralHistory = filterdReferralHistory.filter((each) => {
          return isPostNotOlderThan(30, each.createdAt);
        });
        break;
      }

      case "last7Days": {
        filterdReferralHistory = filterdReferralHistory.filter((each) => {
          return isPostNotOlderThan(7, each.createdAt);
        });
        break;
      }
      default:
    }

    //further filter by referral history status
    switch (activeStatusOption) {
      case "pending":
      case "accepted": {
        filterdReferralHistory = filterdReferralHistory.filter(
          (each) => each?.status === activeStatusOption
        );
        break;
      }

      case "reminder": {
        filterdReferralHistory = filterdReferralHistory.filter(
          (each) => each?.status === "pending" && _isTimeMoreThan24h(each)
        );
        break;
      }

      case "oldest": {
        filterdReferralHistory = sortByOldestData(
          filterdReferralHistory,
          "createdAt"
        );
        break;
      }

      case "newest": {
        filterdReferralHistory = sortByLatestData(
          filterdReferralHistory,
          "createdAt"
        );
        break;
      }

      default:
        break;
    }

    return filterdReferralHistory?.length ? filterdReferralHistory : [];
  }, [userData?.referralHistory, activeDayRangeOption, activeStatusOption]);

  const canCreateInvites = useMemo(
    () => isPermissionToAccess("Promotion", "canCreateInvites", true),
    []
  );

  const _manageLoadingState = (key = "", value = false) => {
    setLoadingState((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const _fetchReferralHistory = async () => {
    try {
      _manageLoadingState("referralHistory", true);

      await fetchReferralHistory()(dispatch);

      _manageLoadingState("referralHistory", false);
    } catch (error) {
      _manageLoadingState("referralHistory", false);
      errorHandler(error);
    }
  };

  const _getAllFreeTrialLink = async () => {
    try {
      _manageLoadingState("coupons", true);

      const res = await getAllCoupons();

      setCoupons(res?.coupons?.length ? res.coupons : []);

      _manageLoadingState("coupons", false);
    } catch (error) {
      errorHandler(error);
      _manageLoadingState("coupons", false);
    }
  };

  const _fetchAndModifiedUserDetails = async () => {
    try {
      setInfluencerUrl(`${FRONTEND_FAN_BASE_URL}/${userData?.user?.username}`);

      await fetchAndModifiedUserDetails()(dispatch);

      setInfluencerUrl(`${FRONTEND_FAN_BASE_URL}/${userData?.user?.username}`);
    } catch (error) {
      errorHandler(error);
    }
  };

  const _shareOnFacebook = (couponToken = null) => {
    let shareableUrl = `https://www.facebook.com/sharer/sharer.php?u=${influencerUrl}`;

    if (couponToken) shareableUrl += `?coupon=${couponToken}`;

    openUrlOnNewTab(shareableUrl);
  };

  const _shareOnTwitter = (couponToken = null) => {
    let shareableUrl = `https://twitter.com/share?url=${influencerUrl}`;

    if (couponToken) shareableUrl += `?coupon=${couponToken}`;

    openUrlOnNewTab(shareableUrl);
  };

  const _shareOnTelegram = (couponToken = null) => {
    let shareableUrl = `https://t.me/share/url?url=${influencerUrl}`;

    if (couponToken) shareableUrl += `?coupon=${couponToken}`;

    openUrlOnNewTab(shareableUrl);
  };

  const _toggleInviteCreator = (isShow = false) => {
    setIsShowInviteCreatorForm(isShow);
  };

  const _goBackBtn = () => {
    if (isShowInviteCreatorForm) {
      _toggleInviteCreator();
    } else {
      history.goBack();
    }
  };

  const _toggleReferralShareModal = (isOpen = false, coupon = null) => {
    setReferralShareModal({
      isOpen,
      coupon,
    });
  };

  const _togglePromotionTrialLinkStatus = async (id) => {
    try {
      const newCoupons = [...coupons];

      const findCoupon = newCoupons.find((each) => each._id === id);

      if (!findCoupon) {
        showToast("Coupon not found.", "error");
        _getAllFreeTrialLink();
        return;
      }

      _manageLoadingState("linkStatus", id);

      const res = await updateActiveInactiveCoupon(id);

      findCoupon.isActive = res.coupon?.isActive ? true : false;

      setCoupons(newCoupons);

      showToast(
        `Link ${res.coupon?.isActive ? "Activated" : "Deactivated"}`,
        "success"
      );

      _manageLoadingState("linkStatus", false);
    } catch (error) {
      errorHandler(error);
      _getAllFreeTrialLink();
      _manageLoadingState("linkStatus", false);
    }
  };

  const _sendReferralReminderToAll = async () => {
    try {
      _manageLoadingState("remindAllLoading", true);

      await resendReferralInvitationToAll();

      showToast("Referral reminder sent to everyone.", "success");
    } catch (error) {
      errorHandler(error);
    } finally {
      _manageLoadingState("remindAllLoading", false);
      _fetchReferralHistory();
    }
  };

  const _sendReferralReminder = async () => {
    try {
      if (!customPrompt?.selectedId) {
        _toggleCustomPrompt({});
        showToast("Something went wrong. Try again after sometime.", "error");
        return;
      }

      const userSelectedId = customPrompt.selectedId;
      _toggleCustomPrompt({});

      _manageLoadingState("reminder", userSelectedId);

      await resendReferralInvitation(userSelectedId);

      _fetchReferralHistory();

      _fetchAndModifiedUserDetails();

      showToast("Invitation Reminder has been sent", "success");

      _manageLoadingState("reminder", false);
    } catch (error) {
      errorHandler(error);
      _toggleCustomPrompt({});
      _manageLoadingState("reminder", false);
    }
  };

  const _deletePromotionTrialLink = async () => {
    try {
      if (!customPrompt?.selectedId) {
        _toggleCustomPrompt({});
        showToast("Something went wrong. Try again after sometime.", "error");
        return;
      }

      const couponId = customPrompt.selectedId;
      _toggleCustomPrompt({});

      const newCoupons = [...coupons];
      const findIndexCoupon = newCoupons.findIndex(
        (each) => each._id === couponId
      );

      if (findIndexCoupon === -1) {
        showToast("Coupon not found.", "error");
        _getAllFreeTrialLink();
        return;
      }

      _manageLoadingState("linkDelete", couponId);

      coupons.splice(findIndexCoupon, 1);

      setCoupons(newCoupons);

      await deleteCoupon(couponId);

      showToast("Link removed", "success");
      _manageLoadingState("linkDelete", false);
    } catch (error) {
      errorHandler(error);
      _toggleCustomPrompt({});
      _getAllFreeTrialLink();
      _manageLoadingState("linkDelete", false);
    }
  };

  /**
   * for resend invitation
   *
   * @param {Object} creatorData - other creator user obj
   */
  const _confirmationPrompt = (creatorData) => {
    _toggleCustomPrompt({
      isOpen: true,
      message: `${creatorData?.email} hasn't accepted your invitation to join ${APP_NAME} yet. 
                    Send them a friendly reminder to create their account.`,
      selectedId: creatorData?.id,
      successButtonText: "Resend Invitation",
      openFor: "reminder",
    });
  };

  const _onPromptSuccess = () => {
    if (customPrompt?.openFor === "reminder") {
      _sendReferralReminder();
    } else if (customPrompt?.openFor === "deleteTrialLink") {
      _deletePromotionTrialLink();
    } else {
      _toggleCustomPrompt({});
    }
  };

  const _toggleCustomPrompt = ({
    isOpen = false,
    message = "",
    successButtonText = "",
    selectedId = null,
    openFor = "",
  }) => {
    setCustomPrompt({
      isOpen,
      message,
      successButtonText,
      selectedId,
      openFor,
    });
  };

  const _toggleAddFreeTrialLinkModal = (isOpen = false) => {
    setIsOpenAddFreeTrialLinkModal(isOpen);
  };

  useEffect(() => {
    _getAllFreeTrialLink();
    _fetchAndModifiedUserDetails();
    _fetchReferralHistory();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="customPgHeight animated fadeIn">
      <Container className="noPadding">
        <Row className="noMargin">
          <Col xs="12" className="pgPadding bg-white pb-4">
            <div className="pgTitle">
              <div className="d-flex align-items-center">
                <Button
                  className="customBackBtn d-block d-md-none"
                  onClick={_goBackBtn}
                >
                  <i className="fa fa-chevron-left" />
                </Button>

                <h2>Promotion Links</h2>
                {loadingState?.coupons ? (
                  <CustomLoader className="mx-2" />
                ) : null}
              </div>

              {!userData?.user?.isScheduleForAccountDelete &&
              ADD_PROMOTION_COUPON_LINK === true ? (
                <Button
                  className="themeBtn addBtn"
                  onClick={() => _toggleAddFreeTrialLinkModal(true)}
                >
                  Add Link
                </Button>
              ) : null}
            </div>

            {!userData?.user?.isScheduleForAccountDelete && (
              <div className="promoteAccount">
                <Input type="text" value={influencerUrl} disabled readOnly />

                <Button
                  className="btn-twitter btn-share"
                  onClick={() => _shareOnTwitter()}
                >
                  <i className="fa fa-twitter" />
                </Button>
                <Button
                  className="btn-telegram btn-share"
                  onClick={() => _shareOnTelegram()}
                >
                  <img
                    src="/assets/img/social/telegram.png"
                    style={{ width: 26 }}
                    alt="telegram"
                    loading="lazy"
                  />
                </Button>

                <Button
                  className="themeBtn copyClipboardBtn"
                  onClick={() => copyToClipboard(influencerUrl)}
                >
                  <i className="fa fa-clone mr-1" />
                  Copy
                </Button>
              </div>
            )}

            {!userData?.user?.isScheduleForAccountDelete && coupons?.length
              ? coupons.map((each) => (
                  <div className="promoteAccount" key={each._id}>
                    <div className="w-100">
                      <div className="d-flex align-items-center">
                        <Label className="mb-0">
                          {each.trialPeriod}{" "}
                          {each.trialPeriod > 1 ? "Days" : "Day"} Trial
                          {each.introductionText ? (
                            <>
                              <sup
                                id={`promotion_links_web_view_introductionText_tooltip_${each._id}`}
                                className="infoIcon"
                              >
                                <i className="fa fa-info-circle" />
                              </sup>
                              <CustomTooltip
                                text={each.introductionText}
                                target={`promotion_links_web_view_introductionText_tooltip_${each._id}`}
                              />
                            </>
                          ) : null}
                        </Label>
                        <CustomInput
                          type="switch"
                          className="ml-3"
                          id={`promotion_trial_link_${each._id}`}
                          disabled={loadingState?.linkStatus === each._id}
                          checked={each.isActive}
                          onChange={(e) =>
                            _togglePromotionTrialLinkStatus(each._id)
                          }
                        />
                      </div>

                      <div className="d-flex align-items-center mt-2">
                        <Input
                          type="text"
                          value={`${influencerUrl}?coupon=${each.couponToken}`}
                          disabled
                          readOnly
                        />

                        <Button
                          className="btn-twitter btn-share"
                          onClick={() => _shareOnTwitter(each.couponToken)}
                        >
                          <i className="fa fa-twitter" />
                        </Button>
                        <Button
                          className="btn-telegram btn-share"
                          onClick={() => _shareOnTelegram(each.couponToken)}
                        >
                          <img
                            src="/assets/img/social/telegram.png"
                            style={{ width: 26 }}
                            alt="telegram"
                            loading="lazy"
                          />
                        </Button>

                        <Button
                          size="sm"
                          className="themeBtn copyClipboardBtn"
                          onClick={() =>
                            copyToClipboard(
                              `${influencerUrl}?coupon=${each.couponToken}`
                            )
                          }
                        >
                          <i className="fa fa-clone mr-1" />
                          Copy
                        </Button>
                      </div>
                    </div>
                  </div>
                ))
              : null}

            {canCreateInvites ? (
              <div
                className={`${
                  !isShowInviteCreatorForm ? "d-none d-md-block" : ""
                }`}
              >
                <ErrorBoundary>
                  <CreatorReferForm
                    fetchReferralHistory={_fetchReferralHistory}
                    fetchAndModifiedUserDetails={_fetchAndModifiedUserDetails}
                    toggleInviteCreator={() => _toggleInviteCreator()}
                  />
                </ErrorBoundary>
              </div>
            ) : null}

            {!isShowInviteCreatorForm ? (
              <>
                <div className="invitesLeftMobView">
                  <div className="invitesButtonWrap">
                    <Button
                      className="themeBtn addBtn"
                      onClick={() => _toggleReferralShareModal(true)}
                    >
                      Invite Subscribers
                    </Button>

                    {coupons?.length
                      ? coupons.map((each) => (
                          <Button
                            key={each._id}
                            className="themeBtn addBtn"
                            onClick={() =>
                              _toggleReferralShareModal(true, each)
                            }
                            title={each.introductionText}
                          >
                            {each.trialPeriod}{" "}
                            {each.trialPeriod > 1 ? "Days" : "Day"} Trial
                          </Button>
                        ))
                      : null}
                  </div>
                  <h4>
                    You have {userData?.user?.avaiableReferralCount}{" "}
                    {userData?.user?.avaiableReferralCount > 1
                      ? "invites"
                      : "invite"}
                    {userData?.user?.referPercentage ? (
                      <>
                        <sup
                          id="promotion_links_mobile_view_referPercentage_tooltip"
                          className="infoIcon"
                        >
                          <i className="fa fa-info-circle" />
                        </sup>
                        <CustomTooltip
                          text={`Earn ${userData?.user?.referPercentage}% commission for inviting and helping your friends use ${APP_NAME}!`}
                          target="promotion_links_mobile_view_referPercentage_tooltip"
                        />
                      </>
                    ) : null}
                  </h4>
                  <p>
                    Get paid for inviting other creators you know to join{" "}
                    {APP_NAME}!
                  </p>
                </div>

                {userData?.user?.avaiableReferralCount && canCreateInvites ? (
                  <Button
                    className="themeBtn d-block d-md-none w-auto px-4"
                    style={{ margin: "25px auto 40px" }}
                    onClick={() => _toggleInviteCreator(true)}
                  >
                    Invite a Creator
                  </Button>
                ) : null}

                <div className="sortReferralWrap">
                  <div className="d-flex align-items-center">
                    <h4 className="referralHistoryTxt">Referral History</h4>
                    {loadingState?.referralHistory ? (
                      <CustomLoader className="ml-2" height={16} width={16} />
                    ) : null}
                  </div>

                  {/* <div className="d-flex align-items-center">
                    Sort By
                    <Input
                      type="select"
                      value={sortBy}
                      onChange={(e) => setSortBy(e.target.value)}
                    >
                      <option value="latest">Latest</option>
                      <option value="oldest">Oldest</option>
                    </Input>
                  </div> */}

                  <div className="d-flex flex-grow-1 justify-content-end">
                    {isShowSendReminderToAll ? (
                      <Button
                        color="link"
                        className="sendReminderToAll px-1"
                        onClick={() => _sendReferralReminderToAll()}
                        disabled={loadingState?.remindAllLoading}
                      >
                        {loadingState?.remindAllLoading ? (
                          <i className="fa fa-spinner fa-spin mr-1" />
                        ) : null}{" "}
                        Send Reminder to All
                      </Button>
                    ) : null}

                    <Button
                      id="referral-history-filter"
                      className="customPopoverBtn ml-sm-3"
                      onClick={() => setIsFilterPopoverOpen((prev) => !prev)}
                    >
                      <img
                        src={"/assets/img/filter-icon.png"}
                        alt="Filter"
                        loading="lazy"
                      />
                    </Button>
                    <UncontrolledPopover
                      className="customPopover"
                      placement="bottom"
                      target="referral-history-filter"
                      trigger="legacy"
                      isOpen={isFilterPopoverOpen}
                      toggle={() => setIsFilterPopoverOpen((prev) => !prev)}
                    >
                      <PopoverBody>
                        {contentFilterOptionsConfig.map((each, index) => (
                          <FormGroup check key={each.value} className="radio">
                            <CustomInput
                              className="form-check-input"
                              id={`referral_activeDayRange_${each.value}`}
                              type="radio"
                              name="activeDayRangeOption"
                              checked={activeDayRangeOption === each.value}
                              value={each.value}
                              onChange={() =>
                                setActiveDayRangeOption(each.value)
                              }
                              label={each.label}
                            />
                          </FormGroup>
                        ))}

                        <DropdownItem divider />

                        {referralHistoryStatusConfig.map((each, index) => (
                          <FormGroup check key={each.value} className="radio">
                            <CustomInput
                              className="form-check-input"
                              id={`referral_activeStatusOption_${each.value}`}
                              type="radio"
                              name="activeStatusOption"
                              checked={activeStatusOption === each.value}
                              value={each.value}
                              onChange={() => setActiveStatusOption(each.value)}
                              label={each.label}
                            />
                          </FormGroup>
                        ))}
                      </PopoverBody>
                    </UncontrolledPopover>
                  </div>
                </div>

                {/* web view */}
                <ErrorBoundary>
                  <ReferralHistoryTable
                    confirmationPrompt={_confirmationPrompt}
                    isTimeMoreThan24h={_isTimeMoreThan24h}
                    loadingState={loadingState}
                    referralHistoryList={filteredAndSortedReferralHistory}
                    isUserAccountScheduledForDelete={
                      userData?.user?.isScheduleForAccountDelete
                    }
                  />
                </ErrorBoundary>

                {/* mobile view */}
                <ErrorBoundary>
                  <ReferralHistoryList
                    confirmationPrompt={_confirmationPrompt}
                    isTimeMoreThan24h={_isTimeMoreThan24h}
                    loadingState={loadingState}
                    referralHistoryList={filteredAndSortedReferralHistory}
                    isUserAccountScheduledForDelete={
                      userData?.user?.isScheduleForAccountDelete
                    }
                  />
                </ErrorBoundary>
              </>
            ) : null}
          </Col>
        </Row>
      </Container>

      <ReferralShareModal
        isOpen={referralShareModal.isOpen}
        coupon={referralShareModal.coupon}
        linkStatusLoading={loadingState?.linkStatus}
        influencerUrl={influencerUrl}
        toggle={() => _toggleReferralShareModal()}
        shareOnFacebook={(couponToken) => _shareOnFacebook(couponToken)}
        shareOnTwitter={(couponToken) => _shareOnTwitter(couponToken)}
        shareOnTelegram={(couponToken) => _shareOnTelegram(couponToken)}
        togglePromotionTrialLinkStatus={_togglePromotionTrialLinkStatus}
      />

      <CustomPrompt
        isOpen={customPrompt.isOpen}
        message={customPrompt.message}
        successButtonText={customPrompt.successButtonText}
        closeButtonText="No"
        onSuccess={() => _onPromptSuccess()}
        onDismiss={() => _toggleCustomPrompt({})}
      />

      <AddFreeTrialLinkModal
        isOpen={isOpenAddFreeTrialLinkModal}
        toggle={() => _toggleAddFreeTrialLinkModal()}
        getAllFreeTrialLink={() => _getAllFreeTrialLink()}
      />
    </div>
  );
};

export default PromotionLinks;
