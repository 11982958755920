import React, { useState } from "react";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { Button, Modal, ModalBody } from "reactstrap";
import {
  errorHandler,
  formatDecimalNumber,
  showToast,
} from "../../helper-methods";
import { editPost, getPosts } from "../../http-calls";
import { updateList } from "../../redux/actions";

const CloseGoalModal = ({
  isVisible,
  post,
  feedIndex,
  toggle,
  successButtonText,
  closeButtonText,
}) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const _dismiss = () => {
    setLoading(false);

    toggle();
  };

  const _getIndividualPost = async (index, limit = 1) => {
    try {
      const res = await getPosts({ skip: index, limit });

      dispatch(updateList({ index, post: res.posts[0] }));

      _dismiss();
    } catch (error) {
      errorHandler(error);
      _dismiss();
    }
  };

  const _editPost = async (payload, postId, feedIndex) => {
    try {
      setLoading(true);

      await editPost(payload, postId);

      _getIndividualPost(feedIndex);

      showToast("Goal has been closed", "success");
    } catch (error) {
      _getIndividualPost(feedIndex);

      errorHandler(error);
      setLoading(false);
    }
  };

  const _onSuccess = () => {
    if (post._id && !post.isGoalPreClosed) {
      _editPost({ isGoalPreClosed: true }, post._id, feedIndex);
    } else {
      showToast("Something went wrong, Try again after sometime", "error");
    }
  };

  const percentage = useMemo(() => {
    return post &&
      (post.goalAmount || post.goalAmount === 0) &&
      (post.goalAmountReached || post.goalAmountReached === 0)
      ? (post.goalAmountReached / post.goalAmount) * 100
      : 0;
  }, [post]);

  return (
    <Modal
      isOpen={isVisible}
      toggle={() => _dismiss()}
      className="modal-dialog-centered modal-dialog-scrollable"
    >
      <ModalBody className="py-4 text-center">
        <h4 className="mb-1 fs-16">
          You have achieved{" "}
          <span>
            {percentage < 100 ? `${formatDecimalNumber(percentage)}%` : "100%"}{" "}
          </span>{" "}
          of your goal.
        </h4>
        <h4 className="mb-3 fs-16">Do you wish to close this goal?</h4>

        <div className="mt-5">
          <Button
            className="modalBtnNo"
            disabled={loading}
            onClick={() => _dismiss()}
          >
            {closeButtonText || "No"}
          </Button>

          <Button
            className="modalBtnYes"
            disabled={loading}
            onClick={() => _onSuccess()}
          >
            {loading ? <i className="fa fa-spinner fa-spin mr-1" /> : null}{" "}
            {successButtonText || "Yes"}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default CloseGoalModal;
