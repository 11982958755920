import React, { useMemo, useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Button,
  CustomInput,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap";
import { DEFAULT_PROFILE_PICTURE } from "../../config";
import {
  capitalizeEveryFirstLetter,
  errorHandler,
  formatCurrencyValue,
  formatDate,
  getLowResolutionLink,
  handleNameObj,
  sortByLatestData,
} from "../../helper-methods";
import { getTransactions } from "../../http-calls";
import CustomLoader from "../custom/CustomLoader";

const VaultTransactionModal = ({
  isOpen,
  data,
  toggle,
  canViewAndSendMessage,
  toggleBroadcastMessage,
}) => {
  const history = useHistory();

  const userData = useSelector((state) => state?.userData);

  const [transactions, setTransactions] = useState([]);
  const [selectedFanForMessages, setSelectedFanForMessages] = useState([]);
  const [loading, setLoading] = useState(false);

  const _resetState = () => {
    setTransactions([]);
    setSelectedFanForMessages([]);
    setLoading(false);
  };

  const _closeModal = () => {
    _resetState();
    toggle();
  };

  const isAllSelected = useMemo(() => {
    if (
      transactions?.length &&
      selectedFanForMessages?.length &&
      transactions?.length === selectedFanForMessages?.length
    ) {
      return true;
    }

    return false;
  }, [transactions, selectedFanForMessages]);

  const _initiateMessage = () => {
    if (!selectedFanForMessages?.length) {
      return;
    }

    if (selectedFanForMessages?.length === 1) {
      const selectedFollower = selectedFanForMessages[0];
      if (selectedFollower?.hasThread) {
        history.push(
          `/messages?threadId=${selectedFollower?.thread?.threadId}`
        );
      } else {
        history.push(`/messages?followerId=${selectedFollower?._fan?._id}`);
      }
    } else {
      toggleBroadcastMessage(true, selectedFanForMessages);
    }

    _closeModal();
  };

  /**
   *
   * @param {Boolean} selectAll - true / false
   * @param {Object} fanData - selected fan data
   * @param {Boolean} value - true / false
   */
  const _selectFanCheckbox = (selectAll = false, fanData, value) => {
    if (!transactions?.length) {
      setSelectedFanForMessages([]);
      return;
    }

    let newSelectedFanForMessages = [...selectedFanForMessages];
    let newTransactions = [...transactions];

    if (selectAll) {
      if (value) {
        newSelectedFanForMessages = newTransactions.map((f) => {
          return {
            ...f,
            _fan: f._from,
            isSelected: value,
          };
        });
      } else {
        newSelectedFanForMessages = [];
      }
      newTransactions = newTransactions.map((f) => {
        return {
          ...f,
          isSelected: value,
        };
      });
    } else {
      const findFan = newTransactions.find((f) => fanData.id === f.id);
      if (findFan) {
        if (value) {
          newSelectedFanForMessages.push(findFan);
        } else {
          newSelectedFanForMessages = newSelectedFanForMessages.filter(
            (f) => f.id !== findFan.id
          );
        }
        findFan["isSelected"] = value;
        findFan["_fan"] = findFan._from;
      }
    }

    setSelectedFanForMessages(newSelectedFanForMessages);
    setTransactions(newTransactions);
  };

  const _getTransactions = async () => {
    try {
      setLoading(true);

      const res = await getTransactions(data?._id, {
        paymentType: "vault",
      });

      let newTransactions = [];
      if (res?.earnings?.length) {
        newTransactions = [...res.earnings];
      }
      if (res?.tips?.length) {
        newTransactions = newTransactions.concat(res.tips);
      }
      newTransactions = newTransactions?.length
        ? sortByLatestData(newTransactions, "createdAt")
        : [];

      setTransactions(newTransactions);
      setLoading(false);
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      _getTransactions();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => _closeModal()}
      className="modal-dialog-centered modal-dialog-scrollable"
    >
      <ModalHeader toggle={() => _closeModal()}>
        Purchase and{" "}
        {userData?.user?.settings?.tipText
          ? capitalizeEveryFirstLetter(userData.user.settings.tipText)
          : "Tips"}{" "}
        History
      </ModalHeader>
      <ModalBody className="py-3">
        <Table responsive className="mb-0">
          <thead>
            <tr>
              {canViewAndSendMessage && (
                <th>
                  <CustomInput
                    type="checkbox"
                    checked={isAllSelected}
                    id="vaultDetailPage_CustomCheckbox_isAllSelected"
                    onChange={(e) =>
                      _selectFanCheckbox(true, null, e.target.checked)
                    }
                    // style={{ marginBottom: -5, marginLeft: -10 }}
                  />
                </th>
              )}
              <th>Date</th>
              <th>Type</th>
              <th>Fan</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {transactions?.length ? (
              transactions.map((transaction, index) => (
                <tr key={index}>
                  {canViewAndSendMessage && (
                    <td>
                      <CustomInput
                        type="checkbox"
                        id={`VaultTransactionModal_CustomCheckbox__selectFanCheckbox_${
                          transaction._id || index
                        }`}
                        checked={transaction.isSelected ? true : false}
                        onChange={(e) =>
                          _selectFanCheckbox(
                            false,
                            transaction,
                            e.target.checked
                          )
                        }
                        // style={{ marginBottom: -5, marginLeft: -10 }}
                      />
                    </td>
                  )}
                  <td>
                    {transaction.createdAt
                      ? formatDate(transaction.createdAt)
                      : "N/A"}
                  </td>

                  <td>
                    {transaction.paymentType === "vault"
                      ? "Purchased"
                      : userData?.user?.settings?.tipText
                      ? capitalizeEveryFirstLetter(
                          userData.user.settings.tipText
                        )
                      : "Tips"}
                  </td>

                  <td>
                    <div
                      className="d-flex align-items-center"
                      onClick={() =>
                        history.push(
                          `/messages?followerId=${transaction?._from?._id}`
                        )
                      }
                    >
                      <img
                        src={
                          getLowResolutionLink(
                            transaction?._from?.profilePicture
                          ) || DEFAULT_PROFILE_PICTURE
                        }
                        onError={(e) =>
                          (e.target.src = DEFAULT_PROFILE_PICTURE)
                        }
                        alt="Fan Img"
                        className="userImg-Comment"
                        loading="lazy"
                      />
                      {handleNameObj(transaction?._from?.name) || "N/A"}
                    </div>
                  </td>
                  <td>
                    {transaction?.amount
                      ? formatCurrencyValue(transaction.amount)
                      : "N/A"}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="text-center">
                  {loading ? <CustomLoader /> : "There is no data to display."}
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </ModalBody>
      <ModalFooter>
        <Button className="modalBtnCancel" onClick={() => _closeModal()}>
          Close
        </Button>

        {canViewAndSendMessage && (
          <Button
            className="themeBtn addBtn"
            onClick={_initiateMessage}
            disabled={!selectedFanForMessages?.length}
          >
            Next
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default VaultTransactionModal;
