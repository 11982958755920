import React from "react";
import Modal from "react-modal";
import { Button } from "reactstrap";
import AudioPlayer from "../custom/AudioPlayer";
import CustomVideoPlayer from "../custom/CustomVideoPlayer";
import ErrorBoundary from "../ErrorBoundary";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1000,
  },
};

const ShowMediaModal = ({
  isOpen,
  toggle,
  externalLink,
  contentType,
  forUpload,
  uploadPreviewData,
  thumbnail,
  hlsLink,
  duration,
}) => {
  const _closeModal = () => {
    toggle();
  };

  const _renderContent = () => {
    switch (contentType) {
      case "image": {
        return (
          <img
            src={externalLink}
            alt="mediaFeed"
            className="mediaPhoto-Feed"
            loading="lazy"
          />
        );
      }
      case "video": {
        return (
          <>
            <ErrorBoundary>
              {/* custom video player support hls */}
              <CustomVideoPlayer
                className="showMediaModal"
                src={externalLink}
                hlsLink={hlsLink}
                thumbnail={thumbnail}
                duration={duration}
                controls
              />
            </ErrorBoundary>
          </>
        );
      }
      case "audio": {
        return (
          <>
            <ErrorBoundary>
              <AudioPlayer
                className="w-100 h-100 d-flex justify-content-center align-items-center bg-white rounded"
                url={externalLink}
              />
            </ErrorBoundary>
          </>
        );
      }
      default:
        return null;
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => _closeModal()}
      style={customStyles}
      contentLabel="Example Modal"
      ariaHideApp={false}
    >
      <button onClick={() => _closeModal()} className="closeModalFeed">
        <i className="fa fa-close modalFeed_closeIcon" />
      </button>

      {externalLink ? (
        <>
          {_renderContent()}

          {forUpload && uploadPreviewData ? (
            <Button
              onClick={() => uploadPreviewData()}
              className="float-right themeBtn uploadBtn mt-4 mr-4"
            >
              <i className="fa fa-upload mr-1" />
              Upload
            </Button>
          ) : null}
        </>
      ) : (
        <>No Content found</>
      )}
    </Modal>
  );
};

export default ShowMediaModal;
