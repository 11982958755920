import React, { useState } from "react";
import ShowMediaModal from "../modals/ShowMediaModal";
import {
  capitalize,
  formatCurrencyValue,
  handleBrokenImageURL,
  handleNameObj,
} from "../../helper-methods";
import IMAGE_ICON from "../../assets/img/image_icon.svg";

const ChatImage = ({ message, fan }) => {
  const _handleBrokenImageURL = handleBrokenImageURL();

  const [showMediaModal, setShowMediaModal] = useState({
    isOpen: false,
    externalLink: "",
    contentType: "",
  });

  const _toggleShowMediaModal = (
    isOpen = false,
    externalLink = "",
    contentType = ""
  ) => {
    setShowMediaModal({
      isOpen,
      externalLink,
      contentType,
    });
  };

  return (
    <>
      <div className="mediaPhotoWrap">
        <img
          src={message?.content?.[0]?._contentId?.url}
          alt="Media"
          onClick={() => {
            _toggleShowMediaModal(
              true,
              message?.content?.[0]?._contentId?.url,
              "image"
            );
          }}
          onError={(e) =>
            _handleBrokenImageURL({
              event: e,
              url: message?.content?.[0]?._contentId?.url,
              fallbackImg: IMAGE_ICON,
            })
          }
          loading="lazy"
        />

        {!message.isPublic ? (
          <div className="msgPrice">
            {message?.content?.[0]?.amount
              ? formatCurrencyValue(message.content[0].amount)
              : message.amount
              ? formatCurrencyValue(message.amount)
              : ""}
          </div>
        ) : null}
      </div>

      {message?.hasPurchased ? (
        <div className="paidMessageText">
          {fan?.name?.full || fan?.name?.first
            ? capitalize(handleNameObj(fan.name))
            : "Fan"}{" "}
          Paid{" "}
          {message?.content?.[0]?.amount
            ? formatCurrencyValue(message.content[0].amount)
            : message.amount
            ? formatCurrencyValue(message.amount)
            : ""}{" "}
          for this message
        </div>
      ) : null}

      {showMediaModal.externalLink ? (
        <ShowMediaModal
          isOpen={showMediaModal.isOpen}
          externalLink={showMediaModal.externalLink}
          contentType={showMediaModal.contentType}
          toggle={() => _toggleShowMediaModal()}
        />
      ) : null}
    </>
  );
};

export default ChatImage;
