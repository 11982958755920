import React, { Component } from "react";
import { UploadQueueManager } from "../upload-queue-manager";
import { Spinner } from "reactstrap";
import { onFilePercentageChange } from "../helper-methods/index";
class MediaQueueViewer extends Component {
  state = { queueCount: 0, percentage: 0 };

  componentDidMount() {
    UploadQueueManager.onQueueCountChange((queueCount) => {
      this.setState({ queueCount });
    });

    let uploadingKeys = {};

    onFilePercentageChange(({ percentage, key }) => {
      uploadingKeys[key] = percentage;

      const totalPercentage = Object.values(uploadingKeys).reduce(
        (acc, each) => acc + each,
        0
      );

      percentage = Math.round(
        totalPercentage / Object.values(uploadingKeys).length
      );

      if (percentage <= 0 || percentage >= 99) {
        percentage = 0;
        uploadingKeys = {};
      }

      this.setState({ percentage });
    });
  }

  render() {
    const { queueCount, percentage } = this.state;

    return (
      <>
        {queueCount > 0 ? (
          <div id="mediaQueueViewerWrapper">
            <div id="uploadProgressHeader">
              <Spinner
                style={{
                  width: "1.1rem",
                  height: "1.1rem",
                  marginRight: 7,
                  verticalAlign: -4
                }}
              />
              {queueCount > 1
                ? `${queueCount} Uploads in progress`
                : `${queueCount} Upload in progress`}{" "}
              {percentage ? `${percentage}%` : null}
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

export default MediaQueueViewer;
