import React, { useState } from "react";
import { useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Row,
  Col,
  Input,
  Label,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import { CURRENCY_SYMBOL } from "../../config";
import {
  errorHandler,
  isValidPrice,
  showToast,
  uploadFileOnServer,
} from "../../helper-methods";
import { AmountConfig } from "../../config/appConfig";

const AddStickerModal = ({ isOpen, data, toggle, updateManageSticker }) => {
  const [formFields, setFormFields] = useState({
    name: "",
    price: "",
    sticker: {
      previewBlob: null,
      uploadData: null,
      uploadUrl: null,
      type: "image",
    },
  });
  // eslint-disable-next-line no-unused-vars
  const [isDirty, setIsDirty] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const _setModalState = (data = null) => {
    setFormFields({
      name: data?.name || "",
      price: data?.price ? data.price.toString() : "",
      sticker: {
        previewBlob: null,
        uploadData: null,
        uploadUrl: data?.url || null,
        type: "image",
      },
    });
    // eslint-disable-next-line no-unused-vars
    setIsDirty({});
    setErrors({});
    setLoading(false);
  };

  const _closeModal = () => {
    _setModalState();
    toggle();
  };

  /**
   * To Validate the form and show the error messages
   */
  const _validateFormfields = ({ newFormFields, newIsDirty }) => {
    return new Promise((resolve) => {
      let isFormValid = true;
      const newErrors = {};

      Object.keys(newFormFields).forEach((key) => {
        if (newIsDirty[key]) {
          switch (key) {
            case "name": {
              if (newFormFields[key]?.trim().length) {
                newErrors[key] = null;
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "price": {
              if (newFormFields[key]?.trim().length) {
                if (
                  isNaN(newFormFields[key]) ||
                  +newFormFields[key] < AmountConfig.minCheerIconPrice ||
                  +newFormFields[key] > AmountConfig.maximumLimit
                ) {
                  newIsDirty[key] = true;
                  newErrors[key] =
                    +newFormFields[key] > AmountConfig.maximumLimit
                      ? `*Maximum ${CURRENCY_SYMBOL}${AmountConfig.maximumLimit}`
                      : `*Minimum ${CURRENCY_SYMBOL}${AmountConfig.minCheerIconPrice}`;
                  isFormValid = false;
                } else {
                  newIsDirty[key] = false;
                  newErrors[key] = null;
                }
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "sticker": {
              if (
                newFormFields[key]?.uploadData ||
                newFormFields[key]?.uploadUrl
              ) {
                newErrors[key] = null;
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            default:
          }
        }
      });

      setIsDirty((prev) => ({
        ...prev,
        ...newIsDirty,
      }));
      setErrors((prev) => ({
        ...prev,
        ...newErrors,
      }));
      resolve(isFormValid);
    });
  };

  const _onChangeLogoFile = (event, key) => {
    if (!event?.target?.files?.length) {
      return;
    }

    const newFormFields = { ...formFields };

    const file = event.target.files[0];
    const fileType = file.type.split("/")[0];

    if (fileType !== "image") {
      showToast("Only image file is allowed", "error");
      return;
    }

    file.isPublicContent = true;

    newFormFields[key] = {
      uploadData: file,
      previewBlob: URL.createObjectURL(file),
      type: fileType,
      uploadUrl: null,
    };

    setFormFields(newFormFields);

    const newIsDirty = {
      [key]: true,
    };
    _validateFormfields({ newFormFields, newIsDirty });
  };

  const _onChangeFormfield = (key, value) => {
    if (key === "price" && value && !isValidPrice(value)) {
      return;
    }

    const newFormFields = { ...formFields };
    newFormFields[key] = value;
    setFormFields(newFormFields);
  };

  const _onBlurFormfield = (key) => {
    const newFormFields = { ...formFields };
    const newIsDirty = {
      [key]: true,
    };
    _validateFormfields({ newFormFields, newIsDirty });
  };

  const _markAllIsDirty = () => {
    return new Promise((resolve) => {
      const newFormFields = { ...formFields };
      const newIsDirty = { ...isDirty };
      Object.keys(newFormFields).forEach((key) => {
        newIsDirty[key] = true;
      });
      setIsDirty(newIsDirty);
      resolve(newIsDirty);
    });
  };

  const _onSubmit = async () => {
    try {
      const newFormFields = { ...formFields };
      const newIsDirty = await _markAllIsDirty();

      const isFormValid = await _validateFormfields({
        newFormFields,
        newIsDirty,
      });

      if (!isFormValid) {
        return;
      }

      setLoading(true);

      let payload = {
        name: newFormFields.name.trim(),
        price: +newFormFields.price.trim(),
      };

      if (newFormFields.sticker.uploadData) {
        if (newFormFields.sticker.uploadUrl) {
          payload["url"] = formFields.sticker.uploadUrl;
        } else {
          const uploadedFilesRes = await uploadFileOnServer([
            formFields.sticker,
          ]);
          payload["url"] = uploadedFilesRes[0].url;
          newFormFields.sticker["uploadUrl"] = uploadedFilesRes[0].url;
          setFormFields(newFormFields);
        }
      }

      await updateManageSticker(payload, data);

      showToast("Cheer Icons Updated", "success");

      _closeModal();
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen && data) {
      _setModalState(data);
    }
  }, [isOpen, data]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => _closeModal()}
      className="modal-dialog-centered modal-dialog-scrollable"
    >
      <ModalHeader toggle={() => _closeModal()}>
        {data ? "Update" : "Add"} Cheer Icons
      </ModalHeader>
      <ModalBody>
        <Row className="align-items-center">
          <Col md="4">
            <div className="img120by120 d-block mx-auto mb-0">
              {formFields?.sticker?.previewBlob || data?.url ? (
                <img
                  src={formFields?.sticker?.previewBlob || data?.url}
                  alt="Cheer Icons"
                  loading="lazy"
                />
              ) : null}
              <Label className="uploadBtn">
                <Input
                  type="file"
                  value=""
                  style={{ display: "none" }}
                  accept="image/*"
                  onChange={(e) => _onChangeLogoFile(e, "sticker")}
                  disabled={loading}
                />
                <i className="fa fa-pencil" />
              </Label>
            </div>

            {errors?.sticker && (
              <div className="validation-error">{errors?.sticker}</div>
            )}
          </Col>
          <Col md="8">
            <FormGroup>
              <Label>Name</Label>
              <Input
                type="text"
                placeholder="Cheer Icons Name"
                name="name"
                spellCheck={false}
                value={formFields.name}
                onChange={(e) => _onChangeFormfield("name", e.target.value)}
                onBlur={(e) => _onBlurFormfield("name")}
                className={errors?.name ? "validation-error" : ""}
              />
              {errors?.name && (
                <div className="validation-error">{errors?.name}</div>
              )}
            </FormGroup>

            <FormGroup>
              <Label>Price</Label>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText className="dollarWrap">
                    <i className="fa fa-dollar" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  type="text"
                  placeholder="Cheer Icons Price"
                  name="name"
                  value={formFields.price}
                  onChange={(e) => _onChangeFormfield("price", e.target.value)}
                  onBlur={(e) => _onBlurFormfield("price")}
                  className={
                    errors?.price
                      ? "validation-error dollarWrapInput"
                      : "dollarWrapInput"
                  }
                />
                {errors?.price && (
                  <div className="validation-error">{errors?.price}</div>
                )}
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button className="modalBtnCancel" onClick={() => _closeModal()}>
          Cancel
        </Button>
        <Button
          className="modalBtnSave"
          type="submit"
          disabled={loading}
          onClick={() => _onSubmit()}
        >
          {loading ? <i className="fa fa-spinner fa-spin mr-1" /> : null}{" "}
          {data ? "Update" : "Add"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddStickerModal;
