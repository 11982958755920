import React, { useMemo, useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Input,
  Label,
} from "reactstrap";
import {
  APP_NAME,
  CURRENCY_SYMBOL,
  INSTANT_PAYOUT_DEDUCT_PERCENTAGE,
  PAYMENT_GATEWAY,
} from "../../config";
import {
  checkInstantPaymentValidCountry,
  errorHandler,
  formatCurrencyValue,
  formatDecimalNumber,
  isValidPrice,
  showToast,
} from "../../helper-methods";
import { createWithdrawalRequest } from "../../http-calls";
import CustomTooltip from "../custom/CustomTooltip";

const RequestWithdrawalModal = ({
  isOpen,
  toggle,
  canMakePayoutRequest,
  userData,
  resetTabData,
  availableToWithdraw,
}) => {
  const history = useHistory();

  const [formFields, setFormFields] = useState({
    amount: "",
  });
  // eslint-disable-next-line no-unused-vars
  const [isDirty, setIsDirty] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [isInstant, setIsInstant] = useState(false);
  const [isInstantByDebitCard, setIsInstantByDebitCard] = useState(false);
  const [isShowPayoutMethod, setIsShowPayoutMethod] = useState(false);

  const calculateExtraCharge = useMemo(() => {
    if (!isNaN(+formFields?.amount) && +formFields?.amount >= 5) {
      const extraCharge =
        +formFields?.amount * (INSTANT_PAYOUT_DEDUCT_PERCENTAGE / 100);
      return extraCharge ? formatDecimalNumber(extraCharge) : 0;
    }
    return 0;
  }, [formFields?.amount]);

  const _resetModalState = () => {
    setFormFields({
      amount: "",
    });
    setIsDirty({});
    setErrors({});
    setLoading(false);
    setIsInstant(false);
    setIsInstantByDebitCard(false);
    setIsShowPayoutMethod(false);
  };

  const _closeModal = () => {
    _resetModalState();
    toggle();
  };

  useEffect(() => {
    if (
      isOpen &&
      checkInstantPaymentValidCountry("all", userData?.user?.address?.country)
    ) {
      if (
        checkInstantPaymentValidCountry(
          "byDebitCard",
          userData?.user?.address?.country
        )
      ) {
        setIsInstant(true);
        setIsInstantByDebitCard(true);
        setIsShowPayoutMethod(true);
      } else {
        setIsInstant(true);
        setIsInstantByDebitCard(false);
        setIsShowPayoutMethod(true);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const _validateFormfields = ({ newFormFields, newIsDirty }) => {
    return new Promise((resolve) => {
      let isFormValid = true;
      const newErrors = {};

      let availableBalance = availableToWithdraw;

      if (isInstant) {
        availableBalance = availableToWithdraw - calculateExtraCharge;
      }

      Object.keys(newFormFields).forEach((key) => {
        if (newIsDirty[key]) {
          switch (key) {
            case "amount": {
              if (newFormFields[key]?.trim().length) {
                if (
                  !isNaN(newFormFields[key]) &&
                  +newFormFields[key] >= 5 &&
                  +newFormFields[key] <= availableBalance
                ) {
                  newErrors[key] = null;
                  newIsDirty[key] = false;
                } else {
                  newErrors[key] =
                    availableBalance > 5
                      ? `*Minimum ${CURRENCY_SYMBOL}5 & Maximum ${formatCurrencyValue(
                          availableBalance
                        )}`
                      : "*Insufficient funds";
                  isFormValid = false;
                }
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            default:
          }
        }
      });

      setIsDirty((prev) => ({
        ...prev,
        ...newIsDirty,
      }));
      setErrors((prev) => ({
        ...prev,
        ...newErrors,
      }));
      resolve(isFormValid);
    });
  };

  const _onChangeFormfield = (key, value) => {
    if (key === "amount" && value && !isValidPrice(value)) {
      return;
    }

    const newFormFields = { ...formFields };
    newFormFields[key] = value;
    setFormFields(newFormFields);
  };

  const _onBlurFormfield = (key) => {
    const newFormFields = { ...formFields };
    const newIsDirty = {
      [key]: true,
    };
    _validateFormfields({ newFormFields, newIsDirty });
  };

  const _markAllIsDirty = () => {
    return new Promise((resolve) => {
      const newFormFields = { ...formFields };
      const newIsDirty = { ...isDirty };
      Object.keys(newFormFields).forEach((key) => {
        newIsDirty[key] = true;
      });
      setIsDirty(newIsDirty);
      resolve(newIsDirty);
    });
  };

  const _createWithdrawalRequest = async (payload) => {
    try {
      setLoading(true);

      const res = await createWithdrawalRequest(payload);

      if (res.isFirstTime) {
        showToast(
          "Congratulations! Your funds are being authenticated. First time withdraws are manually reviewed by Stripe before they are released this can take 24-72hrs."
        );
      } else {
        showToast("Withdrawal request generated successfully", "success");
      }

      _closeModal();

      resetTabData();
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  const _withdraw = async () => {
    try {
      const newFormFields = { ...formFields };
      const newIsDirty = await _markAllIsDirty();

      const isFormValid = await _validateFormfields({
        newFormFields,
        newIsDirty,
      });

      if (!isFormValid) {
        return;
      }

      if (
        PAYMENT_GATEWAY === "stripe" &&
        isInstant &&
        isInstantByDebitCard &&
        !userData?.user?.payoutCard
      ) {
        // condition add for check Debit card
        showToast("Please add a Debit Card to withdraw your funds.", "error");
        return;
      }

      const payload = {
        amount: +formFields?.amount?.trim(),
        isInstant,
      };

      _createWithdrawalRequest(payload);
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => _closeModal()}
      className="modal-dialog-centered modal-dialog-scrollable"
    >
      <ModalHeader toggle={() => _closeModal()}>Request Withdrawal</ModalHeader>
      <ModalBody>
        <Label style={{fontSize: 14, marginBottom: 5}}>Enter withdrawal amount</Label>
        <div className="mb-3">
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText className="dollarWrap">
                <i className="fa fa-dollar" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              type="text"
              placeholder="Enter"
              name="amount"
              value={formFields.amount}
              onChange={(e) => _onChangeFormfield("amount", e.target.value)}
              onBlur={() => _onBlurFormfield("amount")}
              disabled={loading}
              className="dollarWrapInput"
            />
          </InputGroup>
          {errors?.amount ? (
            <div className="form-error">{errors.amount}</div>
          ) : null}{" "}
        </div>

        {PAYMENT_GATEWAY === "stripe" && isShowPayoutMethod ? (
          <>
            {/* UI for instant payout */}
            <Label style={{fontSize: 14, marginBottom: 5}}>
              Select Method
              <sup
                id="request_withdrawal_modal_select_method"
                className="infoIcon"
              >
                <i className="fa fa-info-circle" />
              </sup>
              <CustomTooltip
                text={
                  <>
                    <b>Instant Withdrawal:</b> <br />
                    Transfer your funds to any supported{" "}
                    {isInstantByDebitCard ? "debit card" : "bank account"}{" "}
                    within minutes. (1.5% of withdrawal amount) <br /> <br />
                    <b>Standard Withdrawal:</b> <br />
                    Receive your funds within 3-5 business days (No fee)
                  </>
                }
                target="request_withdrawal_modal_select_method"
              />
            </Label>

            <div className="w-100 d-flex">
              <Label check className="selectMethod">
                <Input
                  type="radio"
                  name="isInstant"
                  checked={isInstant}
                  onChange={() => setIsInstant(true)}
                />
                <div>
                  <span className="d-flex align-items-center">
                    <i className="fa fa-bolt" /> Instant
                  </span>
                  <p>
                    <sup id="request_withdrawal_modal_change_fee_note">
                      <i
                        className="fa fa-info-circle infoIcon mr-1"
                        style={{ fontSize: 14 }}
                      />
                    </sup>
                    <CustomTooltip
                      text={`The Fee price shown will change depending on the amount you choose to withdraw.`}
                      target="request_withdrawal_modal_change_fee_note"
                    />
                    Receive in minutes{" "}
                    {calculateExtraCharge
                      ? `Fee ${formatCurrencyValue(calculateExtraCharge)}`
                      : ""}
                  </p>
                  <p>Fee 1.5% of withdrawal amount</p>
                </div>
              </Label>

              <Label check className="selectMethod">
                <Input
                  type="radio"
                  name="isInstant"
                  checked={!isInstant}
                  onChange={(e) => setIsInstant(false)}
                />
                <div>
                  <span className="d-flex align-items-center">
                    <i className="fa fa-bank" />
                    Standard
                  </span>
                  <p>Receive in 3-5 days</p>
                  <p>No Fee</p>
                </div>
              </Label>
            </div>
          </>
        ) : null}

        <div className="transferToWrap">
          <Label>Transfer To</Label>

          {PAYMENT_GATEWAY === "stripe" && isInstant && isInstantByDebitCard ? (
            <>
              {/* if "instant" option is selected */}
              <div>
                <p>
                  <i className="fa fa-credit-card mr-1" /> Debit Card
                </p>
                {userData?.user?.payoutCard ? (
                  <div className="accInfo flex-column align-items-end">
                    <div className="d-flex align-items-center">
                      {/* don't remove the below span's. They are here for design purpose */}
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      {PAYMENT_GATEWAY === "stripe"
                        ? userData?.user?.payoutCard?.last4
                        : userData?.user?.payoutCard?.cardLastFourDigits}
                    </div>

                    <div className="d-flex align-items-center" style={{marginTop: 2}}>
                      {userData?.user?.payoutCard?.exp_month &&
                      userData?.user?.payoutCard?.exp_year ? (
                        <p>
                          {userData?.user?.payoutCard?.exp_month}/
                          {userData?.user?.payoutCard?.exp_year
                            .toString()
                            .slice(-2)}
                        </p>
                      ) : null}
                      <h5 className="cardBrand">
                        {userData?.user?.payoutCard?.brand?.toUpperCase()}
                      </h5>
                    </div>
                  </div>
                ) : (
                  <div>
                    <p style={{ fontSize: 10 }}>
                      {APP_NAME} cannot transfer payments to you currently.
                      Please add a Debit Card to withdraw your funds.
                    </p>
                    <Button
                      color="link"
                      className="addCardInfo"
                      onClick={() =>
                        history.push(`/settings#add_debit_card_setting_page`)
                      }
                    >
                      Add Debit Card
                    </Button>
                  </div>
                )}
              </div>
            </>
          ) : (
            // {/* if "standard" option is selected */}
            <div>
              <p>
                <i className="fa fa-bank mr-1" /> Bank Account
              </p>
              {userData?.user?.hasBank &&
              userData?.user?.bank?.accountNumber ? (
                <div className="accInfo">
                  {userData.user.bank.accountNumber
                    .slice(0, -4)
                    .split("")
                    .map((e, i) => (
                      <span key={e + i}></span>
                    ))}
                  {userData.user.bank.accountNumber.slice(-4)}
                </div>
              ) : (
                <div>
                  <p style={{ fontSize: 10 }}>
                    {APP_NAME} cannot transfer payments to you currently. Please
                    add a Bank Account to withdraw your funds.
                  </p>
                  <Button
                    color="link"
                    className="addCardInfo"
                    onClick={() =>
                      history.push(`/settings#add_bank_info_setting_page`)
                    }
                  >
                    Add Bank Account
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button className="modalBtnCancel" onClick={() => _closeModal()}>
          Cancel
        </Button>
        {canMakePayoutRequest ? (
          <Button
            className="modalBtnSave"
            disabled={loading}
            onClick={() => _withdraw()}
          >
            {loading ? <i className="fa fa-spinner fa-spin mr-1" /> : null}
            Withdraw
          </Button>
        ) : null}
      </ModalFooter>
    </Modal>
  );
};

export default RequestWithdrawalModal;
