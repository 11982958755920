import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  deepClone,
  errorHandler,
  getLowResolutionLink,
  getWindowDimensions,
  handleNameObj,
} from "../helper-methods";
import {
  Container,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Button,
  Input,
  Label,
  CustomInput,
  Collapse,
  FormGroup,
} from "reactstrap";
import { DEFAULT_PROFILE_PICTURE } from "../config";
import {
  priceCount,
  longerPlanCount,
  tierConfig,
} from "../config/chatFanListConfig";
import { getInfluencerFollowers } from "../http-calls/index";
import colors from "../assets/styles/scss/style.scss";
import { useHistory } from "react-router-dom";
import CustomLoader from "./custom/CustomLoader";
import { useDispatch, useSelector } from "react-redux";
import { saveLocalFilters } from "../redux/actions";
import SkeletonLoading from "./SkeletonLoading";

const ChatFanList = ({ switchToBroadCast }) => {
  const history = useHistory();

  const dispatch = useDispatch();

  const userData = useSelector((state) => state?.userData);
  const localFilters = useSelector((state) => state?.localFilters);

  const observer = useRef();
  const searchTimerRef = useRef({ current: null });

  const [followers, setFollowers] = useState([]);
  const [followersCount, setFollowersCount] = useState(0);
  const [selectedFollowers, setSelectedFollowers] = useState([]);
  const [filters, setFilters] = useState({
    isNonExpired: true,
    isRecentSubscriber: false,
    isRecentlyCancelled: false,
    isExpired: false,
    isFavourited: false,
    tier: "", // 	enum = ["basic", "plus", "premium"]
    amountSpent: "", // { max: number, min: number }
    billingCycle: "", // number
    search: "",
  });
  const [dataPayload, setDataPayload] = useState({
    filters: {},
    skip: 0,
    limit: 20,
  });
  const [loadingState, setLoadingState] = useState({
    data: false,
  });
  const [isSelectedAllFollowers, setIsSelectedAllFollowers] = useState(false);
  const [isOpenFilterCollapse, setIsOpenFilterCollapse] = useState(false);

  const _manageLoadingState = (key = "", value = false) => {
    setLoadingState((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const _toggleIsSelectedAllFollowers = () => {
    setIsSelectedAllFollowers((prev) => !prev);
    setSelectedFollowers([]);
  };

  const _toggleSelection = (follower = null, isToggleCurrentFan = false) => {
    let newSelectedFollowers = [...selectedFollowers];

    if (follower) {
      if (isSelectedAllFollowers) {
        newSelectedFollowers = followers.filter(
          (sf) => sf._fan.id !== follower._fan.id
        );
      } else {
        const isPresent = newSelectedFollowers.find(
          (sf) => sf._fan.id === follower._fan.id
        );

        // Check status first
        if (isPresent) {
          // Remove from selected followers
          newSelectedFollowers = newSelectedFollowers.filter(
            (sf) => sf._fan.id !== follower._fan.id
          );
        } else {
          // add in selected followers
          newSelectedFollowers.push(follower);
        }
      }
    } else {
      newSelectedFollowers = [];
      if (isToggleCurrentFan) {
        newSelectedFollowers = [...followers];
      }
    }

    setIsSelectedAllFollowers(false);
    setSelectedFollowers(newSelectedFollowers);
  };

  const _initiateSingleThread = (id, type) => {
    if (type === "follower") {
      history.push(`/messages?followerId=${id}`);
    } else {
      history.push(`/messages?threadId=${id}`);
    }
  };

  const _initiateMessage = () => {
    if (
      !selectedFollowers?.length &&
      !(isSelectedAllFollowers && followersCount)
    ) {
      return;
    }

    if (selectedFollowers.length === 1) {
      // Initiate single thread
      // Check if already thread exists
      const selectedFollower = selectedFollowers[0];
      if (selectedFollower.hasThread) {
        _initiateSingleThread(selectedFollower.thread.threadId, "thread");
      } else {
        _initiateSingleThread(selectedFollower._fan._id, "follower");
      }
    } else {
      // Initiate broadcast message
      switchToBroadCast(
        selectedFollowers,
        isSelectedAllFollowers,
        followersCount
      );
    }
  };

  const _getInfluencerFollowers = async (payload) => {
    try {
      _manageLoadingState("data", true);

      const res = await getInfluencerFollowers(payload);
      setFollowers((prev) =>
        payload?.skip ? prev.concat(res.followers) : res.followers
      );
      setFollowersCount(res.count || 0);
      if (!payload?.skip) {
        setIsSelectedAllFollowers(false);
      }

      setLoadingState({});
    } catch (error) {
      errorHandler(error);
      setLoadingState({});
    }
  };

  const _onFiltersChange = (newFilters) => {
    const newDataPayload = { ...dataPayload };
    newDataPayload.skip = 0;
    newDataPayload.filters = {};

    dispatch(
      saveLocalFilters({ key: "chatFanList", value: deepClone(newFilters) })
    );

    Object.keys(newFilters).forEach((key) => {
      switch (key) {
        case "isNonExpired":
        case "isRecentSubscriber":
        case "isRecentlyCancelled":
        case "isExpired":
        case "isFavourited": {
          newDataPayload.filters[key] = newFilters[key];
          break;
        }
        case "amountSpent": {
          if (newFilters[key]?.length) {
            newDataPayload.filters[key] = JSON.parse(newFilters[key]);
          }
          break;
        }
        case "search": {
          if (newFilters[key]?.trim().length) {
            newDataPayload[key] = newFilters[key];
          } else {
            newDataPayload[key] = "";
          }
          break;
        }
        default: {
          if (newFilters[key]?.length) {
            newDataPayload.filters[key] = newFilters[key];
          }
          break;
        }
      }
    });

    setDataPayload(newDataPayload);

    _getInfluencerFollowers(newDataPayload);
  };

  const _resetFilterData = () => {
    const newFilters = { ...filters };
    Object.keys(newFilters).forEach((key) => {
      switch (key) {
        case "isNonExpired": {
          newFilters[key] = true;
          break;
        }
        case "isRecentSubscriber":
        case "isRecentlyCancelled":
        case "isFavourited":
        case "isExpired": {
          newFilters[key] = false;
          break;
        }
        default: {
          newFilters[key] = "";
          break;
        }
      }
    });
    setFilters(newFilters);

    _onFiltersChange(newFilters);
  };

  const _onChangeFilters = (key, value) => {
    if (loadingState.data) return;

    const newFilters = { ...filters };

    if (
      key === "isNonExpired" ||
      key === "isRecentSubscriber" ||
      key === "isRecentlyCancelled" ||
      key === "isExpired"
    ) {
      newFilters.isNonExpired = false;
      newFilters.isRecentSubscriber = false;
      newFilters.isRecentlyCancelled = false;
      newFilters.isExpired = false;
    }

    newFilters[key] = value;

    setFilters(newFilters);

    _onFiltersChange(newFilters);
  };

  const _onChangeSearchText = (value) => {
    if (searchTimerRef?.current) clearTimeout(searchTimerRef.current);

    _manageLoadingState("search", true);

    const newFilters = { ...filters };
    newFilters.search = value;
    setFilters(newFilters);

    searchTimerRef.current = setTimeout(() => {
      _onFiltersChange(newFilters);
    }, 1000);
  };

  const lastElementRef = useCallback(
    (node) => {
      if (loadingState?.data) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && followers?.length < followersCount) {
          const newDataPayload = { ...dataPayload };
          newDataPayload.skip = followers?.length || 0;
          setDataPayload(newDataPayload);
          _getInfluencerFollowers(newDataPayload);
        }
      });

      if (node) observer.current.observe(node);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loadingState?.data, followers, followersCount]
  );

  const _isFollowerSelected = (fan) => {
    try {
      if (isSelectedAllFollowers) return true;

      const isSelected = selectedFollowers.find(
        (follower) => follower._fan.id === fan.id
      );

      return isSelected ? true : false;
    } catch (error) {
      return false;
    }
  };

  const _initializeLocalFilter = () => {
    const newFilters = { ...filters };

    Object.keys(newFilters).forEach((key) => {
      switch (key) {
        case "isNonExpired":
        case "isRecentSubscriber":
        case "isRecentlyCancelled":
        case "isExpired":
        case "isFavourited": {
          newFilters[key] = localFilters.filters?.chatFanList?.[key]
            ? true
            : false;
          break;
        }
        default: {
          if (localFilters.filters?.chatFanList?.[key]?.length) {
            newFilters[key] = localFilters.filters?.chatFanList?.[key];
          }
          break;
        }
      }
    });

    setFilters(newFilters);
    _onFiltersChange(newFilters);
  };

  useEffect(() => {
    if (localFilters?.filters?.chatFanList) {
      _initializeLocalFilter();
    } else {
      _getInfluencerFollowers(dataPayload);
    }

    const { screenWidth } = getWindowDimensions();

    if (screenWidth > 768) {
      setIsOpenFilterCollapse(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="customPgHeight animated fadeIn">
      <Container className="noPadding">
        <Row className="noMargin">
          <Col xs="12" className="noPadding">
            <div className="pgTitle customMarginMob">
              <div className="d-flex align-items-center">
                <Button
                  className="customBackBtn"
                  onClick={() => history.goBack()}
                >
                  <i className="fa fa-chevron-left" />
                </Button>

                <h2>New Message</h2>
                {loadingState?.data ? <CustomLoader className="mx-2" /> : null}
              </div>

              <Button
                onClick={() => setIsOpenFilterCollapse((prev) => !prev)}
                className="toggleBtn ml-auto mr-2"
              >
                <img
                  src="/assets/img/filter-icon.png"
                  alt="Filter Icon"
                  height="24"
                  loading="lazy"
                />
              </Button>

              {/* by default, this btn should be disabled, once any subscriber is selected from the list below, then only the below btn should turn active. */}
              <Button className="themeBtn addBtn" onClick={_initiateMessage}>
                Next
              </Button>
            </div>

            <Collapse
              isOpen={isOpenFilterCollapse}
              className="massMsgFilterWrap"
            >
              <Row className="colPaddingWrap">
                <Col xs="6" md="3" lg="3" className="colPadding">
                  {/* toggle filter by active subscriber */}
                  <Button
                    className={
                      filters.isNonExpired
                        ? ["activeMassMessageFilterBtn"].join(" ")
                        : ""
                    }
                    onClick={() => _onChangeFilters("isNonExpired", true)}
                  >
                    Active Subscribers
                  </Button>
                </Col>
                <Col xs="6" md="3" lg="3" className="colPadding">
                  {/* toggle filter by Recent subscriber */}
                  <Button
                    className={
                      filters.isRecentSubscriber
                        ? ["activeMassMessageFilterBtn"].join(" ")
                        : ""
                    }
                    onClick={() => _onChangeFilters("isRecentSubscriber", true)}
                  >
                    Recent Subscribers
                  </Button>
                </Col>
                <Col xs="6" md="3" lg="3" className="colPadding">
                  {/* toggle filter by Recently Cancelled subscriber (still having access) */}
                  <Button
                    className={
                      filters.isRecentlyCancelled
                        ? ["activeMassMessageFilterBtn"].join(" ")
                        : ""
                    }
                    onClick={() =>
                      _onChangeFilters("isRecentlyCancelled", true)
                    }
                    style={{ lineHeight: 1.2 }}
                  >
                    Expiring Soon
                  </Button>
                </Col>
                <Col xs="6" md="3" lg="3" className="colPadding">
                  {/* toggle filter by Expired subscriber */}
                  <Button
                    className={
                      filters.isExpired
                        ? ["activeMassMessageFilterBtn"].join(" ")
                        : ""
                    }
                    onClick={() => _onChangeFilters("isExpired", true)}
                  >
                    Expired
                  </Button>
                </Col>
              </Row>

              <Row className="colPaddingWrapDropdown mt-1 mt-md-3">
                {userData?.user?.multiTierSubscription ? (
                  <Col xs="6" md="2" lg="3" className="colPadding">
                    <FormGroup>
                      <Label>Tier</Label>
                      <Input
                        type="select"
                        name="selectByTier"
                        value={filters.tier}
                        onChange={(e) =>
                          _onChangeFilters("tier", e.target.value)
                        }
                      >
                        <option value="">All</option>
                        {tierConfig.map((option, index) => (
                          <option key={index} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                ) : null}

                <Col xs="6" md="3" className="colPadding">
                  <FormGroup>
                    <Label>Amount Spent</Label>
                    <Input
                      type="select"
                      name="selectBySpentAlot"
                      value={filters.amountSpent}
                      onChange={(e) =>
                        _onChangeFilters("amountSpent", e.target.value)
                      }
                    >
                      <option value="">All</option>
                      {priceCount.map((option, index) => (
                        <option
                          key={index}
                          value={JSON.stringify(option.value)}
                        >
                          {option.label}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>

                <Col xs="6" md="3" className="colPadding">
                  <FormGroup>
                    <Label>Plan Duration</Label>
                    <Input
                      type="select"
                      name="selectByLongerPlan"
                      value={filters.billingCycle}
                      onChange={(e) =>
                        _onChangeFilters("billingCycle", e.target.value)
                      }
                    >
                      <option value="">All</option>
                      {longerPlanCount.map((option, index) => (
                        <option key={index} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>

                <Col xs="6" md="4" lg="3" className="colPadding">
                  <FormGroup>
                    <Label className="invisible">Filter</Label>
                    <div className="d-flex align-items-center">
                      <CustomInput
                        type="checkbox"
                        className="ml-sm-1"
                        id="Favourite_CustomCheckbox_ChatList_Page"
                        label="Favorite"
                        checked={filters.isFavourited ? true : false}
                        onChange={(e) =>
                          _onChangeFilters("isFavourited", e.target.checked)
                        }
                      />

                      <Button
                        className="themeBtn addBtn"
                        onClick={_resetFilterData}
                      >
                        Reset
                      </Button>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
            </Collapse>

            {/* on selecting the subscribers from the below list, the below DIVs gets added */}
            {selectedFollowers?.length ? (
              <div className="subscriberSelectedWrap">
                {selectedFollowers.map(
                  (selectedFollower, selectedFollowerIndex) => (
                    <div
                      className="subscriberSelected"
                      key={selectedFollowerIndex}
                    >
                      <div>
                        <p>{handleNameObj(selectedFollower?._fan?.name)}</p>
                        <span>@{selectedFollower._fan.username}</span>
                      </div>
                      <Button
                        color="link"
                        onClick={() => _toggleSelection(selectedFollower)}
                      >
                        <svg
                          id="blocking_5949247"
                          xmlns="http://www.w3.org/2000/svg"
                          width="256"
                          height="256"
                          viewBox="0 0 256 256"
                        >
                          <g id="object_80_">
                            <g id="Group_32" data-name="Group 32">
                              <path
                                id="Path_58"
                                data-name="Path 58"
                                d="M218.242,37.758A127.622,127.622,0,0,0,37,217.486q.376.379.756.756A127.622,127.622,0,0,0,219,38.514Q218.621,38.134,218.242,37.758ZM128,238A110,110,0,1,1,238,128,110.127,110.127,0,0,1,128,238Z"
                              />
                            </g>
                            <g id="Group_33" data-name="Group 33">
                              <path
                                id="Path_59"
                                data-name="Path 59"
                                d="M176.8,79.205a8.81,8.81,0,0,0-12.459,0L128,115.541,91.664,79.205A8.81,8.81,0,0,0,79.2,91.665L115.54,128,79.2,164.337a8.81,8.81,0,1,0,12.4,12.514l.055-.055L128,140.461,164.336,176.8A8.81,8.81,0,0,0,176.8,164.337L140.459,128,176.8,91.665a8.811,8.811,0,0,0,0-12.46Z"
                              />
                            </g>
                          </g>
                        </svg>
                      </Button>
                    </div>
                  )
                )}
              </div>
            ) : null}

            <div className="massMsgListWrap">
              {/* search bar to select fan from below list */}
              <div className="searchChatListWrap">
                <i className="fa fa-search searchIcon" />
                <Input
                  type="text"
                  placeholder="Search Subscribers"
                  className="searchChatList"
                  value={filters.search}
                  onChange={(e) => _onChangeSearchText(e.target.value)}
                />
                {filters.search ? (
                  loadingState.search ? (
                    <i className="fa fa-spinner fa-spin mr-1 clearSearch" />
                  ) : (
                    <i
                      className="fa fa-times clearSearch"
                      onClick={() => _onChangeSearchText("")}
                    />
                  )
                ) : null}
              </div>

              {followers?.length ? (
                <>
                  <div className="recentWrap">
                    <p>Select Subscriber(s)</p>

                    {/* separate div checkbox for toggle selected follower  */}
                    <div className="d-flex align-items-center">
                      <Label for="customCheckbox_chatFanList_isToggleCurrentFan">
                        {isSelectedAllFollowers
                          ? "Clear All"
                          : followers.length === selectedFollowers.length
                          ? `Clear All ${selectedFollowers.length}`
                          : `Select All ${followers.length}`}
                      </Label>

                      <CustomInput
                        type="checkbox"
                        id={`customCheckbox_chatFanList_isToggleCurrentFan`}
                        onChange={(e) =>
                          _toggleSelection(null, e.target.checked)
                        }
                        checked={
                          followers.length === selectedFollowers.length ||
                          isSelectedAllFollowers
                            ? true
                            : false
                        }
                      />
                    </div>
                  </div>

                  {(followers.length === selectedFollowers?.length &&
                    followers.length < followersCount) ||
                  isSelectedAllFollowers ? (
                    <div
                      className="text-center"
                      style={{ color: "#4f4f4f", marginTop: 15 }}
                    >
                      {isSelectedAllFollowers
                        ? `All ${followersCount} Subscribers are selected.`
                        : `All ${selectedFollowers.length} Subscribers on this page
                            are selected.`}
                      {!isSelectedAllFollowers ? (
                        <Button
                          color="link"
                          className="px-1 py-0"
                          style={{
                            color: colors?.themeColor,
                            marginTop: -1,
                          }}
                          onClick={() => _toggleIsSelectedAllFollowers()}
                        >
                          {isSelectedAllFollowers
                            ? "Clear selection"
                            : `Select all ${followersCount} Subscribers`}
                        </Button>
                      ) : null}
                    </div>
                  ) : null}
                </>
              ) : null}

              <ListGroup className="chatList">
                {followers?.length ? (
                  followers.map((each, index) => (
                    <Fragment key={each._id}>
                      <ListGroupItem>
                        <div
                          className="checkbox selectSubscriber"
                          onClick={() => _toggleSelection(each)}
                          {...(index === followers.length - 1
                            ? { ref: lastElementRef }
                            : {})}
                        >
                          <Label className="form-check-label">
                            <div className="userChatList">
                              <div className="position-relative mr-2">
                                {each.thread?.isFavourited ? (
                                  <i className="fa fa-star topFan" />
                                ) : null}
                                <img
                                  className="userImg"
                                  src={
                                    getLowResolutionLink(
                                      each._fan?.profilePicture
                                    ) || DEFAULT_PROFILE_PICTURE
                                  }
                                  onError={(e) =>
                                    (e.target.src = DEFAULT_PROFILE_PICTURE)
                                  }
                                  alt="Profile Img"
                                  loading="lazy"
                                />

                                {/* show the below icon only when the user subscription has expired */}
                                {each._fan?.isExpired ? (
                                  <i className="fa fa-times-circle expiredSubscriber" />
                                ) : null}
                              </div>
                              <div>
                                <h5>{handleNameObj(each._fan?.name)}</h5>
                                <span>@{each._fan?.username}</span>
                              </div>
                            </div>
                          </Label>
                        </div>

                        {/* separate div checkbox for toggle selected follower  */}
                        <CustomInput
                          className="form-check-input"
                          type="checkbox"
                          id={`customCheckbox_chatFanList_${each._fan?._id}`}
                          // onChange={(e) => {}}
                          onChange={() => _toggleSelection(each)}
                          checked={_isFollowerSelected(each._fan)}
                          label=""
                        />
                      </ListGroupItem>

                      {index === followers.length - 1 && loadingState.data && (
                        <SkeletonLoading type={"chatFanList"} count={4} />
                      )}
                    </Fragment>
                  ))
                ) : loadingState?.data ? (
                  <SkeletonLoading type={"chatFanList"} count={4} />
                ) : (
                  <div className="noContentFound">
                    There is no followers to display.
                  </div>
                )}
              </ListGroup>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ChatFanList;
