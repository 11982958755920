import cuid from "cuid";
import moment from "moment";
import "moment/min/locales";
import React, { Fragment, useCallback, useMemo, useRef, useState } from "react";
import { Button, Col, Container, Label, Row, Spinner } from "reactstrap";
import ChatAudio from "./ChatAudio";
import ChatImage from "./ChatImage";
import ChatPriceSetterModal from "../modals/ChatPriceSetterModal";
import ChatVideo from "./ChatVideo";
import FullScreenVideoRecorder from "../FullScreenVideoRecorder";
import {
  errorHandler,
  formatCurrencyValue,
  formatDecimalNumber,
  generateCalenderDateForChat,
  getLowResolutionLink,
  parseForShowingMessage,
  uploadFileOnServer,
} from "../../helper-methods";
import { isFromSafari } from "../../helper-methods/index";
import {
  createABroadcast,
  saveContents,
  sendMassBulkMessages,
} from "../../http-calls/index";
import { DEFAULT_PROFILE_PICTURE } from "../../config";
import Picker, { SKIN_TONE_NEUTRAL } from "emoji-picker-react";
import TextareaAutosize from "react-textarea-autosize";
import MediaLibraryModal from "../modals/MediaLibraryModal";
import SoundRecordUploadModal from "../modals/SoundRecordUploadModal";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import ChatMultiFile from "./ChatMultiFile";
import { autoHeightStyleChat } from "../../assets/styles/js";

const BroadCastMessage = ({
  goBack,
  fans,
  isSelectedAllFollowers = false,
  totalFollowers,
}) => {
  const history = useHistory();

  const chatWindowRef = useRef();

  const userData = useSelector((state) => state?.userData);
  const localFilters = useSelector((state) => state?.localFilters);

  const [messages, setMessages] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [isFakeRender, setIsFakeRender] = useState(false);

  const [isEmojiSectionShown, setIsEmojiSectionShown] = useState(false);
  const [sendMessage, setSendMessage] = useState({
    text: "",
    images: {},
    videos: {},
    audios: {},
    amount: 0,
  });

  const [mediaLibraryModal, setMediaLibraryModal] = useState({
    isOpen: false,
  });
  const [chatPriceSetterModal, setChatPriceSetterModal] = useState({
    isOpen: false,
  });
  const [fullScreenVideoRecorder, setFullScreenVideoRecorder] = useState({
    isOpen: false,
  });
  const [soundRecordUploadModal, setSoundRecordUploadModal] = useState({
    isOpen: false,
  });

  const mediaPreviews = useMemo(() => {
    const { images, videos, audios, amount } = sendMessage;
    const preview = [];
    // Iterate through images
    for (let imageId in images) {
      const image = images[imageId];
      preview.push({
        ...image,
        fileId: imageId,
        fileType: "image",
        category: "images",
      });
    }
    // Iterate through videos
    for (let videoId in videos) {
      const video = videos[videoId];
      preview.push({
        ...video,
        fileId: videoId,
        fileType: "video",
        category: "videos",
      });
    }
    // Iterate through audio
    for (let audioId in audios) {
      const audio = audios[audioId];
      preview.push({
        ...audio,
        fileId: audioId,
        fileType: "audio",
        category: "audios",
      });
    }
    if (amount > 0) {
      preview.push({
        amount,
        fileType: "priceTag",
      });
    }
    return preview;
  }, [sendMessage]);

  const fileCount = useMemo(
    () =>
      sendMessage.amount ? mediaPreviews?.length - 1 : mediaPreviews?.length,
    [sendMessage, mediaPreviews]
  );

  const _scrollToBottomInChatWindow = () => {
    if (!chatWindowRef?.current?.scrollHeight) return;

    setTimeout(() => {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }, 0);
  };

  const _markMessageAsSent = (message) => {
    setMessages((prev) => {
      const findMessage = prev.find(
        (each) => each.tempMessageId === message.tempMessageId
      );
      if (findMessage) {
        findMessage.isUploading = false;
      }
      return prev;
    });
    setIsFakeRender((prev) => !prev);
  };

  const _sendMessageError = (message) => {
    setMessages((prev) => {
      const findMessage = prev.find(
        (each) => each.tempMessageId === message.tempMessageId
      );
      if (findMessage) {
        console.log({ findMessage });
        findMessage.isError = true;
        findMessage.isUploading = false;
      }
      return prev;
    });
    setIsFakeRender((prev) => !prev);
  };

  const _publishMessageOnChannel = async (message) => {
    try {
      const broadCastId = cuid();

      await createABroadcast(broadCastId);

      const payload = {
        filters: {},
        message: {
          ...message,
          broadCastId,
        },
        externalFanIds: [],
      };

      if (fans?.length && !isSelectedAllFollowers) {
        payload.externalFanIds = fans.map((fan) => fan._fan._id);
      } else {
        const chatFanListFilters = localFilters?.filters?.chatFanList;
        if (chatFanListFilters && Object.keys(chatFanListFilters)?.length) {
          Object.keys(chatFanListFilters).forEach((key) => {
            switch (key) {
              case "isNonExpired":
              case "isRecentSubscriber":
              case "isRecentlyCancelled": {
                payload.filters[key] = chatFanListFilters[key] ? true : false;
                break;
              }
              case "search": {
                if (chatFanListFilters[key]?.trim().length) {
                  payload[key] = chatFanListFilters[key];
                }
                break;
              }
              default: {
                if (chatFanListFilters[key]?.length) {
                  payload.filters[key] = chatFanListFilters[key];
                }
                break;
              }
            }
          });
        }
      }

      await sendMassBulkMessages(payload);

      _markMessageAsSent(message);
    } catch (error) {
      errorHandler(error);
      _sendMessageError(message);
    }
  };

  const _handleBroadcastMediaUpload = (message) => {
    return new Promise(async (resolve, reject) => {
      try {
        const libraryFiles = [];
        const filesToUpload = [];

        Object.keys(message?.media).forEach((key) => {
          if (message?.media?.[key]?.url) {
            libraryFiles.push({
              _contentId: {
                _id: message?.media?.[key]?.id,
                contentType: message.media[key].contentType,
                url: message.media[key].url,
                hlsLink: message.media[key].hlsLink,
                thumbnail: message.media[key].thumbnail,
              },
              _id: message?.media?.[key]?.id,
              mediaType: message.media[key].contentType,
              // thumbnail: e.thumbnail,
              isPublic: message.isPublic,
              amount: message.amount,
            });
          } else {
            filesToUpload.push(message?.media?.[key]);
          }
        });

        let uploadedFiles = [];
        if (filesToUpload?.length) {
          // upload files to server
          let filesUploadedToServer = await uploadFileOnServer(filesToUpload);

          // upload to "/contents" => media lib modal
          uploadedFiles = await saveContents({
            contents: filesUploadedToServer,
          });

          uploadedFiles = uploadedFiles?.contents?.map((each) => ({
            _contentId: {
              _id: each?._id,
              isNew: true,
              contentType: each.contentType,
              url: each.url,
              duration: each?.duration ? each?.duration : null,
            },
            _id: each?.id,
            mediaType: each.contentType,
            // thumbnail: e.thumbnail,
            isPublic: message.isPublic,
            amount: message.amount,
          }));
        }

        let finalList = [];
        if (uploadedFiles?.length) {
          finalList = uploadedFiles.concat(libraryFiles);
        } else {
          finalList = libraryFiles;
        }

        delete message?.media;
        resolve(finalList);
      } catch (error) {
        reject(error);
      }
    });
  };

  const _appendAndPublishNewMessage = async (message) => {
    try {
      if (message.hasMedia) {
        const finalMediaList = await _handleBroadcastMediaUpload(message);
        message["content"] = finalMediaList;
      }

      const newMessages = [...messages];
      newMessages.push({
        ...message,
        time: moment(message.timestamp).format("h:mm A"),
        isMyMessage: true,
        hasMedia: message.content?.length ? true : false,
      });

      setMessages(newMessages);

      _scrollToBottomInChatWindow();

      _publishMessageOnChannel(message);
    } catch (error) {
      console.log("error in _appendAndPublishNewMessage >>>", error);
    }
  };

  const _sendNewMessage = async () => {
    try {
      const { images, videos, audios, amount, text } = sendMessage;

      if (isEmojiSectionShown) {
        _emojiSectionToggle();
      }

      let messageText = text?.trim().length ? text.trim() : null;

      const tempMessageId = cuid();

      const messagesToBeSent = {};

      // Prepare outbox queue
      // Iterate through images
      // Iterate through images videos audios
      if (
        Object.keys(images).length ||
        Object.keys(videos).length ||
        Object.keys(audios).length
      ) {
        messagesToBeSent[tempMessageId] = {
          _from: userData?.user?._id,
          when: new Date(),
          isPublic: amount && amount > 0 ? false : true,
          amount: amount && amount > 0 ? amount : 0,
          isUploading: true,
          media: {},
          hasMedia: true,
          tempMessageId,
          isLocalMessage: true,
        };

        messagesToBeSent[tempMessageId]["media"] = {
          ...images,
          ...videos,
          ...audios,
        };
        if (messageText) {
          messagesToBeSent[tempMessageId]["text"] = messageText;
          messageText = null;
        }
      }

      if (Object.keys(messagesToBeSent)?.length === 0 && messageText) {
        messagesToBeSent[tempMessageId] = {
          _from: userData?.user?._id,
          when: new Date(),
          isUploading: true,
          hasMedia: false,
          tempMessageId,
          isLocalMessage: true,
        };
        if (messageText) {
          messagesToBeSent[tempMessageId]["text"] = messageText;
          messageText = null;
        }
      }

      if (Object.keys(messagesToBeSent)?.length) {
        // Append as local messages in thread
        await _appendAndPublishNewMessage(messagesToBeSent[tempMessageId]);

        //   Clear new message
        setSendMessage({
          text: "",
          images: {},
          videos: {},
          audios: {},
          amount: 0,
        });
      }
    } catch (error) {
      console.log("error>>", error);
    }
  };

  const _onMessageTextInputKeyUp = (e) => {
    if (
      e.key === "Enter" &&
      e.target.value.trim().length &&
      !e.ctrlKey &&
      !e.shiftKey
    ) {
      // inputRef.current.blur();
      _sendNewMessage();
    }
  };

  const _onMessageTextInputChange = (newText, isEmoji) => {
    const newSendMessage = { ...sendMessage };

    if (isEmoji) {
      newSendMessage.text = newSendMessage.text + newText;
    } else {
      newSendMessage.text = newText;
    }

    setSendMessage(newSendMessage);
  };

  const _emojiSectionToggle = () => {
    setIsEmojiSectionShown((prev) => !prev);
  };

  const _onEmojiClick = (event, emojiObject) => {
    _onMessageTextInputChange(emojiObject.emoji, true);
  };

  const _renderMessage = (message) => {
    // Need to decide
    // Is public
    // Has Media
    // Has Text
    let hasImage = false;
    let hasAudio = false;
    let hasVideo = false;
    let hasText = false;
    let hasTip = false;
    let hasMultipleFile = false;
    // Check if it has media
    if (message.content && message.content.length) {
      // Has media
      if (message.content.length > 1) {
        hasMultipleFile = true;
      } else {
        switch (message.content[0].mediaType) {
          case "image": {
            hasImage = true;
            break;
          }
          case "video": {
            hasVideo = true;
            break;
          }
          case "audio": {
            hasAudio = true;
            break;
          }
          default: {
          }
        }
      }
    }
    if (message.media && message.media.length) {
      if (message.media.length > 1) {
        hasMultipleFile = true;
      } else {
        switch (message.media[0].mediaType) {
          case "image": {
            hasImage = true;
            break;
          }
          case "video": {
            hasVideo = true;
            break;
          }
          case "audio": {
            hasAudio = true;
            break;
          }
          default: {
          }
        }
      }
    }

    if (message.isTip) {
      hasTip = true;
    } else {
      if (message.text && message.text.length) {
        hasText = true;
      }
    }

    return (
      <>
        {hasImage ? <ChatImage message={message} /> : null}
        {hasVideo ? <ChatVideo message={message} /> : null}
        {hasAudio ? <ChatAudio message={message} /> : null}
        {hasMultipleFile ? <ChatMultiFile message={message} /> : null}

        {hasText ? (
          <div
            className={`msg ${
              message.isMyMessage ? "bgThemeColor" : "bgOffWhite"
            }`}
          >
            <pre>{parseForShowingMessage(message.text)}</pre>
          </div>
        ) : null}
        {hasTip ? (
          <div className="tipWrap">
            {`You received a ${formatCurrencyValue(message.text)} tip`}
          </div>
        ) : null}
      </>
    );
  };

  const _removeMedia = (mediaFile) => {
    const newSendMessage = { ...sendMessage };
    if (mediaFile?.fileType === "priceTag") {
      newSendMessage.amount = 0;
    } else {
      delete newSendMessage[mediaFile.category][mediaFile.fileId];
    }
    setSendMessage(newSendMessage);
  };

  const _renderMediaPreview = useCallback((mediaFile) => {
    switch (mediaFile?.fileType) {
      case "image": {
        return (
          <>
            <img
              src={mediaFile?.previewBlob || mediaFile?.url}
              alt="Uploaded Img"
              loading="lazy"
            />
            <Button
              className="deletePreview"
              onClick={() => _removeMedia(mediaFile)}
            >
              <i className="fa fa-times" />
            </Button>
          </>
        );
      }
      case "video": {
        return (
          <>
            <video controlsList="nodownload" disablePictureInPicture>
              <source src={mediaFile?.previewBlob || mediaFile?.url} />
              Your browser does not support the video tag.
            </video>
            {/* delete btn */}
            <Button
              className="deletePreview"
              onClick={() => _removeMedia(mediaFile)}
            >
              <i className="fa fa-times" />
            </Button>
          </>
        );
      }
      case "audio": {
        return (
          <>
            <img
              src="/assets/img/microphone.png"
              alt="Microphone"
              style={{ height: 28, width: "unset" }}
              loading="lazy"
            />
            {/* delete btn */}
            <Button
              className="deletePreview"
              onClick={() => _removeMedia(mediaFile)}
            >
              <i className="fa fa-times" />
            </Button>
          </>
        );
      }
      case "priceTag": {
        return (
          <div>
            {mediaFile?.amount ? formatCurrencyValue(mediaFile.amount) : "-"}
            {/* delete btn */}
            <Button
              className="deletePreview"
              onClick={() => _removeMedia(mediaFile)}
            >
              <i className="fa fa-times" />
            </Button>
          </div>
        );
      }
      default: {
        return <div />;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _formatMessageContent = async (contents) => {
    if (!contents?.length) {
      return;
    }

    const newSendMessage = { ...sendMessage };
    const currentTimeStamp = +new Date();

    contents.forEach((each, index) => {
      if (each.contentType.includes("image")) {
        const imageId = currentTimeStamp + "" + index;
        newSendMessage.images[imageId] = each;
      } else if (each.contentType.includes("video")) {
        const videoId = currentTimeStamp + "" + index;
        newSendMessage.videos[videoId] = each;
      } else if (each.contentType.includes("audio")) {
        const audioId = currentTimeStamp + "" + index;
        newSendMessage.audios[audioId] = each;
      } else {
        alert("Only Image, Video and Audio are allowed");
      }
    });

    setSendMessage(newSendMessage);

    _scrollToBottomInChatWindow();
  };

  const _toggleChatPriceSetterModal = (isOpen = false) => {
    setChatPriceSetterModal({ isOpen });
  };

  const _onAmountUpdate = (amount) => {
    const newSendMessage = { ...sendMessage };

    newSendMessage.amount = amount ? formatDecimalNumber(+amount) : 0;

    setSendMessage(newSendMessage);

    _scrollToBottomInChatWindow();
  };

  const _toggleMediaLibraryModal = (isOpen = false) => {
    setMediaLibraryModal({ isOpen });
  };

  const _toggleSoundRecordUploadModal = (isOpen = false) => {
    setSoundRecordUploadModal({ isOpen });
  };

  const _toggleFullScreenVideoRecorder = (isOpen = false) => {
    setFullScreenVideoRecorder({ isOpen });
  };

  const _onRecordingComplete = async (blobObject) => {
    try {
      _toggleFullScreenVideoRecorder();

      if (!blobObject?.blob) return;

      const res = await uploadFileOnServer([
        {
          uploadData: blobObject.blob,
          previewBlob: blobObject.blobURL,
          type: "video",
        },
      ]);

      if (!res?.length) {
        return;
      }

      const { contents } = await saveContents({
        contents: [{ contentType: res[0].contentType, url: res[0].url }],
      });

      if (!contents?.length) {
        return;
      }

      const newSendMessage = { ...sendMessage };

      const videoId = cuid();

      newSendMessage.videos[videoId] = {
        blobURL: blobObject.blobURL,
        type: blobObject.type,
        contentType: contents[0].contentType,
        url: contents[0].url,
        hlsLink: contents[0].hlsLink,
        thumbnail: contents[0].thumbnail,
        _id: contents[0]._id,
      };

      setSendMessage(newSendMessage);

      _scrollToBottomInChatWindow();
    } catch (error) {
      errorHandler(error);
    }
  };

  const _onAudioRecordingStop = async (blobObject) => {
    try {
      _toggleSoundRecordUploadModal();

      if (!blobObject?.blob) return;

      const res = await uploadFileOnServer([
        {
          uploadData: blobObject.blob,
          previewBlob: blobObject.blobURL,
          type: "audio",
        },
      ]);

      if (!res?.length) {
        return;
      }

      const { contents } = await saveContents({
        contents: [{ contentType: res[0].contentType, url: res[0].url }],
      });

      if (!contents?.length) {
        return;
      }

      const newSendMessage = { ...sendMessage };

      const audioId = cuid();

      newSendMessage.audios[audioId] = {
        blobURL: blobObject.blobURL,
        type: blobObject.type,
        contentType: contents[0].contentType,
        url: contents[0].url,
        hlsLink: contents[0].hlsLink,
        thumbnail: contents[0].thumbnail,
        _id: contents[0]._id,
      };

      setSendMessage(newSendMessage);

      _scrollToBottomInChatWindow();
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <Fragment>
      <div className="customPgHeight animated fadeIn">
        <Container className="noPadding">
          <Row className="noMargin">
            <Col xs="12" className="pgPadding bg-white">
              <div className="addMessageWrap">
                <div
                  className="receiverInfo-Chat justify-content-start mb-2"
                  style={{ borderBottom: "1px solid rgba(0,0,0,0.05)" }}
                >
                  <Button
                    className="customBackBtn"
                    onClick={() => (goBack ? goBack() : history.goBack())}
                  >
                    <i className="fa fa-chevron-left" />
                  </Button>

                  <div className="receiverName-Chat">
                    <h4 style={{ marginBottom: 0 }}>
                      {/* New message to {fans.length} user
                          {fans.length > 1 ? "s" : ""} */}
                      {(isSelectedAllFollowers && totalFollowers > 1) ||
                      fans.length > 1
                        ? `New message to ${
                            isSelectedAllFollowers
                              ? totalFollowers
                              : fans.length
                          } users`
                        : `New message to ${
                            isSelectedAllFollowers
                              ? totalFollowers
                              : fans.length
                          } user`}
                    </h4>
                  </div>
                </div>

                <div
                  className="conversationDetails conversationDetailsBroadcast"
                  ref={chatWindowRef}
                >
                  {/* chat timeline */}
                  {messages.length
                    ? messages.map((message, messageIndex) => (
                        <div key={messageIndex}>
                          {messageIndex === 0 ||
                            generateCalenderDateForChat(
                              messages[messageIndex - 1]?.timestamp
                            ) !==
                              generateCalenderDateForChat(message?.timestamp)}
                          <div className="chatDate">
                            {generateCalenderDateForChat(message?.timestamp)}
                          </div>

                          <div>
                            <Fragment>
                              {message.isMyMessage ? (
                                <div className="senderWrap">
                                  <div className="d-flex flex-column align-items-end">
                                    {_renderMessage(message)}
                                  </div>
                                  {/* msg sent time  */}
                                  <div className="sentTime">
                                    {message.time}{" "}
                                    {message.isError ? (
                                      <i
                                        className="fa fa-exclamation-circle"
                                        style={{
                                          color: "red",
                                        }}
                                        aria-hidden="true"
                                      />
                                    ) : !message.isUploading ? (
                                      <i
                                        className="fa fa-check greyCheck"
                                        aria-hidden="true"
                                      />
                                    ) : null}
                                    {!message.isError && message.isUploading ? (
                                      <Spinner
                                        style={{
                                          width: "1rem",
                                          height: "1rem",
                                        }}
                                      />
                                    ) : null}
                                  </div>
                                </div>
                              ) : (
                                <div className="receiverWrap">
                                  <div className="d-flex justify-content-start align-items-start">
                                    <img
                                      className="userImg"
                                      src={
                                        getLowResolutionLink(
                                          message?._from?.profilePicture
                                        ) || DEFAULT_PROFILE_PICTURE
                                      }
                                      onError={(e) =>
                                        (e.target.src = DEFAULT_PROFILE_PICTURE)
                                      }
                                      alt="Profile Img"
                                      loading="lazy"
                                    />

                                    <div className="d-flex flex-column">
                                      {_renderMessage(message)}
                                    </div>
                                  </div>

                                  {/* msg sent time  */}
                                  <div className="sentTime">{message.time}</div>
                                </div>
                              )}
                            </Fragment>
                          </div>
                        </div>
                      ))
                    : null}
                </div>

                <div className="typeMsgWrap pr-2 pr-sm-0">
                  {mediaPreviews.length ? (
                    <div className="msgPreviewWrap mb-2 mt-0">
                      {mediaPreviews.map((media, mediaIndex) => (
                        <div className="msgPreview" key={mediaIndex}>
                          {_renderMediaPreview(media)}
                        </div>
                      ))}
                    </div>
                  ) : null}

                  <div className="position-relative">
                    <TextareaAutosize
                      style={autoHeightStyleChat}
                      placeholder="Type a message..."
                      value={sendMessage.text}
                      onChange={(e) =>
                        _onMessageTextInputChange(e.target.value)
                      }
                      onKeyUp={(e) => _onMessageTextInputKeyUp(e)}
                    />

                    {isEmojiSectionShown ? (
                      <Picker
                        onEmojiClick={_onEmojiClick}
                        skinTone={SKIN_TONE_NEUTRAL}
                      />
                    ) : null}

                    <Button
                      disabled={
                        fileCount || sendMessage.text?.trim().length
                          ? false
                          : true
                      }
                      className="sendMsg"
                      onClick={_sendNewMessage}
                    >
                      <img
                        src="/assets/img/send.png"
                        alt="Send"
                        loading="lazy"
                      />
                    </Button>
                  </div>

                  <div className="createPostBtnsWrapper my-1">
                    <div className="createPostBtnsWrap">
                      <div className="d-flex">
                        <Label className="btn mediaBtn">
                          <img
                            src="/assets/img/img-gallery-active.png"
                            alt="Img"
                            onClick={() => _toggleMediaLibraryModal(true)}
                            loading="lazy"
                          />
                        </Label>
                        {/* on clicking the below btn, an audio recorder feature should be shown, for this a modal has been added  */}
                        {!isFromSafari() ? (
                          <Button
                            className="mediaBtn"
                            onClick={() => _toggleSoundRecordUploadModal(true)}
                          >
                            <img
                              src="/assets/img/microphone-feed.png"
                              alt="Microphone"
                              className="audioIcon"
                              loading="lazy"
                            />
                          </Button>
                        ) : null}

                        {/* on clicking the below btn, an video recorder feature should be shown.  */}
                        <Button
                          className="mediaBtn"
                          onClick={() => _toggleFullScreenVideoRecorder()}
                        >
                          <img
                            src="/assets/img/video-gallery-active.png"
                            alt="Video"
                            className="videoIcon"
                            loading="lazy"
                          />
                        </Button>

                        {/* the below btn will show 2 modals, "Message Price" & "Enter Tip Amount" */}
                        <Button
                          className="mediaBtn ml-1 abc"
                          onClick={() => _toggleChatPriceSetterModal(true)}
                          disabled={
                            userData?.user?.hasOwnProperty("paymentEnabled") &&
                            !userData?.user?.paymentEnabled
                          }
                        >
                          {/* hide this img for subscriber/ show this icon for creator. When this icon is there show "Message Price" modal */}
                          <img
                            src="/assets/img/price.png"
                            alt="Price"
                            className="priceIcon"
                            loading="lazy"
                            style={{ height: 23, marginTop: 0 }}
                          />
                        </Button>
                      </div>

                      {fileCount > 0 ? (
                        <div className="filesSelected-CreatePost my-0">
                          {fileCount} {fileCount > 1 ? "files" : "file"}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              {/* addMessageWrap */}
            </Col>
          </Row>
        </Container>
      </div>

      <FullScreenVideoRecorder
        isOpen={fullScreenVideoRecorder.isOpen}
        toggle={() => _toggleFullScreenVideoRecorder()}
        // onStartRecording={_onStartRecording}
        onRecordingComplete={(blobObject) => _onRecordingComplete(blobObject)}
      />

      <ChatPriceSetterModal
        isOpen={chatPriceSetterModal.isOpen}
        toggle={() => _toggleChatPriceSetterModal()}
        onSave={(amount) => _onAmountUpdate(amount)}
        amount={sendMessage?.amount || ""}
      />

      <SoundRecordUploadModal
        isOpen={soundRecordUploadModal.isOpen}
        toggle={() => _toggleSoundRecordUploadModal()}
        onSave={(blobObject) => _onAudioRecordingStop(blobObject)}
        isHideUploadAudioFile={true}
      />

      <MediaLibraryModal
        isOpen={mediaLibraryModal.isOpen}
        toggle={() => _toggleMediaLibraryModal()}
        uploadContents={(contents) => _formatMessageContent(contents)}
        isMultipleImages={true}
        isMultipleVideos={true}
        isMultipleAudios={true}
        isMultipleUpload={true}
        isAudioUpload={true}
        isVideoUpload={true}
        isImageUpload={true}
        // shouldUploadInModal={true}
      />
    </Fragment>
  );
};

export default BroadCastMessage;
