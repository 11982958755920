import { Button } from "reactstrap";
import React from "react";
import VideoRecorder from "react-video-recorder";

const FullScreenVideoRecorder = ({
  isOpen,
  toggle,
  onStartRecording,
  onRecordingComplete,
}) => {
  const _onStartRecording = (...args) => {
    if (onStartRecording) onStartRecording(...args);
  };

  const _onRecordingComplete = (blobObject) => {
    if (onRecordingComplete) onRecordingComplete(blobObject);
  };

  return (
    <>
      {isOpen ? (
        <div
          style={{
            position: "fixed",
            zIndex: 999999999,
            width: "100vw",
            height: "100vh",
            top: 0,
            left: 0,
          }}
        >
          <Button className="cancelVideoRecord" onClick={() => toggle()}>
            Cancel
          </Button>
          <VideoRecorder
            onStartRecording={_onStartRecording}
            onRecordingComplete={_onRecordingComplete}
          />
        </div>
      ) : null}
    </>
  );
};

export default FullScreenVideoRecorder;
