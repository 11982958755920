import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  FormGroup,
} from "reactstrap";
import { X_COIN_API_KEY } from "../../config";
import { bitCoinTypesConfig } from "../../config/helper-config";
import { deepClone, formatDecimalNumber } from "../../helper-methods";
import { getCryptoExchangeRate } from "../../http-calls";

class CreateNftModal extends Component {
  state = {
    formFields: {
      collectionName: {
        value: "",
        error: null,
        isDirty: false,
      },
      bitCoinType: {
        value: "BTC",
        error: null,
        isDirty: false,
      },
      bitCoinCount: {
        value: "",
        error: null,
        isDirty: false,
      },
      bitCoinPrice: {
        value: "",
        error: null,
        isDirty: false,
      },
      noOfCopies: {
        value: "1",
        error: null,
        isDirty: false,
      },
    },
    exchangeRates: {
      BTC: null,
      USD: null,
      ETH: null,
      SOL: null,
      // BTC: {
      //   BTC: 0,
      //   USD: 0,
      // },
      // USD: {
      //   USD: 0,
      //   BTC: 0,
      //   ETH: 0,
      //   SOL: 0,
      // },
      // ETH: {
      //   ETH: 0,
      //   USD: 0,
      // },
      // SOL: {
      //   SOL: 0,
      //   USD: 0,
      // },
    },
  };

  _setModalForm = (nftObject = null) => {
    this.setState({
      formFields: {
        collectionName: {
          value: nftObject?.collectionName ? nftObject.collectionName : "",
          error: null,
          isDirty: false,
        },
        bitCoinType: {
          value: nftObject?.bitCoinType ? nftObject.bitCoinType : "BTC",
          error: null,
          isDirty: false,
        },
        bitCoinCount: {
          value: nftObject?.bitCoinCount ? nftObject.bitCoinCount : "",
          error: null,
          isDirty: false,
        },
        bitCoinPrice: {
          value: nftObject?.bitCoinPrice ? nftObject.bitCoinPrice : "",
          error: null,
          isDirty: false,
        },
        noOfCopies: {
          value: nftObject?.noOfCopies ? nftObject.noOfCopies : "1",
          error: null,
          isDirty: false,
        },
      },
    });
  };

  _closeModal = () => {
    this._setModalForm();
    this.props.onClose();
  };

  componentDidUpdate = (prevProps) => {
    const { nftObject, isOpen } = this.props;

    if (isOpen && isOpen !== prevProps.isOpen) this._getConversionRate();

    if (isOpen && nftObject && isOpen !== prevProps.isOpen) {
      this._setModalForm(nftObject);
    }
  };

  _getConversionRate = () => {
    try {
      const baseAsset = "BTC";
      const queryParams = {
        filter_asset_id: "USD,ETH,SOL",
      };
      const customHeaders = {
        "X-CoinAPI-Key": X_COIN_API_KEY,
      };
      getCryptoExchangeRate(baseAsset, queryParams, customHeaders)
        .then((res) => {
          if (res.rates.length) {
            const USD =
              res.rates.find((each) => each.asset_id_quote === "USD")?.rate ||
              0;
            const ETH =
              res.rates.find((each) => each.asset_id_quote === "ETH")?.rate ||
              0;
            const SOL =
              res.rates.find((each) => each.asset_id_quote === "SOL")?.rate ||
              0;

            if (ETH && SOL && USD) {
              this.setState({
                exchangeRates: {
                  BTC: {
                    BTC: 1,
                    USD: USD.toFixed(9),
                  },
                  USD: {
                    USD: 1,
                    BTC: (1 / USD).toFixed(9),
                    ETH: (1 / (USD / ETH)).toFixed(9),
                    SOL: (1 / (USD / SOL)).toFixed(9),
                  },
                  ETH: {
                    ETH: 1,
                    USD: (USD / ETH).toFixed(9),
                  },
                  SOL: {
                    SOL: 1,
                    USD: (USD / SOL).toFixed(9),
                  },
                },
              });
            }
          }
        })
        .catch((error) => console.log(error));
    } catch (error) {
      console.log("error>>", error);
    }
  };

  _validateForm = () => {
    return new Promise((resolve) => {
      const { formFields } = deepClone(this.state);

      let isFormValid = true;

      Object.keys(formFields).forEach((key) => {
        if (formFields[key].isDirty) {
          switch (key) {
            case "bitCoinType":
            case "collectionName": {
              if (formFields[key].value?.trim().length) {
                formFields[key].error = null;
                formFields[key].isDirty = false;
              } else {
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "bitCoinCount":
            case "bitCoinPrice":
            case "noOfCopies": {
              formFields[key].value = formFields[key].value?.trim()
                ? formFields[key].value.trim()
                : "";

              if (formFields[key].value.length) {
                if (
                  !isNaN(formFields[key].value) &&
                  Number(formFields[key].value) > 0
                ) {
                  formFields[key].error = null;
                  formFields[key].isDirty = false;
                } else {
                  formFields[key].error = `*Value Should be greater than 0`;
                  isFormValid = false;
                }
              } else {
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            default:
          }
        }
      });

      this.setState({ formFields }, () => resolve(isFormValid));
    });
  };

  // key = bitCoinCount / bitCoinPrice
  _setCountAndPrice = (key, value, bitCoinType) => {
    return new Promise((resolve) => {
      const { formFields, exchangeRates } = deepClone(this.state);

      const rates = exchangeRates[bitCoinType];

      if (rates) {
        if (key === "bitCoinCount") {
          formFields["bitCoinPrice"].value = formatDecimalNumber(
            value * rates.USD
          );
        } else if (key === "bitCoinPrice") {
          formFields["bitCoinCount"].value = formatDecimalNumber(
            value * exchangeRates.USD[bitCoinType]
          );
        }
      }

      this.setState({ formFields }, () => resolve(true));
    });
  };

  _updateFieldValue = async (key, value) => {
    if (key === "bitCoinCount" || key === "bitCoinPrice") {
      if (
        isNaN(value) ||
        (value.includes(".") && value.split(".")[1].length > 2)
      )
        return;

      await this._setCountAndPrice(
        key,
        value,
        this.state.formFields?.bitCoinType?.value
      );
    }

    if (key === "bitCoinType" && this.state.formFields?.bitCoinCount?.value) {
      await this._setCountAndPrice(
        "bitCoinCount",
        this.state.formFields["bitCoinCount"].value,
        value
      );
    }

    if (key === "noOfCopies") {
      if (isNaN(value) || value.includes(".")) return;
    }

    const { formFields } = deepClone(this.state);

    formFields[key].value = value;
    formFields[key].isDirty = true;

    this.setState({ formFields }, () => this._validateForm());
  };

  _makeAllFieldDirty = () => {
    return new Promise((resolve) => {
      const { formFields } = this.state;
      Object.keys(formFields).forEach((key) => {
        formFields[key].isDirty = true;
      });
      this.setState({ formFields }, () => resolve(true));
    });
  };

  _saveNft = async () => {
    await this._makeAllFieldDirty();

    const isFormValid = await this._validateForm();

    if (isFormValid) {
      const { formFields } = this.state;

      const payload = {
        collectionName: formFields.collectionName.value.trim(),
        bitCoinType: formFields.bitCoinType.value.trim(),
        bitCoinCount: formFields.bitCoinCount.value.trim(),
        bitCoinPrice: formFields.bitCoinPrice.value.trim(),
        noOfCopies: formFields.noOfCopies.value.trim(),
      };

      this.props.onSave(payload);

      this._closeModal();
    }
  };

  render() {
    const { formFields } = this.state;

    return (
      <React.Fragment>
        <Modal
          isOpen={this.props.isOpen}
          toggle={() => this._closeModal()}
          className="modal-dialog-centered modal-dialog-scrollable"
        >
          <ModalHeader toggle={() => this._closeModal()}>
            Create NFT Collection
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label>Collection Name</Label>
              <Input
                value={formFields.collectionName.value}
                type="text"
                name="collectionName"
                placeholder="Enter the collection name"
                onChange={(e) =>
                  this._updateFieldValue("collectionName", e.target.value)
                }
              />
              {formFields.collectionName.error ? (
                <div className="form-error">
                  {formFields.collectionName.error}
                </div>
              ) : null}
            </FormGroup>

            <Label>Set Price</Label>
            <div className="d-flex mb-3">
              <div className="setNFTPriceWrap mr-1">
                {/* default value will be "BTC", user can change/select as per his/her requirement & enter the amt in the below text field */}
                <Input
                  style={{
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                  }}
                  value={formFields.bitCoinType.value}
                  type="select"
                  name="bitCoinType"
                  onChange={(e) =>
                    this._updateFieldValue("bitCoinType", e.target.value)
                  }
                >
                  {bitCoinTypesConfig.map((each) => (
                    <option key={each.value} value={each.value}>
                      {each.label}
                    </option>
                  ))}
                </Input>
                {formFields.bitCoinType.error ? (
                  <div className="form-error">
                    {formFields.bitCoinType.error}
                  </div>
                ) : null}
                <Input
                  style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                  value={formFields.bitCoinCount.value}
                  type="text"
                  name="bitCoinCount"
                  placeholder={`Enter the ${
                    formFields.bitCoinType.value || ""
                  } count`}
                  onChange={(e) =>
                    this._updateFieldValue("bitCoinCount", e.target.value)
                  }
                />
                {formFields.bitCoinCount.error ? (
                  <div className="form-error">
                    {formFields.bitCoinCount.error}
                  </div>
                ) : null}
              </div>

              <div className="setNFTPriceWrap ml-1">
                {/* here, we will convert the amount entered above and show it(in Dollars) below */}
                {/* creator can either enter the amt in BitCoin(or any other crypto) or in dollars */}
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText className="dollarWrap">
                      <i className="fa fa-dollar" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    value={formFields.bitCoinPrice.value}
                    type="text"
                    name="bitCoinPrice"
                    placeholder={`Enter the ${
                      formFields.bitCoinType.value || ""
                    } price`}
                    onChange={(e) =>
                      this._updateFieldValue("bitCoinPrice", e.target.value)
                    }
                    className="dollarWrapInput"
                  />
                  {formFields.bitCoinPrice.error ? (
                    <div className="form-error">
                      {formFields.bitCoinPrice.error}
                    </div>
                  ) : null}
                </InputGroup>
              </div>
            </div>

            <FormGroup>
              <Label>Number Of Copies</Label>
              {/* default value will be 1, creator can change it to any other number */}
              <Input
                value={formFields.noOfCopies.value}
                type="text"
                name="noOfCopies"
                placeholder="Enter the number of copies"
                onChange={(e) =>
                  this._updateFieldValue("noOfCopies", e.target.value)
                }
              />
              {formFields.noOfCopies.error ? (
                <div className="form-error">{formFields.noOfCopies.error}</div>
              ) : null}
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              className="modalBtnCancel"
              onClick={() => this._closeModal()}
            >
              Cancel
            </Button>
            <Button className="modalBtnSave" onClick={() => this._saveNft()}>
              Save
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default CreateNftModal;
