import moment from "moment";
import React, { useState } from "react";
import ReactDatetime from "react-datetime";
import { useSelector } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  CustomInput,
} from "reactstrap";
import { DEFAULT_COVER_PICTURE } from "../../config";
import {
  formatCurrencyValue,
  formatDateAndTime,
  isSubscriptionPriceExist,
  reactHtmlParser,
  showToast,
} from "../../helper-methods";
import { postContent } from "../../http-calls";
import { customExpirationDays } from "../../config/helper-config";
import ReadMoreReadLess from "../custom/ReadMoreReadLess";

const SharePostModal = ({ modalHeading, isOpen, toggle, data, shareType }) => {
  const userData = useSelector((state) => state["userData"]);

  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState({});
  const [isDirty, setIsDirty] = useState({
    text: false,
    shcedulePostDate: false,
    expirationDate: false,
  });

  const [formFields, setFormFields] = useState({
    text: "",
    expirationDate: "",
    expirationDay: "0",
    shcedulePostDate: "",
  });

  const [toggleFields, setToggleFields] = useState({
    isExpirationPeriod: false,
    isSchedulePostDate: false,
  });

  const _toggleFields = (key, flag) => {
    const newToggleFields = { ...toggleFields };
    newToggleFields[key] = flag;
    setToggleFields({ ...newToggleFields });

    _validateFormFields(formFields, isDirty, newToggleFields);
  };

  const _validateFormFields = (newFormFields, newIsDirty, newToggleFields) => {
    return new Promise((resolve) => {
      try {
        const newErrors = { ...errors };

        let isFormValid = true;

        Object.keys(newFormFields).forEach((key) => {
          if (newIsDirty[key]) {
            switch (key) {
              case "expirationDate": {
                if (
                  newToggleFields.isExpirationPeriod &&
                  !newFormFields.expirationDay
                ) {
                  if (newFormFields[key]) {
                    if (new Date(newFormFields[key]) > new Date()) {
                      if (
                        newFormFields.shcedulePostDate &&
                        newToggleFields.isSchedulePostDate
                      ) {
                        if (
                          new Date(newFormFields.shcedulePostDate) <
                          new Date(newFormFields.expirationDate)
                        ) {
                          delete newErrors[key];
                          newIsDirty[key] = false;
                        } else {
                          newErrors[key] =
                            "*Expire date must be greater than schedule date";
                          isFormValid = false;
                        }
                      } else {
                        delete newErrors[key];
                        newIsDirty[key] = false;
                      }
                    } else {
                      newErrors[key] =
                        "*Expire date must be greater than current date";
                      isFormValid = false;
                    }
                  } else {
                    newErrors[key] = "*Required";
                    isFormValid = false;
                  }
                } else {
                  delete newErrors[key];
                  newIsDirty[key] = false;
                }
                break;
              }
              case "shcedulePostDate": {
                if (newToggleFields.isSchedulePostDate) {
                  if (newFormFields[key]) {
                    if (new Date(newFormFields[key]) > new Date()) {
                      if (
                        newFormFields.expirationDate &&
                        newToggleFields.isExpirationPeriod &&
                        !newFormFields.expirationDay
                      ) {
                        if (
                          new Date(newFormFields.expirationDate) >
                          new Date(newFormFields[key])
                        ) {
                          delete newErrors[key];
                          newIsDirty[key] = false;
                        } else {
                          newErrors[key] =
                            "*Schedule date must be less than expire date";
                          isFormValid = false;
                        }
                      } else {
                        delete newErrors[key];
                        newIsDirty[key] = false;
                      }
                    } else {
                      newErrors[key] =
                        "*Schedule date must be greater than current date";
                      isFormValid = false;
                    }
                  } else {
                    newErrors[key] = "*Required";
                    isFormValid = false;
                  }
                } else {
                  delete newErrors[key];
                  newIsDirty[key] = false;
                }
                break;
              }
              default:
            }
          }
        });

        setErrors({ ...newErrors });
        setIsDirty({ ...newIsDirty });

        resolve(isFormValid);
      } catch (error) {
        console.log("error>>", error);
        resolve(false);
      }
    });
  };

  const _onChangeFormFields = (key, value) => {
    const newFormFields = { ...formFields };
    const newIsDirty = { ...isDirty };

    if (key === "expirationDate" || key === "shcedulePostDate") {
      newFormFields[key] = value ? new Date(value).toISOString() : "";
    } else {
      newFormFields[key] = value;
    }

    newIsDirty[key] = true;
    setFormFields({ ...newFormFields });
    setIsDirty({ ...newIsDirty });

    _validateFormFields(newFormFields, newIsDirty, toggleFields);
  };

  const _resetModal = () => {
    setLoading(false);

    setErrors({});
    setIsDirty({
      text: false,
      shcedulePostDate: false,
      expirationDate: false,
    });

    setFormFields({
      text: "",
      expirationDate: "",
      expirationDay: "0",
      shcedulePostDate: "",
    });

    setToggleFields({
      isExpirationPeriod: false,
      isSchedulePostDate: false,
    });
  };

  const _closeModal = () => {
    _resetModal();
    toggle();
  };

  const _shareContent = async () => {
    try {
      let newIsDirty = { ...isDirty };
      newIsDirty = {
        text: true,
        shcedulePostDate: true,
        expirationDate: true,
      };
      setIsDirty({ ...newIsDirty });

      const isValidForm = await _validateFormFields(
        formFields,
        newIsDirty,
        toggleFields
      );

      if (isValidForm) {
        setLoading(true);

        const payload = {
          text: formFields.text || "",
          isPublic: true,
          category: "general",
        };

        switch (shareType) {
          case "vault": {
            payload["folderId"] = data._id;
            break;
          }
          case "ppv": {
            payload["ppvId"] = data._id;
            break;
          }
          case "event": {
            payload["eventId"] = data._id;
            break;
          }
          default: {
            showToast("Something went wrong, Try again after sometime");
            return;
          }
        }

        if (
          toggleFields.isSchedulePostDate &&
          toggleFields.isExpirationPeriod &&
          formFields.expirationDay !== "0"
        ) {
          payload["postType"] = "scheduled-temporary";
          payload["startDate"] = formFields.shcedulePostDate;
          if (formFields.expirationDay && Number(formFields.expirationDay)) {
            payload["endDate"] = moment(formFields.shcedulePostDate)
              .add(Number(formFields.expirationDay), "day")
              .toISOString();
          }
          if (formFields.expirationDate && !formFields.expirationDay) {
            payload["endDate"] = formFields.expirationDate;
          }
        } else if (
          toggleFields.isSchedulePostDate &&
          (!toggleFields.isExpirationPeriod || formFields.expirationDay === "0")
        ) {
          payload["postType"] = "scheduled";
          payload["startDate"] = formFields.shcedulePostDate;
        } else if (
          !toggleFields.isSchedulePostDate &&
          toggleFields.isExpirationPeriod &&
          formFields.expirationDay !== "0"
        ) {
          payload["postType"] = "temporary";
          if (formFields.expirationDay && Number(formFields.expirationDay)) {
            payload["endDate"] = moment()
              .add(Number(formFields.expirationDay), "day")
              .toISOString();
          }
          if (formFields.expirationDate && !formFields.expirationDay) {
            payload["endDate"] = formFields.expirationDate;
          }
        } else {
          payload["postType"] = "instant";
        }

        await postContent(payload);

        _closeModal();

        showToast("Shared successfully", "success");
      }
    } catch (error) {
      console.log("error>>", error);

      setLoading(false);
      showToast(
        error?.reason?.length
          ? error.reason
          : "Something went wrong, Try again after sometime",
        "error"
      );
    }
  };

  const _renderEventOrPPVPrice = () => {
    const user = userData?.user;

    if (data && !user?.multiTierSubscription && data.price) {
      return (
        <div className="eventPrice">
          {data.price ? (
            <div>
              Price: <span>{formatCurrencyValue(data.price)}</span>
            </div>
          ) : null}
        </div>
      );
    }

    if (data && data.price && !data.plusPrice && !data.premiumPrice) {
      return (
        <div className="eventPrice">
          {data.price ? (
            <div>
              Price: <span>{formatCurrencyValue(data.price)}</span>
            </div>
          ) : null}
        </div>
      );
    }

    if (data && (data.price || data.plusPrice || data.premiumPrice)) {
      return (
        <div className="eventPrice">
          {isSubscriptionPriceExist("premium") && (
            <div>
              <i className="fa fa-star" style={{ color: "#FFC107" }} /> Premium:{" "}
              <span>
                {data.premiumPrice
                  ? formatCurrencyValue(data.premiumPrice)
                  : "FREE"}
              </span>
            </div>
          )}

          {isSubscriptionPriceExist("plus") && (
            <div>
              <i className="fa fa-star" style={{ color: "#2196F3" }} /> Plus:{" "}
              <span>
                {data.plusPrice ? formatCurrencyValue(data.plusPrice) : "FREE"}
              </span>
            </div>
          )}

          <div>
            <i className="fa fa-star" style={{ color: "#828282" }} /> Basic:{" "}
            <span>{data.price ? formatCurrencyValue(data.price) : "FREE"}</span>
          </div>
        </div>
      );
    }
    return "FREE";
  };

  const _renderPrice = () => {
    switch (shareType) {
      case "vault": {
        return data.price ? (
          <span>{formatCurrencyValue(data.price)}</span>
        ) : null;
      }
      case "event":
      case "ppv": {
        return _renderEventOrPPVPrice();
      }
      default: {
        return null;
      }
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => _closeModal()}
      className="modal-dialog-centered modal-dialog-scrollable"
    >
      <ModalHeader toggle={() => _closeModal()}>
        {modalHeading || "Share Post"}
      </ModalHeader>
      {data && (
        <ModalBody>
          {/* share post text/description */}
          <Input
            type="textarea"
            rows="4"
            name="text"
            className="shareTextarea"
            placeholder={`What's on your mind${
              userData?.user?.name?.first
                ? `, ${userData.user.name.first.trim()}?`
                : userData?.user?.username
                ? `, @${userData?.user?.username}?`
                : "?"
            }`}
            value={formFields.text}
            onChange={(e) => _onChangeFormFields("text", e.target.value)}
          />

          {/* share post preview */}
          <div className="sharePostWrap">
            <img
              src={data?.preview || data?.coverImage || DEFAULT_COVER_PICTURE}
              alt="Cover Img"
              loading="lazy"
            />

            {data?.name || data?.description ? (
              <ReadMoreReadLess
                className="readMore"
                text={reactHtmlParser(data?.name || data?.description)}
              />
            ) : null}

            <div>{_renderPrice()}</div>
          </div>

          {/* expiration period */}
          <div className="customFilterShare">
            <div className="d-flex justify-content-between align-items-center">
              <p>Expiration Period</p>

              <CustomInput
                type="switch"
                id="isExpirationPeriod__CustomSwitch"
                name="isExpirationPeriod"
                checked={toggleFields.isExpirationPeriod}
                onChange={(e) =>
                  _toggleFields("isExpirationPeriod", e.target.checked)
                }
              />
            </div>

            {toggleFields.isExpirationPeriod ? (
              <div>
                <div className="setExpirationDateWrap">
                  {React.Children.toArray(
                    customExpirationDays.map((each) => (
                      <CustomInput
                        type="radio"
                        id={`sharePostModalCustomExpirationDays_${each.day}_${each.label}`}
                        name="expirationDay"
                        label={each.label}
                        checked={
                          formFields.expirationDay === each.day ? true : false
                        }
                        onChange={(e) =>
                          _onChangeFormFields("expirationDay", each.day)
                        }
                      />
                    ))
                  )}

                  {/* for select date */}
                  <CustomInput
                    type="radio"
                    id={`sharePostModalCustomExpirationDays_selectDate`}
                    name="expirationDay"
                    label={"Select Date"}
                    checked={formFields.expirationDay === "" ? true : false}
                    onChange={(e) => {
                      _onChangeFormFields("expirationDay", "");
                    }}
                  />
                </div>

                {formFields.expirationDay === "" ? (
                  <div>
                    <ReactDatetime
                      inputProps={{
                        className: "form-control",
                        placeholder: "Choose Date",
                        value: formFields.expirationDate
                          ? formatDateAndTime(formFields.expirationDate)
                          : "",
                      }}
                      onChange={(e) =>
                        _onChangeFormFields("expirationDate", e["_d"])
                      }
                      isValidDate={(current) =>
                        current.isAfter(moment().subtract(1, "day"))
                      }
                      dateFormat={true}
                      timeFormat={true}
                      timeConstraints={{ minutes: { step: 15 } }}
                    />
                    {errors["expirationDate"] && isDirty.expirationDate && (
                      <div className="form-error">
                        {errors["expirationDate"]}
                      </div>
                    )}
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>

          {/* schedule post */}
          <div className="customFilterShare mb-1">
            <div className="d-flex justify-content-between align-items-center">
              <p>Schedule Post</p>

              <CustomInput
                type="switch"
                id="isSchedulePostDate__CustomSwitch"
                name="isSchedulePostDate"
                checked={toggleFields.isSchedulePostDate}
                onChange={(e) =>
                  _toggleFields("isSchedulePostDate", e.target.checked)
                }
              />
            </div>

            {toggleFields.isSchedulePostDate ? (
              <div style={{ marginTop: 10 }}>
                <ReactDatetime
                  inputProps={{
                    className: "form-control",
                    placeholder: "Choose Date",
                    value: formFields.shcedulePostDate
                      ? formatDateAndTime(formFields.shcedulePostDate)
                      : "",
                  }}
                  onChange={(e) =>
                    _onChangeFormFields("shcedulePostDate", e["_d"])
                  }
                  isValidDate={(current) =>
                    current.isAfter(moment().subtract(1, "day"))
                  }
                  dateFormat={true}
                  timeFormat={true}
                  timeConstraints={{ minutes: { step: 15 } }}
                />
                {errors["shcedulePostDate"] && isDirty.shcedulePostDate && (
                  <div className="form-error">{errors["shcedulePostDate"]}</div>
                )}
              </div>
            ) : null}
          </div>
        </ModalBody>
      )}
      <ModalFooter>
        <Button className="modalBtnCancel" onClick={() => _closeModal()}>
          Cancel
        </Button>
        <Button
          className="modalBtnSave"
          onClick={() => _shareContent()}
          disabled={loading}
        >
          {loading ? <i className="fa fa-spinner fa-spin mr-1" /> : null} Share
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default SharePostModal;
