import React from "react";
import {
  Row,
  Col,
  Button,
  Input,
  Card,
  CardTitle,
  Container,
} from "reactstrap";
import { faqs } from "../config/FaqConfig";
import FaqAccordion from "../components/FaqAccordion";
import { CONTACT_SUPPORT_URL } from "../config";
import { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

const FaqPage = () => {
  const history = useHistory();

  const [isSearchModeEnabled, setIsSearchModeEnabled] = useState(false);
  const [searchedTerm, setSearchedTerm] = useState("");
  const [categorizedFaqs, setCategorizedFaqs] = useState({});
  const [formattedFaqs, setFormattedFaqs] = useState([]);
  const [decategorizedFaqs, setDecategorizedFaqs] = useState([]);
  const [filteredFaqs, setFilteredFaqs] = useState([]);
  const [selectedCategoryName, setSelectedCategoryName] = useState([]);

  useEffect(() => {
    const categorizedFaqs = _categorizedFaqs();
    const formattedFaqs = _formattedFaqs(categorizedFaqs);
    const decategorizedFaqs = _decategorizeFaqs(categorizedFaqs);

    setCategorizedFaqs(categorizedFaqs);
    setFormattedFaqs(formattedFaqs);
    setDecategorizedFaqs(decategorizedFaqs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _getFaqHeaderImage = (categoryName) => {
    switch (categoryName) {
      case "General Questions": {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" width="255.999" height="256" viewBox="0 0 255.999 256">
            <g id="shuttle_2285485" transform="translate(0 0)">
              <path id="Path_72" data-name="Path 72" d="M255.7,19.111A19.9,19.9,0,0,0,236.888.3C216.369-.82,176.217.27,137.824,20.128,118.251,30.252,97.551,47.417,81.032,67.221c-.2.244-.4.49-.6.734l-37.49,2.9a19.791,19.791,0,0,0-15.08,8.873L2.281,118.41a13.709,13.709,0,0,0,9.337,21.107L43.2,144.409c-.111.614-.223,1.227-.326,1.842a19.888,19.888,0,0,0,5.575,17.3l44,44a19.937,19.937,0,0,0,14.071,5.843,19.6,19.6,0,0,0,3.229-.268c.614-.1,1.227-.214,1.841-.326l4.892,31.586a13.7,13.7,0,0,0,21.107,9.337l38.686-25.577a19.787,19.787,0,0,0,8.872-15.08l2.9-37.489c.245-.2.49-.4.734-.6,19.8-16.519,36.969-37.219,47.093-56.792,19.859-38.4,20.948-78.548,19.828-99.065ZM168,215.629,131,240.1l-4.768-30.782a168.015,168.015,0,0,0,45.851-21.83l-1.887,24.422A4.886,4.886,0,0,1,168,215.629ZM103.055,196.94l-44-44a4.861,4.861,0,0,1-1.387-4.22,129.023,129.023,0,0,1,5.856-22.281l66.014,66.014a129.567,129.567,0,0,1-22.267,5.87,4.863,4.863,0,0,1-4.22-1.387ZM44.094,85.806,68.516,83.92a168,168,0,0,0-21.83,45.851L15.9,125,40.37,88A4.886,4.886,0,0,1,44.094,85.806Zm135.077,77.643a171.957,171.957,0,0,1-34.719,22.706l-74.61-74.61A174.933,174.933,0,0,1,92.55,76.829c15.287-18.327,34.3-34.138,52.165-43.378,35.237-18.226,72.362-19.208,91.356-18.174a4.919,4.919,0,0,1,4.65,4.651c1.035,18.994.052,56.119-18.174,91.356-9.239,17.864-25.05,36.877-43.377,52.164Z"/>
              <path id="Path_73" data-name="Path 73" d="M175.36,118.121a37.469,37.469,0,1,0-26.515-10.965,37.391,37.391,0,0,0,26.515,10.965Zm-15.91-53.39a22.5,22.5,0,1,1-6.59,15.909A22.427,22.427,0,0,1,159.45,64.731Z"/>
              <path id="Path_74" data-name="Path 74" d="M7.65,210.968a7.473,7.473,0,0,0,5.3-2.2L37.44,184.285a7.5,7.5,0,1,0-10.606-10.606L2.347,198.165a7.5,7.5,0,0,0,5.3,12.8Z"/>
              <path id="Path_75" data-name="Path 75" d="M59.88,196.119a7.5,7.5,0,0,0-10.606,0L2.2,243.2A7.5,7.5,0,1,0,12.8,253.8L59.88,206.725a7.5,7.5,0,0,0,0-10.606Z"/>
              <path id="Path_76" data-name="Path 76" d="M71.714,218.559,47.228,243.046a7.5,7.5,0,1,0,10.606,10.606L82.32,229.166a7.5,7.5,0,0,0-10.606-10.606Z"/>
            </g>
          </svg>
        );
      }
      case "Posting Content": {
        return (
          <svg id="more_3161837" xmlns="http://www.w3.org/2000/svg" width="256" height="256" viewBox="0 0 256 256">
            <g id="_x33_">
              <path id="Path_83" data-name="Path 83" d="M192,21.333A42.71,42.71,0,0,1,234.667,64V192A42.71,42.71,0,0,1,192,234.667H64A42.71,42.71,0,0,1,21.333,192V64A42.71,42.71,0,0,1,64,21.333ZM192,0H64A64,64,0,0,0,0,64V192a64,64,0,0,0,64,64H192a64,64,0,0,0,64-64V64A64,64,0,0,0,192,0Z"/>
            </g>
            <g id="_x32_">
              <path id="Path_84" data-name="Path 84" d="M128,192a10.664,10.664,0,0,1-10.667-10.667V74.667a10.667,10.667,0,1,1,21.333,0V181.333A10.664,10.664,0,0,1,128,192Z"/>
            </g>
            <g id="_x31_">
              <path id="Path_85" data-name="Path 85" d="M64,128a10.664,10.664,0,0,1,10.667-10.667H181.333a10.667,10.667,0,0,1,0,21.333H74.667A10.664,10.664,0,0,1,64,128Z"/>
            </g>
          </svg>
        );
      }
      case "Commissions": {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" width="255.92" height="256" viewBox="0 0 255.92 256">
            <g id="hand_1077976_1_" data-name="hand_1077976 (1)" transform="translate(-0.251)">
              <path id="Path_77" data-name="Path 77" d="M117.9,173.633a5,5,0,1,0-7.072,0A5.006,5.006,0,0,0,117.9,173.633Zm0,0"/>
              <path id="Path_78" data-name="Path 78" d="M94.225,54.985A54.985,54.985,0,1,0,149.209,0,55.049,55.049,0,0,0,94.225,54.985Zm99.973,0A44.988,44.988,0,1,1,149.209,10,45.039,45.039,0,0,1,194.2,54.985Zm0,0"/>
              <path id="Path_79" data-name="Path 79" d="M57.826,254.522a5,5,0,0,0,7.055.041l24.234-23.875a15.105,15.105,0,0,0,3.559-15.512l5.213-5.027a14.907,14.907,0,0,1,10.408-4.205h66.451A44.754,44.754,0,0,0,206.125,193.2c.348-.338-2.639,3.18,45.334-54.157a20,20,0,0,0-30.426-25.951l-29.5,30.316A20.089,20.089,0,0,0,176,135.963h-55.74a64.489,64.489,0,0,0-25.031-5c-24.07,0-45.088,11.117-56.367,31.961a14.932,14.932,0,0,0-13.369,4.143L1.709,190.918a5,5,0,0,0-.008,7.051Zm37.4-113.561a54.567,54.567,0,0,1,22.006,4.58,4.989,4.989,0,0,0,2,.42H176a10,10,0,1,1,0,19.994H135.213a5,5,0,1,0,0,10H176a19.964,19.964,0,0,0,19.818-22.607c28.533-29.33,32.557-33.451,32.729-33.656a10,10,0,1,1,15.266,12.918l-44.834,53.6a34.806,34.806,0,0,1-24.229,9.744H108.295a24.852,24.852,0,0,0-17.352,7.008l-4.248,4.1L47.535,167.9c9.152-17.17,26.326-26.936,47.688-26.936Zm-62.66,33.172a4.987,4.987,0,0,1,6.189-.7c.865.527-1.619-1.734,43.295,43.117a5.008,5.008,0,0,1,.027,7.045l-20.66,20.354L12.3,194.455Zm0,0"/>
              <path id="Path_80" data-name="Path 80" d="M143.211,24.994v5.857a14.995,14.995,0,0,0,5,29.133,5,5,0,1,1,0,10c-2.135,0-4.441-1.342-6.49-3.781a5,5,0,1,0-7.652,6.434,20.733,20.733,0,0,0,9.145,6.51v5.834a5,5,0,1,0,10,0V79.119a14.995,14.995,0,0,0-5-29.133,5,5,0,0,1,0-10,8.026,8.026,0,0,1,5.406,2.613,5,5,0,0,0,6.953-7.184,19.311,19.311,0,0,0-7.361-4.6V24.994a5,5,0,1,0-10,0Zm0,0"/>
            </g>
          </svg>
        );
      }
      case "Affiliates": {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" width="256" height="256" viewBox="0 0 256 256">
            <path id="public-relation_4547463" d="M234.363,106.364A21.67,21.67,0,0,0,213.316,123H198.4a70.282,70.282,0,0,0-17.1-41.221L191.867,71.2a21.66,21.66,0,1,0-7.071-7.071L174.222,74.708A70.282,70.282,0,0,0,133,57.6V42.684a21.637,21.637,0,1,0-10,0V57.6a70.279,70.279,0,0,0-41.221,17.1L71.2,64.133a21.657,21.657,0,1,0-7.07,7.071L74.709,81.779A70.279,70.279,0,0,0,57.6,123H42.684a21.638,21.638,0,1,0,0,10H57.6a70.282,70.282,0,0,0,17.1,41.221L64.133,184.8a21.659,21.659,0,1,0,7.071,7.071l10.575-10.574A70.283,70.283,0,0,0,123,198.4v14.919a21.637,21.637,0,1,0,10,0V198.4a70.282,70.282,0,0,0,41.221-17.1L184.8,191.867a21.659,21.659,0,1,0,7.071-7.071l-10.574-10.574A70.279,70.279,0,0,0,198.4,133h14.919a21.635,21.635,0,1,0,21.047-26.637ZM21.636,139.637A11.637,11.637,0,1,1,33.273,128,11.65,11.65,0,0,1,21.636,139.637ZM203.21,41.153A11.637,11.637,0,1,1,191.574,52.79,11.649,11.649,0,0,1,203.21,41.153ZM116.363,21.637A11.636,11.636,0,1,1,128,33.274a11.65,11.65,0,0,1-11.636-11.637ZM52.789,64.427A11.637,11.637,0,1,1,64.426,52.79,11.649,11.649,0,0,1,52.789,64.427Zm0,150.421a11.637,11.637,0,1,1,11.637-11.637A11.649,11.649,0,0,1,52.789,214.847Zm86.847,19.516A11.636,11.636,0,1,1,128,222.727,11.65,11.65,0,0,1,139.636,234.364Zm63.574-42.79a11.637,11.637,0,1,1-11.637,11.637A11.649,11.649,0,0,1,203.21,191.574Zm-75.211-3A60.576,60.576,0,1,1,188.576,128,60.645,60.645,0,0,1,128,188.576Zm106.364-48.939A11.637,11.637,0,1,1,246,128,11.65,11.65,0,0,1,234.363,139.637ZM128,118.509a18.137,18.137,0,1,0-18.138-18.139A18.159,18.159,0,0,0,128,118.509Zm0-26.275a8.137,8.137,0,1,1-8.138,8.136A8.146,8.146,0,0,1,128,92.234Zm0,29.332a33.464,33.464,0,0,0-33.428,33.425c0,12.381,18.007,16.774,33.427,16.774s33.427-4.393,33.427-16.774A33.464,33.464,0,0,0,128,121.566Zm0,40.2c-15.381,0-23.427-4.41-23.427-6.774a23.427,23.427,0,0,1,46.853,0c0,2.365-8.046,6.774-23.426,6.774Z" transform="translate(0)"/>
          </svg>
        );
      }
      default: {
        return null;
      }
    }
  };

  const _formattedFaqs = (categorizedFaqs) => {
    let formattedFaqs = [];
    for (let categoryName in categorizedFaqs) {
      formattedFaqs.push({
        categoryName,
        svgCode: _getFaqHeaderImage(categoryName),
        faqs: categorizedFaqs[categoryName],
      });
    }
    return formattedFaqs;
  };

  const _categorizedFaqs = () => {
    const categorizedFaqs = faqs.categories;
    for (let categoryName in categorizedFaqs) {
      categorizedFaqs[categoryName].forEach((faq) => {
        faq["htmlContent"] = _formatToHtml(faq["content"]);
        faq["searchableContent"] = (faq["content"] + faq["title"])
          .split("<|<>|>")
          .join("")
          .split("[[")
          .join("")
          .split("||")
          .join("")
          .split("]]")
          .join("");
      });
    }

    return categorizedFaqs;
  };

  const _decategorizeFaqs = (categorizedFaqs) => {
    const decategorizedFaqs = [];
    for (let categoryName in categorizedFaqs) {
      categorizedFaqs[categoryName].forEach((faq) => {
        decategorizedFaqs.push(faq);
      });
    }
    return decategorizedFaqs;
  };

  const _formatToHtml = (originalString) => {
    // Iterate through the string
    const links = [];
    for (let i = 0; i < originalString.length; i++) {
      if (originalString[i] === "[" && originalString[i + 1] === "[") {
        const linkStartsAt = i + 2;
        // Link occurance found
        // Iterate to link close ']]'
        for (let j = i + 2; j < originalString.length; j++) {
          if (originalString[j] === "]" && originalString[j + 1] === "]") {
            // Link end found
            const linkEndsAt = j - 1;
            const link = originalString.substring(linkStartsAt, linkEndsAt);
            // Update parent iterator
            i = j + 1;
            const linkParts = link.split("||");
            links.push({
              originalString: originalString.substring(
                linkStartsAt - 2,
                linkEndsAt + 3
              ),
              formattedString: `<a href=${linkParts[1]} target="_blank">${linkParts[0]}</a>`,
            });
            break;
          }
        }
      }
    }
    // Replace links
    links.forEach((link) => {
      originalString = originalString.replace(
        link.originalString,
        link.formattedString
      );
    });
    // Replace newlines
    originalString = originalString.split("<|<>|>").join("<br />");
    return `<p>${originalString}</p>`;
  };

  const _searchFaqs = (value) => {
    setSearchedTerm(value);

    if (!value?.trim()?.length) {
      setIsSearchModeEnabled(false);
      setSelectedCategoryName("");
      return;
    }

    // Perform search
    const newFilteredFaqs = decategorizedFaqs.filter(
      (faq) =>
        faq.searchableContent
          .toLowerCase()
          .indexOf(searchedTerm.trim().toLowerCase()) > -1
    );

    setIsSearchModeEnabled(true);
    setSelectedCategoryName("");
    setFilteredFaqs(newFilteredFaqs);
  };

  const _filterByCardSelect = (categoryName) => {
    const newFilteredFaqs = categorizedFaqs[categoryName];

    setIsSearchModeEnabled(true);
    setFilteredFaqs(newFilteredFaqs);
    setSelectedCategoryName(categoryName);
  };

  const _goBackBtn = () => {
    if (isSearchModeEnabled) {
      _searchFaqs("");
    } else {
      history.goBack();
    }
  };

  return (
    <>
      <div className="customPgHeight animated fadeIn">
        <Container fluid className="noPadding">
          <Row className="customMarginFaq justify-content-center">
            <Col
              xs={12}
              sm={11}
              md={12}
              className="pgPadding customPaddingFaq bg-white"
            >
              <div className="customWrapperFaq">
                <div className="pgTitle justify-content-start">
                  <Button className="customBackBtn customBackBtnFaq" onClick={_goBackBtn}>
                    <i className="fa fa-chevron-left" />
                  </Button>
                  <h2>FAQ</h2>
                </div>
                {isSearchModeEnabled ? (
                  <>
                    <h4 className="faqCategoryTitle">{selectedCategoryName}</h4>
                    <p className="someTipsFaq">
                      Some tips to help you. If you don't find an answer to your
                      issue here,{" "}
                      <a
                        href={CONTACT_SUPPORT_URL}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        write to us
                      </a>
                    </p>
                  </>
                ) : (
                  <h4 className="faqCategoryTitle">How can we help you?</h4>
                )}

                <div className="searchBarFaqWrap">
                  <i className="fa fa-search searchIconFaq" />
                  <Input
                    type="text"
                    name=""
                    placeholder="Search"
                    onChange={(e) => _searchFaqs(e.target.value)}
                    value={searchedTerm}
                    autoFocus
                  />
                  {searchedTerm && (
                    <i
                      className="fa fa-times clearSearch"
                      onClick={() => _searchFaqs("")}
                    />
                  )}
                </div>
              </div>

              <Row className="customMarginFaq justify-content-center">
                <Col xs={12} md={10} lg={8} xl={7} className="px-0">
                  <Row className="noMargin">
                    {isSearchModeEnabled ? (
                      // {/* create the list of faqs */}
                      <FaqAccordion categoryMode={false} faqs={filteredFaqs} />
                    ) : // create the card by categories name or icon
                    formattedFaqs && formattedFaqs.length ? (
                      formattedFaqs.map((faqObj, index) => (
                        <Col sm="6" className="noPadding" key={index}>
                          <div key={index}>
                            <Card
                              body
                              className="faqCategoryWrap"
                              onClick={() =>
                                _filterByCardSelect(faqObj.categoryName)
                              }
                            >
                              <CardTitle>
                                {faqObj.svgCode ? (
                                  <>{faqObj.svgCode}</>
                                ) : (
                                  <i className="fa fa-question" />
                                )}
                              </CardTitle>
                              <p>{faqObj.categoryName}</p>
                            </Card>
                          </div>
                        </Col>
                      ))
                    ) : null}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default FaqPage;
