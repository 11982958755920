import React, { useState } from "react";
import { Button } from "reactstrap";

const ReadMoreReadLess = ({
  text = "",
  count = 70,
  className = "",
  isHideMoreButton = false,
  btnClassName = "",
  inlineStyle = { paddingTop: 0 },
}) => {
  const [isOpen, setIsOpen] = useState(false);

  if (!text) return <></>;

  if (text.length <= count) return <p className={className}>{text}</p>;

  return (
    <p className={`${className}`}>
      {isOpen ? text : text.slice(0, count) + "..."}

      {!isHideMoreButton ? (
        <Button
          color="link"
          onClick={() => setIsOpen((prev) => !prev)}
          className={btnClassName}
          style={inlineStyle}
        >
          {isOpen ? "Less" : "More"}
        </Button>
      ) : null}
    </p>
  );
};

export default ReadMoreReadLess;
