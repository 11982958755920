import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getReferralHistory,
  getInfluencerDetails,
} from "../../http-calls/index";

const updateUserData = createAction("updateUserData");
const storeAssistantData = createAction("storeAssistantData");
const updateOnlyUserData = createAction("updateOnlyUserData");
const clearUserData = createAction("clearUserData");

const fetchReferralHistory = createAsyncThunk(
  "userData/fetchReferralHistory",
  async (payload, thunkAPI) => {
    const res = await getReferralHistory(payload);
    return res?.invitations;
  }
);

const fetchAndModifiedUserDetails = createAsyncThunk(
  "userData/fetchAndModifiedUserDetails",
  async (payload, thunkAPI) => {
    const res = await getInfluencerDetails();
    return res?.user;
  }
);

export {
  updateUserData,
  storeAssistantData,
  updateOnlyUserData,
  clearUserData,
  fetchReferralHistory,
  fetchAndModifiedUserDetails,
};
