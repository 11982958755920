import React, { useState } from "react";
import { useEffect } from "react";
import {
  Button,
  Modal,
  ModalBody,
  FormGroup,
  Label,
  Input,
  InputGroupText,
  InputGroup,
  InputGroupAddon,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { errorHandler, showToast } from "../../helper-methods";
import { changeContentName } from "../../http-calls";

const EditVaultContentNameModal = ({ isOpen, data, toggle, onSave }) => {
  const [name, setName] = useState("");
  const [extension, setExtension] = useState("");
  const [loading, setLoading] = useState(false);

  const _setModalState = (data = null) => {
    const splitContentName = data?.name?.split(".");

    setName(splitContentName?.[0] || "");
    setExtension(splitContentName?.[1] || "");

    setLoading(false);
  };

  const _closeModal = () => {
    _setModalState();
    toggle();
  };

  const _onSave = async () => {
    try {
      const newName = name?.trim();

      if (!newName?.length) {
        showToast("Name is required", "error");
        return;
      }
      setLoading(true);

      await changeContentName({ name }, data?._id);

      onSave(data, newName);

      _closeModal();
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      _setModalState(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => _closeModal()}
      className="modal-dialog-centered modal-dialog-scrollable"
    >
      <ModalHeader toggle={() => _closeModal()}>Change file name</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label>File Name</Label>
          <InputGroup>
            <Input
              className="customRadius dollarWrapInput"
              type="text"
              name="name"
              placeholder="Enter"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            {extension && (
              <InputGroupAddon addonType="append">
                <InputGroupText className="dollarWrap">
                  .{extension}
                </InputGroupText>
              </InputGroupAddon>
            )}
          </InputGroup>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button className="modalBtnCancel" onClick={() => _closeModal()}>
          Cancel
        </Button>

        <Button
          className="modalBtnSave"
          onClick={() => _onSave()}
          disabled={loading}
        >
          {loading ? <i className="fa fa-spinner fa-spin mr-1" /> : null} Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default EditVaultContentNameModal;
