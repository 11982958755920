import React, { Fragment } from "react";
import Skeleton from "react-loading-skeleton";

const SkeletonLiveStreamComment = () => {
  return (
    <>
      <div className="liveStreamBrief">
        <div className="d-flex align-items-center">
          {/* user image */}
          <Skeleton width={30} height={30} borderRadius={50} />

          <p className="creatorName-liveStream">
            {/* is live now text */}
            <Skeleton
              width={Math.floor(Math.random() * 70 + 70)}
              height={10}
              borderRadius={15}
            />
          </p>
        </div>

        {/* live event name */}
        <div className="liveStreamName">
          <Skeleton
            width={Math.floor(Math.random() * 70 + 70)}
            height={10}
            borderRadius={15}
          />
        </div>
      </div>

      <div className="commentSection comment-LiveStreamEvent">
        {[...Array(5)].map((_, index) => (
          <Fragment key={`SkeletonLiveStreamComment_${index}`}>
            <div className="d-flex mt-3">
              {/* user image */}
              <Skeleton width={30} height={30} borderRadius={50} />

              <div>
                <div
                  className="d-flex align-items-center"
                  style={{ marginBottom: 2 }}
                >
                  <p className="creatorName-liveStream">
                    {/* user name */}
                    <Skeleton
                      width={Math.floor(Math.random() * 70 + 70)}
                      height={10}
                      borderRadius={15}
                    />
                  </p>

                  <div className="commentTime">
                    {/* comment time */}
                    <Skeleton
                      width={Math.floor(Math.random() * 20 + 20)}
                      height={10}
                      borderRadius={15}
                    />
                  </div>
                </div>

                <Skeleton
                  width={Math.floor(Math.random() * 100 + 100)}
                  height={35}
                  borderRadius={15}
                />
              </div>
            </div>
          </Fragment>
        ))}
      </div>

      <div className="commentArea-liveStream">
        <div className="d-flex position-relative">
          {/* user image */}
          <Skeleton width={30} height={30} borderRadius={50} />

          {/* comment text input */}
          <div style={{ width: "100%" }}>
            <Skeleton height={40} borderRadius={15} className="mx-1" />
          </div>
        </div>

        <div className="liveStreamingBtnOptions">
          {/* icons */}
          {[...Array(5)].map((_, index) => (
            <Fragment key={`SkeletonLiveStreamIcon_${index}`}>
              <Skeleton
                width={30}
                height={30}
                borderRadius={50}
                className="mx-1"
              />
            </Fragment>
          ))}
        </div>
      </div>
    </>
  );
};

export default SkeletonLiveStreamComment;
