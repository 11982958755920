import React, { useState } from "react";
import { Button } from "reactstrap";
import { DEFAULT_COVER_PICTURE } from "../../config";
import {
  capitalize,
  formatCurrencyValue,
  handleNameObj,
} from "../../helper-methods";
import ShowMediaModal from "../modals/ShowMediaModal";

const ChatVideo = ({ message, fan }) => {
  const [showMediaModal, setShowMediaModal] = useState({
    isOpen: false,
    externalLink: "",
    contentType: "",
    thumbnail: "",
    hlsLink: "",
    duration: 0,
  });

  const _toggleShowMediaModal = (
    isOpen = false,
    externalLink = "",
    contentType = "",
    thumbnail = "",
    hlsLink = "",
    duration = 0
  ) => {
    setShowMediaModal({
      isOpen,
      externalLink,
      contentType,
      thumbnail,
      hlsLink,
      duration,
    });
  };

  return (
    <>
      <div className="mediaVideoWrap">
        <img
          src={message?.content?.[0]?._contentId?.thumbnail}
          onError={(e) => (e.target.src = DEFAULT_COVER_PICTURE)}
          alt="thumbnail"
          loading="lazy"
        />

        <Button
          color="link"
          className="videoPlayBtn"
          onClick={() => {
            _toggleShowMediaModal(
              true,
              message?.content?.[0]?._contentId?.url,
              "video",
              message?.content?.[0]?._contentId?.thumbnail,
              message?.content?.[0]?._contentId?.hlsLink,
              message?.content?.[0]?._contentId?.duration
            );
          }}
        >
          <i className="fa fa-play" />
        </Button>

        {!message?.isPublic ? (
          <div className="msgPrice">
            {message?.content?.[0]?.amount
              ? formatCurrencyValue(message.content[0].amount)
              : message?.amount
              ? formatCurrencyValue(message.amount)
              : ""}
          </div>
        ) : null}
      </div>

      {message?.hasPurchased ? (
        <div className="paidMessageText">
          {fan?.name?.full || fan?.name?.first
            ? capitalize(handleNameObj(fan.name))
            : "Fan"}{" "}
          Paid{" "}
          {message?.content?.[0]?.amount
            ? formatCurrencyValue(message.content[0].amount)
            : message?.amount
            ? formatCurrencyValue(message.amount)
            : ""}{" "}
          for this message
        </div>
      ) : null}

      {showMediaModal.externalLink ? (
        <ShowMediaModal
          isOpen={showMediaModal.isOpen}
          externalLink={showMediaModal.externalLink}
          thumbnail={showMediaModal.thumbnail}
          contentType={showMediaModal.contentType}
          duration={showMediaModal?.duration}
          hlsLink={showMediaModal.hlsLink}
          toggle={() => _toggleShowMediaModal()}
        />
      ) : null}
    </>
  );
};

export default ChatVideo;
