import React from "react";
import Skeleton from "react-loading-skeleton";
import { Card, CardBody, Col } from "reactstrap";

const SkeletonVault = ({ count }) => {
  return (
    <>
      {[...Array(count)].map((_, index) => (
        <Col xs="6" sm="6" lg="4" className="vaultCustomCol" key={`item-${index}`}>
          <Card className="vaultFolderWrap">
            <CardBody>
              <Skeleton height={"100%"} width={"100%"} borderRadius={16} style={{verticalAlign: 3}} />
            </CardBody>
          </Card>
        </Col>
      ))}
    </>
  );
};

export default SkeletonVault;
