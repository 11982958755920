import React, { useEffect, useState } from "react";
import {
  Button,
  InputGroup,
  Input,
  InputGroupAddon,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  InputGroupText,
} from "reactstrap";
import { isValidPrice } from "../../helper-methods";
import { AmountConfig } from "../../config/appConfig";
import { CURRENCY_SYMBOL } from "../../config";

const ChatPriceSetterModal = ({ isOpen, amount, toggle, onSave }) => {
  const [currentAmount, setCurrentAmount] = useState("");
  const [errors, setErrors] = useState("");

  const _closeModal = () => {
    setCurrentAmount("");
    toggle();
  };

  const _validateAmount = (amountValue) => {
    return new Promise((resolve) => {
      if (+amountValue) {
        if (
          +amountValue < AmountConfig.minChatPrice ||
          +amountValue > AmountConfig.maximumLimit
        ) {
          setErrors(
            amountValue < AmountConfig.minChatPrice
              ? `*Minimum ${CURRENCY_SYMBOL}${AmountConfig.minChatPrice}`
              : `*Maximum ${CURRENCY_SYMBOL}${AmountConfig.maximumLimit}`
          );
          resolve(false);
        } else {
          setErrors({});
          resolve(true);
        }
      } else {
        setErrors({});
        resolve(true);
      }
    });
  };

  const _onChangeFormfields = (value) => {
    if (!isValidPrice(value)) return;

    setCurrentAmount(value);
    _validateAmount(value);
  };

  const _onSave = async () => {
    if (!+currentAmount || isNaN(+currentAmount)) {
      onSave(0);
      _closeModal();
      return;
    }

    const isFormValid = await _validateAmount(+currentAmount);
    if (!isFormValid) return;

    onSave(currentAmount);
    _closeModal();
  };

  useEffect(() => {
    if (isOpen && amount && !isNaN(amount)) {
      setCurrentAmount(amount);
    }
  }, [isOpen, amount]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => _closeModal()}
      className="modal-dialog-centered modal-dialog-scrollable"
    >
      <ModalHeader toggle={() => _closeModal()}>Message Price</ModalHeader>
      <ModalBody>
        {/* message price input field */}
        <InputGroup className="my-3 px-3">
          <InputGroupAddon addonType="prepend">
            <InputGroupText className="dollarWrap">
              <i className="fa fa-dollar" />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            type="number"
            value={currentAmount}
            onChange={(e) => _onChangeFormfields(e.target.value)}
            name="input1-group1"
            placeholder="Free"
            className="dollarWrapInput"
          />
        </InputGroup>
        <div className="form-error">{errors?.length ? errors : null}</div>
      </ModalBody>
      <ModalFooter>
        <Button className="modalBtnCancel" onClick={() => _closeModal()}>
          Cancel
        </Button>
        <Button className="modalBtnSave" onClick={() => _onSave()}>
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ChatPriceSetterModal;
