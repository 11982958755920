import { createSlice } from "@reduxjs/toolkit";
import {
  fetchPosts,
  getAndUpdateVaults,
  updateVaultKey,
  clearAllFeedData,
  deletePost,
  updateAPost,
  updateList,
} from "../actions";

const initialState = {
  feeds: [],
  feedsCount: 0,
  vaults: [],
  vaultsCount: 0,
};

const feedDataSlice = createSlice({
  name: "feedData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateVaultKey, (state, action) => {
        const { folder, key, value } = action.payload;

        const findVault = state.vaults?.find((each) => each._id === folder._id);

        if (findVault) {
          findVault[key] = value;
        }
      })

      .addCase(updateAPost, (state, action) => {
        const { index, attr, value } = action.payload;
        state.feeds[index][attr] = value;
      })

      .addCase(updateList, (state, action) => {
        const { index, post } = action.payload;
        state.feeds[index] = post;
      })

      .addCase(deletePost, (state, action) => {
        const index = action.payload;

        const toDelete = state.feeds.splice(index, 1);
        if (toDelete?.length) {
          state.total--;
        }
      })

      .addCase(clearAllFeedData, (state, action) => {
        state.feeds = [];
        state.feedsCount = 0;
        state.vaults = [];
        state.vaultsCount = 0;
      })

      .addCase(fetchPosts.fulfilled, (state, action) => {
        const { posts, total, isConcat } = action.payload;

        if (isConcat) {
          state.feeds = state.feeds.concat(posts);
        } else {
          state.feeds = posts;
        }

        state.feedsCount = total;
      })

      .addCase(getAndUpdateVaults.fulfilled, (state, action) => {
        const { vaults, vaultsCount, isConcat } = action.payload;

        if (isConcat) {
          state.vaults = state.vaults.concat(vaults);
        } else {
          state.vaults = vaults;
        }

        state.vaultsCount = vaultsCount;

        state.loading = false;
      });
  },
});

export const feedDataReducer = feedDataSlice.reducer;
