import React, { useEffect, useMemo, useState } from "react";
import { FormGroup, Label, Input, Button } from "reactstrap";
import {
  errorHandler,
  isPermissionToAccess,
  showToast,
} from "../../helper-methods";
import { getSettings, updateProfile } from "../../http-calls";
import { useDispatch, useSelector } from "react-redux";
import { updateOnlyUserData } from "../../redux/actions";

const SettingsDonationTip = () => {
  const influencer = useSelector((state) => state?.userData?.user);
  const dispatch = useDispatch();

  const [selectedTipText, setSelectedTipText] = useState(
    influencer?.settings?.tipText?.length ? influencer?.settings?.tipText : ""
  );
  const [tipTexts, setTipTexts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const canUpdateSubscriptionSettings = useMemo(
    () =>
      isPermissionToAccess(
        "profileAndSettings",
        "canUpdateSubscriptionSettings",
        true
      ),
    []
  );

  const _saveDynamicTipText = async () => {
    try {
      setIsLoading(true);
      let payload = {
        settings: {
          tipText: selectedTipText?.length ? selectedTipText : "",
        },
      };
      const res = await updateProfile(payload);
      dispatch(updateOnlyUserData(res?.user));
      showToast("Tip Text Updated", "success");
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  };

  const _onChangeHandler = (value) => {
    setSelectedTipText(value);
  };

  const _getTipText = async () => {
    try {
      const res = await getSettings();
      setTipTexts(res?.setting.tipTexts?.length ? res?.setting.tipTexts : []);
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    _getTipText();
  }, []);

  return (
    <div className="settingOptionsWrap" style={{ marginTop: 27 }}>
      <div className="header_Settings">
        <h4>Dynamic Tip Text</h4>
      </div>
      <FormGroup>
        <Label>Select your tip label</Label>
        <Input
          type="select"
          value={selectedTipText}
          disabled={!canUpdateSubscriptionSettings}
          onChange={(e) => _onChangeHandler(e.target.value)}
        >
          <option value="">Select </option>
          {tipTexts.map((tipText, tipIndex) => (
            <option key={tipIndex} value={tipText}>
              {tipText}
            </option>
          ))}
        </Input>

        {canUpdateSubscriptionSettings ? (
          <Button
            disabled={!selectedTipText.length || isLoading}
            className="themeBtn saveBtn"
            onClick={() => _saveDynamicTipText()}
          >
            {isLoading ? <i className="fa fa-spinner fa-spin" /> : null} Save
          </Button>
        ) : null}
      </FormGroup>
    </div>
  );
};

export default SettingsDonationTip;
