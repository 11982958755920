export const countryWiseVerification = {
  US: ["SSN"],
  CA: ["ID_FRONT", "ID_BACK"],
  DE: ["ID_FRONT", "ID_BACK", "ADDL_ID_FRONT", "ADDL_ID_BACK"],
  FR: ["ID_FRONT", "ID_BACK", "ADDL_ID_FRONT", "ADDL_ID_BACK"]
};

export const countryWiseOptionalDocument = {
  US: [],
  CA: ["ID_FRONT", "ID_BACK"],
  DE: ["BANK_ACCOUNT_ROUTING_NUMBER"],
  FR: ["BANK_ACCOUNT_ROUTING_NUMBER"],
  IT: ["BANK_ACCOUNT_ROUTING_NUMBER"]

};
