import React, { useEffect, useState } from "react";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import { APP_LOGO } from "../config";
import PublicFooter from "../components/PublicFooter";
import {
  deepClone,
  errorHandler,
  extractQueryParams,
  showToast,
} from "../helper-methods";
import { forgotPassword } from "../http-calls";
import ForgotPasswordSuccess from "../components/ForgotPasswordSuccess";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { RegexConfig } from "../config/RegexConfig";

const ForgotPasswordPage = () => {
  const history = useHistory();
  const userData = useSelector((state) => state?.userData);

  const [formFields, setFormFields] = useState({
    handle: "",
  });

  const [isDirty, setIsDirty] = useState({});
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const _onBlurHandler = (fieldName) => {
    const newFormFields = deepClone(formFields);
    const newIsDirty = deepClone(isDirty);

    newIsDirty[fieldName] = true;
    setIsDirty(newIsDirty);

    _validateForm({ newFormFields, newIsDirty });
  };

  const _updateFieldValue = (fieldName, value) => {
    const newFormFields = deepClone(formFields);
    const newIsDirty = deepClone(isDirty);

    newFormFields[fieldName] = value;

    setFormFields(newFormFields);
    _validateForm({ newFormFields, newIsDirty });
  };

  const _validateForm = ({ newFormFields, newIsDirty }) => {
    return new Promise((resolve) => {
      const newErrors = {};
      let isFormValid = true;

      Object.keys(newFormFields)?.forEach((key) => {
        if (newIsDirty[key]) {
          switch (key) {
            case "handle":
              if (newFormFields[key]?.trim()?.length) {
                if (
                  RegexConfig.email.test(
                    String(newFormFields[key]).toLowerCase()
                  )
                ) {
                  newErrors[key] = null;
                  newIsDirty[key] = false;
                } else {
                  newErrors[key] = "*Invalid email";
                  isFormValid = false;
                }
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;

            default: {
            }
          }
        }

        setIsDirty(newIsDirty);
        setErrors(newErrors);

        resolve(isFormValid);
      });
    });
  };

  const _markAllIsDirty = () => {
    return new Promise((resolve) => {
      const newIsDirty = deepClone(isDirty);
      const newFormFields = deepClone(formFields);
      Object.keys(newFormFields)?.forEach((key) => {
        newIsDirty[key] = true;
      });
      setIsDirty(newIsDirty);
      resolve(newIsDirty);
    });
  };

  const _validateAndSubmit = async (e) => {
    try {
      if (e) e.preventDefault();

      const newIsDirty = await _markAllIsDirty();
      const newFormFields = deepClone(formFields);
      const isFormValid = await _validateForm({
        newFormFields,
        newIsDirty,
      });

      if (!isFormValid) {
        return;
      }

      setIsLoading(true);
      const payload = newFormFields?.handle;
      await forgotPassword(payload);

      setIsSuccess(true);
      setIsLoading(false);
    } catch (err) {
      errorHandler(err);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (userData?.token) {
      // Already logged in
      showToast("Logout to reset password");
      history.replace("/");
    } else {
      const queries = extractQueryParams();
      if (queries?.email?.length) {
        let newFormFields = deepClone(formFields);
        newFormFields.handle = queries?.email;
        setFormFields(newFormFields);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isSuccess) {
    return (
      <ForgotPasswordSuccess
        email={formFields.handle}
        resetSuccessState={() => setIsSuccess(false)}
      />
    );
  }

  return (
    <div
      className="animated fadeIn authFormWrapper"
      data-test="forgot-password-page"
    >
      <div className="loginWrapper mt-auto">
        <img
          src={APP_LOGO}
          alt="Project Logo"
          className="projectLogo mt-0"
          onClick={() => history.replace("/")}
          loading="lazy"
        />
        <div className="authPgFormWrap">
          <h4>Forgot Password</h4>
          <Form onSubmit={(e) => _validateAndSubmit(e)}>
            <FormGroup>
              <Label>Email</Label>
              <Input
                data-test="email-input-box"
                type="text"
                autoComplete="Email"
                placeholder="Email"
                value={formFields?.handle}
                onChange={(e) => _updateFieldValue("handle", e.target.value)}
                onBlur={() => _onBlurHandler("handle")}
              />
              {errors?.handle ? (
                <div className="form-error" data-test="email-error-div">
                  {errors?.handle}
                </div>
              ) : null}
            </FormGroup>

            <Button
              className="themeBtn loginBtn"
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? <i className="fa fa-spinner fa-spin mr-1" /> : null}
              Forgot Password
            </Button>

            <Link to="/login" className="backToLogin">
              Back to Login
            </Link>
          </Form>
        </div>
      </div>

      <PublicFooter />
    </div>
  );
};

export default ForgotPasswordPage;
