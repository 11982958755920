import React, { useState } from "react";
import { CardElement, ElementsConsumer } from "@stripe/react-stripe-js";
import { useEffect } from "react";
import { errorHandler, showToast } from "../helper-methods";
import { CURRENCY_TYPE } from "../config";

const StripeElement = (props) => {
  const {
    addCard,
    stripe,
    showLoader,
    hideLoader,
    elements,
    onStripeError,
    onCardAdded,
  } = props || {};

  const [errorMessage, setErrorMessage] = useState("");

  const _onStripeError = (error) => {
    setErrorMessage(error?.message);
    onStripeError(error);
  };

  const _addCard = (e) => {
    try {
      if (e) e.preventDefault();

      if (!stripe) {
        showToast("Stripe.js hasn't loaded yet.", "error");
        return;
      }

      showLoader();

      stripe
        .createToken(elements.getElement(CardElement), {
          currency: CURRENCY_TYPE,
        })
        .then((payload) => {
          if (payload?.error?.message) {
            _onStripeError(payload.error);
            hideLoader();
            return;
          }

          hideLoader();
          onCardAdded(payload);
        })
        .catch((error) => {
          console.log({ error });
          _onStripeError(error);
          hideLoader();
        });
    } catch (error) {
      errorHandler(error);
      hideLoader();
    }
  };

  const _updateErrorMessage = (e) => {
    if (e && e.error && e.error.message) {
      setErrorMessage(e.error.message);
    } else {
      setErrorMessage("");
    }
  };

  useEffect(() => {
    addCard(_addCard);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-100">
      <CardElement {...props} onChange={_updateErrorMessage} />
      {errorMessage ? <div className="form-error">{errorMessage}</div> : null}
    </div>
  );
};

const InjectedStripeElement = (props) => (
  <ElementsConsumer>
    {({ stripe, elements }) => (
      <>
        <StripeElement {...props} stripe={stripe} elements={elements} />
      </>
    )}
  </ElementsConsumer>
);

export default InjectedStripeElement;
