import React, { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Input,
  Label,
  FormGroup,
} from "reactstrap";
import { errorHandler, showToast } from "../../helper-methods";
import { createCoupon } from "../../http-calls";

const AddFreeTrialLinkModal = ({ isOpen, toggle, getAllFreeTrialLink }) => {
  const [formFields, setFormFields] = useState({
    introductionText: {
      value: "",
      error: null,
      isDirty: false,
      isValidationRequired: true,
    },
    trialPeriod: {
      value: "",
      error: null,
      isDirty: false,
      isValidationRequired: true,
    },
  });
  const [loading, setLoading] = useState(false);

  const _resetStateModal = () => {
    setFormFields({
      introductionText: {
        value: "",
        error: null,
        isDirty: false,
        isValidationRequired: true,
      },
      trialPeriod: {
        value: "",
        error: null,
        isDirty: false,
        isValidationRequired: true,
      },
    });
    setLoading(false);
  };

  const _closeModal = () => {
    _resetStateModal();
    toggle();
  };

  const _validateFormFields = (newFormFields) => {
    return new Promise((resolve) => {
      let isFormValid = true;

      Object.keys(newFormFields).forEach((key) => {
        if (newFormFields[key].isDirty) {
          switch (key) {
            case "introductionText": {
              if (newFormFields[key]?.value?.trim().length) {
                newFormFields[key].error = null;
                newFormFields[key].isDirty = false;
              } else {
                newFormFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "trialPeriod": {
              if (newFormFields[key]?.value?.trim().length) {
                if (
                  !isNaN(newFormFields[key].value) &&
                  Number(newFormFields[key].value) > 0
                ) {
                  newFormFields[key].error = null;
                  newFormFields[key].isDirty = false;
                } else {
                  newFormFields[key].error = `*Minimum 1`;
                  isFormValid = false;
                }
              } else {
                newFormFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            default:
          }
        }
      });

      setFormFields(newFormFields);

      resolve(isFormValid);
    });
  };

  const _onChangeFormFields = (key, value) => {
    const newFormFields = { ...formFields };

    if (key === "trialPeriod" && (isNaN(value) || value.includes("."))) return;

    newFormFields[key].value = value;
    newFormFields[key].isDirty = true;
    setFormFields(newFormFields);
    _validateFormFields(newFormFields);
  };

  const _createPromotionTrialLink = (payload) => {
    createCoupon(payload)
      .then((res) => {
        showToast("Link created successfully.", "success");
        getAllFreeTrialLink();
        _closeModal();
      })
      .catch((error) => {
        setLoading(false);
        errorHandler(error);
      });
  };

  const _onSubmitForm = async () => {
    try {
      const newFormFields = { ...formFields };
      Object.keys(newFormFields).forEach(
        (key) => (newFormFields[key].isDirty = true)
      );
      setFormFields(newFormFields);
      const isFormValid = await _validateFormFields(newFormFields);

      if (isFormValid) {
        setLoading(true);

        const payload = {
          introductionText: formFields.introductionText.value.trim(),
          trialPeriod: formFields.trialPeriod.value.trim(),
        };

        _createPromotionTrialLink(payload);
      }
    } catch (error) {
      setLoading(false);
      errorHandler(error);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => _closeModal()}
      className="modal-dialog-centered modal-dialog-scrollable"
    >
      <ModalHeader toggle={() => _closeModal()}>
        Free Trial Link{" "}
        {loading ? <i className="fa fa-spinner fa-spin mr-1" /> : null}
      </ModalHeader>

      <ModalBody>
        <FormGroup>
          <Label style={{fontSize: 14, marginBottom: 5}}>Introduction</Label>
          <Input
            type="textarea"
            rows="3"
            placeholder="Enter"
            value={formFields.introductionText.value}
            onChange={(e) =>
              _onChangeFormFields("introductionText", e.target.value)
            }
          />
          {formFields.introductionText.error && (
            <div className="validation-error">
              {formFields.introductionText.error}
            </div>
          )}
        </FormGroup>
        <FormGroup className="mb-2">
          <Label style={{fontSize: 14, marginBottom: 5}}>Free Trial Days #</Label>
          <Input
            type="text"
            placeholder="Enter"
            value={formFields.trialPeriod.value}
            onChange={(e) => _onChangeFormFields("trialPeriod", e.target.value)}
          />
          {formFields.trialPeriod.error && (
            <div className="validation-error">
              {formFields.trialPeriod.error}
            </div>
          )}
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        <Button className="modalBtnCancel" onClick={() => _closeModal()}>
          Cancel
        </Button>
        <Button
          className="modalBtnSave"
          onClick={() => _onSubmitForm()}
          disabled={loading}
        >
          {loading ? <i className="fa fa-spinner fa-spin mr-1" /> : null} Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddFreeTrialLinkModal;
