import React, { memo, useEffect, useRef } from "react";
import { ZegoUIKitPrebuilt } from "@zegocloud/zego-uikit-prebuilt";
import { errorHandler } from "../../helper-methods";

export const CoHostStreaming = ({ eventStreamingData }) => {
  const streamingDivRef = useRef();
  const zpRef = useRef({ current: null });

  const sharedLinks = [];

  // start the call
  const _startMeeting = async () => {
    try {
      // Host / Cohost / Audience
      const role = ZegoUIKitPrebuilt[eventStreamingData?.userRole || "Cohost"];

      const kitToken = ZegoUIKitPrebuilt.generateKitTokenForProduction(
        eventStreamingData?.appId,
        eventStreamingData?.token,
        eventStreamingData?.roomId,
        eventStreamingData?.userId,
        eventStreamingData?.userName
      );

      // Create instance object from Kit Token.
      zpRef.current = ZegoUIKitPrebuilt.create(kitToken);

      // start the call
      zpRef.current.joinRoom({
        container: streamingDivRef.current,
        showTextChat: false,
        showPreJoinView: false,
        turnOnCameraWhenJoining: false,
        showRoomTimer: true,
        showRemoveUserButton: false,
        showRoomDetailsButton: false,
        scenario: {
          mode: ZegoUIKitPrebuilt.LiveStreaming,
          config: {
            role,
          },
        },
        sharedLinks,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  const _stopMeeting = () => {
    try {
      zpRef.current.destroy();
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    _startMeeting();

    return () => {
      _stopMeeting();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div ref={streamingDivRef} className="myCallContainer" />
    </>
  );
};

export default memo(CoHostStreaming);
