import React, { Component } from "react";
import { connect } from "react-redux";
import { UploadQueueManager } from "../upload-queue-manager";
import { onFilePercentageChange } from "../helper-methods/index";
import CustomLoader from "../components/custom/CustomLoader";
import ConfigureString from "../components/ConfigureString";

class FullPageLoader extends Component {
  state = { queueCount: 0, percentage: 0 };

  componentDidMount() {
    UploadQueueManager.onQueueCountChange((queueCount) => {
      this.setState({ queueCount });
    });

    let uploadingKeys = {};

    onFilePercentageChange(({ percentage, key }) => {
      uploadingKeys[key] = percentage;

      const totalPercentage = Object.values(uploadingKeys).reduce(
        (acc, each) => acc + each,
        0
      );

      percentage = Math.round(
        totalPercentage / Object.values(uploadingKeys).length
      );

      if (percentage <= 0 || percentage >= 99) {
        percentage = 0;
        uploadingKeys = {};
      }

      this.setState({ percentage });
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { loaderData } = this.props;
    if (
      loaderData?.isVisible &&
      loaderData?.isVisible !== prevProps?.loaderData?.isVisible
    ) {
      this.setState({ queueCount: 0, percentage: 0 });
    }
  }

  render() {
    const { queueCount, percentage } = this.state;
    const { loaderData } = this.props;
    if (loaderData?.isVisible) {
      return (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            zIndex: 9999999,
            opacity: 0.95,
            backgroundColor: "white",
          }}
        >
          <div className="loaderWrap">
            <CustomLoader type="Oval" height={70} width={70} />

            {loaderData?.loaderText ? (
              <p>
                <ConfigureString keyString={loaderData.loaderText} />
              </p>
            ) : (
              <></>
            )}

            {queueCount > 0 ? (
              <p>
                {queueCount > 1
                  ? `${queueCount} Uploads in progress`
                  : `${queueCount} Upload in progress`}{" "}
                {percentage ? `${percentage}%` : null}
              </p>
            ) : null}
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    loaderData: state.loaderData,
  };
};

export default connect(mapStateToProps, null)(FullPageLoader);
