import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import {
  Button,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap";
import { capitalize, deepClone, handleNameObj } from "../../helper-methods";

const LiveEventStatusModal = ({ isOpen, data, toggle }) => {
  const [status, setStatus] = useState("");
  const [rsvp, setRsvp] = useState([]);
  const [rsvpYes, setRsvpYes] = useState([]);
  const [rsvpNo, setRsvpNo] = useState([]);
  const [rsvpMaybe, setRsvpMaybe] = useState([]);

  const filteredRsvp = useMemo(() => {
    if (status) {
      return rsvp.filter((each) => each.status === status);
    }

    return rsvp;
  }, [rsvp, status]);

  const _closeModal = () => {
    toggle();
  };

  const _setModalState = (data) => {
    try {
      let { rsvpYes, rsvpNo, rsvpMaybe } = deepClone(data);

      rsvpYes = rsvpYes.map((each) => ({ ...each, status: "yes" }));
      rsvpNo = rsvpNo.map((each) => ({ ...each, status: "no" }));
      rsvpMaybe = rsvpMaybe.map((each) => ({ ...each, status: "maybe" }));

      setRsvp([...rsvpYes, ...rsvpNo, ...rsvpMaybe]);
      setRsvpYes(rsvpYes);
      setRsvpNo(rsvpNo);
      setRsvpMaybe(rsvpMaybe);
    } catch (error) {
      setRsvp([]);
    }
  };

  useEffect(() => {
    if (isOpen && data) {
      _setModalState(data);
    }
  }, [isOpen, data]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => _closeModal()}
      className="modal-dialog-centered modal-dialog-scrollable"
    >
      <ModalHeader toggle={() => _closeModal()}>RSVP Status</ModalHeader>
      <ModalBody>
        <div className="rsvpStatusWrap">
          <div className="text-center">
            <h4>{rsvpYes.length}</h4>
            <p>Yes</p>
          </div>
          <div className="text-center">
            <h4>{rsvpMaybe.length}</h4>
            <p>Maybe</p>
          </div>
          <div className="text-center">
            <h4>{rsvpNo.length}</h4>
            <p>Not Attending</p>
          </div>
        </div>
        <FormGroup className="float-right mb-2">
          <Input
            type="select"
            className="fs-14"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          >
            <option value="">Status</option>
            <option value="yes">Yes</option>
            <option value="maybe">Maybe</option>
            <option value="notAttending">Not Attending</option>
          </Input>
        </FormGroup>
        <Table responsive>
          <thead>
            <tr>
              <th>Subscribers</th>
              <th>RSVP Status</th>
            </tr>
          </thead>
          <tbody>
            {filteredRsvp?.length ? (
              filteredRsvp.map((each, index) => (
                <tr key={index}>
                  <td>{handleNameObj(each.name)}</td>
                  <td>{capitalize(each.status)}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="2">
                  <div className="noContentFound my-0">No result found</div>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </ModalBody>
      <ModalFooter>
        <Button className="modalBtnCancel" onClick={() => _closeModal()}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default LiveEventStatusModal;
