import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  // Label,
  // InputGroup,
  // InputGroupAddon,
  // InputGroupText,
  // Input,
  // FormGroup,
} from "reactstrap";
import { convertDate } from "../../helper-methods";
import DateTime from "react-datetime";
import moment from "moment";

class SelectDateTimeModal extends Component {
  state = {
    date: new Date(),
    today: new Date(),
  };

  initializeScheduleDate = () => {
    this.setState({
      date: new Date(),
      today: new Date(),
    });
  };

  onChange = (date) => this.setState({ date });

  _save = () => {
    if (moment(this.state.date).isValid()) {
      this.props.onSave(convertDate(this.state.date));
      // this.initializeScheduleDate();
    } else {
      this.props.onSave(null);
      this.initializeScheduleDate();
    }
  };

  _close = () => {
    this.props.onCancel();
  };

  validation = (selectedDate) => {
    return !selectedDate.isBefore(moment(new Date()).add(-1, "days"));
  };

  timeValidation = (selectedTime) => {
    return true;
  };

  render() {
    // const {
    //   postedData,
    //   activeIndex,
    //   uploadImages,
    //   recordredAudio,
    //   pollOptions,
    //   expirationPeriod
    // } = deepClone(this.state);

    return (
      <React.Fragment>
        <Modal
          isOpen={this.props.isOpen}
          toggle={() => this._close()}
          className="modal-dialog-centered modal-dialog-scrollable"
        >
          <ModalHeader toggle={() => this._close()}>
            Select Date
            {/* Create NFT Collection */}
          </ModalHeader>
          <ModalBody>
            <div className="dateTimePickerWrap">
              <DateTime
                timeFormat={true}
                isValidDate={this.validation}
                timeConstraints={moment()}
                isValidTime={this.validation}
                dateFormat="YYYY-MM-DD"
                minDate={this.props.startDate || this.state.today}
                onChange={this.onChange}
                value={this.state.date}
                input={false}
              />
            </div>

            {/* <FormGroup>
              <Label>Collection Name</Label>
              <Input type="text" placeholder="Enter Name" />
            </FormGroup>

            <Label>Set Price</Label>
            <div className="d-flex mb-3">
              <div className="setNFTPriceWrap mr-1"> */}
            {/* default value will be "BTC", user can change/select as per his/her requirement & enter the amt in the below text field */}
            {/* <Input
                  type="select"
                  style={{
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                  }}
                >
                  <option>BTC</option>
                  <option>ETH</option>
                  <option>SOL</option>
                </Input>
                <Input
                  type="text"
                  placeholder="Enter"
                  style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                />
              </div>

              <div className="setNFTPriceWrap ml-1"> */}
            {/* here, we will convert the amount entered above and show it(in Dollars) below */}
            {/* creator can either enter the amt in BitCoin(or any other crypto) or in dollars */}
            {/* <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText className="dollarWrap">
                      <i className="fa fa-dollar" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="" />
                </InputGroup>
              </div>
            </div>

            <FormGroup>
              <Label>No. Of Copies</Label> */}
            {/* default value will be 1, creator can change it to any other number */}
            {/* <Input type="text" placeholder="1" />
            </FormGroup> */}
          </ModalBody>
          <ModalFooter>
            <Button className="modalBtnCancel" onClick={() => this._close()}>
              Cancel
            </Button>
            <Button className="modalBtnSave" onClick={() => this._save()}>
              Save
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default SelectDateTimeModal;
