import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import { APP_LOGO } from "../../config";
import {
  capitalize,
  errorHandler,
  getSrcUrl,
  uploadFileOnServer,
} from "../../helper-methods";
import { saveContents, updateInfluencerDetails } from "../../http-calls";
import { fetchAndModifiedUserDetails } from "../../redux/actions";
import ReactCropper from "../ReactCropper";
import CustomVideoPlayer from "../custom/CustomVideoPlayer";
import MediaLibraryModal from "./MediaLibraryModal";

const CoverImageModal = ({ isOpen, data, toggle }) => {
  const dispatch = useDispatch();

  const [carousel, setCarousel] = useState([]);
  const [currentCarouselIndex, setCurrentCarouselIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [mediaLibraryModal, setMediaLibraryModal] = useState({
    isOpen: false,
  });
  const [changeMediaData, setChangeMediaData] = useState({
    index: -1,
    contentType: null,
  });

  const totalMediaCount = useMemo(() => {
    return {
      image: carousel?.reduce(
        (acc, each) => (each.contentType === "image" ? acc + 1 : acc),
        0
      ),
      video: carousel?.reduce(
        (acc, each) => (each.contentType === "video" ? acc + 1 : acc),
        0
      ),
    };
  }, [carousel]);

  const _resetState = () => {
    setCarousel([]);
    setCurrentCarouselIndex(0);
    setLoading(false);
    setMediaLibraryModal({
      isOpen: false,
    });
    setChangeMediaData({
      index: -1,
      contentType: null,
    });
  };

  const _closeModal = () => {
    _resetState();
    toggle();
  };

  // toggle media library modal
  const _toggleMediaLibraryModal = (isOpen = false) => {
    if (isOpen && changeMediaData.contentType && changeMediaData.index > -1) {
      setChangeMediaData({
        index: -1,
        contentType: null,
      });
    }

    setMediaLibraryModal({ isOpen });
  };

  const _makeCover = (index) => {
    const newCarousel = [...carousel];
    const removed = newCarousel.splice(index, 1);
    newCarousel.splice(0, 0, ...removed);
    setCarousel(newCarousel);

    setCurrentCarouselIndex(0);
  };

  const _deleteMedia = (index) => {
    const newCarousel = [...carousel];
    newCarousel.splice(index, 1);
    setCarousel(newCarousel);

    setCurrentCarouselIndex(0);
  };

  const _changeMedia = (index) => {
    _toggleMediaLibraryModal(true);

    setChangeMediaData({
      index,
      contentType: carousel[index]?.contentType,
    });
  };

  // update carousel content from collection of uploaded media library
  const _uploadCoverContent = async (contents) => {
    try {
      if (!contents?.length) return;

      let newCarousel = [...carousel];

      if (changeMediaData.index >= 0) {
        newCarousel.splice(changeMediaData.index, 1);

        newCarousel.splice(changeMediaData.index, 0, contents?.[0]);

        contents.shift();

        setCurrentCarouselIndex(changeMediaData?.index);

        setChangeMediaData({
          index: -1,
          contentType: null,
        });
      } else {
        newCarousel.push(...contents);

        setCurrentCarouselIndex(newCarousel?.length - 1);
      }

      setCarousel(newCarousel);

      //for re-rendering
      setTimeout(() => {
        setChangeMediaData({ index: -1, contentType: null });
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  const _onCropComplete = ({ index, crop }) => {
    const newCarousel = [...carousel];

    newCarousel[index].crop = crop;

    setCarousel(newCarousel);
  };

  const _onApply = async () => {
    try {
      setLoading(true);

      const payload = {
        // carousel,
        carouselCrop: [],
      };

      const filesToUpload = [];
      let mediaLibraryFiles = [];

      carousel.forEach((each, index) => {
        if (each?.url) {
          mediaLibraryFiles?.push(each);
        } else {
          each["forKeyName"] = each.crop;
          filesToUpload.push(each);
        }
      });

      if (filesToUpload?.length) {
        // uploadFiles on AWS  Server
        const res = await uploadFileOnServer(filesToUpload);

        // upload files url to mediaLibraryModal
        const newLibraryFiles = await saveContents({ contents: res });

        newLibraryFiles?.contents?.forEach((each, index) => {
          let obj = {
            _id: each._id,
            url: each.url,
            contentType: each.contentType,
            hlsLink: each.hlsLink,
            thumbnail: each.thumbnail,
            crop: res?.[index]?.["forKeyName"],
          };

          mediaLibraryFiles?.push(obj);
        });
      }

      mediaLibraryFiles.forEach((each, index) => {
        if (!isNaN(each.crop?.x) && !isNaN(each.crop?.y)) {
          payload.carouselCrop.push({
            ...each.crop,
            carouselIndex: index,
          });
        }
      });

      payload["carousel"] = mediaLibraryFiles;

      await updateInfluencerDetails(payload);

      await fetchAndModifiedUserDetails()(dispatch);

      setLoading(false);

      _closeModal();
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  const _initialize = (data = null) => {
    if (!data?.carousel?.length) return;

    const carouselCrop = data?.carouselCrop?.length ? data?.carouselCrop : [];

    const newCarousel = [];

    data?.carousel?.forEach((each, index) => {
      const carouselObj = {
        _id: each._id,
        url: each.url,
        contentType: each.contentType,
        hlsLink: each.hlsLink,
        thumbnail: each.thumbnail,
      };

      if (each.contentType === "image") {
        const findCropObj = carouselCrop?.find(
          (each) => each.carouselIndex === index
        );

        if (findCropObj) {
          carouselObj.crop = findCropObj;
        }
      }

      newCarousel.push(carouselObj);
    });
    setCarousel(newCarousel);
  };

  useEffect(() => {
    if (isOpen && data?.carousel) {
      _initialize(data);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, data]);

  let imageCount = 1;

  return (
    <>
      <Modal
        isOpen={isOpen}
        className="modal-dialog-centered modal-dialog-scrollable modal-lg"
      >
        <ModalHeader toggle={() => _closeModal()}>
          Edit Cover Image/Video
        </ModalHeader>
        <ModalBody className="">
          {carousel?.length ? (
            <>
              <Nav tabs className="customTabs px-0">
                {carousel.map((each, index) => (
                  <NavItem key={`carousel_NavLink_${index}`}>
                    <NavLink
                      className={currentCarouselIndex === index ? "active" : ""}
                      onClick={() => setCurrentCarouselIndex(index)}
                    >
                      {capitalize(each?.contentType || each?.type)}{" "}
                      {each?.contentType === "image" || each?.type === "image"
                        ? imageCount++
                        : ""}
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>

              <TabContent
                activeTab={currentCarouselIndex}
                className="customTabContent"
              >
                {carousel.map((each, index) => (
                  <TabPane key={`carousel_TabPane_${index}`} tabId={index}>
                    {each?.contentType === "image" || each?.type === "image" ? (
                      <div className="my-1">
                        {currentCarouselIndex === index ? (
                          <ReactCropper
                            imageSrc={getSrcUrl(each) || each?.previewBlob}
                            crop={each?.crop}
                            onCropComplete={(crop) =>
                              _onCropComplete({
                                index,
                                crop,
                              })
                            }
                            locked={true}
                            disabled={loading}
                          />
                        ) : null}
                      </div>
                    ) : (
                      <div className="videoInImageCropModal">
                        <CustomVideoPlayer
                          src={each?.url || each?.previewBlob}
                          hlsLink={each?.hlsLink}
                          thumbnail={each?.thumbnail}
                          controls
                        />
                      </div>
                    )}

                    <div
                      className="d-flex justify-content-center"
                      style={{ marginTop: 10 }}
                    >
                      {index > 0 ? (
                        <Button
                          className="modalBtnSave"
                          disabled={loading}
                          onClick={() => _makeCover(index)}
                        >
                          Make Cover
                        </Button>
                      ) : null}

                      {totalMediaCount?.image < 3 ||
                      totalMediaCount?.video < 1 ? (
                        <Button
                          className="modalBtnSave"
                          disabled={loading}
                          onClick={() => _toggleMediaLibraryModal(true)}
                        >
                          Add Media
                        </Button>
                      ) : null}

                      <Button
                        className="modalBtnCancel"
                        disabled={loading}
                        onClick={() => _changeMedia(index)}
                      >
                        Change
                      </Button>

                      <Button
                        className="deleteCoverImg"
                        disabled={loading}
                        onClick={() => _deleteMedia(index)}
                      >
                        Delete
                      </Button>
                    </div>
                  </TabPane>
                ))}
              </TabContent>
            </>
          ) : (
            <>
              <div className="defaultCoverImg" style={{ height: "unset" }}>
                <img src={APP_LOGO} alt="logo" />
              </div>

              <Button
                className="themeBtn addBtn mt-4 d-block mx-auto"
                disabled={loading}
                onClick={() => _toggleMediaLibraryModal(true)}
              >
                Add Media
              </Button>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button className="modalBtnCancel" onClick={() => _closeModal()}>
            Cancel
          </Button>
          <Button
            className="modalBtnSave"
            onClick={() => _onApply()}
            disabled={loading}
          >
            {loading ? <i className="fa fa-spinner fa-spin mr-1" /> : null}{" "}
            Apply
          </Button>
        </ModalFooter>
      </Modal>

      <MediaLibraryModal
        isOpen={mediaLibraryModal.isOpen}
        changeMediaData={changeMediaData}
        toggle={() => _toggleMediaLibraryModal()}
        uploadContents={(contents) => _uploadCoverContent(contents)}
        isMultipleImages={true}
        isMultipleVideos={true}
        isMultipleAudios={true}
        isMultipleUpload={true}
        isAudioUpload={false}
        isImageUpload={true}
        isVideoUpload={true}
        isUploadLimit={true}
        uploadedFiles={carousel?.length ? carousel : []}
        imageLimit={
          changeMediaData?.contentType === "image" &&
          changeMediaData?.index > -1
            ? 4
            : 3
        }
        videoLimit={
          changeMediaData?.contentType === "video" &&
          changeMediaData?.index > -1
            ? 2
            : 1
        }
      />
    </>
  );
};

export default CoverImageModal;
