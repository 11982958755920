import React, { useEffect, useState } from "react";
import { isPermissionToAccess, showToast } from "../helper-methods";
import BroadCastMessage from "../components/messages/BroadCastMessage";
import ChatFanList from "../components/ChatFanList";
import { useHistory } from "react-router-dom";

const MessageInitiatorPage = () => {
  const history = useHistory();

  const [broadCastMessage, setBroadCastMessage] = useState({
    isOpen: false,
    fans: [],
    isSelectedAllFollowers: false,
    totalFollowers: 0,
  });

  const _toggleBroadCastMessage = (
    isOpen = false,
    fans = [],
    isSelectedAllFollowers = false,
    totalFollowers = 0
  ) => {
    setBroadCastMessage({
      isOpen,
      fans,
      isSelectedAllFollowers,
      totalFollowers,
    });
  };

  useEffect(() => {
    if (!isPermissionToAccess("messaging", "canViewMessaging")) {
      showToast("Unauthorized", "error");
      history.push("/my-profile");
      return;
    }

    if (!isPermissionToAccess("messaging", "canSendGroupMessaging")) {
      showToast("Unauthorized", "error");
      history.push("/messages");
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (broadCastMessage.isOpen) {
    return (
      <BroadCastMessage
        goBack={() => _toggleBroadCastMessage()}
        fans={broadCastMessage.fans}
        isSelectedAllFollowers={broadCastMessage.isSelectedAllFollowers}
        totalFollowers={broadCastMessage.totalFollowers}
      />
    );
  }

  return (
    <ChatFanList
      switchToBroadCast={(fans, isSelectedAllFollowers, totalFollowers) =>
        _toggleBroadCastMessage(
          true,
          fans,
          isSelectedAllFollowers,
          totalFollowers
        )
      }
    />
  );
};

export default MessageInitiatorPage;
