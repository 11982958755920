import { CURRENCY_SYMBOL } from ".";

export const tierConfig = [
  { label: "Basic", value: "basic" },
  { label: "Plus", value: "plus" },
  { label: "Premium", value: "premium" },
];

export const priceCount = [
  { label: `${CURRENCY_SYMBOL}0`, value: { min: 0, max: 0 } },
  { label: `Under ${CURRENCY_SYMBOL}50`, value: { min: 1, max: 50 } },
  { label: `Under ${CURRENCY_SYMBOL}200`, value: { min: 1, max: 200 } },
  { label: `${CURRENCY_SYMBOL}200+`, value: { min: 200 } },
];

export const longerPlanCount = [
  { label: "Trial", value: 0 },
  { label: "Monthly", value: 30 },
  { label: "3 Months", value: 90 },
  { label: "6 Months", value: 180 },
  { label: "9 Months", value: 270 },
  { label: "12 Months", value: 360 },
];

export const expiredPlanCount = [
  { label: "Under 3 days", value: { min: 0, max: 2 } },
  { label: "Under 7 days", value: { min: 0, max: 6 } },
  { label: "Under 30 days", value: { min: 0, max: 29 } },
  { label: "Under 90 days", value: { min: 0, max: 89 } },
  { label: "More than 90 days", value: { min: 90 } },
];

export const categoriesConfig = [
  { label: "Muted", value: "muted" },
  { label: "Blocked", value: "blocked" },
  { label: "Favourited", value: "favourited" },
  { label: "Expired", value: "expired" },
];
