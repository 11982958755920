import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Form,
  Button,
} from "reactstrap";
import { APP_LOGO, CURRENCY_SYMBOL } from "../config";
import { errorHandler, isValidPrice, showToast } from "../helper-methods";
import { createOrUpdate } from "../http-calls";
import { AmountConfig } from "../config/appConfig";

const AddSubscriptionPrice = () => {
  const history = useHistory();

  const [price, setPrice] = useState("");
  const [loading, setLoading] = useState(false);

  const _onChangePrice = (value) => {
    if (value && !isValidPrice(value)) return;

    setPrice(value);
  };

  const _setSubscriptionPrice = async (event) => {
    try {
      if (event) event.preventDefault();

      if (
        !+price ||
        isNaN(price) ||
        +price < AmountConfig.minimumLimit ||
        +price > AmountConfig.maximumLimit
      ) {
        showToast(
          `${
            +price > AmountConfig.maximumLimit
              ? `Maximum ${CURRENCY_SYMBOL}${AmountConfig.maximumLimit}`
              : `Minimum ${CURRENCY_SYMBOL}${AmountConfig.minimumLimit}`
          } `,
          "error"
        );
        return;
      }

      setLoading(true);

      const payload = {
        tier: "basic",
        price,
      };

      await createOrUpdate(payload);

      showToast("Updated Successfully", "success");

      history.replace("/feed");
    } catch (error) {
      errorHandler(error);
      history.replace("/feed");
    }
  };

  return (
    <div className="animated fadeIn authFormWrapper mobile_view">
      <div className="loginWrapper">
        <img
          src={APP_LOGO}
          alt="Project Logo"
          className="projectLogo"
          loading="lazy"
        />

        <div className="subscrip_content">
          <p>
            Good job! your account is being verified, we will send you an email
            once it is done.
          </p>
          <h4>Set Subscription Price</h4>
        </div>

        <div className="authPgFormWrap price_box">
          <Form onSubmit={_setSubscriptionPrice}>
            <InputGroup className="subscriptionPrice">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>{CURRENCY_SYMBOL}</InputGroupText>
              </InputGroupAddon>
              <Input
                style={{
                  width: `${price.length ? price.length * 28 : 100}px`,
                }}
                type="text"
                name="price"
                value={price}
                onChange={(e) => _onChangePrice(e.target.value)}
                disabled={loading}
                placeholder="0.00"
                autoComplete="off"
              />
            </InputGroup>
            <span
              className="d-block text-center mt-2"
              style={{ color: "#828282" }}
            >
              {CURRENCY_SYMBOL}
              {price || null}/month
            </span>

            <Button
              type="submit"
              disabled={loading}
              className="themeBtn loginBtn"
            >
              {loading ? <i className="fa fa-spinner fa-spin mr-2" /> : null}{" "}
              Set Price
            </Button>
          </Form>
          <Button
            color="link"
            className="skipTxt text-center w-100 mt-2 mb-3"
            disabled={loading}
            onClick={() => history.replace("/feed")}
          >
            Skip for now
          </Button>
          <p style={{ color: "#828282", marginTop: 15, textAlign: "center" }}>
            This is what your fans will pay before they can see your posts.
            Average subscriptions are from {CURRENCY_SYMBOL}10-25. You can
            always change this later if you want.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AddSubscriptionPrice;
