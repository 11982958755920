export const MonthsConfig = [
  {
    month: "01",
    short_name: "Jan",
    full_name: "January",
  },
  {
    month: "02",
    short_name: "Feb",
    full_name: "February",
  },
  {
    month: "03",
    short_name: "Mar",
    full_name: "March",
  },
  {
    month: "04",
    short_name: "Apr",
    full_name: "April",
  },
  {
    month: "05",
    short_name: "May",
    full_name: "May",
  },
  {
    month: "06",
    short_name: "Jun",
    full_name: "June",
  },
  {
    month: "07",
    short_name: "Jul",
    full_name: "July",
  },
  {
    month: "08",
    short_name: "Aug",
    full_name: "August",
  },
  {
    month: "09",
    short_name: "Sep",
    full_name: "September",
  },
  {
    month: "10",
    short_name: "Oct",
    full_name: "October",
  },
  {
    month: "11",
    short_name: "Nov",
    full_name: "November",
  },
  {
    month: "12",
    short_name: "Dec",
    full_name: "December",
  },
];

export const MonthsInEngConfig = [
  { month: "01", short_name: "Jan", full_name: "January" },
  { month: "02", short_name: "Feb", full_name: "February" },
  { month: "03", short_name: "Mar", full_name: "March" },
  { month: "04", short_name: "Apr", full_name: "April" },
  { month: "05", short_name: "May", full_name: "May" },
  { month: "06", short_name: "Jun", full_name: "June" },
  { month: "07", short_name: "Jul", full_name: "July" },
  { month: "08", short_name: "Aug", full_name: "August" },
  { month: "09", short_name: "Sep", full_name: "September" },
  { month: "10", short_name: "Oct", full_name: "October" },
  { month: "11", short_name: "Nov", full_name: "November" },
  { month: "12", short_name: "Dec", full_name: "December" },
];
