import React, { useState } from "react";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import { deepClone, errorHandler, showToast } from "../../helper-methods";
import { changeInfluencerPassword } from "../../http-calls";

const ChangePassword = () => {
  const [formFields, setFormFields] = useState({
    oldPassword: "",
    newPassword: "",
    reEnterPassword: "",
  });
  const [isDirty, setIsDirty] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const [showPassword, setShowPassword] = useState({
    oldPassword: false,
    newPassword: false,
    reEnterPassword: false,
  });

  const _updateFieldValue = (fieldName, value) => {
    const newFormFields = deepClone(formFields);
    const newIsDirty = deepClone(isDirty);

    newFormFields[fieldName] = value;
    setFormFields(newFormFields);

    _validateForm({ newFormFields, newIsDirty });
  };

  const _onBlurHandler = (fieldName) => {
    const newFormFields = deepClone(formFields);
    const newIsDirty = deepClone(isDirty);

    newIsDirty[fieldName] = true;
    setIsDirty(newIsDirty);

    _validateForm({ newFormFields, newIsDirty });
  };

  // validate formfield values
  const _validateForm = ({ newFormFields, newIsDirty }) => {
    return new Promise((resolve) => {
      const newErrors = {};
      let isFormValid = true;

      Object.keys(newFormFields)?.forEach((key, index) => {
        if (newIsDirty[key]) {
          switch (key) {
            case "oldPassword":
            case "newPassword": {
              if (
                (key === "oldPassword" && newFormFields[key]?.trim()?.length) ||
                (key === "newPassword" &&
                  newFormFields[key]?.trim()?.length >= 6)
              ) {
                newErrors[key] = null;
                newIsDirty[key] = false;
              } else {
                isFormValid = false;
                newErrors[key] =
                  key === "oldPassword"
                    ? "Old Password is required"
                    : !newFormFields[key]?.trim()?.length
                    ? "New Password is required"
                    : "New Password length should be atleast 6 characters";
              }
              break;
            }

            case "reEnterPassword": {
              if (newFormFields[key]?.trim()?.length) {
                if (newFormFields[key] === newFormFields["newPassword"]) {
                  newErrors[key] = null;
                  newIsDirty[key] = false;
                } else {
                  isFormValid = false;
                  newErrors[key] =
                    "Re-enter password should be as same as new password";
                }
              } else {
                newErrors[key] = "Re-enter Password is required";
              }
              break;
            }
            default: {
            }
          }
        }
      });

      setIsDirty(newIsDirty);
      setErrors(newErrors);

      resolve(isFormValid);
    });
  };

  const _clearFormData = () => {
    setFormFields({
      oldPassword: "",
      newPassword: "",
      reEnterPassword: "",
    });

    setErrors({});
    setIsDirty({});
  };

  // marking all formfields dirty
  const _markAllIsDirty = () => {
    return new Promise((resolve) => {
      const newIsDirty = deepClone(isDirty);
      const newFormFields = deepClone(formFields);
      Object.keys(newFormFields)?.forEach((key) => {
        newIsDirty[key] = true;
      });
      setIsDirty(newIsDirty);
      resolve(newIsDirty);
    });
  };

  const _validateAndSubmit = async (e) => {
    try {
      if (e) e.preventDefault();
      const newIsDirty = await _markAllIsDirty();
      const newFormFields = deepClone(formFields);
      const isFormValid = await _validateForm({
        newFormFields,
        newIsDirty,
      });

      if (!isFormValid) {
        return;
      }

      setLoading(true);

      const payload = {
        oldPassword: newFormFields["oldPassword"],
        newPassword: newFormFields["newPassword"],
      };
      await changeInfluencerPassword(payload);

      _clearFormData();
      setLoading(false);
      showToast("Password changed successfully", "success");
    } catch (error) {
      setLoading(false);
      errorHandler(error);
    }
  };

  const _togglePasswordVisibility = (key) => {
    const newShowPassword = deepClone(showPassword);

    newShowPassword[key] = !newShowPassword[key];

    setShowPassword(newShowPassword);
  };

  return (
    <>
      <div className="header_Settings" style={{ marginTop: 30 }}>
        <h4>Change Password</h4>
      </div>
      <Form onSubmit={(e) => _validateAndSubmit(e)}>
        <FormGroup>
          <Label>Current Password</Label>
          <div className="position-relative">
            <Input
              type={showPassword?.oldPassword ? "text" : "password"}
              placeholder="Enter"
              value={formFields?.oldPassword}
              onChange={(e) => _updateFieldValue("oldPassword", e.target.value)}
              onBlur={() => _onBlurHandler("oldPassword")}
            />
            <div className="eyeIcon">
              <i
                className={
                  showPassword?.oldPassword ? "fa fa-eye" : "fa fa-eye-slash"
                }
                onClick={() => _togglePasswordVisibility("oldPassword")}
              />
            </div>
          </div>
          {errors?.oldPassword ? (
            <div className="form-error pl-0">{errors?.oldPassword}</div>
          ) : null}
        </FormGroup>
        <FormGroup>
          <Label>New Password</Label>
          <div className="position-relative">
            <Input
              type={showPassword?.newPassword ? "text" : "password"}
              placeholder="Enter"
              value={formFields?.newPassword}
              onChange={(e) => _updateFieldValue("newPassword", e.target.value)}
              onBlur={() => _onBlurHandler("newPassword")}
            />
            <div className="eyeIcon">
              <i
                className={
                  showPassword?.newPassword ? "fa fa-eye" : "fa fa-eye-slash"
                }
                onClick={() => _togglePasswordVisibility("newPassword")}
              />
            </div>
          </div>
          {errors?.newPassword ? (
            <div className="form-error pl-0">{errors?.newPassword}</div>
          ) : null}
        </FormGroup>
        <FormGroup>
          <Label>Re-enter New Password</Label>
          <div className="position-relative">
            <Input
              type={showPassword?.reEnterPassword ? "text" : "password"}
              placeholder="Enter"
              value={formFields?.reEnterPassword}
              onChange={(e) =>
                _updateFieldValue("reEnterPassword", e.target.value)
              }
              onBlur={() => _onBlurHandler("reEnterPassword")}
            />
            <div className="eyeIcon">
              <i
                className={
                  showPassword?.reEnterPassword
                    ? "fa fa-eye"
                    : "fa fa-eye-slash"
                }
                onClick={() => _togglePasswordVisibility("reEnterPassword")}
              />
            </div>
          </div>

          {errors?.reEnterPassword ? (
            <div className="form-error pl-0">{errors?.reEnterPassword}</div>
          ) : null}
        </FormGroup>
        <Button className="themeBtn saveBtn" type="submit" disabled={loading}>
          {loading ? <i className="fa fa-spinner fa-spin mr-1" /> : null} Save
        </Button>
      </Form>
      <hr
        style={{
          marginTop: 25,
          borderTopColor: "rgba(204, 204, 204, 0.5)",
        }}
      />
    </>
  );
};

export default ChangePassword;
