import { AppNavbarBrand } from "@coreui/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { APP_LOGO } from "../config/index";
import { unreadNotificationCountUpdate } from "../redux/actions/notificationData";
import Footer from "./Footer";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

const Header = ({ isVisibleSidebar, toggleSideBar }) => {
  const history = useHistory();

  const dispatch = useDispatch();

  const notificationData = useSelector((state) => state?.notificationData);

  const _realTimeNotificationUpdated = () => {
    try {
      var OneSignal = window.OneSignal || [];

      if (OneSignal && OneSignal.length) {
        OneSignal.on("notificationDisplay", (event) => {
          let unreadNotificationCount =
            notificationData?.unreadNotificationCount || 0;
          if (event.heading !== "Message") {
            if (unreadNotificationCount) {
              unreadNotificationCount += 1;
            } else {
              unreadNotificationCount = 1;
            }
            dispatch(unreadNotificationCountUpdate(unreadNotificationCount));
          }
        });
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const _redirectToFeedPage = () => {
    if (isVisibleSidebar) {
      toggleSideBar();
    }
    history.replace("/feed");
  };

  useEffect(() => {
    _realTimeNotificationUpdated();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <AppNavbarBrand
        onClick={() => _redirectToFeedPage()}
        full={{
          src: APP_LOGO,
          width: 125,
          height: 16,
          alt: "Logo",
        }}
      />

      <div className="d-sm-down-none ml-1">
        <Footer
          forHeader={true}
          toggleSideBar={() => toggleSideBar()}
          isVisibleSidebar={isVisibleSidebar}
        />
      </div>
    </>
  );
};

export default Header;
