export const allEarningFieldNameConfig = [
  {
    label: "Post",
    value: "post",
    showInDropdown: false,
  },
  {
    label: "Vault",
    value: "vault",
    languageParseText: "vault",
    showInDropdown: true,
  },
  {
    label: "Live Events",
    value: "liveEvent",
    languageParseText: "event",
    showInDropdown: true,
  },
  {
    label: "Pay Per View",
    value: "payperview",
    languageParseText: "ppv",
    showInDropdown: true,
  },
  {
    label: "Shout Out",
    value: "shoutout",
    languageParseText: "shoutout",
    showInDropdown: true,
  },
  {
    label: "Tips",
    value: "tips",
    showInDropdown: false,
  },
  {
    label: "Subscription",
    value: "subscription",
    showInDropdown: false,
  },
  {
    label: "Message",
    value: "message",
    showInDropdown: false,
  },
  {
    label: "Referrals",
    value: "referrals",
    showInDropdown: true,
  },
];
