import { createSlice } from "@reduxjs/toolkit";
import {
  updateUserData,
  storeAssistantData,
  updateOnlyUserData,
  clearUserData,
  fetchReferralHistory,
  fetchAndModifiedUserDetails,
} from "../actions";

const initialState = {
  referralHistory: [],
  token: null,
  user: null,
  handle: null,
  userAssistant: null,
};

const userDataSlice = createSlice({
  name: "userData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateUserData, (state, action) => {
        state.token = action.payload?.token;
        state.handle = action.payload?.handle;
        state.user = action.payload?.user;
      })

      .addCase(updateOnlyUserData, (state, action) => {
        state.user = action.payload;
      })

      .addCase(storeAssistantData, (state, action) => {
        state.userAssistant = action.payload;
      })

      .addCase(clearUserData, (state, action) => {
        state.referralHistory = [];
        state.token = null;
        state.user = null;
        state.handle = null;
        state.userAssistant = null;
      })

      .addCase(fetchReferralHistory.fulfilled, (state, action) => {
        state.referralHistory = action.payload;

        state.loading = false;
      })

      .addCase(fetchAndModifiedUserDetails.fulfilled, (state, action) => {
        state.user = action.payload;

        state.loading = false;
      });
  },
});

export const userDataReducer = userDataSlice.reducer;
