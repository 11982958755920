import React, { useEffect, useState } from "react";
import {
  Button,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Container,
  Row,
  Col,
  CustomInput,
} from "reactstrap";
import ReactDatetime from "react-datetime";
import {
  errorHandler,
  formatDateAndTime,
  getRecentHourDate,
  getYesterdayDate,
  isPermissionToAccess,
  isValidPrice,
  showToast,
  uploadFileOnServer,
} from "../helper-methods";
import {
  createVaultFolder,
  postFolderContents,
  saveContents,
} from "../http-calls";
import { CURRENCY_SYMBOL, DEFAULT_COVER_PICTURE } from "../config";
import ConfigureString from "../components/ConfigureString";
import { useHistory } from "react-router-dom";
import CustomTooltip from "../components/custom/CustomTooltip";
import { getConfiguredString } from "../helper-methods/configureString";
import ErrorBoundary from "../components/ErrorBoundary";
import CustomVideoPlayer from "../components/custom/CustomVideoPlayer";
import { AmountConfig } from "../config/appConfig";
import MediaLibraryModal from "../components/modals/MediaLibraryModal";

const VaultAddPage = () => {
  const history = useHistory();

  const [formFields, setFormFields] = useState({
    name: "",
    description: "",
    price: "",
    scheduledAt: getRecentHourDate(),
  });
  const [preview, setPreview] = useState({
    coverImage: {
      uploadData: null,
      previewBlob: null,
      type: "image",
      uploadUrl: null,
    },
    previewVideo: {
      uploadData: null,
      previewBlob: null,
      type: "video",
      uploadUrl: null,
    },
  });
  // eslint-disable-next-line no-unused-vars
  const [isDirty, setIsDirty] = useState({});
  const [errors, setErrors] = useState({});
  const [vaultMedia, setVaultMedia] = useState([]);
  const [isPublishNow, setIsPublishNow] = useState(false);

  const [loadingState, setLoadingState] = useState({
    isLoading: false,
    isCoverImageLoading: false,
    isCoverVideoLoading: false,
    isVaultMediaLoading: false,
  });

  const [isMediaLibraryModalOpen, setIsMediaLibraryModalOpen] = useState(false);
  const [isModalOpenForCoverImage, setIsModalOpenForCoverImage] =
    useState(false);
  const [isModalOpenForPreviewVideo, setIsModalOpenForPreviewVideo] =
    useState(false);

  const _toggleModalForPreviewVideo = () => {
    setIsModalOpenForPreviewVideo((prev) => !prev);
  };
  const _toggleModalForCoverImage = () => {
    setIsModalOpenForCoverImage((prev) => !prev);
  };

  useEffect(() => {
    if (!isPermissionToAccess("vault", "canCreateVault")) {
      showToast("Unauthorized", "error");
      history.push("/my-profile");
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _manageLoadingState = (key = "", value = false) => {
    setLoadingState((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const _updateCoverImageAndPreview = (key, file) => {
    let fileType = file?.[0]?.contentType || file?.[0]?.type;

    if (key === "coverImage" && fileType !== "image") {
      showToast("Only image file is allowed", "error");
      _toggleModalForCoverImage();
      return;
    } else if (key === "previewVideo" && fileType !== "video") {
      showToast("Only video file is allowed", "error");
      _toggleModalForPreviewVideo();
      return;
    }

    const newPreview = { ...preview };

    if (key) {
      if (file?.[0]?.url) {
        newPreview[key]["previewBlob"] = "";
        newPreview[key]["uploadData"] = null;
        newPreview[key]["type"] = fileType;
        newPreview[key]["uploadUrl"] = file?.[0]?.url;
      } else {
        newPreview[key] = file?.[0];
      }
    }

    setPreview(newPreview);

    if (key === "coverImage") {
      _toggleModalForCoverImage();
    } else {
      _toggleModalForPreviewVideo();
    }
  };

  // const _onChangeFile = async (event, key) => {
  //   console.log("fileeeeeeeeeeeeee");
  //   if (!event?.target?.files?.length) {
  //     return;
  //   }

  //   if (key === "vaultMedia") {
  //     _manageLoadingState("isVaultMediaLoading", true);
  //     const files = Array.from(event.target.files);

  //     const newVaultMedia = [...vaultMedia];

  //     const mediaFilePayload = await mediaFilesHandler({
  //       newFiles: files,
  //       oldFiles: newVaultMedia,
  //       isAudioUpload: true,
  //       isPdfUpload: true,
  //     });

  //     if (mediaFilePayload.error) {
  //       errorHandler();
  //       setLoadingState({});
  //       return;
  //     }

  //     setVaultMedia(mediaFilePayload.mediaFiles);
  //   } else {
  //     const newPreview = { ...preview };

  //     const file = event.target.files[0];
  //     const fileType = file.type.split("/")[0];

  //     _manageLoadingState(
  //       key === "coverImage" ? "isCoverImageLoading" : "isCoverVideoLoading",
  //       true
  //     );

  //     if (key === "coverImage" && fileType !== "image") {
  //       showToast("Only image file is allowed", "error");
  //       return;
  //     } else if (key === "previewVideo" && fileType !== "video") {
  //       showToast("Only video file is allowed", "error");
  //       return;
  //     }

  //     const res = await mediaFilesHandler({ newFiles: [file] });

  //     if (res.error) {
  //       errorHandler();
  //       setLoadingState({});
  //       return;
  //     }

  //     newPreview[key] = {
  //       uploadData: res?.mediaFiles?.[0]?.uploadData,
  //       previewBlob: res?.mediaFiles?.[0]?.previewBlob,
  //       type: res?.mediaFiles?.[0]?.type,
  //       uploadUrl: null,
  //     };

  //     setPreview(newPreview);
  //   }
  //   setLoadingState({});
  // };

  const _deleteContent = (index) => {
    if (index === "coverImage" || index === "previewVideo") {
      const newPreview = { ...preview };
      newPreview[index] = {
        previewBlob: null,
        uploadData: null,
        type: null,
        uploadUrl: null,
      };
      setPreview(newPreview);
    } else {
      const newVaultMedia = [...vaultMedia];
      newVaultMedia.splice(index, 1);
      setVaultMedia(newVaultMedia);
    }
  };

  const _validateFormfields = ({ newFormFields, newIsDirty }) => {
    return new Promise((resolve) => {
      let isFormValid = true;
      const newErrors = {};

      Object.keys(newFormFields).forEach((key) => {
        if (newIsDirty[key]) {
          switch (key) {
            case "name": {
              if (newFormFields[key]?.trim().length) {
                newErrors[key] = null;
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "price": {
              if (newFormFields[key]?.trim().length) {
                if (
                  !isNaN(newFormFields[key]) &&
                  +newFormFields[key] >= +AmountConfig.minVaultPrice &&
                  +newFormFields[key] <= +AmountConfig.maximumLimit
                ) {
                  newErrors[key] = null;
                  newIsDirty[key] = false;
                } else {
                  newErrors[key] =
                    +newFormFields[key] > +AmountConfig.maximumLimit
                      ? `*Maximum ${CURRENCY_SYMBOL}${AmountConfig.maximumLimit}`
                      : `*Minimum ${CURRENCY_SYMBOL}${AmountConfig.minVaultPrice}`;
                  isFormValid = false;
                }
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "scheduledAt": {
              if (!isPublishNow) {
                if (
                  newFormFields[key] &&
                  new Date() < new Date(newFormFields[key])
                ) {
                  newIsDirty[key] = false;
                  newErrors[key] = null;
                } else {
                  newErrors[key] = "*Should be future date & time";
                  isFormValid = false;
                }
              } else {
                newIsDirty[key] = false;
                newErrors[key] = null;
              }
              break;
            }
            default:
          }
        }
      });

      setIsDirty((prev) => ({
        ...prev,
        ...newIsDirty,
      }));
      setErrors((prev) => ({
        ...prev,
        ...newErrors,
      }));
      resolve(isFormValid);
    });
  };

  const _toggleIsPublishNow = (isPublish = false) => {
    setIsPublishNow(isPublish);
    setIsDirty((prev) => ({
      ...prev,
      scheduledAt: false,
    }));
    setErrors((prev) => ({
      ...prev,
      scheduledAt: null,
    }));
  };

  const _onChangeFormfield = (key, value) => {
    if (key === "price" && value && !isValidPrice(value)) {
      return;
    }

    const newFormFields = { ...formFields };

    if (key === "scheduledAt") {
      newFormFields[key] = value?._d ? new Date(value).toISOString() : "";
      const newIsDirty = {
        [key]: true,
      };
      _validateFormfields({ newFormFields, newIsDirty });
    } else {
      newFormFields[key] = value;
    }

    setFormFields(newFormFields);
  };

  const _onBlurFormfield = (key) => {
    const newFormFields = { ...formFields };
    const newIsDirty = {
      [key]: true,
    };
    _validateFormfields({ newFormFields, newIsDirty });
  };

  const _markAllIsDirty = () => {
    return new Promise((resolve) => {
      const newFormFields = { ...formFields };
      const newIsDirty = { ...isDirty };
      Object.keys(newFormFields).forEach((key) => {
        newIsDirty[key] = true;
      });
      setIsDirty(newIsDirty);
      resolve(newIsDirty);
    });
  };

  const _saveContents = (contents = []) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await saveContents({ contents });

        const uploadedContents = res.contents.map((content) => {
          return {
            contentType: content.contentType,
            url: content.url,
            hlsLink: content.hlsLink,
            thumbnail: content.thumbnail,
            _id: content._id,
            extension: content.extension,
            isNew: true,
          };
        });

        resolve(uploadedContents);
      } catch (error) {
        reject(error);
      }
    });
  };

  const _createVaultFolder = async (payload) => {
    try {
      const response = await createVaultFolder(payload);

      if (!response?.folder?._id) {
        history.replace("/vault");
        return;
      }

      const newVaultMedia = [...vaultMedia];

      const uploadFiles = [];
      const libraryFiles = [];

      newVaultMedia.forEach((each) => {
        if (each.url) {
          libraryFiles.push({
            id: each?._id, //for avoiding repeatition of content via BE
            _id: each?._id,
            isNew: true,
            contentType: each.contentType,
            url: each.url,
            duration: each?.duration ? each?.duration : null,
          });
        } else {
          uploadFiles.push(each);
        }
      });

      if (newVaultMedia?.length) {
        try {
          let uploadedContents = [];
          if (uploadFiles?.length) {
            const contents = await uploadFileOnServer(uploadFiles);
            uploadedContents = await _saveContents(contents);
          }

          await postFolderContents({
            contents: uploadedContents?.concat(libraryFiles),
            folderId: response.folder._id,
          });

          showToast("Folder created successfully.", "success");
        } catch (error) {
          errorHandler(error);
        }
      }

      // history.replace(
      //   `/vault/folder/${response.folder.id || response.folder._id}`
      // );

      history.replace("/vault");
    } catch (error) {
      errorHandler(error);
      setLoadingState({});
    }
  };

  const _createVault = async () => {
    try {
      const newFormFields = { ...formFields };
      const newIsDirty = await _markAllIsDirty();

      const isFormValid = await _validateFormfields({
        newFormFields,
        newIsDirty,
      });

      if (!isFormValid) {
        return;
      }

      _manageLoadingState("isLoading", true);

      const payload = {
        name: formFields.name?.trim(),
        description: formFields.description?.trim(),
        price: +formFields.price?.trim(),
      };

      if (isPublishNow) {
        payload.isPublished = true;
      } else {
        payload.scheduledAt = new Date(
          newFormFields?.scheduledAt
        ).toISOString();
      }

      const uploadMedia = [];

      if (preview.coverImage?.uploadData) {
        preview.coverImage["forKeyName"] = "preview";
        uploadMedia.push({ ...preview.coverImage });
      } else if (preview.coverImage?.uploadUrl) {
        payload["preview"] = preview.coverImage?.uploadUrl;
      }

      if (preview.previewVideo?.uploadData) {
        preview.previewVideo["forKeyName"] = "previewVideoUrl";
        uploadMedia.push({ ...preview.previewVideo });
      } else if (preview?.previewVideo?.uploadUrl) {
        payload["previewVideoUrl"] = preview.previewVideo?.uploadUrl;
      }

      if (uploadMedia.length) {
        const uploadMediaRes = await uploadFileOnServer(uploadMedia);
        uploadMediaRes.forEach((res) => {
          payload[res.forKeyName] = res.url;
        });
      }

      _createVaultFolder(payload);
    } catch (error) {
      errorHandler(error);
      setLoadingState({});
    }
  };

  const _toggleMediaLibraryModal = () => {
    setIsMediaLibraryModalOpen((prev) => !prev);
  };

  const _uploadFiles = async (selectedFiles) => {
    setVaultMedia((prev) => {
      if (prev?.length) {
        return prev.concat(selectedFiles);
      } else {
        return selectedFiles;
      }
    });
    _toggleMediaLibraryModal();
  };

  return (
    <div className="customPgHeight animated fadeIn">
      <Container className="noPadding">
        <Row className="justify-content-center noMargin">
          <Col
            sm={12}
            md={10}
            lg={9}
            xl={8}
            className="pgPadding bg-white createEvent"
          >
            <div className="pgTitle justify-content-start">
              <Button
                className="customBackBtn"
                onClick={() => history.goBack()}
              >
                <i className="fa fa-chevron-left" />
              </Button>

              <h2>
                <ConfigureString keyString="New vault" />
              </h2>
            </div>

            <FormGroup>
              <Label>Title</Label>
              <Input
                type="text"
                name="title"
                value={formFields.name}
                onChange={(e) => _onChangeFormfield("name", e.target.value)}
                onBlur={() => _onBlurFormfield("name")}
                disabled={loadingState?.isLoading}
              />
              {errors?.name ? (
                <div className="form-error">{errors.name}</div>
              ) : null}
            </FormGroup>

            <FormGroup>
              <Label>
                Description
                <sup id="VaultAddPage_description" className="infoIcon">
                  <i className="fa fa-info-circle" />
                </sup>
                <CustomTooltip
                  text={`Customize your description to tell your fanz about awesome content and why it's a terrific idea for them to unlock access.`}
                  target="VaultAddPage_description"
                />
              </Label>
              <Input
                type="textarea"
                name="description"
                value={formFields.description}
                onChange={(e) =>
                  _onChangeFormfield("description", e.target.value)
                }
                onBlur={() => _onBlurFormfield("description")}
                disabled={loadingState?.isLoading}
                rows="3"
              />
              {errors?.description ? (
                <div className="form-error">{errors.description}</div>
              ) : null}
            </FormGroup>

            <FormGroup>
              <Label>Add Cover Image</Label>
              <Label className="coverImgWrap">
                {/* <Input
                  type="file"
                  style={{ display: "none" }}
                  accept="image/*, .heic, .heif "
                  value=""
                  name="coverImage"
                  onChange={(event) => _onChangeFile(event, "coverImage")}
                  disabled={
                    loadingState?.isLoading ||
                    loadingState?.isCoverVideoLoading ||
                    loadingState?.isCoverImageLoading
                  }
                /> */}

                <Button
                  style={{ display: "none" }}
                  onClick={() => _toggleModalForCoverImage()}
                  disabled={
                    loadingState?.isLoading ||
                    loadingState?.isCoverVideoLoading ||
                    loadingState?.isCoverImageLoading
                  }
                />

                {loadingState?.isCoverImageLoading ? (
                  <i className="fa fa-spinner fa-spin" />
                ) : preview?.coverImage?.previewBlob ||
                  preview?.coverImage?.uploadUrl ? (
                  <>
                    <img
                      src={
                        preview?.coverImage?.previewBlob ||
                        preview?.coverImage?.uploadUrl ||
                        DEFAULT_COVER_PICTURE
                      }
                      alt="Cover Img"
                      loading="lazy"
                    />
                    <div className="changeImg">
                      <i className="fa fa-pencil" />
                    </div>
                  </>
                ) : (
                  <div className="text-center">
                    <img
                      src="/assets/img/upload.png"
                      alt="Upload"
                      className="uploadImg"
                      loading="lazy"
                    />
                    <p className="mb-0">Upload Image</p>
                  </div>
                )}
              </Label>
            </FormGroup>

            <FormGroup>
              <Label>Add Preview Video</Label>

              {preview?.previewVideo?.previewBlob ||
              preview?.previewVideo?.uploadUrl ? (
                <div className="coverImgWrap">
                  <ErrorBoundary>
                    {/* custom video player support hls */}
                    <CustomVideoPlayer
                      // className={`${getAspectRatioClass(
                      //   vaultData?.previewVideoUrlAspectRatio
                      // )}`}
                      src={
                        preview?.previewVideo?.previewBlob ||
                        preview?.previewVideo?.uploadUrl
                      }
                      hlsLink={preview?.previewVideo?.uploadUrl}
                      controls
                      inlineStyle={{ display: "block" }}
                    />
                  </ErrorBoundary>

                  <Label className="changeImg">
                    {/* <Input
                      type="file"
                      style={{ display: "none" }}
                      accept="video/*"
                      value=""
                      name="previewVideoUrl"
                      onChange={(event) => _onChangeFile(event, "previewVideo")}
                      disabled={
                        loadingState.isLoading ||
                        loadingState?.isCoverImageLoading ||
                        loadingState.isCoverVideoLoading
                      }
                    /> */}

                    <Button
                      style={{ display: "none" }}
                      onClick={() => _toggleModalForPreviewVideo()}
                      disabled={
                        loadingState.isLoading ||
                        loadingState?.isCoverImageLoading ||
                        loadingState.isCoverVideoLoading
                      }
                    />
                    <i className="fa fa-pencil" />
                  </Label>
                </div>
              ) : (
                <Label className="coverImgWrap">
                  {/* <Input
                    type="file"
                    style={{ display: "none" }}
                    accept="video/*"
                    value=""
                    name="previewVideoUrl"
                    onChange={(event) => _onChangeFile(event, "previewVideo")}
                    disabled={
                      loadingState.isLoading ||
                      loadingState?.isCoverImageLoading ||
                      loadingState.isCoverVideoLoading
                    }
                  /> */}
                  <Button
                    style={{ display: "none" }}
                    onClick={() => _toggleModalForPreviewVideo()}
                    disabled={
                      loadingState.isLoading ||
                      loadingState?.isCoverImageLoading ||
                      loadingState.isCoverVideoLoading
                    }
                  />

                  <div className="text-center">
                    {loadingState?.isCoverVideoLoading ? (
                      <i className="fa fa-spinner fa-spin" />
                    ) : (
                      <>
                        <img
                          src="/assets/img/upload.png"
                          alt="Upload"
                          className="uploadImg"
                          loading="lazy"
                        />
                        <p className="mb-0">Upload Video</p>
                      </>
                    )}
                  </div>
                </Label>
              )}
            </FormGroup>

            <FormGroup>
              <Label>{getConfiguredString("vault Price")}</Label>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText className="dollarWrap">
                    <i className="fa fa-dollar" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  type="text"
                  name="price"
                  value={formFields.price}
                  onChange={(e) => _onChangeFormfield("price", e.target.value)}
                  onBlur={() => _onBlurFormfield("price")}
                  disabled={loadingState?.isLoading}
                  className="dollarWrapInput"
                />
              </InputGroup>
              {errors?.price ? (
                <div className="form-error">{errors.price}</div>
              ) : null}
            </FormGroup>

            <FormGroup>
              <CustomInput
                type="checkbox"
                id="isPublishNow_vaultAddPage"
                label="Publish Now"
                checked={isPublishNow}
                onChange={(e) => {
                  _toggleIsPublishNow(e.target.checked);
                }}
              />
            </FormGroup>

            {/* the below field should be hidden if "Publish Now" is checked/selected */}
            <FormGroup>
              <Label>{getConfiguredString("vault Release Schedule")}</Label>
              {/* Add a date & time selector plugin in place of the input field below */}
              <ReactDatetime
                inputProps={{
                  className: "form-control",
                  placeholder: "Select release date & time",
                  value: formatDateAndTime(formFields.scheduledAt),
                  disabled: isPublishNow,
                }}
                value={
                  formFields.scheduledAt ? new Date(formFields.scheduledAt) : ""
                }
                onChange={(e) => _onChangeFormfield("scheduledAt", e)}
                isValidDate={(current) => current.isAfter(getYesterdayDate())}
                timeConstraints={{
                  minutes: {
                    step: 15,
                  },
                }}
                dateFormat={true}
                timeFormat={true}
              />
              {errors?.scheduledAt ? (
                <div className="form-error">{errors?.scheduledAt}</div>
              ) : null}
            </FormGroup>

            <FormGroup>
              <Label>Add Media</Label>
              <div className="d-flex flex-wrap" style={{ margin: "0 -1%" }}>
                <div className="vaultMediaWrap">
                  <Label className="vaultMedia cursorPointer">
                    {/* <Input
                      type="file"
                      style={{ display: "none" }}
                      accept="image/*,video/*,audio/*,.pdf, .heic, .heif"
                      multiple
                      value=""
                      onChange={(event) => _onChangeFile(event, "vaultMedia")}
                      disabled={
                        loadingState.isLoading ||
                        loadingState.isVaultMediaLoading
                      }
                    /> */}

                    <Button
                      className="d-none"
                      onClick={() => _toggleMediaLibraryModal()}
                    />

                    {loadingState.isVaultMediaLoading ? (
                      <i className="fa fa-spinner fa-spin" />
                    ) : (
                      <svg
                        id="add_992651."
                        xmlns="http://www.w3.org/2000/svg"
                        width="256"
                        height="256"
                        viewBox="0 0 256 256"
                      >
                        <g id="Group_57" data-name="Group 57">
                          <g id="Group_56" data-name="Group 56">
                            <path
                              id="Path_91"
                              data-name="Path 91"
                              d="M128,0A128,128,0,1,0,256,128,128.14,128.14,0,0,0,128,0Zm0,236.171A108.171,108.171,0,1,1,236.171,128,108.3,108.3,0,0,1,128,236.171Z"
                            />
                          </g>
                        </g>
                        <g id="Group_59" data-name="Group 59">
                          <g id="Group_58" data-name="Group 58">
                            <path
                              id="Path_92"
                              data-name="Path 92"
                              d="M177.574,117.193H137.915V77.534a9.915,9.915,0,1,0-19.83,0v39.659H78.426a9.915,9.915,0,1,0,0,19.83h39.659v39.659a9.915,9.915,0,0,0,19.83,0V137.023h39.659a9.915,9.915,0,0,0,0-19.83Z"
                            />
                          </g>
                        </g>
                      </svg>
                    )}
                  </Label>
                </div>

                {vaultMedia?.length
                  ? vaultMedia.map((media, index) => (
                      <div className="vaultMediaWrap" key={index}>
                        <div className="vaultMedia">
                          {media.type === "image" ||
                          media.contentType === "image" ? (
                            <img
                              key={index}
                              src={media.previewBlob || media?.url}
                              alt="File Icon"
                              loading="lazy"
                            />
                          ) : media.type === "doc" ? (
                            <img
                              key={index}
                              src="/assets/img/pdf_icon.png"
                              alt="Pdf"
                              style={{ height: 110, width: 110 }}
                              loading="lazy"
                            />
                          ) : media.type === "video" ||
                            media?.contentType === "video" ? (
                            <video
                              muted
                              autoPlay
                              key={index}
                              src={media.previewBlob || media?.url}
                            />
                          ) : media.type === "audio" ||
                            media.contentType === "audio" ? (
                            // <audio
                            //   key={index}
                            //   src={media.previewBlob}
                            //   controls="controls"
                            // />
                            <img
                              src="/assets/img/mic.png"
                              alt="Add"
                              style={{ height: 110, width: 110 }}
                              loading="lazy"
                            />
                          ) : null}
                          <Button
                            onClick={() => _deleteContent(index)}
                            className="deleteVaultMedia"
                            disabled={loadingState?.isLoading}
                          >
                            <svg
                              id="blocking_5949247"
                              xmlns="http://www.w3.org/2000/svg"
                              width="256"
                              height="256"
                              viewBox="0 0 256 256"
                            >
                              <g id="object_80_">
                                <g id="Group_32" data-name="Group 32">
                                  <path
                                    id="Path_58"
                                    data-name="Path 58"
                                    d="M218.242,37.758A127.622,127.622,0,0,0,37,217.486q.376.379.756.756A127.622,127.622,0,0,0,219,38.514Q218.621,38.134,218.242,37.758ZM128,238A110,110,0,1,1,238,128,110.127,110.127,0,0,1,128,238Z"
                                  />
                                </g>
                                <g id="Group_33" data-name="Group 33">
                                  <path
                                    id="Path_59"
                                    data-name="Path 59"
                                    d="M176.8,79.205a8.81,8.81,0,0,0-12.459,0L128,115.541,91.664,79.205A8.81,8.81,0,0,0,79.2,91.665L115.54,128,79.2,164.337a8.81,8.81,0,1,0,12.4,12.514l.055-.055L128,140.461,164.336,176.8A8.81,8.81,0,0,0,176.8,164.337L140.459,128,176.8,91.665a8.811,8.811,0,0,0,0-12.46Z"
                                  />
                                </g>
                              </g>
                            </svg>
                          </Button>

                          {/* file name */}
                          {/* {media.uploadData && media.uploadData.name
                            ? media.uploadData.name
                            : null} */}
                        </div>
                      </div>
                    ))
                  : null}
              </div>
            </FormGroup>

            <div className="d-flex justify-content-center mb-3">
              <Button
                className="cancelBtn mx-2 mt-0"
                onClick={() => history.replace("/vault")}
              >
                Cancel
              </Button>

              <Button
                className="themeBtn saveBtn mx-2 mt-0"
                onClick={() => _createVault()}
                disabled={loadingState?.isLoading}
              >
                {loadingState?.isLoading ? (
                  <i className="fa fa-spinner fa-spin mr-1" />
                ) : null}{" "}
                Create
              </Button>
            </div>
          </Col>
        </Row>
      </Container>

      <MediaLibraryModal
        isOpen={isMediaLibraryModalOpen}
        toggle={() => _toggleMediaLibraryModal()}
        uploadContents={(contents) => _uploadFiles(contents)}
        isMultipleImages={true}
        isMultipleVideos={true}
        isMultipleAudios={true}
        isMultipleUpload={true}
        isAudioUpload={true}
        isVideoUpload={true}
        isImageUpload={true}
        isUploadLimit={false}
        // imageLimit={}
        // videoLimit={}
        // audioLimit={}
        uploadedFiles={vaultMedia}
        shouldUploadInModal={false}
      />

      <MediaLibraryModal
        isOpen={isModalOpenForCoverImage}
        toggle={() => _toggleModalForCoverImage()}
        uploadContents={(contents) =>
          _updateCoverImageAndPreview("coverImage", contents)
        }
        isMultipleImages={false}
        isMultipleVideos={false}
        isMultipleAudios={false}
        isMultipleUpload={false}
        isImageUpload={true}
        isUploadLimit={true}
        imageLimit={1}
        uploadedFiles={vaultMedia}
        shouldUploadInModal={false}
      />
      <MediaLibraryModal
        isOpen={isModalOpenForPreviewVideo}
        toggle={() => _toggleModalForPreviewVideo()}
        uploadContents={(contents) =>
          _updateCoverImageAndPreview("previewVideo", contents)
        }
        isMultipleImages={false}
        isMultipleVideos={false}
        isMultipleAudios={false}
        isMultipleUpload={false}
        isUploadLimit={true}
        isVideoUpload={true}
        videoLimit={1}
        uploadedFiles={vaultMedia}
        shouldUploadInModal={false}
      />
    </div>
  );
};

export default VaultAddPage;
