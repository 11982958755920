import React from "react";
import Mentions from "rc-mentions";
import { DEFAULT_PROFILE_PICTURE } from "../config";
import { errorHandler, getLowResolutionLink } from "../helper-methods";
import { searchInfluencersToMention } from "../http-calls";
import { Button } from "reactstrap";
import Picker, { SKIN_TONE_NEUTRAL } from "emoji-picker-react";
import { useRef } from "react";
import { useState } from "react";

const MentionUserInput = ({
  textAreaRef,
  isShowEmoji,
  maxTextLength,
  placeholder,
  rows,
  value: propsValue,
  className,
  onChange,
}) => {
  const searchMentionCreatorTimerRef = useRef({ current: null });

  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [isEmojiSectionShown, setIsEmojiSectionShown] = useState(false);
  const [isIntroductionFieldFocus, setIsIntroductionFieldFocus] =
    useState(false);
  const [usersList, setUsersList] = useState([]);

  const _onChange = (value, isEmoji = false) => {
    if (maxTextLength && value?.length > maxTextLength) {
      if (propsValue?.length >= maxTextLength)
        value = propsValue.slice(0, maxTextLength);
      else
        value = propsValue + value.slice(0, maxTextLength - propsValue?.length);
    }

    if (isEmoji) {
      if (maxTextLength && propsValue?.length > maxTextLength - 2) return;
      value = propsValue + value;
    }

    onChange(value);
  };

  const _searchInfluencersToMention = async (key) => {
    try {
      const res = await searchInfluencersToMention({ search: key ? key : "" });

      setUsersList((prev) =>
        res?.influencers?.filter((each) => each?.username?.includes(key))
      );
      setLoading(false);
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  const _onSearch = (search) => {
    if (searchMentionCreatorTimerRef?.current)
      clearTimeout(searchMentionCreatorTimerRef.current);

    setSearch(search);
    setLoading(true);
    setUsersList([]);

    searchMentionCreatorTimerRef.current = setTimeout(() => {
      _searchInfluencersToMention(search);
    }, 1000);
  };

  const _emojiSectionToggle = (isShow = false) => {
    setIsEmojiSectionShown(isShow);
  };

  const _onEmojiClick = (emoji) => {
    _onChange(emoji, true);
  };

  const _onAboutFieldFocus = (isFocus = false) => {
    setIsIntroductionFieldFocus(isFocus);
    setIsEmojiSectionShown(false);
  };

  return (
    <>
      <div
        className={`react_mentions position-relative ${
          className ? className : ""
        }`}
      >
        <Mentions
          ref={textAreaRef}
          onSearch={_onSearch}
          onChange={_onChange}
          value={propsValue}
          rows={rows ? rows : 1}
          placeholder={placeholder || ""}
          onFocus={() =>
            maxTextLength
              ? _onAboutFieldFocus(true)
              : _emojiSectionToggle(false)
          }
          onBlur={() =>
            maxTextLength ? _onAboutFieldFocus() : _emojiSectionToggle(false)
          }
          autoFocus={true}
        >
          {loading ? (
            <Mentions.Option
              value={search}
              disabled={true}
              className="searchingMention"
            >
              Searching {`'@${search}'`}...
            </Mentions.Option>
          ) : usersList?.length ? (
            usersList.map(({ username, profilePicture }) => (
              <Mentions.Option
                key={username}
                value={username}
                className="dynamic-option"
              >
                <img
                  src={
                    getLowResolutionLink(profilePicture) ||
                    DEFAULT_PROFILE_PICTURE
                  }
                  alt={username}
                  onError={(e) => (e.target.src = DEFAULT_PROFILE_PICTURE)}
                  loading="lazy"
                />
                <span>@{username}</span>
              </Mentions.Option>
            ))
          ) : (
            <Mentions.Option
              key={"notfound"}
              disabled={true}
              className="mentionNotFound"
            >
              Not found
            </Mentions.Option>
          )}
        </Mentions>

        {maxTextLength && (isIntroductionFieldFocus || isEmojiSectionShown) ? (
          <div className="textRemaining">
            {maxTextLength - propsValue?.length} left
          </div>
        ) : null}

        {isShowEmoji ? (
          <Button
            className="emojiBtnCreatePost"
            onClick={() => _emojiSectionToggle(!isEmojiSectionShown)}
          >
            <i className="fa fa-smile-o" />
          </Button>
        ) : null}
      </div>
      {isEmojiSectionShown ? (
        <Picker
          onEmojiClick={(event, emojiObject) =>
            _onEmojiClick(emojiObject.emoji)
          }
          skinTone={SKIN_TONE_NEUTRAL}
        />
      ) : null}
    </>
  );
};

export default MentionUserInput;
