// { name: "United States", dial_code: "+1", code: "US" }
// { name: "Canada", dial_code: "+1", code: "CA" }
// { name: "Singapore", dial_code: "+65", code: "SG" }
// { name: "United Kingdom", dial_code: "+44", code: "GB" }
export const instantPaymentCountry = {
  all: ["US", "CA", "SG"], // GB
  byDebitCard: ["US", "CA", "SG"],
  byBankAccount: [], // GB
};

export const bitCoinTypesConfig = [
  { label: "BTC", value: "BTC" },
  { label: "ETH", value: "ETH" },
  { label: "SOL", value: "SOL" },
];

export const tierMonthValidation = {
  greater: {
    price: [],
    threeMonthsPrice: ["price"],
    sixMonthsPrice: ["price", "threeMonthsPrice"],
    nineMonthsPrice: ["price", "threeMonthsPrice", "sixMonthsPrice"],
    twelveMonthsPrice: [
      "price",
      "threeMonthsPrice",
      "sixMonthsPrice",
      "nineMonthsPrice",
    ],
  },
  lesser: {
    price: [
      "threeMonthsPrice",
      "sixMonthsPrice",
      "nineMonthsPrice",
      "twelveMonthsPrice",
    ],
    threeMonthsPrice: [
      "sixMonthsPrice",
      "nineMonthsPrice",
      "twelveMonthsPrice",
    ],
    sixMonthsPrice: ["nineMonthsPrice", "twelveMonthsPrice"],
    nineMonthsPrice: ["twelveMonthsPrice"],
    twelveMonthsPrice: [],
  },
};

export const withOtherTierMonthValidation = {
  greater: {
    basic: [],
    plus: ["basic"],
    premium: ["basic", "plus"],
  },
  lesser: {
    basic: ["plus", "premium"],
    plus: ["premium"],
    premium: [],
  },
};

export const customExpirationDays = [
  {
    day: "1",
    label: "1 Day",
  },
  {
    day: "3",
    label: "3 Days",
  },
  {
    day: "7",
    label: "7 Days",
  },
  {
    day: "30",
    label: "30 Days",
  },
  {
    day: "0",
    label: "No Limit",
  },
];

export const notificationTab = [
  { label: "All Activities", value: "all" },
  { label: "Comments", value: "comment" },
  { label: "Likes", value: "like" },
  { label: "Subscribers", value: "subscription" },
  { label: "Earned", value: "earned" },
  { label: "Invites", value: "invites" },
  { label: "Alerts", value: "alerts" },
];

export const contentFilterOptionsConfig = [
  {
    label: "All Time",
    value: "allTime",
  },
  {
    label: "Last 90 days",
    value: "last90days",
  },
  {
    label: "Last 30 days",
    value: "last30days",
  },
  {
    label: "Last 7 days",
    value: "last7Days",
  },
];

export const contentSortOptionsConfig = [
  {
    label: "Latest Posts",
    value: "latestPosts",
  },
  {
    label: "Most Liked",
    value: "mostLiked",
  },
  {
    label: `Highest Tip`,
    value: "highestTips",
  },
];

export const referralHistoryStatusConfig = [
  {
    label: "Pending",
    value: "pending",
  },
  {
    label: "Needs Reminder",
    value: "reminder",
  },
  {
    label: "Accepted",
    value: "accepted",
  },
  {
    label: `Oldest`,
    value: "oldest",
  },
  {
    label: `Newest`,
    value: "newest",
  },
];

export const defaultDescription =
  "Want to get instant access to my most exclusive content? Click on a folder below and unlock it now!";

export const pushNotificationsConfig = [
  { label: "New Comment", value: "comment" },
  { label: "New Like", value: "like" },
  { label: "Tip", value: "tip" },
  { label: "New Subscription", value: "newSubscription" },
  { label: "Subscription Cancellation", value: "cancelSubscription" },
  { label: "Payout Processed", value: "payoutProcessed" },
  { label: "Refferal Accepted", value: "referralAccepted" },
  // { label: "Likes Count", value: "isLikeEnabled" },
];

export const emailNotificationsConfig = [
  { label: "Tip", value: "tip" },
  { label: "New Subscription", value: "newSubscription" },
  { label: "Subscription Cancellation", value: "cancelSubscription" },
  { label: "Payout Processed", value: "payoutProcessed" },
  { label: "Refferal Accepted", value: "referralAccepted" },
  { label: "Recieve Daily Digest", value: "dailyDigest" },
  // { label: "Likes Count", value: "isLikeEnabled" },
];

export const securitySettingsTooltipsConfig = [
  {
    label: "Enable Comments",
    value: "enableComments",
    tooltipValue: "Turn this on to enable comments on posts",
  },
  {
    label: "Show Fans Count On Your Profile",
    value: "showFanCountOnProfile",
    tooltipValue: "Turn this on to show the number of fanz subscribed to you",
  },
  {
    label: "Show Likes Count",
    value: "isLikeEnabled",
    tooltipValue:
      "Turn this on to show the number of Likes you get in a post in Fan App.",
  },
  {
    label: "",
    value: "isFaceTimeEnabled",
    tooltipValue: "",
  },
  {
    label: "Screenshot Detection",
    value: "takeScreenshot",
    tooltipValue: "Turn this on to enable screenshot detection.",
  },
  {
    label: "Caption Privacy",
    value: "captionPrivacy",
    tooltipValue:
      "When enabled, Non-subscribers will not be able to view caption until they subscribe.",
  },
  {
    label: "Show Fans Your Full Name",
    value: "showFullName",
    tooltipValue: "Turn this on to show your full name to the fanz",
  },
  // {
  //   label: "Show Post Tip Summary",
  //   value: "showPostTipSummary",
  //   tooltipValue: "",
  // },
  // {
  //   label: "Public Friends List",
  //   value: "publicFriendsList",
  //   tooltipValue: "",
  // },
];

export const deleteAccountWarning =
  "You will not be able to run subscriptions for your followers or earn money from Live Events, PPV etc. Are you sure, you want to delete your account?";

export const socialAccountKeys = [
  "twitter",
  "youtube",
  "facebook",
  "tiktok",
  "instagram",
];

export const videoResolutionsConfig = {
  threeTwoZero: 320,
  fiveFourZero: 540,
  sevenTwoZero: 720,
  oneZeroEightZero: 1080,
};

export const bitrateConfig = {
  fiveFourZero: 200000,
  oneZeroEightZero: 400000,
  sevenTwoZero: 800000,
  threeTwoZero: 1500000,
};

export const MonthsWithDays = {
  1: 31,
  2: 28,
  3: 31,
  4: 30,
  5: 31,
  6: 30,
  7: 31,
  8: 31,
  9: 30,
  10: 31,
  11: 30,
  12: 31,
};

export const mediaLibraryFilterOptionsConfig = [
  {
    label: "All Time",
    value: "allTime",
  },
  {
    label: "Last 90 days",
    value: "last90days",
  },
  {
    label: "Last 30 days",
    value: "last30days",
  },
  {
    label: "Last 7 days",
    value: "last7Days",
  },
  {
    label: "Custom",
    value: "customRange",
  },
];

export const mediaLibrarySortOptionsConfig = [
  {
    label: "By Size",
    value: "size",
  },
  {
    label: "Date Uploaded",
    value: "createdAt",
  },
];

export const mediaLibrarySortByDurationConfig = [
  // { label: "0-1 minute", value: "0-1" },
  { label: "All duration", value: "allDuration" },
  { label: "0-5 minutes", value: "0-300" },
  { label: "5-15 minutes", value: "300-900" },
  { label: "15+ minutes", value: "900" },
];
