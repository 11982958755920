import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Alert, Button } from "reactstrap";
import { PAYMENT_GATEWAY } from "../config";
import { errorHandler, getTimeDifferenceInMinutes } from "../helper-methods";
import { getUserKycLink } from "../http-calls";
import { fetchAndModifiedUserDetails } from "../redux/actions/userData";
import { APP_NAME } from "../config";

const KYCAlertCard = () => {
  const dispatch = useDispatch();

  const userData = useSelector((state) => state?.userData);

  const [loading, setLoading] = useState(false);

  const _getAndRedirectUserKycLink = async () => {
    try {
      setLoading(true);

      const res = await getUserKycLink();

      setLoading(false);

      if (!res?.kycLink?.url) {
        return;
      }

      dispatch(fetchAndModifiedUserDetails());

      // openUrlOnNewTab(res.kycLink.url);
      // let windowReference = window.open();
      // windowReference.location = res.kycLink.url;
      window.open(res.kycLink.url, "_self");
    } catch (error) {
      errorHandler({
        error: true,
        reason: "Server error. Try again after sometime.",
      });
      setLoading(false);
    }
  };

  if (
    PAYMENT_GATEWAY !== "stripe" ||
    !userData?.user ||
    !userData?.user?.hasOwnProperty("paymentEnabled") ||
    userData?.user?.paymentEnabled ||
    userData?.user?.isScheduleForAccountDelete
  )
    return <></>;

  return userData?.user?.hasOwnProperty("lastKYCSubmitted") &&
    userData?.user?.lastKYCSubmitted &&
    getTimeDifferenceInMinutes(userData.user.lastKYCSubmitted) < 30 ? (
    <Alert color="warning">
      <i className="fa fa-exclamation-triangle alertIconDanger" />
      {`Your account verification is in progress. ${APP_NAME} cannot accept payments on your behalf currently.`}
    </Alert>
  ) : (
    <Alert color="danger">
      <i className="fa fa-exclamation-triangle alertIconDanger" />
      {`${APP_NAME} cannot accept payments on your behalf currently. Please fill the needed information `}
      <b>correctly</b> to active payments for your account.
      <hr style={{ margin: "0.9rem 0" }} />
      <Button
        className="themeBtn addBtn"
        disabled={loading}
        onClick={() => _getAndRedirectUserKycLink()}
      >
        {loading ? <i className="fa fa-spinner fa-spin mr-2" /> : null} Proceed
      </Button>
    </Alert>
  );
};

export default KYCAlertCard;
