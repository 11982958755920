import React from "react";
import { Button, Modal, ModalBody } from "reactstrap";

const CustomPrompt = ({
  isOpen,
  message,
  metadata = {},
  isEmphasisNo,
  className = "",
  closeButtonText,
  successButtonText,
  onDismiss,
  onSuccess,
}) => {
  const _onDismiss = () => {
    onDismiss();
  };

  const _onSuccess = () => {
    onSuccess(metadata);
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => _onDismiss()}
      className={`modal-dialog-centered modal-dialog-scrollable ${className}`}
    >
      {message?.length ? (
        <ModalBody className="py-4 text-center">
          <h4 className="mb-3 fs-16">{message}</h4>

          <div className="mt-5">
            <Button
              className={isEmphasisNo ? "modalBtnYes mr-2" : "modalBtnNo"}
              onClick={() => _onDismiss()}
            >
              {closeButtonText || "No"}
            </Button>

            <Button
              className={isEmphasisNo ? "modalBtnNo mr-0" : "modalBtnYes"}
              onClick={() => _onSuccess()}
            >
              {successButtonText || "Yes"}
            </Button>
          </div>
        </ModalBody>
      ) : null}
    </Modal>
  );
};

export default CustomPrompt;
