export const RegexConfig = {
  // name: /^[A-Za-z][\w!@#$%^&*()_+{}[\]:;"'<>,.?/|`~\s-]*$/, //for variables name type convention
  name: /^[A-Za-z'\s-.]+(\s?[A-Za-z'\s-.]+)*$/,
  phone: /^(\+\d{1,3}[- ]?)?\d{8,11}$/,
  email:
    /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  url: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\\.-]+)+[\w\-\\._~:/?#[\]@!\\$&'\\(\\)\\*\\+,;=.]+$/,
  digitOnly: /^[0-9]*$/,
  ipAddress:
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
  withoutSpaceText: /^\S+$/,
  isoDate: /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+/,
  panNumber: /(^([a-zA-Z]{5})([0-9]{4})([a-zA-Z]{1})$)/,
  password: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
  username: /^[a-z0-9_-]{3,16}$/,
};
