import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { getPosts, getVaults } from "../../http-calls";

const deletePost = createAction("deletePost");
const updateAPost = createAction("updateAPost");
const updateList = createAction("updateList");
const updateVaultKey = createAction("updateVaultKey");
const clearAllFeedData = createAction("clearAllFeedData");

const fetchPosts = createAsyncThunk(
  "feedData/fetchPosts",
  async (payload, thunkAPI) => {
    const res = await getPosts(payload);

    return {
      posts: res.posts,
      total: res.total,
      isConcat: payload?.skip > 0 ? true : false,
    };
  }
);

const getAndUpdateVaults = createAsyncThunk(
  "feedData/getAndUpdateVaults",
  async (payload, thunkAPI) => {
    const res = await getVaults(payload);

    return {
      vaults: res?.vault,
      vaultsCount: res?.count,
      isConcat: payload?.pageNumber > 1 ? true : false,
    };
  }
);

export {
  getAndUpdateVaults,
  updateVaultKey,
  fetchPosts,
  clearAllFeedData,
  deletePost,
  updateAPost,
  updateList,
};
