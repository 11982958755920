import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  InputGroupText,
  Input,
  InputGroup,
  InputGroupAddon,
  CustomInput,
} from "reactstrap";
import {
  deepClone,
  isSubscriptionPriceExist,
  TierAmountValidation,
} from "../../helper-methods";
import CustomTooltip from "../custom/CustomTooltip";
import { AmountConfig } from "../../config/appConfig";
import { CURRENCY_SYMBOL } from "../../config";

class PostCostModal extends Component {
  state = {
    formFields: {
      price: {
        value: "0",
        isValid: false,
        isDirty: false,
        error: "",
      },
      plusPrice: {
        value: "0",
        isValid: false,
        isDirty: false,
        error: "",
      },
      premiumPrice: {
        value: "0",
        isValid: false,
        isDirty: false,
        error: "",
      },
    },
    isFreeContentForAll: false,
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.isOpen && prevProps.isOpen !== this.props.isOpen) {
      this.setState({
        formFields: {
          price: {
            value: this.props.price ? this.props.price.toString() : "0",
            isValid: false,
            isDirty: false,
            error: "",
          },
          plusPrice: {
            value: this.props.plusPrice ? this.props.plusPrice.toString() : "0",
            isValid: false,
            isDirty: false,
            error: "",
          },
          premiumPrice: {
            value: this.props.premiumPrice
              ? this.props.premiumPrice.toString()
              : "0",
            isValid: false,
            isDirty: false,
            error: "",
          },
        },
        isFreeContentForAll: this.props.isFreeContentForAll || false,
      });
    }
  };

  _initializeForm = () => {
    let { formFields } = deepClone(this.state);

    formFields = {
      price: {
        value: "0",
        isValid: false,
        isDirty: false,
      },
      plusPrice: {
        value: "0",
        isValid: false,
        isDirty: false,
      },
      premiumPrice: {
        value: "0",
        isValid: false,
        isDirty: false,
      },
    };

    this.setState({
      formFields,
    });
  };

  _markAsDirty = (fieldName) => {
    const { formFields } = this.state;
    formFields[fieldName].isDirty = true;
    this.setState({ formFields }, () => this._validateForm());
  };

  _updateFieldValue = (key, value) => {
    let { formFields } = this.state;

    if (key === "price" || key === "plusPrice" || key === "premiumPrice") {
      if (
        isNaN(value) ||
        (value.includes(".") && value.split(".")[1].length > 2)
      )
        return;
    }

    formFields[key].value = value;

    this.setState({ formFields, isFreeContentForAll: false }, () => {
      if (formFields[key].isDirty) {
        // Validate
        this._validateForm();
      }
    });
  };

  _validateForm = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      const { multiTierSubscription } = deepClone(this.props);

      let isFormValid = true;

      Object.keys(formFields).forEach((fieldName, index) => {
        switch (fieldName) {
          case "price": {
            if (+formFields?.price.value > +AmountConfig.maximumLimit) {
              formFields.price.error = `Maximum ${CURRENCY_SYMBOL}${AmountConfig.maximumLimit}`;
              formFields.price.isValid = false;
              isFormValid = false;
              break;
            }

            if (
              TierAmountValidation(
                formFields.price.value,
                formFields.plusPrice.value,
                formFields.premiumPrice.value
              ) &&
              String(formFields.price.value).length &&
              !isNaN(Number(formFields.price.value)) &&
              Number(formFields.price.value) >= 0
            ) {
              formFields.price.isValid = true;
            } else {
              formFields.price.isValid = false;
              isFormValid = false;
              if (multiTierSubscription) {
                formFields.price.error =
                  "Basic price should be greater or equal to plus and premium price";
              } else {
                formFields.price.error =
                  "Price should be greater or equal to zero";
              }
            }
            break;
          }

          case "plusPrice": {
            if (multiTierSubscription && isSubscriptionPriceExist("plus")) {
              if (+formFields?.plusPrice.value > AmountConfig.maximumLimit) {
                formFields.plusPrice.error = `Maximum ${CURRENCY_SYMBOL}${AmountConfig.maximumLimit}`;
                formFields.plusPrice.isValid = false;
                isFormValid = false;
                break;
              }

              if (
                TierAmountValidation(
                  formFields.price.value,
                  formFields.plusPrice.value,
                  formFields.premiumPrice.value
                ) &&
                String(formFields.plusPrice.value).length &&
                !isNaN(Number(formFields.plusPrice.value)) &&
                Number(formFields.plusPrice.value) >= 0
              ) {
                formFields.plusPrice.isValid = true;
              } else {
                if (+formFields.plusPrice.value > +formFields.price.value) {
                  formFields.plusPrice.isValid = false;
                  isFormValid = false;
                  formFields.plusPrice.error =
                    "Plus price should be less or equal to basic price";
                } else {
                  formFields.plusPrice.isValid = true;
                }
              }
            } else {
              formFields.plusPrice.isValid = true;
            }
            break;
          }
          case "premiumPrice": {
            if (multiTierSubscription && isSubscriptionPriceExist("premium")) {
              if (+formFields?.premiumPrice.value > AmountConfig.maximumLimit) {
                formFields.premiumPrice.error = `Maximum ${CURRENCY_SYMBOL}${AmountConfig.maximumLimit}`;
                formFields.premiumPrice.isValid = false;
                isFormValid = false;
                break;
              }

              if (
                TierAmountValidation(
                  formFields.price.value,
                  formFields.plusPrice.value,
                  formFields.premiumPrice.value
                ) &&
                String(formFields.premiumPrice.value).length &&
                !isNaN(Number(formFields.premiumPrice.value)) &&
                Number(formFields.premiumPrice.value) >= 0
              ) {
                formFields.premiumPrice.isValid = true;
              } else {
                if (
                  +formFields.premiumPrice.value > +formFields.plusPrice.value
                ) {
                  formFields.premiumPrice.isValid = false;
                  isFormValid = false;
                  formFields.premiumPrice.error =
                    "Premium price should be less or equal to plus.";
                } else if (
                  +formFields.premiumPrice.value > +formFields.price.value
                ) {
                  formFields.premiumPrice.isValid = false;
                  isFormValid = false;
                  formFields.premiumPrice.error =
                    "Premium price should be less or equal to basic price.";
                } else {
                  formFields.premiumPrice.isValid = true;
                }
              }
            } else {
              formFields.plusPrice.isValid = true;
            }
            break;
          }
          default:
        }
      });

      this.setState({ formFields }, () => {
        resolve(isFormValid);
      });
    });
  };

  _makeAllFieldDirty = () => {
    return new Promise((resolve) => {
      const { formFields } = this.state;
      Object.keys(formFields).forEach((fieldName, index) => {
        formFields[fieldName].isDirty = true;
      });
      this.setState({ formFields }, () => resolve(true));
    });
  };

  _savePrice = async () => {
    const { formFields, isFreeContentForAll } = this.state;

    await this._makeAllFieldDirty();

    const isFormValid = await this._validateForm();

    if (!isFormValid) {
      return;
    } else {
      let basic = formFields["price"]["value"];
      let plus = formFields["plusPrice"]["value"];
      let premium = formFields["premiumPrice"]["value"];

      this.props.onSave({ basic, plus, premium, isFreeContentForAll });
      // this._initializeForm();
    }
  };

  _saveWelcomePrice = () => {
    const { formFields, isFreeContentForAll } = this.state;
    let basic = formFields["price"]["value"];
    this.props.onSave({ basic, isFreeContentForAll });
  };

  _closeModal = () => {
    // this._initializeForm();
    this.props.onClose();
  };

  _toggleIsFreeContentForAll = (isFreeContentForAll = false) => {
    const { formFields } = this.state;
    if (isFreeContentForAll === true) {
      formFields.price.value = "0";
      formFields.price.isDirty = false;
      formFields.price.isValid = true;
      formFields.price.error = null;

      formFields.plusPrice.value = "0";
      formFields.plusPrice.isDirty = false;
      formFields.plusPrice.isValid = true;
      formFields.plusPrice.error = null;

      formFields.premiumPrice.value = "0";
      formFields.premiumPrice.isDirty = false;
      formFields.premiumPrice.isValid = true;
      formFields.premiumPrice.error = null;
    }
    this.setState({ isFreeContentForAll });
  };

  render() {
    const { formFields, isFreeContentForAll } = deepClone(this.state);
    const { multiTierSubscription, isFreeContentPreviewOn } = this.props;

    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={() => this._closeModal()}
        className="modal-dialog-centered modal-dialog-scrollable"
      >
        <ModalHeader toggle={() => this._closeModal()}>Post Price</ModalHeader>

        <ModalBody className="px-4">
          {isFreeContentPreviewOn ? (
            <div className="d-flex mb-4">
              <CustomInput
                type="checkbox"
                id="post_cost_modal_Free_for_all"
                label="Free preview"
                checked={isFreeContentForAll}
                onChange={(e) =>
                  this._toggleIsFreeContentForAll(e.target.checked)
                }
              />
              <div>
                <sup
                  id="post_cost_modal_Free_for_all_tooltip"
                  className="infoIcon"
                >
                  <i className="fa fa-info-circle" />
                </sup>
                <CustomTooltip
                  text={`Selecting free preview enables the content to be free and visible to both subscribers and non subscribers.`}
                  target="post_cost_modal_Free_for_all_tooltip"
                />
              </div>
            </div>
          ) : null}

          {!this.props.hasOwnProperty("type") ? (
            <>
              <div className="mb-4">
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText
                      className="dollarWrap"
                      style={{ width: 100 }}
                    >
                      Basic
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="text"
                    value={formFields.price.value}
                    name="input1-group1"
                    placeholder="Enter 0 for Free"
                    onChange={(e) =>
                      this._updateFieldValue("price", e.target.value)
                    }
                    onBlur={() => this._markAsDirty("price")}
                    style={{ paddingLeft: 25 }}
                    className="dollarWrapInput"
                  />
                  <i className="fa fa-dollar currencyPostPrice" />
                </InputGroup>
                <div className="form-error">
                  {formFields["price"]["isDirty"] &&
                  !formFields["price"]["isValid"]
                    ? // ? "Cost should be a number and greater than 1"
                      formFields.price.error
                    : ""}
                </div>
              </div>

              {/* Plus Price */}
              {/* For Support Multi tier */}
              {multiTierSubscription && isSubscriptionPriceExist("plus") ? (
                <div className="mb-4">
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText
                        className="dollarWrap"
                        style={{ width: 100 }}
                      >
                        Plus
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="text"
                      value={formFields.plusPrice.value}
                      name="input1-group1"
                      placeholder="Enter 0 for Free"
                      onChange={(e) =>
                        this._updateFieldValue("plusPrice", e.target.value)
                      }
                      onBlur={() => this._markAsDirty("plusPrice")}
                      style={{ paddingLeft: 25 }}
                      className="dollarWrapInput"
                    />
                    <i className="fa fa-dollar currencyPostPrice" />
                  </InputGroup>
                  <div className="form-error">
                    {formFields["plusPrice"]["isDirty"] &&
                    !formFields["plusPrice"]["isValid"]
                      ? // ? "Cost should be a number and greater than 1"
                        formFields.plusPrice.error
                      : ""}
                  </div>
                </div>
              ) : null}

              {/* premium Price */}
              {/* For Support Multi tier */}
              {multiTierSubscription && isSubscriptionPriceExist("premium") ? (
                <div>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText
                        className="dollarWrap"
                        style={{ width: 100 }}
                      >
                        Premium
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="text"
                      value={formFields.premiumPrice.value}
                      name="input1-group1"
                      placeholder="Enter 0 for Free"
                      onChange={(e) =>
                        this._updateFieldValue("premiumPrice", e.target.value)
                      }
                      onBlur={() => this._markAsDirty("premiumPrice")}
                      style={{ paddingLeft: 25 }}
                      className="dollarWrapInput"
                    />
                    <i className="fa fa-dollar currencyPostPrice" />
                  </InputGroup>
                  <div className="form-error">
                    {formFields["premiumPrice"]["isDirty"] &&
                    !formFields["premiumPrice"]["isValid"]
                      ? // ? "cost should be number and greater than 1"
                        formFields.premiumPrice.error
                      : ""}
                  </div>
                </div>
              ) : null}
            </>
          ) : (
            <>
              <div className="mb-4">
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText className="dollarWrap">
                      <i className="fa fa-dollar" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="number"
                    value={formFields.price.value}
                    name="input1-group1"
                    placeholder="Enter 0 for Free"
                    onChange={(e) =>
                      this._updateFieldValue("price", e.target.value)
                    }
                    className="dollarWrapInput"
                    onBlur={() => this._markAsDirty("price")}
                  />
                </InputGroup>
                <div className="form-error">
                  {formFields["price"]["isDirty"] &&
                  !formFields["price"]["isValid"]
                    ? formFields.price.error
                    : ""}
                </div>
              </div>
            </>
          )}
        </ModalBody>

        <ModalFooter>
          {!this.props.hasOwnProperty("type") ? (
            <>
              <Button
                className="modalBtnCancel"
                onClick={() => this._closeModal()}
              >
                Cancel
              </Button>
              <Button
                className="modalBtnSave"
                disabled={
                  !formFields.price.value &&
                  !formFields.plusPrice.value &&
                  !formFields.premiumPrice.value
                }
                onClick={() => this._savePrice()}
              >
                Save
              </Button>
            </>
          ) : (
            <>
              <Button
                className="modalBtnCancel"
                onClick={() => this._closeModal()}
              >
                Cancel
              </Button>
              <Button
                className="modalBtnSave"
                onClick={() => this._saveWelcomePrice()}
              >
                Save
              </Button>
            </>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

export default PostCostModal;
