import React, { useMemo, useState } from "react";
import {
  Button,
  FormGroup,
  Input,
  Label,
  Card,
  CardBody,
  Row,
  Col,
} from "reactstrap";
import {
  errorHandler,
  isPermissionToAccess,
  showToast,
} from "../../helper-methods";
import { RegexConfig } from "../../config/RegexConfig";
import { countryCodes } from "../../config/country-codes";
import { States } from "../../config/states";
import { updateInfluencerDetails } from "../../http-calls";
import { updateOnlyUserData } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import CustomTooltip from "../custom/CustomTooltip";

const SettingsAddress = () => {
  const dispatch = useDispatch();
  const influencer = useSelector((state) => state?.userData?.user);

  const [formFields, setFormFields] = useState({
    city: influencer?.address?.city?.length ? influencer?.address?.city : "",
    country: influencer?.address?.country?.length
      ? influencer?.address?.country
      : "US",
    state: influencer?.address?.state?.length ? influencer?.address?.state : "",
    street: influencer?.address?.street?.length
      ? influencer?.address?.street
      : "",
    zip: influencer?.address?.zip?.length ? influencer?.address?.zip : "",
  });

  const canUpdateProfileSettings = useMemo(
    () =>
      isPermissionToAccess(
        "profileAndSettings",
        "canUpdateProfileSettings",
        true
      ),
    []
  );

  const [errors, setErrors] = useState({});
  const [isDirty, setIsDirty] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  // on fields blur, update their dirty true then validate
  const _onBlurHandler = (key) => {
    let newFormFields = { ...formFields };
    let newIsDirty = { ...isDirty };
    newIsDirty[key] = true;
    setIsDirty(newIsDirty);

    _validateForm({ newFormFields, newIsDirty });
  };

  // marking all formfields dirty
  const _markAllIsDirty = () => {
    return new Promise((resolve) => {
      const newIsDirty = { ...isDirty };
      const newFormFields = { ...formFields };
      Object.keys(newFormFields).forEach((key) => {
        newIsDirty[key] = true;
      });
      setIsDirty(newIsDirty);
      resolve(newIsDirty);
    });
  };

  const _updateFieldValue = (key, value) => {
    if (!canUpdateProfileSettings) return;

    if (
      key === "zip" &&
      (isNaN(value) || value.includes(".") || Number(value) < 0)
    ) {
      return;
    }

    let newFormFields = { ...formFields };
    newFormFields[key] = value;

    if (key === "country") {
      newFormFields["state"] = "";
    }

    setFormFields(newFormFields);
  };

  const _validateForm = ({ newFormFields, newIsDirty }) => {
    const newErrors = { ...errors };
    return new Promise((resolve) => {
      let isFormValid = true;

      Object.keys(newFormFields).forEach((key) => {
        switch (key) {
          case "street": {
            if (newFormFields?.street?.length) {
              newIsDirty[key] = false;
              newErrors[key] = null;
            } else {
              newErrors[key] = "Street is required";
              isFormValid = false;
            }
            break;
          }
          case "city": {
            if (newFormFields.city?.length) {
              newIsDirty[key] = false;
              newErrors[key] = null;
            } else {
              newErrors[key] = "City is required";
              isFormValid = false;
            }
            break;
          }
          case "state": {
            if (newFormFields?.state?.length) {
              newIsDirty[key] = false;
              newErrors[key] = null;
            } else {
              newErrors[key] = "State is required";
              isFormValid = false;
            }
            break;
          }
          case "zip": {
            if (newFormFields?.zip?.length) {
              if (newFormFields.country === "IN") {
                if (
                  newFormFields?.zip?.length === 6 &&
                  RegexConfig.sixDigitIndianPinCode.test(
                    String(newFormFields.zip)
                  )
                ) {
                  newIsDirty[key] = false;
                  newErrors[key] = null;
                } else {
                  newErrors[key] = "Invalid Zip/Postal Code";
                }
              } else {
                newIsDirty[key] = false;
                newErrors[key] = null;
              }
            } else {
              newErrors[key] = "Zip/Postal Code is required";
              isFormValid = false;
            }
            break;
          }
          case "country": {
            if (newFormFields?.country?.length) {
              newIsDirty[key] = false;
              newErrors[key] = null;
            } else {
              errors[key] = "Country is required";
              isFormValid = false;
            }
            break;
          }
          default: {
          }
        }
      });
      setErrors(newErrors);
      setIsDirty(newIsDirty);
      resolve(isFormValid);
    });
  };

  const _handleSubmit = async (e) => {
    if (e) e.preventDefault();
    try {
      setIsLoading(true);
      const newFormFields = { ...formFields };
      const newIsDirty = await _markAllIsDirty();
      const isFormValid = await _validateForm({ newFormFields, newIsDirty });
      if (!isFormValid) {
        setIsLoading(false);

        return errorHandler({ reason: "Fill Details Correctly" });
      }
      setIsLoading(true);
      const response = await updateInfluencerDetails({ address: formFields });
      dispatch(updateOnlyUserData(response?.user));
      showToast("Address Updated", "success");
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      errorHandler(error);
    }
  };

  return (
    <>
      <Card className="profileCard mb-2">
        <CardBody className="p-0">
          <div className="header_Settings" style={{ marginTop: 5 }}>
            <h4>
              Address
              <sup
                id="setting_address_section_address_tooltip"
                className="infoIcon"
              >
                <i className="fa fa-info-circle" />
              </sup>
              <CustomTooltip
                text={`This account information will not appear on your public page.`}
                target="setting_address_section_address_tooltip"
              />
            </h4>
          </div>

          <FormGroup>
            <Label>Street</Label>

            <Input
              type="text"
              value={formFields.street}
              disabled={!canUpdateProfileSettings}
              onChange={(e) => _updateFieldValue("street", e.target.value)}
              onBlur={() => _onBlurHandler("street")}
            />

            {errors?.street ? (
              <p className="form-error">{errors?.street}</p>
            ) : null}
          </FormGroup>

          <Row className="noMargin">
            <Col xs={6} className="noPadding pr-1">
              <FormGroup>
                <Label>City</Label>

                <Input
                  type="text"
                  placeholder=""
                  value={formFields.city}
                  disabled={!canUpdateProfileSettings}
                  onChange={(e) => _updateFieldValue("city", e.target.value)}
                  onBlur={() => _onBlurHandler("city")}
                />

                {errors?.city ? (
                  <p className="form-error">{errors?.city}</p>
                ) : null}
              </FormGroup>
            </Col>

            <Col xs={6} className="noPadding pl-1">
              <FormGroup>
                <Label>State</Label>

                {formFields?.country === "IN" ||
                formFields?.country === "US" ? (
                  <Input
                    type="select"
                    name="state"
                    value={formFields.state}
                    onChange={(e) => _updateFieldValue("state", e.target.value)}
                    onBlur={() => _onBlurHandler("state", 2)}
                  >
                    <option value="">Select State</option>
                    {States[formFields.country].map((state) => (
                      <option key={state.code} value={state.code}>
                        {state.name}
                      </option>
                    ))}
                  </Input>
                ) : (
                  <Input
                    type="text"
                    autoComplete="true"
                    placeholder="Enter State"
                    value={formFields.state}
                    onChange={(e) => _updateFieldValue("state", e.target.value)}
                    onBlur={() => _onBlurHandler("state")}
                  />
                )}

                {errors?.state ? (
                  <p className="form-error">{errors?.state}</p>
                ) : null}
              </FormGroup>
            </Col>

            <Col xs={6} className="noPadding pr-1">
              <FormGroup>
                <Label>Zip</Label>

                <Input
                  type="text"
                  placeholder=""
                  value={formFields.zip}
                  disabled={!canUpdateProfileSettings}
                  onChange={(e) => _updateFieldValue("zip", e.target.value)}
                  onBlur={() => _onBlurHandler("zip")}
                />

                {errors?.zip ? (
                  <p className="form-error">{errors?.zip}</p>
                ) : null}
              </FormGroup>
            </Col>

            <Col xs={6} className="noPadding pl-1">
              <FormGroup>
                <Label>Country</Label>

                <Input
                  type="select"
                  onChange={(e) => _updateFieldValue("country", e.target.value)}
                  value={formFields.country}
                  disabled={true}
                >
                  {countryCodes.map((countryCode, countryIndex) => (
                    <option key={countryIndex} value={countryCode.code}>
                      {countryCode.name}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
          </Row>

          {canUpdateProfileSettings ? (
            <Button
              onClick={(e) => _handleSubmit(e)}
              className="themeBtn saveBtn mt-2"
              disabled={isLoading}
            >
              {isLoading ? <i className="fa fa-spinner fa-spin mr-1" /> : null}{" "}
              Save
            </Button>
          ) : null}

          <hr
            style={{
              marginTop: 25,
              borderTopColor: "rgba(204, 204, 204, 0.5)",
            }}
          />
        </CardBody>
      </Card>
    </>
  );
};

export default SettingsAddress;
